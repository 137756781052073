
import Vue from 'vue'

import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';

export default Vue.extend({
  computed: {
    ...customMapState({
      locale: (state: IRootState) => state.i18n.locale,
    }),
  },

  mounted() {
    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      const { origin, pathname } = window.location;
      this.$store.commit(
        'setDonationIncludedGatewayParams',
        { redirect_url: `${origin}${pathname}?lang=${this.locale}` },
      );
      resolve(false);
    });
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },
})
