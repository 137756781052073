
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import { generateDeviceId } from '@/helpers/generateDeviceId';
import { countryReference } from '@/constants/dLoacalDocuments';
import { InstallmentConfig } from '@/models/IDonationParams';
import { IRootState } from '../../../models/IRootState';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  props: {
    shouldValidate: Boolean,
  },

  data() {
    return {
      document: '',

      card: null as any,
      cardEmpty: true,
      cardBrand: '',
      cardError: '',

      installmentId: '',
    };
  },

  computed: {
    ...customMapState({
      psID: (s: IRootState) => s.donation.paymentConfig.id,
      version: (s: IRootState) => s.donation.paymentConfig.attributes.configuration.version,
      country: (s: IRootState) => s.donation.donationData.attributes.address_country,
      amount: (s: IRootState) => s.donation.donationData.attributes.amount,
      config: (s: IRootState) => s.donation.donationParams.data.attributes.config,
      donationCurrency: (s: IRootState) => ({
        code: s.donation.donationData.attributes.currency,
        sign: s.donation.donationData.attributes.currency_sign,
      }),
      dlocalRef: (s: IRootState) => s.dlocal.dlocalRef,
      fieldsRef: (s: IRootState) => s.dlocal.fieldsRef,
      installments: (s: IRootState) => s.dlocal.installmentsConfig?.installments,
      createInstallmentsPlanError: (s: IRootState) => s.dlocal.createInstallmentsPlanError,
    }),

    ...mapGetters({
      getFullBillingName: 'getFullBillingName',
    }),

    documentConfig(): typeof countryReference[0] | undefined {
      return countryReference.find(el => el.country_code === this.country)
    },

    docName(): string {
      return this.documentConfig?.doc_name || ''
    },

    docFormat(): string {
      return this.documentConfig?.doc_format || ''
    },

    v2(): boolean {
      return this.version === '2';
    },

    psIDV2(): string {
      return `${this.psID}${this.version}`;
    },

    installmentConfig(): InstallmentConfig {
      return this.config.installment_config;
    },

    installmentAvailable(): boolean {
      const { available, currencies } = this.installmentConfig;

      if (available) {
        if (currencies && currencies.length) {
          return currencies.includes(this.donationCurrency.code);
        }
        return true;
      }

      return false;
    },
  },

  watch: {
    donationCurrency() {
      this.resetData();
    },
    amount() {
      this.resetData();
    },
    installmentId(id: string) {
      if (id) {
        const plan = this.installments?.find(e => e.id === id);

        if (plan) {
          const period = plan.installments
          this.$store.commit('setInstallmentPeriod', period);
          this.$store.commit('setInstallmentStatus', period > 1);
        }
      } else {
        this.$store.commit('setInstallmentPeriod', 1);
        this.$store.commit('setInstallmentStatus', false);
      }
    },
  },

  mounted() {
    this.addDLocalScript().then(() => {
      this.$watch(
        'psIDV2',
        () => {
          if (this.v2) {
            this.useDLocalSmartFields();
            this.initDLocalFields();
            this.cretePaymentForm();
          } else {
            this.resetData();
          }
        },
        { immediate: true },
      )
    })

    const getParams = () => ({
      dlocal_document_id: this.document,
      dlocal_device_id: generateDeviceId().toString(),
      dlocal_token: '',
      dlocal_installments_id: '',
    })

    if (this.v2) {
      this.$root.$on(
        'dlocalCreateAndSetToken',
        (resolve: (value?: unknown) => void) => {
          const params = getParams()

          this.$store.commit('setDonationIncludedGatewayParams', params);

          this.dlocalRef.createToken(
            this.card,
            { name: this.getFullBillingName },
          )
            .then(({ token }: any) => {
              params.dlocal_token = token
              params.dlocal_installments_id = this.installmentId

              this.$store.commit('setDonationIncludedGatewayParams', params);

              resolve(true);
            })
            .catch(({ error }: any) => {
              this.$store.commit(
                'setError',
                {
                  title: 'DLocal Create Token Error!',
                  text: error.message,
                },
              )
              resolve(false);
            })
        },
      );
    } else {
      this.$root.$on(
        'setDonationGatewayParams',
        (resolve: (value?: unknown) => void) => {
          const params = getParams()

          this.$store.commit('setDonationIncludedGatewayParams', params);

          resolve(false);
        },
      );
    }
  },

  beforeDestroy() {
    this.$root.$off('dlocalCreateAndSetToken');
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    ...mapActions({
      addDLocalScript: 'addDLocalScript',
      useDLocalSmartFields: 'useDLocalSmartFields',
      initDLocalFields: 'initDLocalFields',
      dLocalCreateInstallmentsPlan: 'dLocalCreateInstallmentsPlan',
    }),

    resetData() {
      this.card?.clear();
      this.cardError = '';
      this.cardBrand = '';

      this.installmentId = '';

      this.$store.commit('setInstallmentsConfig', null);
    },

    cretePaymentForm() {
      const style = {
        base: {
          fontSize: '16px',
          fontFamily: "'Inter UI medium', sans-serif",
          lineHeight: '31.5px',
          fontSmoothing: 'antialiased',
          fontWeight: '500',
          color: '#666',
          '::placeholder': {
            color: '#aab7c4',
          },
          iconColor: '#adbfd3',
        },
      };

      // Create an instance of the card Field.
      this.card = this.fieldsRef.create('card', { style });

      this.card.addEventListener('change', (event: any) => {
        if (event.error) {
          if (!event.empty) {
            this.cardError = event.error.message;
          }
        } else {
          this.cardError = '';
          this.cardEmpty = event.empty;
          this.cardBrand = event.brand
        }
      });

      this.card.on('brand', (event: any) => {
        if (event.brand) {
          this.createInstallmentsPlan();
        }
      });

      const $el = document.getElementById('cardField')

      this.card.mount($el);
    },

    createInstallmentsPlan() {
      if (this.amount && this.card && !this.cardEmpty && this.installmentAvailable) {
        this.dLocalCreateInstallmentsPlan({ card: this.card });
      }
    },
  },
});
export default class DLocal extends VueComponent {}
