export interface IGooglePurchaseConversionConfig {
  google_conversion_id: number,
  google_conversion_label: string;
  google_remarketing_only: 'false' | 'true';
  google_conversion_value: number;
  google_conversion_currency: string;
}

export const addGooglePurchaseConversion = (config :IGooglePurchaseConversionConfig) => {
  const script = document.createElement('script');
  const script2 = document.createElement('script');

  const code = `
    var google_conversion_id = ${config.google_conversion_id};
    var google_conversion_label = "${config.google_conversion_label}";
    var google_remarketing_only = "${config.google_remarketing_only}";
    var google_conversion_value = ${config.google_conversion_value};
    var google_conversion_currency = "${config.google_conversion_currency}";
  `;

  script.type = 'text/javascript';
  script.setAttribute('google-conversion', 'true');
  script.appendChild(document.createTextNode(code));

  document.body.appendChild(script);

  // <script type="text/javascript" src="//www.googleadservices.com/pagead/conversion.js">

  script2.type = 'text/javascript';
  script2.src = '//www.googleadservices.com/pagead/conversion.js';

  document.body.appendChild(script2);
}

export default { addGooglePurchaseConversion }
