
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { useExternalScript } from '@/helpers/useExternalScript';
import { IDataDirect } from './payments-models/MercadoPagoCheckout.model';
import { IRootState } from '../../../models/IRootState';

const mercadopagoScript = useExternalScript('https://sdk.mercadopago.com/js/v2')

const locales: Record<string, string> = {
  es: 'es',
  en: 'en',
  pt: 'pt-BR',
}

const VueComponent = Vue.extend({
  data(): IDataDirect {
    return {
      mp: null,
      loading: true,
    }
  },

  computed: {
    ...customMapState({
      donorData: (s: IRootState) => s.donation.donationData.attributes,
      publicKey: (s: IRootState) => s.donation.paymentConfig.attributes.configuration.public_key,
      locale: (s: IRootState) => s.i18n.locale,
    }),
  },

  mounted(): void {
    mercadopagoScript?.onLoad(() => {
      this.init();
    })

    mercadopagoScript?.load()
  },

  beforeDestroy() {
    window.cardPaymentBrickController?.unmount()
  },

  methods: {
    init(): void {
      this.mp = new MercadoPago(this.publicKey)
      const $vm = this;
      const bricksBuilder = this.mp?.bricks();

      const renderCardPaymentBrick = (bricksBuilder: any) => {
        const settings = {
          locale: locales[this.locale] || 'en',
          initialization: {
            amount: this.donorData.amount / 100,
            payer: {
              email: this.donorData.email,
            },
          },
          customization: {
            visual: {
              hidePaymentButton: true,
              style: {
                theme: 'default', // | 'dark' | 'bootstrap' | 'flat'
              },
            },
            paymentMethods: {
              maxInstallments: 1,
            },
          },
          callbacks: {
            onReady: () => {
              $vm.loading = false
            },
            onError: (error: any) => {
              // eslint-disable-next-line no-console
              console.log(error);
            },
          },
        };
        bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings).then((i: any) => {
          window.cardPaymentBrickController = i
        });
      };
      renderCardPaymentBrick(bricksBuilder);
    },

    onPay(): void {
      window.cardPaymentBrickController.getFormData()
        .then((cardFormData: any) => {
          const { origin, pathname } = window.location;

          this.$store.commit(
            'setDonationIncludedGatewayParams',
            {
              mercadopago_form_data: JSON.stringify(cardFormData),
              redirect_url: `${origin}${pathname}?lang=${this.locale}`,
            },
          );

          this.$store.dispatch('makeDonation');
        })
        .catch((error: any) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
  },
});

export default class MercadoPagoDirect extends VueComponent {}
