
import Vue from 'vue';
import VueSlider from 'vue-slider-component';
// import 'vue-slider-component/theme/antd.css';
import { mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import { tipRecurrringFor } from '@/constants/';
import { rackcdnHost } from '@/constants/urls';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  components: {
    VueSlider,
  },

  data() {
    return {
      rangePickerTipAmount: 0,
      ohterTipAmount: 0,
      selectedPercent: 0,
      showRangePicker: true,

      rackcdnHost,
    };
  },

  computed: {
    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config.tip_config,
      isLoadCampaignParams: (state: IRootState) => state.donation.isLoadCampaignParams,
      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
      lang: (state: IRootState) => state.translation.locate,
      category: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.category?.name,
    }),

    ...mapGetters({
      tipOptions: 'tipOptions',
      donationAmount: 'donationAmount',
      tipAmount: 'tipAmount',
      recurringPeriod: 'recurringPeriod',
    }),

    show(): boolean {
      const isSupport = this.config.gateways?.includes(this.paymentMethod);
      const showRangePicker = this.config.range_bar?.value

      return this.config.available && isSupport && showRangePicker === true;
    },

    title(): string {
      const { hesed } = this.config;
      const title = this.$t(`tip.title_${this.category}`) as string;

      if (hesed) {
        return this.$t('tip.title_hesed', 'Hesed Support') as string;
      }
      if (!title.includes('tip.title_')) {
        return title;
      }

      return this.$t('tip.title', 'Charidy Support') as string;
    },

    boxText(): string {
      const { hesed } = this.config;
      const title = this.$t(`tip.box_text_${this.category}`) as string;

      if (hesed) {
        return this.$t('tip.box_text_hesed', 'Hesed towards the great cause of this campaign') as string;
      }
      if (!title.includes('tip.box_text_')) {
        return title;
      }

      return this.$t(
        'tip.box_text',
        'Charidy sponsors campaigns in need and get {tipAmt}$ profit from them. by supporting charidy you help spread kindness around the world.',
        {
          tipAmt: this.rangePickerTipAmount,
        },
      ) as string;
    },

    supportMessage(): string {
      const { hesed } = this.config;
      const title = this.$t(`tip.support_message_${this.category}`) as string;

      if (hesed) {
        return this.$t('tip.support_message_hesed', 'Adding a hesed can spread more act of kindness into the world') as string;
      }
      if (!title.includes('tip.support_message_')) {
        return title;
      }

      return this.$t(
        'tip.support_message',
        'Charidy sponsors campaigns in need and get {tipAmt}$ profit from them. by supporting charidy you help spread kindness around the world.',
        {
          tipAmt: this.rangePickerTipAmount,
        },
      ) as string;
    },

    presetOther(): boolean {
      if (this.config.preset_other) {
        return this.config.preset_other;
      }

      return false;
    },

    amount(): number {
      if (this.config.amount >= 0) {
        return this.config.amount;
      }

      return 0;
    },

    defaultOtherAmount(): number {
      return this.config.default_other_amount;
    },

    Percent(): number {
      return this.config.percent;
    },

    PresetOther(): boolean {
      return this.config.preset_other
    },

    barColor(): string {
      return this.config.range_bar?.bar_color || '#a6a4a4'
    },
    progressColor(): string {
      return this.config.range_bar?.progress_color || '#b3e0fc'
    },
    dotSize(): number {
      return this.config.range_bar?.dot_size || 28
    },
    dotColor(): string {
      return this.config.range_bar?.dot_color || '#43b2fa'
    },
    dotBorderColor(): string {
      return this.config.range_bar?.dot_border_color || 'white'
    },

    min(): number {
      return this.config.range_bar?.min || 0
    },

    max(): number {
      return this.config.range_bar?.max || 30
    },

    barSize(): number {
      return this.config.range_bar?.bar_size || 12
    },

    interval(): number {
      const { max, min } = this
      const interval = this.config.range_bar?.interval || 5
      if ((max - min) % interval === 0) {
        return interval
      }

      // if interval is uneven the range bar will not work
      return Math.round((max - min) / interval)
    },

    totalCharge(): number {
      return (+this.tipAmount + this.donationAmount) / 100;
    },

    totalChargeSuffix(): string {
      if (!tipRecurrringFor.includes(this.paymentMethod)) {
        return ''
      }
      if (this.recurringPeriod > 1) {
        return this.$t('donations.in_month', 'in month') as string;
      }
      return ''
    },
  },

  watch: {
    selectedPercent: {
      handler() {
        this.controlSuppotMessage()

        this.setTip()
      },

      immediate: true,
    },
    show(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.$store.commit('setTip', 0);
      } else {
        this.setTip()
      }
    },
    donationAmount: {
      handler() {
        this.setTip()

        this.controlSuppotMessage()
      },
      immediate: true,
    },
    ohterTipAmount: {
      handler() {
        this.setTip()
      },
      immediate: true,
    },
    showRangePicker: {
      handler() {
        this.controlSuppotMessage()
      },
    },
  },

  mounted() {
    this.selectedPercent = this.Percent;
    this.ohterTipAmount = this.defaultOtherAmount

    if (this.presetOther) {
      this.ohterTipAmount = this.amount;
      this.hideRangePicker()
    }
  },

  methods: {
    round(amt: number) {
      if (this.config?.amount_with_fraction) {
        return Number(amt.toFixed(this.config?.fraction_precision));
      }
      return Math.round(amt);
    },
    controlSuppotMessage(): void {
      if (!this.$refs.tipMessageRef) {
        return
      }

      if (this.showRangePicker && this.selectedPercent <= 0 && this.donationAmount > 0) {
        const ref: any = this.$refs.tipMessageRef
        ref.style.display = 'flex'
        setTimeout(() => {
          ref.classList.add('tip-message-show')
          ref.classList.remove('tip-message-hide')
        }, 100);
      } else {
        const ref: any = this.$refs.tipMessageRef
        ref.classList.add('tip-message-hide')
        ref.classList.remove('tip-message-show')
        setTimeout(() => {
          ref.style.display = 'none'
        }, 500);
      }
    },
    setTip(): void {
      if (!this.show) {
        return
      }

      let tipTotal = 0;

      if (!this.showRangePicker && this.ohterTipAmount > 0) {
        tipTotal = this.ohterTipAmount * 100
      } else if (this.showRangePicker) {
        this.rangePickerTipAmount = (this.selectedPercent * (this.donationAmount / 100)) / 100;

        tipTotal = this.rangePickerTipAmount * 100
      }

      this.$store.commit('setTip', this.round(tipTotal));
    },

    setFocus() {
      this.$nextTick(() => {
        const iRef: any = this.$refs.inputRef
        iRef.focus()

        const wRef: any = this.$refs.inputWrap
        wRef.style.border = '3px solid #004196'
      });
    },

    displayRangePicker() {
      this.showRangePicker = true

      this.setTip()
    },
    hideRangePicker() {
      this.showRangePicker = false

      this.setTip()

      this.setFocus()
    },
  },
});
export default class DonationTip extends VueComponent {}
