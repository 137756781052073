
import Vue from 'vue';

import customMapState from '@/helpers/mapHelper';

import { IRootState } from '@/models/IRootState';
import { useExternalScript } from '@/helpers/useExternalScript';
import { StripeACHData } from './payments-models/StripeACH.model';

const plaidScript = useExternalScript('https://cdn.plaid.com/link/v2/stable/link-initialize.js')

const VueComponent = Vue.extend({
  data(): StripeACHData {
    return {
      linkHandler: null,
      resolve: () => {},
    }
  },

  computed: {
    ...customMapState({
      env: (state: IRootState) => state.donation.paymentConfig.attributes.configuration.plaid_env,
      key: (state: IRootState) => state.donation.paymentConfig.attributes.configuration.plaid_key,
    }),
  },

  mounted(): void {
    plaidScript?.onLoad(() => {
      this.linkHandler = Plaid.create({
        env: this.env,
        clientName: 'Charidy',
        key: this.key,
        product: ['auth'],
        selectAccount: true,
        onSuccess: (public_token: string, metadata: any) => {
          this.$store.commit('setDonationIncludedGatewayParams', {
            stripe_plaid_public_token: public_token,
            stripe_plaid_account_id: metadata.account_id,
            stripe_idempotency_key: this.$nanoid(),
          });

          this.resolve(true);
        },
        onExit: (err: any, metadata: any) => {
          this.resolve(false);

          if (err != null) {
            this.$store.commit('setError', {
              title: 'Plaid Error!',
              text: err.error_message,
            });
            this.$store.dispatch('trackClientError')
            return;
          }

          this.$store.commit('setError', {
            title: 'Plaid Error!',
            text: this.$t('template.user_exited_the_link_flow'),
          });
        },
      });

      this.$root.$on('openPlaidLink', this.openPlaidLink);
    })

    plaidScript?.load()
  },

  methods: {
    openPlaidLink(resolve: Function): void {
      this.resolve = resolve;
      this.linkHandler.open();
    },
  },
})

export default class StripeACH extends VueComponent {}
