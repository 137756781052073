
import Vue from 'vue'

import customMapState from '@/helpers/mapHelper'
import { IRootState } from '@/models/IRootState'
import { IDonationLevel } from '@/models/IDonationParams'

import Levels from './Levels.vue';
import TeamsPreFormStep from './TeamsPreFormStep.vue';

export default Vue.extend({
  components: {
    Levels,
    TeamsPreFormStep,
  },

  data() {
    return {
      activeStepIndex: 0,
    }
  },

  computed: {
    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      redirectIsSucceeded: (state: IRootState) => state.redirect.isSucceeded,
    }),

    levels(): IDonationLevel[] {
      return this.config.donation_levels;
    },

    levelsStep(): boolean {
      return this.config.show_levels_on_donation;
    },

    teamsPreFormStep(): boolean {
      return this.config.teams_pre_form_step?.value;
    },

    availbleSteps(): string[] {
      const steps = [];

      if (this.teamsPreFormStep) {
        steps.push('teams')
      }

      if (this.levelsStep && this.levels.length) {
        steps.push('levels')
      }

      return steps
    },

    activeStep(): string | undefined {
      return this.availbleSteps[this.activeStepIndex]
    },
  },

  mounted() {
    if (this.activeStep === undefined && !this.redirectIsSucceeded) {
      this.$store.commit('setStepFlag', 1);
    }
  },

  methods: {
    next() {
      this.activeStepIndex += 1;

      if (this.activeStep === undefined) {
        this.$store.commit('setStepFlag', 1);
      }
    },
  },
})
