
import Vue from 'vue'

import icons from '@/components/shared/icons/icons.vue';

import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import { LevelsConfig } from '@/models/IDonationParams';
import { isLevelAvailable } from '@/helpers/levels';

export default Vue.extend({
  components: {
    icons,
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    level: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  computed: {
    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
    }),

    levelsConfig(): LevelsConfig {
      return this.config.levels_config;
    },

    active(): boolean {
      return this.levelsConfig.quantity_selection
    },

    count(): number {
      return this.value[this.level.id]?.count || 0
    },

    available(): boolean {
      return isLevelAvailable(this.level)
    },

    availableNum(): number {
      if (this.level.limited > 0) {
        return this.level.available
      }
      return Infinity
    },
  },

  methods: {
    add() {
      if (!this.available || this.count === this.availableNum) return;

      const data = {
        count: this.count + 1,
      }

      this.$set(this.value, this.level.id, data)
      this.$emit('input', this.value)
    },

    remove() {
      if (!this.available) return;

      if (this.count > 0) {
        const data = {
          count: this.count - 1,
        }

        if (data.count === 0) {
          this.$delete(this.value, this.level.id)
        } else {
          this.$set(this.value, this.level.id, data)
        }
        this.$emit('input', this.value)
      }
    },
  },
})
