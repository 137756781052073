
import Vue from 'vue';
import { mapActions } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import { get } from '@/helpers/getUrlParameters';
import { IRootState } from '../../models/IRootState';

import PhoneField from './PhoneField.vue';
import DedicationDropdown from './DedicationDropdown.vue';

const VueComponent = Vue.extend({
  components: {
    PhoneField,
    DedicationDropdown,
  },

  data() {
    return {
      changeNameFlag: false,
      skipEmailFlag: false,
      nameToDisplayLeft: 150,
      nameToDisplayWarning: false,
      messageLeft: 75,
      messageWarning: false,
      displaynameReadonly: get('displayname_readonly') === '1',
    };
  },

  computed: {
    ...customMapState({
      donateAnonymously: (state: IRootState) => state.donation.donateAnonymouslyFlag,
    }),

    donateAnonymouslyFlag: {
      get(): boolean {
        return this.donateAnonymously;
      },
      set(value: boolean) {
        this.$store.commit('setDonateAnonymouslyFlag', value);
      },
    },

    firstName: {
      get(): string {
        return this.$store.state.donation.donationData.attributes
          .billing_first_name;
      },
      set(value: string) {
        this.$store.commit('setFirstName', value);
        if (!this.donateAnonymouslyFlag) {
          this.$store.commit('setDisplayName', `${value} ${this.lastName}`);
        }
      },
    },

    lastName: {
      get(): string {
        return this.$store.state.donation.donationData.attributes
          .billing_last_name;
      },
      set(value: string) {
        this.$store.commit('setLastName', value);
        if (!this.donateAnonymouslyFlag) {
          this.$store.commit('setDisplayName', `${this.firstName} ${value}`);
        }
      },
    },

    billingName: {
      get(): string {
        return this.$store.state.donation.donationData.attributes.billing_name;
      },
      set(value: string) {
        this.$store.commit('setBillingName', value);
        if (!this.donateAnonymouslyFlag && !this.displayName) {
          this.$store.commit('setDisplayName', value);
        }
      },
    },

    displayName: {
      get(): string {
        return this.$store.state.donation.donationData.attributes.display_name;
      },
      set(value: string) {
        this.$store.commit('setDisplayName', value);
      },
    },

    salutationsModel: {
      get(): string {
        return this.$store.state.donation.salutations;
      },
      set(value: string) {
        this.$store.commit('setSalutations', value);
      },
    },

    receiptName: {
      get(): string {
        return this.$store.state.donation.donationData.attributes.receipt_name;
      },
      set(value: string) {
        this.$store.commit('setReceiptName', value);
      },
    },

    email: {
      get(): string {
        return this.$store.state.donation.donationData.attributes.email;
      },
      set(value: string) {
        this.$store.commit('setEmail', value);
      },
    },

    organizationEmail: {
      get(): string {
        return this.$store.state.donation.donationData.attributes
          .organization_email;
      },
      set(value: string) {
        this.$store.commit('setOrganizationEmail', value);
      },
    },

    message: {
      get(): string {
        return this.$store.state.donation.donationData.attributes.message;
      },
      set(value: string) {
        this.$store.commit('setMessage', value);
      },
    },

    test: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes.test;
      },
    },

    showDedicationField(): boolean {
      if (this.showMessage) {
        if (
          this.hasAnonymousOption
            && this.hideDedicationWhenAnonymously
            && this.donateAnonymouslyFlag
        ) {
          return false;
        }
        return true;
      }

      return false;
    },

    emailRequired(): boolean {
      return this.emailConfig.required;
    },

    salutations(): boolean {
      return this.salutationsConfig?.value || false;
    },

    salutationsOpt(): string[] {
      return this.salutationsConfig?.options || [];
    },

    salutationsMandatory(): boolean {
      return this.salutationsConfig?.mandatory || false;
    },

    ...customMapState({
      translations: (state: IRootState) => state.translation.translation,

      isLoadCampaignParams: (state: IRootState) => state.donation.isLoadCampaignParams,

      splitDonorName: (state: IRootState) => state.donation.donationParams.data.attributes.config
        .split_donor_billing_name,

      differentNameAvailable: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.different_name_for_receipt_option,

      hasSkipEmailOption: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.skip_email_option,

      hasAnonymousOption: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.show_anonymous_option,

      hideDedicationWhenAnonymously: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.hide_dedication_when_anonymously,

      showMessage: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.show_message,

      donationToOrganization: (state: IRootState) => state.donation.campaign.data.type === '',

      commentLength: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.comment_length || 75,

      displayNameLength: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.display_name_length || 55,

      phoneConfig: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.phone_config,

      emailConfig: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.email_config,

      messageConfig: (state: IRootState) => state.donation.donationParams
        .data.attributes.config?.message_config,

      salutationsConfig: (state: IRootState) => state.donation.donationParams
        .data.attributes.config?.salutations,
    }),
  },

  watch: {
    displayName() {
      this.nameCounter(this.displayNameLength);
    },

    donateAnonymouslyFlag() {
      if (this.donateAnonymouslyFlag) {
        this.displayName = this.$t('account.anonymous') as string
      } else if (this.billingName) {
        this.displayName = this.billingName
      } else {
        this.displayName = `${this.firstName} ${this.lastName}`
      }
    },

    skipEmailFlag(val: boolean) {
      this.$nextTick(() => {
        if (val && this.emailConfig.default_email) {
          this.email = this.emailConfig.default_email

          if (this.emailConfig.gateway_id) {
            this.setGatewayAndLock(this.emailConfig.gateway_id)
          }
        } else {
          this.email = ''
          this.setGatewayAndLock(0)
        }
      })
    },
  },

  mounted() {
    this.nameToDisplayLeft = this.displayNameLength;
    this.messageLeft = this.commentLength;

    if (this.messageConfig?.default_value) {
      this.message = this.messageConfig?.default_value.slice(0, this.commentLength);
      this.messageCounter(this.commentLength);
    }
    if (get('message')) {
      this.message = get('message').slice(0, this.commentLength);
      this.messageCounter(this.commentLength);
    }
    if (this.hasAnonymousOption && get('anonymously') === '1') {
      this.donateAnonymouslyFlag = true;
    }
    if (this.differentNameAvailable && get('receiptnameflag') === '1') {
      this.changeNameFlag = true;
    }
    if (this.differentNameAvailable && get('receiptname')) {
      this.changeNameFlag = true;
      this.receiptName = get('receiptname');
    }

    if (this.salutations) {
      [this.salutationsModel] = this.salutationsOpt
    }
  },

  methods: {
    ...mapActions({
      setGatewayAndLock: 'setGatewayAndLock',
    }),

    vErrorsHas(field:string, rule: string) {
      return this.vErrors.items.find((el: any) => el.field === field && el.rule === rule);
    },

    nameCounter(value: number) {
      this.nameToDisplayLeft = value - this.displayName.length;
      this.nameToDisplayWarning = value - this.displayName.length < 10;
    },

    messageCounter(value: number) {
      this.messageLeft = value - this.message.length;
      this.messageWarning = value - this.message.length < 10;
    },
  },
});
export default class PErsonalData extends VueComponent {}
