
import Vue from 'vue'

export default Vue.extend({
  props: {
    x: {
      type: [String, Number],
      default: '2',
    },
  },
})
