
import Vue from 'vue';
import customMapState from '../helpers/mapHelper';
import { IRootState } from '../models/IRootState';

export default Vue.extend({
  computed: customMapState({
    show: (state: IRootState) => state.donation.overlay.show,
    paymentMethod: (state: IRootState) => state.donation.paymentMethod,
    redirectLink: (state: IRootState) => state.donation.overlay.redirectLink,
  }),

  methods: {
    getPaymentMethodName(): string {
      switch (this.paymentMethod) {
        case 'paypal':
          return 'PayPal'
        case 'checkout-fi':
          return 'Paytrail'
        case 'bancontact':
          return 'Bancontact'
        default:
          return ''
      }
    },
  },
});
