import { IRootState } from '@/models/IRootState';

export const getOverwriteReturnToWithVars = (state: IRootState): string => {
  const { donation, redirect } = state;

  let link = donation.donationParams.data.attributes.config?.donation_overwrite_return_to

  const did = redirect.data?.donation_id || donation.donationId;

  link = link.split('[DONATION_ID]').join(String(did));

  return link
}

export default { getOverwriteReturnToWithVars }
