
import Vue from 'vue';
import axios from 'axios';
import urls from '@/constants/urls';
import { mapActions } from 'vuex';
import { getOverwriteReturnToWithVars } from '@/helpers/overwriteReturnTo';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '../../../models/IRootState';

const VueComponent = Vue.extend({
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      ird: '',
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)',
      },
      disabled: false,
      terms: false,
      submitting: false,
      success: false,
    };
  },

  computed: {
    ...customMapState({
      owerwriteLink: (state: IRootState) => getOverwriteReturnToWithVars(state),
      orgName: (state: IRootState) => state.donation.donationParams.data.attributes.config.organization.orgname,
      donationId: (state: IRootState) => state.donation.donationId,
      payment: (state:IRootState) => state.donation.paymentConfig,
      dEmail: (state: IRootState) => state.donation.donationData.attributes.email,
      dFirstName: (state: IRootState) => state.donation.donationData.attributes.billing_first_name,
      dLastName: (state: IRootState) => state.donation.donationData.attributes.billing_first_name,
      dName: (state: IRootState) => state.donation.donationData.attributes.billing_name,
    }),
  },

  created() {
    this.prefillNameFields();
  },

  methods: {
    ...mapActions(['returnToCampaign']),

    prefillNameFields() {
      if (this.dFirstName === '' && this.dLastName === '') {
        let rest;
        [this.firstName, this.lastName, ...rest] = this.dName.split(' ');
      } else {
        this.firstName = this.dFirstName;
        this.lastName = this.dLastName;
      }
      this.email = this.dEmail;
    },

    vErrorsHas(field:string, rule: string) {
      return this.vErrors.items.find(el => el.field === field && el.rule === rule);
    },

    clear() {
      (this.$refs.signaturePad as any).clearSignature();
    },

    save() {
      const { isEmpty, data } = (this.$refs.signaturePad as any).saveSignature();
      this.submitting = true;

      // Signature is empty
      if (isEmpty) {
        this.submitting = false;
        this.$store.dispatch('addError', {
          title: this.$t('donation.no_signature_taxgift', 'No Signature Error'),
          text: this.$t('donation.no_signature_taxgift_msg', 'Please add a signature'),
        });
        return;
      }

      const body = {
        firstname: this.firstName,
        surname: this.lastName,
        email: this.email,
        irdnumber: this.ird.toString(),
        signaturebase64: data.toString(),
        donationid: this.donationId,
        legalentity: this.payment.attributes.organization_legal_entity.id,
      };
      axios.post(urls.taxgift, body)
        .then(({ data }) => {
          this.submitting = false;
          this.success = true;
        })
        .catch(({ response }) => {
          this.submitting = false;
          this.$store.dispatch('addError', {
            title: this.$t('donation.error_taxgift', 'TaxGift Error'),
            text: this.$t('donation.error_taxgift_msg', 'Please contact customer support'),
          });
        });
    },
  },
});
export default class TaxGiftForm extends VueComponent { }
