import { IRootState } from '@/models/IRootState';
import { IDonationLevel } from '@/models/IDonationParams';
import { RedirectDataLevel } from '../store/modules/models/redirect';
import { get } from './getUrlParameters'
import { extractLevelAmountByLocationDefaultCurrency } from './levels';

export const getGAClientID = () => {
  const gacid = get('clientId');
  return gacid ? { clientId: gacid } : {};
}

export const isBlocked = () => {
  const gaLoaded = ga?.loaded && typeof google_tag_data !== 'undefined' && google_tag_data;
  const gtmLoaded = typeof google_tag_manager !== 'undefined' && (
    google_tag_manager?.dataLayer?.gtmLoad
    || google_tag_manager?.dataLayer2?.gtmLoad
  )

  if (gaLoaded && gtmLoaded) {
    return 3
  }

  if (gtmLoaded) {
    return 2
  }

  if (gaLoaded) {
    return 1
  }

  return 0
}

export const getDataForAnalytics = ({
  donation, redirect, levels, translation,
}: IRootState) => {
  const {
    donationData, campaign, donationId, donationIncluded, donationParams, paymentConfig,
  } = donation;
  const { currency, recurring_period } = donationData.attributes;
  const { config, ip_location_default_currency } = donationParams.data.attributes;
  const lang = translation.locate;
  const amount = donationData.attributes.amount / 100;
  const recurring = config.recurring_config.available ? config.call_level_as_recurring : 0

  const realAmount = Math
    .floor(amount * (recurring_period || 1));
  const fullAmount = redirect.data?.original_amount || realAmount;

  const clean = (str: string) => str.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '');

  const cId = campaign.data.id;
  const campaignTitle = clean(donationParams.data?.attributes.campaign_title || '');
  const gatewayName = redirect.data?.gateway_name || paymentConfig?.attributes?.name;

  const levelIds = () => {
    if (levels.slectedLevels) {
      return Object.keys(levels.slectedLevels).map(el => Number(el));
    }

    if (redirect.data?.levels) {
      return redirect.data?.levels.map(el => el.level_id);
    }

    return [];
  };

  const constructLevels = (levelIds: number[]) => {
    const levels = config.donation_levels.filter(el => levelIds.includes(el.id));

    const getTitle = (level: IDonationLevel): string => level.meta?.title_loc?.[lang] || level.title

    const getSubTitle = (level: IDonationLevel): string => level.meta?.subtitle_loc?.[lang] || level.subtitle

    const getAmount = (level: IDonationLevel) => {
      const { levelAmount } = extractLevelAmountByLocationDefaultCurrency(level, ip_location_default_currency)
      return levelAmount
    }

    const getRecurring = (level: IDonationLevel) => {
      const rp = (!level?.meta?.installment && level?.recurring_n) || recurring;
      return rp
    }

    return levels
      .map(el => ({
        id: String(el.id),
        category: getTitle(el),
        price: getAmount(el),
        quantity: getRecurring(el),
        name: campaignTitle,
        variant: getSubTitle(el),
      }));
  }

  let teams = donationIncluded
    .filter(el => el.type === 'donate_to_multiple_teams')
    .map(el => ({
      id: el.id,
      category: clean(el.attributes.name || ''),
      price: (el.attributes.amount || 0) / 100,
      quantity: '1',
      name: campaignTitle,
      variant: gatewayName,
    }));

  if (redirect.data?.teams.length) {
    teams = redirect.data?.teams.map(el => ({
      id: String(el.id),
      category: clean(el.name),
      price: el.amount,
      quantity: '1',
      name: campaignTitle,
      variant: gatewayName,
    }));
  }

  if (teams.length === 0) {
    teams.push({
      id: '0',
      category: '',
      price: Number(fullAmount),
      quantity: '1',
      name: campaignTitle,
      variant: gatewayName,
    })
  }

  return {
    cId,
    campaignTitle,
    donationId: redirect.data?.donation_id || donationId,
    realAmount: Number(fullAmount),
    currency: redirect.data?.currency_code || currency,
    gatewayName,
    teams,
    levels: constructLevels(levelIds()),
  }
}

export const addGALinker = (url: string) => {
  let $url = url

  const gobj = window[window.GoogleAnalyticsObject];
  if (gobj && gobj.getAll) {
    const tracker = gobj.getAll()[0];
    if (tracker && window?.gaplugins?.Linker) {
      const linker = new window.gaplugins.Linker(tracker);
      $url = linker.decorate(url);
    }
  }

  return $url;
}

export default {
  getDataForAnalytics,
}
