
import Vue from 'vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { get } from '@/helpers/getUrlParameters';
import customMapState from '@/helpers/mapHelper';
import { AvailableDisplayCurrencyList } from '@/store/modules/models/donation';
import { deepCopy } from '@/helpers/objectHelper';
import type { Ii18n } from 'vuex-i18n'
import { ALLOWED_TYPES } from '@/validator'
import ExtraInfoMinAmount from '../shared/extra-info-min-amount.vue'
import MultiselectSearch from '../shared/multiselect-search.vue';
import checkboxGroup from '../shared/checkbox-group/checkbox-group.vue';
import { IRootState } from '../../models/IRootState';
import { ICustomDataRequests } from '../../models/IDonationParams';
import urls from '../../constants/urls';

interface IConfig {
  [key: string]: {
    valueFormat: string,
    defaultValue: any,
  }
}

interface ICustomData {
  value: {} | null;
  id: number;
  type: string;
  key: string;
  placeholder: string;
  valueFormat: string;
  priceOfOption: number;
  defaultValue: number;
}

interface ICustomData2 {
  type: string;
  id: string;
  attributes: {
    [x: string]: any;
    type: string;
  };
}

const defaultConfig: IConfig = {
  text: {
    valueFormat: 'val_str',
    defaultValue: '',
  },
  textarea: {
    valueFormat: 'val_str',
    defaultValue: '',
  },
  number: {
    valueFormat: 'val_int',
    defaultValue: 0,
  },
  multiselect: {
    valueFormat: 'val_str_list',
    defaultValue: [],
  },
  ddl: {
    valueFormat: 'val_str',
    defaultValue: '',
  },
  ddl_search: {
    valueFormat: 'val_str',
    defaultValue: '',
  },
  file: {
    valueFormat: 'val_str',
    defaultValue: '',
  },
  checkbox: {
    valueFormat: 'val_bool',
    defaultValue: false,
  },
  autocomplete: {
    valueFormat: 'val_str',
    defaultValue: '',
  },
};

type $t = Ii18n['translate']

const VueComponent = Vue.extend({
  components: {
    Multiselect,
    checkboxGroup,
    MultiselectSearch,
    ExtraInfoMinAmount,
  },

  data() {
    return {
      formData: {},
      custom_data: [] as ICustomData[],
      isDonateTo: true,
      isCheckList: true,
      file: '',

      defaultConfig,

      ALLOWED_TYPES_SEPCOMMA: Object.values(ALLOWED_TYPES).join(', '),
    };
  },

  computed: {
    ...customMapState({
      donationTotalAmount: (state: IRootState) => state.donation.totalAmount,
      recurringPeriod: (state: IRootState) => state.donation.donationData.attributes.recurring_period,
      multiplier: (state: IRootState) => state.donation.donationParams.data.attributes.current_multiplier,

      fieldList(this: any, state: IRootState) {
        const customFields = state.donation.donationParams.data.attributes.config.custom_data
          .sort(this.compare)
          .filter(
            ({ custom_view, gateways }) => (
              (Number(get('custom_view')) === custom_view || custom_view === 0)
              && (gateways ? gateways.includes(this.psName) : true)
            ),
          ).map(el => {
            if (el.options) {
              const sorted = Object.keys(el.options)
                .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
                .reduce((acc, key) => ({ ...acc, [key]: el.options[key] }), {})
              el.options = sorted
            }
            return el
          });

        if (customFields.length > 0) {
          this.custom_data = customFields.map(field => ({
            value: this.getValueFromURL(field) || (field.type === 'checkbox' ? field.checked : (field.value || '')),
            id: field.id,
            type: field.type,
            key: field.key,
            placeholder: field.placeholder,
            valueFormat: this.defaultConfig[field.type]?.valueFormat,
            dataList: field.data_list,
            priceOfOption: field.price_of_option,
            defaultValue: field?.options?.[0] || '',
          }));
        }
        return customFields;
      },

      psName: (state: IRootState) => state.donation.paymentConfig?.attributes?.name,
    }),
    ...mapGetters({
      showAmountInLocCurrency: 'showAmountInLocCurrency',
    }),

    campaignCurrency(): AvailableDisplayCurrencyList {
      const state = this.$store.state as IRootState;
      if (this.showAmountInLocCurrency) {
        return this.showAmountInLocCurrency;
      }
      return {
        sign: state.donation.campaign.data.attributes.currency_sign,
        code: state.donation.campaign.data.attributes.currency,
      }
    },

    totalAmount(): number {
      const {
        donationTotalAmount, recurringPeriod, multiplier,
      } = this;

      return (donationTotalAmount * (recurringPeriod || 1) * multiplier)
    },
  },

  watch: {
    donationTotalAmount() {
      this.custom_data = this.custom_data.map(f => {
        if (f.priceOfOption > 0) {
          f.value = f.defaultValue
        }
        return f
      })
    },
  },

  mounted() {
    this.$root.$on('extraInfoSetData', this.setData)
  },

  beforeDestroy() {
    this.$root.$off('extraInfoSetData', this.setData)
  },

  methods: {
    hasErrorMsg(field: string, rule: string): boolean {
      const exist = this.vErrors.items.find(el => el.field === field && el.rule === rule)

      if (exist) {
        return true;
      }

      return false;
    },

    getErrorMsg(field: string, rule: string, tErrorMsg?: string): string {
      const exist = this.hasErrorMsg(field, rule);

      if (exist) {
        return tErrorMsg as string;
      }

      return '';
    },

    getLabel(f: ICustomDataRequests): string {
      return this.$t(`donation.custom_field_${f.key}`, f.label) as string
    },

    getDDLOptions(f: ICustomDataRequests): Record<string, string> {
      if (f.price_of_option > 0) {
        const { donationTotalAmount, totalAmount } = this;
        const cnt = Math.floor(
          (f.price_of_option_by_total_amount ? totalAmount : donationTotalAmount) / f.price_of_option,
        )

        const options: Record<string, string> = {}

        if (f.options['0']) {
          const indx = this.custom_data.findIndex(_f => _f.key === f.key && _f.value === '')
          const text = this.$t(
            `donation.custom_field_${f.key}_option_${0}`,
            f.options[String(0)] || `Option ${0}`,
          ) as string
          options[0] = text
          if (indx >= 0) {
            this.custom_data[indx].value = text
          }
        }

        for (let i = 1; i <= cnt; i += 1) {
          const text = this.$t(
            `donation.custom_field_${f.key}_option_${i}`,
            f.options[String(i)] || `Option ${i}`,
          ) as string
          options[i] = text
        }

        return options
      }
      return f.options
    },

    isDisabled(f: ICustomDataRequests) {
      if (f.available_min_amount && f.available_min_amount > this.totalAmount) {
        return f.available_min_amount > this.totalAmount
      }
      return false
    },

    handleFileUpload() {
      const [fileInput] = this.$refs.file as HTMLInputElement[];
      [this.file] = fileInput.files as any || [];
    },

    getValueFromURL({ key, type }: ICustomDataRequests): any {
      switch (type) {
        case 'multiselect': {
          try {
            return JSON.parse(get(key)) || [];
          } catch {
            return [];
          }
        }

        case 'checkbox': {
          return Boolean(get(key));
        }

        case 'number': {
          return Number(get(key)) || 0;
        }

        default:
          return get(key);
      }
    },

    compare(a: ICustomDataRequests, b: ICustomDataRequests) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    },

    async setData(ready: (value: boolean | PromiseLike<boolean>) => void) {
      const copy = deepCopy(this.custom_data)

      const customData: Promise<ICustomData2>[] = copy.map(async i => {
        if (i.type === 'file') {
          const formData = new FormData();
          if (this.file !== '') {
            formData.append('file', this.file);
            const { data } = await axios.post(
              urls.uploadFile, formData, { headers: { 'Content-Type': 'multipart/form-data' } },
            )
            i.value = data.url;
          }
        }

        if (i.type === 'autocomplete') {
          i.value = (i.value as { id: number; text: string }).text;
        }

        return {
          type: 'custom_data',
          id: String(i.id),
          attributes: {
            type: i.type,
            key: i.key,
            [i.valueFormat]: i.value || this.defaultConfig[i.type].defaultValue,
          },
        };
      });
      const cd = await Promise.all(customData);
      this.$store.commit(
        'setDonationRelationshipsCustomData',
        this.prepareCustomData(cd),
      );
      this.$store.commit('setDonationIncluded', cd);
      ready?.(true)
    },

    prepareCustomData(data: ICustomData2[]) {
      return data.map(i => ({
        type: 'custom_data',
        id: i.id,
      }));
    },

    // from https://stackoverflow.com/a/6394168
    index(obj: any, is: any, value?: any): any {
      if (typeof is === 'string') return this.index(obj, is.split('.'), value);
      // eslint-disable-next-line no-return-assign, eqeqeq
      if (is.length == 1 && value !== undefined) return obj[is[0]] = value;
      // eslint-disable-next-line eqeqeq
      if (is.length == 0) return obj;
      return this.index(obj[is[0]], is.slice(1), value);
    },

    autocompleteSearchUrl(field: ICustomDataRequests): string {
      if (!field.data_list) {
        throw new Error('data_list property required when type autocomplete')
      }

      const urlObj = new URL(field.data_list.search_url);
      const searchParams = new URLSearchParams();

      Object.entries(field.data_list.params).forEach(([key, value]) => {
        if (value) {
          if (Array.isArray(value)) {
            value.forEach(v => searchParams.append(`${key}[]`, v));
          } else {
            searchParams.append(key, String(value));
          }
        }
      });

      urlObj.search = decodeURIComponent(searchParams.toString());
      const url = decodeURI(urlObj.toString())

      return url;
    },

    autocompleteSearchOptions(field: ICustomDataRequests): any {
      if (!field.data_list) {
        throw new Error('data_list property required when type autocomplete')
      }

      return {
        placeholder: this.$t('template.extra_info_autocomplete_placeholder', 'Start typing...'),
        changeParams: ({
          query, page, per_page: pp,
        }: any) => ({
          q: query,
          offset: (page - 1) * pp,
          limit: pp,
        }),
        trackBy: 'id',
        customLabel: (e: any) => {
          if (typeof e === 'string' || typeof e === 'number') {
            return e;
          }

          return e.text
        },
        processResults: (data: any[]) => {
          let data$1 = data;
          if (field.data_list.records_path) {
            data$1 = this.index(data, field.data_list.records_path)
          }

          return data$1.map((el, i) => {
            if (field.data_list.track_by) {
              return {
                id: i,
                text: el[field.data_list.track_by].trim(),
              }
            }

            return el;
          })
        },
      };
    },
  },
});
export default class ExtraInfo extends VueComponent { }
