
import customMapState from '@/helpers/mapHelper';
import { RootState } from '@/store/modules/translation';
import { ApplePayResponse } from '@/types/cardknox';
import axios from 'axios';
import Vue from 'vue'
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      loaded: false,
    }
  },

  computed: {
    ...customMapState({
      donationData: (s: RootState) => s.donation.donationData.attributes,
      test: (s: RootState) => s.donation.donationData.attributes.test || false,
      paymentConfig: (state: RootState) => state.donation.paymentConfig.attributes.configuration,
    }),
    ...mapGetters({
      getFullBillingName: 'getFullBillingName',
    }),

    buttonOptions(): any {
      return {
        buttonContainer: 'ap-container',
        buttonColor: APButtonColor.black,
        buttonType: APButtonType.donate,
      }
    },
  },

  mounted() {
    window.apRequest = this

    ckApplePay.enableApplePay({ initFunction: 'apRequest.initAP' });
  },

  methods: {
    initAP(): any {
      return {
        buttonOptions: this.buttonOptions,
        merchantIdentifier: this.paymentConfig.apple_merchant_id,
        requiredBillingContactFields: ['postalAddress', 'name', 'phone', 'email'],
        onGetTransactionInfo: 'apRequest.onGetTransactionInfo',
        onPaymentMethodSelected: 'apRequest.onPaymentMethodSelected',
        onValidateMerchant: 'apRequest.onValidateMerchant',
        onPaymentAuthorize: 'apRequest.onPaymentAuthorize',
        onPaymentComplete: 'apRequest.onPaymentComplete',
        onAPButtonLoaded: 'apRequest.apButtonLoaded',
        isDebug: this.test,
        walletCheckEnabled: false,
      }
    },
    onGetTransactionInfo():any {
      const amt = this.donationData.amount / 100;

      const lineItems = [
        {
          label: 'Donation amount',
          type: 'final',
          amount: amt,
        },
      ];

      return {
        lineItems,
        total: {
          type: 'final',
          label: 'Total',
          amount: amt,
        },
      };
    },
    onPaymentMethodSelected(): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          const resp = this.onGetTransactionInfo();
          resolve(resp);
        } catch (err) {
          reject(err);
        }
      })
    },
    validateApplePayMerchant(url: string) {
      return axios.post('https://api.cardknox.com/applepay/validate', { validationUrl: url })
    },
    onValidateMerchant():Promise<any> {
      return new Promise((resolve, reject) => {
        this.validateApplePayMerchant(window.location.href)
          .then(response => {
            resolve(JSON.stringify(response.data));
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    onPaymentAuthorize(applePayload: ApplePayResponse):any {
      return new Promise(resolve => {
        const billingAddress = applePayload.billingContact

        if (billingAddress.countryCode) {
          this.$store.commit('setCountry', billingAddress.countryCode);
        }
        if (billingAddress.administrativeArea) {
          this.$store.commit('setState', billingAddress.administrativeArea);
        }
        if (billingAddress.locality) {
          this.$store.commit('setCity', billingAddress.locality);
        }
        if (billingAddress.postalCode) {
          this.$store.commit('setZipcode', billingAddress.postalCode);
        }
        if (billingAddress.addressLines.length) {
          this.$store.commit('setAddress', billingAddress.addressLines[0]);
        }
        if (billingAddress.addressLines.length === 2) {
          this.$store.commit('setSuite', billingAddress.addressLines[1]);
        }

        this.$store.commit(
          'setDonationIncludedGatewayParams',
          {
            card_token: btoa(JSON.stringify(applePayload.token.paymentData)),
            cardknox_digital_wallet_type: 'applepay',
          },
        );
        resolve(applePayload)
      });
    },
    onPaymentComplete(paymentComplete:any):any {
      if (paymentComplete.response) { // Success
        this.$store.dispatch('makeDonation')
      } else if (paymentComplete.error) {
        this.$store.commit('setError', {
          title: 'Cardknox Error!',
          translationKey: [null, paymentComplete.error],
        });
        this.$store.dispatch('trackClientError')
      }
    },
    apButtonLoaded(resp: any): void {
      if (!resp) return;
      if (resp.status === iStatus.success) {
        this.loaded = true
        this.$emit('loaded')
      } else if (resp.reason) {
        this.$store.commit('setError', {
          title: 'Cardknox Error!',
          translationKey: [null, resp.reason],
        });
        this.$store.dispatch('trackClientError')
      }
    },
  },
})
