
import Vue from 'vue';

import { IBlackbaudGatewayParams } from './payments-models/Blackbaud.model';

import cardNumberInput from '../../shared/card-number-input/card-number-input.vue';
import cardExpDate from '../../shared/card-exp-date/card-exp-date.vue';

const VueComponent = Vue.extend({
  components: {
    cardNumberInput,
    cardExpDate,
  },

  inject: ['$validator'],

  data() {
    return {
      cardNo: '',
      expDate: {
        month: '',
        year: '',
      },
      cccvv: '',
    }
  },

  computed: {
    gatewayParams(): IBlackbaudGatewayParams {
      return {
        blackbaud_card_no: this.cardNo.replace(/ /g, ''),
        blackbaud_exp_date: Object.values(this.expDate).join(''),
        blackbaud_exp_cvv: this.cccvv,
      };
    },
  },

  watch: {
    gatewayParams() {
      this.setParams();
    },
  },

  methods: {
    setParams() {
      this.$store.commit('setDonationIncludedGatewayParams', this.gatewayParams);
    },
  },
});
export default class Blackbaud extends VueComponent {}
