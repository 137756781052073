import 'url-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import './helpers/jquery';
import './helpers/polyfills';

import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueDebounce from 'vue-debounce'
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
// import VueI18n from 'vue-i18n';
import VueMask from 'v-mask'
import VueMobileDetection from 'vue-mobile-detection';
import VueAnimtaeNumber from 'vue-animate-number';
import VueSignaturePad from 'vue-signature-pad';

import './helpers/nanoid-ie';
import { nanoid, customAlphabet } from 'nanoid';

import App from './App.vue';

import store from './store/store';
import vuetify from './plugins/vuetify';
import './plugins/vue-tel-input';
import './plugins/animte-number';
import './plugins/overrides.scss';

import './validator';

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: '13785b9de75868a92a7dd9ea3ba79eb5',
    plugins: [new BugsnagPluginVue()],
  });
  Bugsnag.getPlugin('vue')?.installVueErrorHandler(Vue)
}

Vue.use(VueMask);
Vue.use(VueDebounce)
Vue.use(VueMobileDetection);
Vue.use(VueAnimtaeNumber);
Vue.use(VueSignaturePad);
Vue.use({
  install(v) {
    v.prototype.$nanoidCustomAlphabet = customAlphabet;
    v.prototype.$nanoid = nanoid;
  },
})

// Vue.use(VueI18n)

Vue.use(VeeValidate, {
  errorBagName: 'vErrors',
  events: 'change|input|blur',
});

Vue.prototype.$t = function t(...props: any[]) {
  const result = this.$i18n.translate(...props);
  const stc = new URLSearchParams(window.location.search).get('stk') === '1';

  if (stc) {
    let str = ''
    if (props.length > 1) {
      const values = (typeof props[1] !== 'string' ? props[1] : props[2]) || {};
      Object.keys(values).forEach(key => {
        str += `${key} => ${values[key]}, `
      });
    }

    return `${props[0]} [${str}]`;
  }
  return result;
};

/**
 * v-focus
 * directive uses in input element to make autofocus on it
 */
Vue.directive('focus', {
  // when binded element inserted in DOM
  inserted(el) {
    // make fosuc on the element
    el.focus();
  },
});

Vue.directive('int', {
  inserted(el, binding) {
    el.addEventListener('input', () => {
      let update = false;
      const input = el as HTMLInputElement;
      const formattedValue = input.value.match(/\d+/)?.[0] || '';
      update = input.value !== formattedValue;
      input.value = formattedValue;

      if (binding.value?.max) {
        const { max } = binding.value;
        if (input.value > max) {
          input.value = max;
          update = true;
        }
      }

      if (binding.value?.max_length) {
        const { max_length } = binding.value;
        if (input.value.length > max_length) {
          input.value = input.value.slice(0, max_length);
          update = true;
        }
      }

      if (update) {
        input.dispatchEvent(new CustomEvent('input'));
      }
    })
  },
});

// eslint-disable-next-line no-new
const app = new Vue({
  el: '#app',
  store,
  vuetify,
  render: h => h(App),
});

if (window.Cypress) {
  // only available during E2E tests
  window.app = app
}
