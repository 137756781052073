
import Vue from 'vue';
import { useExternalScript } from '@/helpers/useExternalScript'
import { RootState } from '@/store/modules/translation';
import customMapState from '@/helpers/mapHelper';

import cardNumberInput from '../../shared/card-number-input/card-number-input.vue';
import cardExpDate from '../../shared/card-exp-date/card-exp-date.vue';

const VueComponent = Vue.extend({
  components: {
    cardNumberInput,
    cardExpDate,
  },

  inject: ['$validator'],

  data() {
    return {
      cardNo: '',
      expDate: {
        month: '',
        year: '',
      },
      cvv: '',
    }
  },

  computed: {
    ...customMapState({
      test: (s: RootState) => s.donation.donationData.attributes.test || false,
      paymentConfig: (state: RootState) => state.donation.paymentConfig.attributes.configuration,
    }),

    apiLoginID(): string {
      return this.paymentConfig.api_login_id
    },

    clientKey(): string {
      return this.paymentConfig.public_key
    },
  },

  mounted() {
    const authorizeScript = useExternalScript(`https://js${this.test ? 'test' : ''}.authorize.net/v1/Accept.js`)

    authorizeScript?.load()

    this.$root.$on(
      'setDonationGatewayParams',
      (resolve: (value?: unknown) => void) => {
        this.sendPaymentDataToAnet()
          .then(data => {
            this.paymentFormUpdate(data)
            resolve(false)
          })
          .catch(errMsg => {
            resolve(errMsg)
          })
      },
    );

    this.$root.$on(
      'authorizeCreateAndSetToken',
      (next: (value?: unknown) => void) => {
        this.sendPaymentDataToAnet()
          .then(data => {
            this.paymentFormUpdate(data)
            next(true)
          })
          .catch(errMsg => {
            this.$store.commit('setError', {
              title: 'Authorize Create Token Error!',
              text: errMsg,
            });
            next(false);
          })
      },
    );
  },

  beforeDestroy() {
    this.$root.$off('authorizeCreateAndSetToken');
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    sendPaymentDataToAnet() {
      const authData = {
        clientKey: this.clientKey,
        apiLoginID: this.apiLoginID,
      };

      const cardData = {
        cardNumber: this.cardNo.replaceAll(' ', ''),
        month: this.expDate.month,
        year: this.expDate.year,
        cardCode: this.cvv,
      };

      const secureData = {
        authData,
        cardData,
      };

      return new Promise((resolve, reject) => {
        const responseHandler = this.getResponseHandler(resolve, reject)
        Accept.dispatchData(secureData, responseHandler);
      })
    },

    getResponseHandler(
      resolve: (value: unknown) => void,
      reject: (reason?: any) => void,
    ) {
      return (response: any) => {
        if (response.messages.resultCode === 'Error') {
          const errMsg = response.messages.message.map((msg: any) => `${msg.code}:${msg.text}`).join('\n')
          reject(errMsg)
        } else {
          resolve(response.opaqueData);
        }
      }
    },

    paymentFormUpdate(opaqueData: any): void {
      this.$store.commit(
        'setDonationIncludedGatewayParams',
        {
          opaque_data_descriptor: opaqueData.dataDescriptor,
          opaque_data_value: opaqueData.dataValue,
        },
      );
    },
  },

});
export default class CardknoxGoogleApplePay extends VueComponent {}
