
import customMapState from '@/helpers/mapHelper'
import { IRootState } from '@/models/IRootState'
import { useStripeTerminal } from '@/compositions/stripe-terminal'
import Vue from 'vue'
import LdsSpinner from '@/components/LdsSpinner.vue'
import { IError } from '@/store/modules/models/donation'

const stripeTerminal = useStripeTerminal()

export default Vue.extend({
  components: {
    LdsSpinner,
  },

  data() {
    return {
      loadingState: '',
      error: null as IError | null,

      readers: [] as any[],
      connectedReader: null as any | null,

      simulated: false,
      testCardNumber: '4242424242424242',
    };
  },

  computed: {
    ...customMapState({
      paymentIntentClientSecret: (state: IRootState) => state.stripe.paymentIntentClientSecret,
      paymentConfig: (s: IRootState) => s.donation.paymentConfig,
      campaign: (s: IRootState) => s.donation.campaign.data,
      test: (s: IRootState) => s.donation.donationData.attributes.test || false,
    }),

    initialized(): boolean {
      return stripeTerminal.initialized.value
    },

    showLoading(): boolean {
      return !this.initialized || this.loadingState !== '';
    },

    fallbackText(): string {
      switch (this.loadingState) {
        case 'discover_readers':
          return 'Discovering reader ...'

        case 'connect_reader':
          return 'Connecting ...'

        case 'collect_payment_method':
          return 'Collecting payment mothod ...'

        case 'payment_status_waiting_for_input':
          return 'Waiting for input ...'

        case 'payment_status_processing':
        case 'process_payment':
          return 'Processing ...'

        default:
          return ''
      }
    },
  },

  watch: {
    initialized(val) {
      if (val) {
        this.discoverReaders()
      }
    },
  },

  mounted() {
    if (this.initialized) {
      this.discoverReaders()
    } else {
      stripeTerminal.initialize(this.campaign.id, this.paymentConfig)
    }
  },

  methods: {
    async discoverReaders() {
      this.loadingState = 'discover_readers'

      const [readers, err] = await stripeTerminal.discoverReaders({ simulated: this.simulated })

      this.loadingState = ''

      if (err) {
        this.error = err
        return Promise.reject()
      }

      if (readers) {
        this.readers = readers;
        if (readers.length === 1) {
          this.connectReader(readers[0])
        }
      }

      this.error = null
      return Promise.resolve()
    },

    async connectReader(reader: any) {
      if (this.connectedReader) {
        return
      }

      this.loadingState = 'connect_reader'

      const [connectedReader, err] = await stripeTerminal.connectReader(reader)

      this.loadingState = ''

      if (err) {
        this.error = err
        return
      }

      this.error = null
      this.connectedReader = connectedReader
    },

    async collectPaymentMethod(paymentIntentClientSecret: any) {
      this.loadingState = 'collect_payment_method'

      if (this.test) {
        stripeTerminal.terminal.value.setSimulatorConfiguration({ testCardNumber: this.testCardNumber });
      }

      const paymentStatusReady = new Promise<[boolean, IError | null]>(resolve => {
        const iID = setInterval(async () => {
          const [status, err] = await stripeTerminal.getPaymentStatus()
          if (err) {
            clearInterval(iID)
            resolve([false, err])
          }
          if (status === 'ready') {
            clearInterval(iID)
            resolve([true, null])
          } else {
            this.loadingState = `payment_status_${status}`
          }
        }, 100)
      })

      const [, errStatus] = await paymentStatusReady
      if (errStatus) {
        this.error = errStatus
        return
      }

      const [intent, err] = await stripeTerminal.collectPaymentMethod(paymentIntentClientSecret)

      this.loadingState = ''

      if (err) {
        this.error = err
        return
      }

      this.error = null
      this.processPayment(intent)
    },

    async processPayment(intent: any) {
      this.loadingState = 'process_payment'

      const [intentProcessed, err] = await stripeTerminal.processPayment(intent)

      if (err) {
        this.loadingState = ''
        this.error = err
        const res = await stripeTerminal.statusUpdate(intentProcessed.id, true)
          .then(resp => resp)
          .catch(e => e.response)
        if (res.status >= 400) {
          this.$store.commit('setError', {
            title: 'Error!',
            text: JSON.stringify(res.data),
          });
          return
        }
        return
      }

      const res = await stripeTerminal.statusUpdate(intentProcessed.id, false)
        .then(resp => resp)
        .catch(e => e.response)
      if (res.status >= 400) {
        this.$store.commit('setError', {
          title: 'Error!',
          text: JSON.stringify(res.data),
        });
        return
      }

      window.postMessage({
        type: 'gateway-response-success',
      }, '*');

      this.error = null
      this.loadingState = ''
    },
  },
})
