
import Vue from 'vue';
import { mapActions } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import { getOverwriteReturnToWithVars } from '@/helpers/overwriteReturnTo';
import { addGooglePurchaseConversion, IGooglePurchaseConversionConfig } from '@/helpers/googlePurchaseConversion';
import { networks } from '@/constants/networks';
import icons from '@/components/shared/icons/icons.vue';
import { DonationSuccessPageConfig, IShareList, IgulLetovaGatewayConfig } from '@/models/IDonationParams';
import { getDonationDataFromLoacalStarage } from '@/helpers/localStorage';
import { IRootState } from '../../models/IRootState';

import createPersonalPage from './CreatePersonalPage.vue';
import actionAfterDonation from './ActionAfterDonation.vue';
import partOfGivingDayMsg from './PartOfGivingDayMsg.vue';
import taxGiftExtraPanel from './taxgift/TaxGiftExtraPanel.vue';
import giveAndGetExtraPanel from './GiveAndGetExtraPanel.vue';
import igulLetovaPanel from './igulLetovaPanel.vue';
import inviteFriendsToMatchDonation from './InviteFriendsToMatchDonation.vue';

const VueComponent = Vue.extend({
  components: {
    icons,
    createPersonalPage,
    actionAfterDonation,
    partOfGivingDayMsg,
    taxGiftExtraPanel,
    giveAndGetExtraPanel,
    igulLetovaPanel,
    inviteFriendsToMatchDonation,
  },

  data() {
    return {
      showCopy: false,
    };
  },

  computed: {
    ...customMapState({
      campaignShortLink: (state: IRootState) => state.donation.campaign.data.attributes.short_link,
      owerwriteLink: (state: IRootState) => getOverwriteReturnToWithVars(state),
      owerwriteLinkTrigger: (state: IRootState) => state.donation.donationParams.data.attributes.config?.overwrite_return_to_trigger,
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      campaignShortUrl: (state: IRootState) => state.donation.donationParams.data.attributes.campaign_short_url,
      donationDataAttrs: (state: IRootState) => state.donation.donationData.attributes,
      orgLegalEntity: (state: IRootState) => state.donation.paymentConfig?.attributes?.organization_legal_entity,
      gateway: (state: IRootState) => state.donation.paymentMethod,
      igulLetovaConfign: (state: IRootState) => state.donation.donationParams.data.attributes.config?.igul_letova_config?.gateway_config,
    }),

    getDonationData(): any {
      return this.donationDataAttrs;
    },

    shareLinks(): IShareList[] {
      return this.config.share_list;
    },

    externalLinksHide(): boolean {
      return this.config.external_links_hide;
    },

    successPageConfig(): DonationSuccessPageConfig {
      return this.config.donation_success_page_config;
    },

    customMessage(): string {
      return this.successPageConfig?.custom_message;
    },

    bannerImage(): string {
      const bannerImageStruct = this.successPageConfig?.banner_image;

      if (typeof bannerImageStruct === 'string') {
        return bannerImageStruct;
      }

      return bannerImageStruct.url;
    },

    text(): string {
      return this.successPageConfig?.text;
    },

    email(): string {
      const dd = getDonationDataFromLoacalStarage();

      return dd?.email || this.donationDataAttrs.email;
    },

    href(): string {
      const copyLink = this.shareLinks.find(el => el.social === 'copy_link');

      if (copyLink?.url_for_share) {
        return copyLink?.url_for_share;
      }

      const domain = this.campaignShortUrl || 'charidy.com';

      return `https://${domain}/${this.campaignShortLink}`;
    },

    customMsgParams(): any {
      const lsdd = getDonationDataFromLoacalStarage();
      const dd = Object.keys(lsdd || {}).length ? lsdd : this.donationDataAttrs;
      const computed_name = dd?.receipt_name || dd?.billing_name || `${dd?.billing_first_name} ${dd?.billing_last_name}`;

      const res = {
        billing_first_name: dd?.billing_first_name,
        billing_last_name: dd?.billing_last_name,
        billing_name: dd?.billing_name,
        display_name: dd?.display_name,
        receipt_name: dd?.receipt_name,
        computed_name,
        email: dd?.email,
        phone: dd?.phone,
        amount: (dd?.amount || 0) / 100,
        currency: dd?.currency.toUpperCase(),
        currency_sign: dd?.currency_sign,
        tip_amount: (dd?.tip_amount || 0) / 100,
      };

      return res;
    },

    taxGiftEligible(): boolean {
      return this.config.tax_gift_config?.legal_entities?.includes(this.orgLegalEntity.id)
    },

    igulLetovaConfig(): IgulLetovaGatewayConfig {
      const config :IgulLetovaGatewayConfig = {
        value: false,
        gateway_name: '',
        show_zehut: false,
        show_issuer: false,
      }

      if (!this.igulLetovaConfign) {
        return config;
      }

      const igConfig = this.igulLetovaConfign.find((el: IgulLetovaGatewayConfig) => el.gateway_name === this.gateway);
      if (igConfig) {
        igConfig.value = true;
        return igConfig;
      }

      return config;
    },
  },

  created() {
    const { tracking_org_google_conversion_id: togci, tracking_org_google_conversion_label: togcl } = this.config;

    if (togci && togcl) {
      const config: IGooglePurchaseConversionConfig = {
        google_conversion_id: Number(togci),
        google_conversion_label: togcl,
        google_remarketing_only: 'false',
        google_conversion_value: this.donationDataAttrs.amount / 100,
        google_conversion_currency: this.donationDataAttrs.currency,
      };

      addGooglePurchaseConversion(config);
    }
  },

  mounted() {
    if (this.owerwriteLinkTrigger === 'immediately' && this.owerwriteLink) {
      this.returnToCampaign();
    }
  },

  methods: {
    ...mapActions(['returnToCampaign']),

    createSharingUrl(network: IShareList): string {
      let { social } = network;

      const {
        url_for_share, description, title, quote, hashtag, media, twitter_user,
      } = network;

      if (social === 'whatsapp' && this.config?.share_config?.use_whatsapp_web && !this.$isMobile()) {
        social = 'whatsapp-web';
      }

      return networks[social].sharer
        .replace(/@url/g, encodeURIComponent(url_for_share || this.href))
        .replace(/@title/g, encodeURIComponent(title))
        .replace(/@description/g, encodeURIComponent(description))
        .replace(/@quote/g, encodeURIComponent(quote))
        .replace(/@hashtags/g, hashtag)
        .replace(/@media/g, media)
        .replace(/@twitteruser/g, twitter_user ? `&via=${twitter_user}` : '');
    },

    onCopy(): void {
      const copyText = this.$refs.copyTextInput as HTMLInputElement;

      copyText.select();
      copyText.setSelectionRange(0, 99999);

      document.execCommand('copy');
    },
  },
});
export default class Success extends VueComponent {}
