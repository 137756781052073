import { Translations } from 'vuex-i18n';
import getCookie from '@/helpers/cookie';

export interface Account {
    acc_setting: string;
    acte_authentique: string;
    acte_sous_seing_prive: string;
    add_entities: string;
    add_entity: string;
    add_organization: string;
    add_organization_subtitle: string;
    add_organization_title: string;
    add_organizations: string;
    address_line_1: string;
    address_line_2: string;
    anonymous: string;
    autres1: string;
    autres2: string;
    avatar: string;
    bank_statement: string;
    bsd: string;
    cancel: string;
    change_password: string;
    cheque: string;
    city: string;
    confirm_cancel: string;
    confirm_delete: string;
    confirm_delete_contact: string;
    confirm_delete_entitie: string;
    confirm_delete_sub_account: string;
    confirm_new_password: string;
    contacts_button: string;
    contacts_email: string;
    contacts_first_name: string;
    contacts_last_name: string;
    contacts_modal_header: string;
    contacts_note: string;
    contacts_note_placeholder: string;
    contacts_phone: string;
    contacts_title: string;
    contacts_type: string;
    contacts_type_donor_support: string;
    contacts_type_main: string;
    country: string;
    current_password: string;
    declaration_de_don_manuel: string;
    donate_anonymously: string;
    edit_entity: string;
    email: string;
    entities: string;
    entity_contact_missing_note: string;
    entity_missing_title: string;
    fax: string;
    first_checkbox_group: string;
    first_name: string;
    fourth_checkbox_group: string;
    full_name: string;
    inst_number: string;
    is_200_du_cgi: string;
    is_238_bis_du_cgi: string;
    is_885_0v_bis_du_cgi: string;
    is_oeuvre: string;
    language: string;
    last_name: string;
    legal_entity_detalis: string;
    legal_entity_detalis_add: string;
    legal_entity_detalis_edit: string;
    legal_entity_subtitle: string;
    legal_entity_subtitle_add: string;
    legal_entity_subtitle_edit: string;
    legal_name: string;
    logo: string;
    message_dedication: string;
    message_dedication_placeholder: string;
    name_to_display: string;
    name_to_display_placeholder: string;
    new_password: string;
    numeraire: string;
    object: string;
    org_about: string;
    org_detalis: string;
    org_legal_name: string;
    org_name: string;
    org_short_link: string;
    org_site: string;
    organization_notification: string;
    phone: string;
    public_contact_missing_note: string;
    public_contact_missing_title: string;
    receipt_prefix: string;
    receipt_start_n: string;
    remise_d_especes: string;
    role_full: string;
    role_operator: string;
    save: string;
    save_change: string;
    second_checkbox_group: string;
    select_role: string;
    select_state: string;
    send_CERFA_receipts: string;
    send_invitation: string;
    signature: string;
    skip_email: string;
    state: string;
    sub_account: string;
    sub_account_add_button: string;
    sub_account_modal_description: string;
    sub_account_modal_header: string;
    sub_account_no_subaccounts: string;
    sub_account_role: string;
    success_delete: string;
    success_edit: string;
    switch_public_account: string;
    switch_public_account_contacts: string;
    symbols_left: string;
    tax_deductible_receipt: string;
    tax_id: string;
    tax_id_canada: string;
    third_checkbox_group: string;
    timezone: string;
    timezone_modal: string;
    titres_de_societes_cotes: string;
    type: string;
    upload_signature: string;
    verify_email_alert: string;
    your_contact_details: string;
    zpi: string;
}

export interface AddCampaign {
    add_new_campaign: string;
    change_anytime: string;
    close: string;
    create_new_campaign: string;
    create_new_campaign_button: string;
    date_in_mind: string;
    deadlines: string;
    end_date: string;
    end_date_fail: string;
    goal_in_mind: string;
    raising_for: string;
    start_date_fail: string;
    title: string;
}

export interface Campaigns {
    campaign_end: string;
    campaigns: string;
    clone: string;
    confirm_delete_campaign: string;
    countdown: string;
    create_new_campaign: string;
    current_campaigns: string;
    delete: string;
    demo: string;
    edit: string;
    failed: string;
    goal: string;
    live: string;
    past_campaigns: string;
    success: string;
}

export interface Dashboard {
    activity_donate: string;
    activity_total: string;
    add_button_title: string;
    add_donate_add_row: string;
    add_donate_address: string;
    add_donate_address_2: string;
    add_donate_advanced: string;
    add_donate_billing_last_name: string;
    add_donate_billing_name: string;
    add_donate_category: string;
    add_donate_charged_amount: string;
    add_donate_city: string;
    add_donate_country: string;
    add_donate_dedication: string;
    add_donate_delete_row: string;
    add_donate_display_name: string;
    add_donate_earmark: string;
    add_donate_email: string;
    add_donate_mass_one: string;
    add_donate_payment_address_2: string;
    add_donate_payment_state: string;
    add_donate_phone: string;
    add_donate_postcode: string;
    add_donate_referer: string;
    add_donate_referrer: string;
    add_donate_state_area: string;
    add_donate_stream: string;
    add_donate_success: string;
    add_donate_team: string;
    add_donation_hide_optionaly: string;
    add_donation_show_optionaly: string;
    add_donors_button_form: string;
    add_modal_title: string;
    add_success_add: string;
    cancel_donation: string;
    cancel_donation_description: string;
    cancel_refund: string;
    cancel_refund_description: string;
    cancel_subscription: string;
    cancel_subscription_description: string;
    change_request_note: string;
    change_request_type: string;
    change_request_type_desc_: string;
    change_request_type_desc_cancel: string;
    change_request_type_desc_cancel_refund: string;
    chart_description: string;
    chart_title: string;
    comment: string;
    confirm: string;
    confirm_delete_cancel_donate: string;
    conversion: string;
    conversion_period_infinity: string;
    conversion_total_infinity: string;
    danation_phone: string;
    description_cancel_donate_yaadpay: string;
    disabled_add_button: string;
    disabled_import_button: string;
    donation: string;
    donation_add_cancel: string;
    donation_amount_charged: string;
    donation_amount_effective: string;
    donation_count_label_chart: string;
    donation_date: string;
    donation_dedication: string;
    donation_display_name: string;
    donation_donation_not_appearing: string;
    donation_edit: string;
    donation_edit_cancel: string;
    donation_edit_save: string;
    donation_email: string;
    donation_email_phone: string;
    donation_hide_optionaly: string;
    donation_manual_close: string;
    donation_manual_import: string;
    donation_manual_save: string;
    donation_methods: string;
    donation_name: string;
    donation_no_selected_team: string;
    donation_recurring_period: string;
    donation_status: string;
    donation_total_label_chart: string;
    donation_via: string;
    donation_was_reviewed: string;
    donations_empty: string;
    donations_pdf_link_open: string;
    edit_donate_address: string;
    edit_donate_billing_last_name: string;
    edit_donate_billing_name: string;
    edit_donate_category: string;
    edit_donate_city: string;
    edit_donate_country: string;
    edit_donate_dedication: string;
    edit_donate_display_name: string;
    edit_donate_email: string;
    edit_donate_pending_description: string;
    edit_donate_phone: string;
    edit_donate_postcode: string;
    edit_donate_processed_description: string;
    edit_donate_status: string;
    edit_donate_team: string;
    edit_donation_hide_optionaly: string;
    edit_donation_show_optionaly: string;
    edit_edit_donation: string;
    edit_success_edit: string;
    email_template_save: string;
    entitis: string;
    export_button_receipt_title: string;
    export_button_teams_title: string;
    export_button_title: string;
    export_check_email: string;
    export_donation_button_title: string;
    export_dowload_export_file: string;
    export_email: string;
    export_export_description: string;
    export_export_in_modal: string;
    export_loading_link: string;
    export_modal_teams_title: string;
    export_modal_title: string;
    export_please_enter_email: string;
    export_receipts_modal_title: string;
    export_success_have_button: string;
    export_teams: string;
    finalize_campaign_message: string;
    go_to_edit_campaign: string;
    group: string;
    hide_modal: string;
    impoprt_donation_dowloadfile: string;
    impoprt_donation_imported_charge_amount_sum: string;
    impoprt_donation_imported_effective_amount_sum: string;
    impoprt_donation_imported_number: string;
    impoprt_donation_successful_import: string;
    impoprt_donation_upload_file: string;
    import_button_title: string;
    import_donation_button_title: string;
    import_donation_description: string;
    import_donation_mandatory: string;
    import_donations: string;
    import_integration: string;
    import_manual_button_title: string;
    import_modal_title: string;
    import_one_donation_dont_have_all_input: string;
    in_last_hour: string;
    integration_export_add_mailchimp: string;
    integration_export_close: string;
    integration_mailchimp: string;
    integration_salsesforce: string;
    loading_tems_export: string;
    mailchimp_export: string;
    no_chart_data_message: string;
    none: string;
    not_have_campaigns: string;
    page_view_label_chart: string;
    page_visits: string;
    parent_team: string;
    payment_referer_for_all: string;
    placeholder_cancel_donate: string;
    receipt_cancel: string;
    receipt_delivered: string;
    receipt_email: string;
    receipt_name: string;
    receipt_receipt: string;
    receipt_resend: string;
    receipt_sending: string;
    receipt_sent: string;
    recent: string;
    recent_activity: string;
    recurring_filter_onetime_only: string;
    recurring_filter_please_select_recurring: string;
    recurring_filter_recurring_and_onetime: string;
    recurring_filter_recurring_only: string;
    regenerate_receipt: string;
    regenerate_receipt_loading: string;
    regenerate_receipt_success: string;
    regenerate_receipt_tooltip: string;
    select_entiti: string;
    select_filter_: string;
    select_filter_achisomoch: string;
    select_filter_authorized: string;
    select_filter_canceled: string;
    select_filter_check: string;
    select_filter_creditguard: string;
    select_filter_israeltoremet: string;
    select_filter_payfast: string;
    select_filter_payme: string;
    select_filter_paypal: string;
    select_filter_payu: string;
    select_filter_pending: string;
    select_filter_placeholder_input: string;
    select_filter_please_select: string;
    select_filter_please_select_campaign: string;
    select_filter_please_select_gateway: string;
    select_filter_please_select_status: string;
    select_filter_pledge: string;
    select_filter_processed: string;
    select_filter_stripe: string;
    select_filter_swipers: string;
    select_filter_touchnet: string;
    select_filter_venmo: string;
    select_filter_yaadpay: string;
    select_parent_team: string;
    select_teams: string;
    send_confirmation_email: string;
    send_receipt: string;
    status_accepted_cancel: string;
    status_accepted_cancel_refund: string;
    status_accepted_cancel_subscription: string;
    status_declined_cancel: string;
    status_declined_cancel_refund: string;
    status_declined_cancel_subscription: string;
    status_review_after_delete_cancel: string;
    status_review_after_delete_cancel_refund: string;
    status_review_after_delete_cancel_subscription: string;
    status_review_before_delete_cancel: string;
    status_review_before_delete_cancel_refund: string;
    status_review_before_delete_cancel_subscription: string;
    status_review_delete_cancel: string;
    status_review_delete_cancel_refund: string;
    status_review_delete_cancel_subscription: string;
    team_export_text: string;
    title_donation: string;
    total: string;
    total_offline: string;
    total_online: string;
    type: string;
    updates: string;
    updates_add: string;
}

export interface Donation {
    address_placeholder: string;
    address_title: string;
    apt_suite_placeholder: string;
    apt_suite_title: string;
    billing_address_title: string;
    city_placeholder: string;
    city_title: string;
    country_title: string;
    divide_by: string;
    instalments_label: string;
    make_account_checkbox_beginning: string;
    make_account_checkbox_ending: string;
    make_account_notice: string;
    make_account_password: string;
    make_account_password_confirm: string;
    make_account_password_confirm_placeholder: string;
    make_account_password_placeholder: string;
    make_account_site_link: string;
    make_account_title: string;
    make_account_title_optional: string;
    months: string;
    no_instalments: string;
    no_recurring: string;
    please_select_placeholder: string;
    recurring_during: string;
    recurring_label: string;
    state_province_placeholder: string;
    state_province_title: string;
    stream_title: string;
    with_matcher_pledges: string;
    your_donation: string;
    zip_postcode_placeholder: string;
    zip_postcode_title: string;
    box_title: string;
    single_payment: string;
    matching_label: string;
    monthly_payments: string;
}

export interface Tip {
  box_title: string;
  label2: string;
}

export interface Donors {
    account_holder: string;
    add_donor: string;
    address: string;
    amount: string;
    bank_transfer: string;
    campaign: string;
    cash: string;
    charity_voucher: string;
    check: string;
    check_email: string;
    clear_filter: string;
    credit_card: string;
    date: string;
    dedication: string;
    donation_approve: string;
    donation_approved: string;
    donation_dedication: string;
    donation_id: string;
    donation_moderation_check_note: string;
    donation_on_review: string;
    donation_status_change_describe: string;
    donation_type: string;
    donor: string;
    donor_details: string;
    donors: string;
    dowload_export_file: string;
    email: string;
    empty_text: string;
    empty_title: string;
    export_csv: string;
    export_description: string;
    export_donors: string;
    export_in_modal: string;
    general_info: string;
    loading_link: string;
    no_donors_found: string;
    no_team: string;
    offline_donation_note: string;
    offline_donation_source: string;
    ok: string;
    phone: string;
    please_enter_email: string;
    public_name: string;
    recurring_bank_transfer: string;
    recurring_credit_card: string;
    revenue_stream: string;
    search: string;
    search_name_email: string;
    see_more: string;
    select_bank_name: string;
    select_campaign: string;
    select_campaign_in_filter: string;
    show_all: string;
    status: string;
    team_id: string;
    type: string;
    upload_donation_list: string;
}

export interface EditCampaign {
    add_new_donation_level: string;
    add_new_donation_stream: string;
    add_new_matcher: string;
    bonus_goal: string;
    campaign_in_countdown_mode: string;
    campaign_in_live_mode: string;
    campaign_in_success_mode: string;
    campaign_mode_1: string;
    campaign_mode_2: string;
    campaign_mode_3: string;
    campaign_mode_4: string;
    campaign_mode_5: string;
    campaign_video: string;
    campaign_video_placeholder: string;
    cancel: string;
    color_title: string;
    completed: string;
    confirm_cancel: string;
    confirm_delete: string;
    confirm_delete_desc: string;
    confirm_delete_donation_level: string;
    confirm_delete_donation_stream: string;
    confirm_delete_image: string;
    confirm_delete_matcher: string;
    confirm_delete_parent_campaign: string;
    count_down_image: string;
    countdown: string;
    delete: string;
    delete_all_teams: string;
    delete_parent_campaign: string;
    description: string;
    description_title: string;
    donation_level_amount: string;
    donation_level_color: string;
    donation_level_empty: string;
    donation_level_form: string;
    donation_level_subtitle: string;
    donation_level_title: string;
    donation_levels_title: string;
    donation_stream_default: string;
    donation_stream_description: string;
    donation_stream_empty: string;
    donation_stream_form: string;
    donation_stream_goal: string;
    donation_stream_order: string;
    donation_stream_title: string;
    edit: string;
    edit_time_zone_close: string;
    edit_time_zone_modal: string;
    edit_time_zone_save: string;
    email_template: string;
    email_template_confirmation: string;
    email_template_description: string;
    email_template_description_receipt: string;
    email_template_edit_content: string;
    email_template_edit_content_modal: string;
    email_template_edit_description: string;
    email_template_receipt: string;
    email_template_reset_all: string;
    email_template_title: string;
    email_template_update_image: string;
    export_button_teams_title: string;
    general_info: string;
    generalinfo_bonus_goal: string;
    generalinfo_bonus_round_matching: string;
    generalinfo_click_here: string;
    generalinfo_end_date: string;
    generalinfo_facebook_pixel: string;
    generalinfo_finish_date: string;
    generalinfo_goal_amount: string;
    generalinfo_google_analitics: string;
    generalinfo_name: string;
    generalinfo_primary_goal_maching: string;
    generalinfo_save: string;
    generalinfo_short_link: string;
    generalinfo_short_link_allowe: string;
    generalinfo_short_link_awailable: string;
    generalinfo_short_link_validating: string;
    generalinfo_start_date: string;
    generalinfo_title: string;
    generalinfo_to_change: string;
    generalinfo_video_help_text: string;
    generalinfo_x1: string;
    generalinfo_x2: string;
    generalinfo_x3: string;
    generalinfo_x4: string;
    generalinfo_x5: string;
    generalinfo_x6: string;
    generalinfo_x7: string;
    generalinfo_your_time_zone_set: string;
    generalinfo_youtube_link: string;
    hide_modal: string;
    image_add_new: string;
    image_delete: string;
    image_for_desktop: string;
    image_for_desktop_mobile_sizes: string;
    image_for_desktop_sizes: string;
    image_for_mobile: string;
    image_for_mobile_sizes: string;
    image_replace: string;
    images: string;
    images_title: string;
    import_teams: string;
    import_teams_description: string;
    import_teams_dowloadfile: string;
    import_teams_imported_number: string;
    import_teams_mandatory: string;
    import_teams_modal_title: string;
    import_teams_successful_import: string;
    import_teams_upload_file: string;
    import_teams_uploading: string;
    join_gd: string;
    join_gd_header: string;
    join_gd_label_pending: string;
    join_gd_text: string;
    lang_image_default: string;
    matcher_description: string;
    matcher_empty: string;
    matcher_form: string;
    matcher_form_close: string;
    matcher_form_save: string;
    matcher_name: string;
    matcher_order: string;
    matcher_type: string;
    matcher_type_0: string;
    matcher_type_1: string;
    matcher_type_default: string;
    matcher_type_hosts: string;
    matchers: string;
    matchers_title: string;
    open_campaign_page: string;
    open_join_gd_modal: string;
    parent_campaign_pending: string;
    primary_goal: string;
    save_link: string;
    select_campaign_image: string;
    select_description_tag: string;
    select_dg: string;
    select_lang_image: string;
    shared_image: string;
    show_on_org: string;
    standart_template_image: string;
    streams_title: string;
    success_add_image: string;
    success_delete_image: string;
    success_delete_matcher: string;
    success_delete_parent_campaign: string;
    success_edit: string;
    success_edit_image: string;
    success_save_donation_levels: string;
    success_save_matchers: string;
    teams: string;
    teams_options: string;
    untagged: string;
    upload_donation_level_image: string;
    upload_donation_stream_image: string;
    upload_file_too_big: string;
    upload_image: string;
    upload_image_shared: string;
    upload_image_slider: string;
    upload_matcher_image: string;
    upload_slider_image: string;
}

export interface EmailConfirm {
    email_verified: string;
    token_not_found: string;
}

export interface Errors {
    amount: string;
}

export interface Gateways {
    access_token: string;
    accesstoken: string;
    account_number: string;
    achisomoch_descriptions: string;
    add_achisomoch: string;
    add_asser: string;
    add_broom: string;
    add_gateways: string;
    add_israeltoremet: string;
    add_payfast: string;
    add_payme: string;
    add_paypal: string;
    add_stripe: string;
    add_yaadpay: string;
    address: string;
    api_key: string;
    api_password: string;
    api_secret: string;
    bank_account_currency: string;
    bank_account_holder_name: string;
    bank_account_last_4: string;
    bank_account_number: string;
    bank_account_routing_number: string;
    bank_code: string;
    bank_detalis: string;
    bank_info: string;
    bank_name: string;
    branch_code: string;
    bsb: string;
    business_name: string;
    campaign_id: string;
    city: string;
    clearing_code: string;
    close: string;
    close_view: string;
    country: string;
    create_entity_button: string;
    create_entity_text: string;
    currency: string;
    currency_list: string;
    default_currency: string;
    disabled: string;
    email: string;
    emv: string;
    enabled: string;
    entity_country_full: string;
    external_account: string;
    gateway: string;
    gateway_name: string;
    gov_id: string;
    hash_string: string;
    hk: string;
    iban: string;
    iban_bank_account_number: string;
    import_file: string;
    installments: string;
    institution_number: string;
    legal_entity: string;
    legal_entity_dob: string;
    legal_name: string;
    list_currencies_to_display: string;
    max_payments: string;
    merchant_id: string;
    merchant_key: string;
    no_payment_gateways: string;
    not_have_entitys: string;
    not_have_gateways: string;
    not_verified: string;
    org_name: string;
    org_representative: string;
    org_representative_dob: string;
    org_type: string;
    org_type_company: string;
    org_type_individual: string;
    page_lang: string;
    passphrase: string;
    plus_add_payment_gateway: string;
    postal_code: string;
    primary_currency: string;
    project_id: string;
    public_key: string;
    routing_number: string;
    save: string;
    select_entitys: string;
    seller_address_city: string;
    seller_address_country: string;
    seller_address_street: string;
    seller_address_street_number: string;
    seller_bank_account_number: string;
    seller_bank_branch: string;
    seller_bank_code: string;
    seller_birthdate: string;
    seller_description: string;
    seller_email: string;
    seller_file_cheque: string;
    seller_file_corporate: string;
    seller_file_social_id: string;
    seller_first_name: string;
    seller_gender: string;
    seller_gender_m: string;
    seller_gender_w: string;
    seller_inc: string;
    seller_inc_code: string;
    seller_info: string;
    seller_last_name: string;
    seller_merchant_name: string;
    seller_person_business_type: string;
    seller_phone: string;
    seller_social_id: string;
    seller_social_id_issued: string;
    signature_key: string;
    sort_code: string;
    statement: string;
    statement_display: string;
    status: string;
    stripe_acc_detalis: string;
    stripe_available_in: string;
    terminal: string;
    terminal_id: string;
    title: string;
    transit_number: string;
    update_view: string;
    verification_due_by: string;
    verified_info: string;
    view_data_achisomoch: string;
    view_data_creditguard: string;
    view_data_israeltoremet: string;
    view_data_mercadopago: string;
    view_data_payfast: string;
    view_data_paypal: string;
    view_data_stripe: string;
    view_data_venmo: string;
    view_data_yaadpay: string;
    zpass: string;
}

export interface Integration {
    add_integration: string;
    add_integration_mailchimp: string;
    add_integration_overpass: string;
    add_integration_salesforce: string;
    cancel: string;
    close: string;
    donor_Object_type: string;
    donor_custom_type: string;
    exporting_process: string;
    integration: string;
    login: string;
    login_at_overpass: string;
    mailchimp_step_1: string;
    mailchimp_step_2: string;
    mailchimp_step_success: string;
    map_donation_property_hide: string;
    map_donation_property_show: string;
    map_donors_property_hide: string;
    map_donors_property_show: string;
    next: string;
    not_have_gateways: string;
    not_have_integration: string;
    not_have_list_in_mailchimp: string;
    not_have_this_integration: string;
    overpass_launched_five_minutes: string;
    overpass_login: string;
    sales_force_step_1: string;
    sales_force_step_2: string;
    sales_force_step_3: string;
    sales_force_step_success: string;
    service_integrated: string;
    service_title: string;
    static_value: string;
    successfuly_exported: string;
}

export interface Leads {
    all: string;
    amount: string;
    campaign_id: string;
    campaigns: string;
    cancel: string;
    created_at: string;
    date: string;
    email: string;
    export_csv: string;
    export_list: string;
    modal_heder: string;
    name: string;
    no_leads_found: string;
    phone: string;
    reminder_list: string;
    search_remind: string;
}

export interface Success {
    challenge_teaser: string;
    choose_friends: string;
    click_here: string;
    employer_match_donation: string;
    encourage_friends: string;
    increae_impact: string;
    return_to_campaign: string;
    sharing_possibility: string;
    thank_you: string;
}

export interface Teams {
    action: string;
    add_new_team: string;
    add_team_modal: string;
    close: string;
    confirm_cancel: string;
    confirm_delete: string;
    confirm_delete_all_teams: string;
    confirm_delete_team: string;
    confirm_delete_team_text: string;
    custom_email_paragraph: string;
    delete: string;
    description: string;
    edit: string;
    edit_team: string;
    email: string;
    goal: string;
    id: string;
    image: string;
    leader_email: string;
    leader_name: string;
    name: string;
    order: string;
    paragraph_email: string;
    save: string;
    see_more: string;
    shortlink: string;
    slug: string;
    success_delete: string;
    success_delete_all_teams: string;
    team_id: string;
    team_lead_email: string;
    team_lead_name: string;
    teams_access_error: string;
    teams_empty: string;
    teams_form: string;
    teams_title: string;
    title: string;
    upload_image: string;
}

export interface Confirmation {
  box_title: string,
  your_donation: string,
  first_donation: string,
  charidy_support: string,
  support_charidy: string,
  optional_charidy_support: string,
  total_charged: string,
  first_donation_total: string,
  help_tip: string,
  edit: string,
  processing_fee_sentiment: string,
  your_total_donation: string,
  matching_statement: string,
  submit_text: string,
  tipLabel0: string,
  tipLabel1: string,
  tipLabel2: string,
  tipLabel3: string,
  tipLabel4: string,
  agree_to_terms: string,
}

export interface Template {
    about: string;
    account_settings: string;
    ad_journal: string;
    already_have_account: string;
    and: string;
    article: string;
    back: string;
    back_to_login: string;
    bank_name: string;
    billing_last_name: string;
    billing_name: string;
    blog: string;
    bonus_goal: string;
    by_clicked_i_aggree: string;
    campaign_name: string;
    campaigns: string;
    category: string;
    charged_amount: string;
    check_your_email: string;
    confirm_donation: string;
    confirmation_newsletter_subscription: string;
    confirmation_notification_beginning: string;
    confirmation_notification_error: string;
    contact_us: string;
    continue_btn: string;
    copyright: string;
    core_values: string;
    currency_code: string;
    dashboard: string;
    date: string;
    dedication: string;
    display_name: string;
    documents: string;
    donate: string;
    donate_to_org_btn_title: string;
    donate_to_org_label: string;
    donation_will_be: string;
    donors: string;
    donors_menu: string;
    dont_have_account: string;
    effective_amount: string;
    email: string;
    email_address: string;
    email_input_placeholder: string;
    email_is_required: string;
    emails_notice: string;
    empty_kay: string;
    enter_your_detalis: string;
    error_default: string;
    faq: string;
    field_is_required: string;
    firs_name: string;
    first_name_is_required: string;
    for_universities: string;
    forgot_password: string;
    gateways: string;
    get_started: string;
    giving_day: string;
    goal: string;
    hepdata_placeholder: string;
    hepdata_submit: string;
    hepdata_success: string;
    hepdata_title: string;
    hosts: string;
    integration: string;
    join: string;
    join_journal: string;
    last_name: string;
    last_name_is_required: string;
    learn_more: string;
    matchers: string;
    matchers_menu: string;
    non_profit_menu: string;
    non_profits: string;
    notification_beginning: string;
    offline_donation_note: string;
    offline_donation_source: string;
    one_number: string;
    org_admin_dashboard: string;
    org_email: string;
    org_phone: string;
    organization_name: string;
    organization_name_is_required: string;
    organization_settings: string;
    password: string;
    password_is_required: string;
    password_reset_please_check_email: string;
    payment_address: string;
    payment_address_2: string;
    payment_city: string;
    payment_country: string;
    payment_methods_title: string;
    payment_postcode: string;
    payment_state: string;
    payouts: string;
    phone: string;
    phone_number: string;
    pladge_donations_phoe: string;
    pladge_donations_text: string;
    pre_donations_button: string;
    preview_donation: string;
    preview_donations_text: string;
    privacy: string;
    private_policy: string;
    processing_charged_amount: string;
    processing_charged_currency: string;
    receives: string;
    recurring_period: string;
    referrer: string;
    reminder_list: string;
    resset_my_password: string;
    secure_payments: string;
    select_organization: string;
    seriously_no_spam: string;
    sign_in: string;
    sign_in_to_charidy: string;
    sign_int_text: string;
    sign_int_tip_1: string;
    sign_int_tip_2: string;
    sign_int_tip_3: string;
    sign_int_tip_4: string;
    sign_up: string;
    sign_up_text: string;
    signout: string;
    social: string;
    special_symbols: string;
    specialist_in_touch: string;
    status: string;
    success_menu: string;
    support: string;
    team: string;
    team_id: string;
    teams: string;
    teams_grandparent: string;
    teams_parent: string;
    terms: string;
    terms_and_private_policy: string;
    terms_is_required: string;
    terms_of_service: string;
    testimonial: string;
    thank_you_for_contact: string;
    training: string;
    users: string;
    video: string;
    with_matcher_pledges: string;
    work_email_address: string;
    you_dont_have_organization: string;
}

export interface Training {
    dont_have_access: string;
    head_influencer_traning: string;
    matchers_traning: string;
    material_download_not_awailable: string;
    material_download_title: string;
    restricted_video_close: string;
    restricted_video_message: string;
    training_article_link: string;
    training_video_link: string;
    training_videos: string;
}

export interface En {
    account: Account;
    add_campaign: AddCampaign;
    campaigns: Campaigns;
    dashboard: Dashboard;
    donation: Donation;
    donors: Donors;
    edit_campaign: EditCampaign;
    email_confirm: EmailConfirm;
    errors: Errors;
    gateways: Gateways;
    integration: Integration;
    leads: Leads;
    success: Success;
    teams: Teams;
    template: Template;
    training: Training;
}

export interface Translation {
    [key: string]: Translations;
}

export interface ITranslationResponse {
  en?: En;
}

export interface State {
    translation: Translation;
    locate: string;
    loadLang: boolean;
}

export interface Actions {
}

export interface Mutations {
}

export interface Getters {
}

export default class NewState implements State {
    translation: Translation = {};

    locate: string = 'en';

    loadLang: boolean = true;

    constructor(state?: State) {
      Object.assign(this, state);
      this.getLanguageFromCookie();
    }

    getLanguageFromCookie() {
      const { searchParams } = new URL(window.location.href);

      this.locate = searchParams.get('lang') || getCookie('language') || 'en';

      const html = document.documentElement;
      html.setAttribute('lang', this.locate);
    }
}
