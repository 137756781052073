
import Vue from 'vue'
import axios from 'axios';

import urls from '@/constants/urls';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import { removeBeforeUnloadListener } from '@/before-unload';

export default Vue.extend({
  data() {
    return {
      loading: false,
      docuSignTab: null as Window | null,
    };
  },

  computed: {
    ...customMapState({
      donationAttrs: (state: IRootState) => state.donation.donationData.attributes,
      donationId: (state: IRootState) => state.donation.donationId,
    }),

    belzID(): string | null {
      return this.donationAttrs.get_data;
    },
  },

  methods: {
    sign() {
      if (this.belzID) {
        this.loading = true;

        const url = urls.belzDocuSign.replace(':id', this.belzID)

        const params = {
          donation_id: this.donationId,
        }

        removeBeforeUnloadListener();

        axios.post(url, null, { params })
          .then(({ data }) => {
            const { link } = data;

            window.location.href = link;

            this.loading = false;
          })
          .catch(({ response }) => {
            this.loading = false;

            const { commit } = this.$store
            const [error] = response.data?.errors || []

            commit('setError', {
              title: 'Belz docu sign Error!',
              translationKey: [error?.meta?.error_translation_key, error?.detail],
            });
          });
      }
    },
  },
})
