
import Vue from 'vue';
import { mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import parseStringToNumber from '@/helpers/parseStringToNumber';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  components: {
  },

  data() {
    return {
      selectedTip: 5 as number|string,
      inpurIsFocused: false,
    };
  },

  computed: {
    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.tip_config,
      isLoadCampaignParams: (state: IRootState) => state.donation.isLoadCampaignParams,
      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
    }),

    ...mapGetters([
      'tipOptions',
      'donationAmount',
      'tipAmount',
      'recurringPeriod',
    ]),

    show(): boolean {
      const isSupport = this.config.gateways?.includes(this.paymentMethod);
      return this.config.available && isSupport;
    },

    percent(): number {
      const isExist = this.tipOptions.includes(this.config.percent);
      return (isExist && this.config.percent) || this.tipOptions[1];
    },

    amount: {
      get(): string {
        const formatted = this.tipAmount ? `${this.donationCurrency.sign}${this.tipAmount.toLocaleString()}` : '';
        if (this.inpurIsFocused) {
          return formatted;
        }
        return formatted;
      },
      set(value: string): void {
        const numeric = parseStringToNumber(value);
        this.$store.commit('setTip', numeric);
      },
    },

    presetOther(): boolean {
      if (this.config.preset_other) {
        return this.config.preset_other;
      }

      return false;
    },

    defaultOtherAmount(): number {
      return this.config.default_other_amount || 0;
    },

    calculatePercentage(): string {
      return (Math.round(this.tipAmount / this.donationAmount * 1000) / 10).toString();
    },
  },

  watch: {
    show(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.$store.commit('setTip', 0);
      }
    },
    donationAmount: {
      handler(donationAmount, oldVal) {
        if (this.selectedTip !== 'other') {
          this.setTip();
        }
      },
      immediate: true,
    },
    recurringPeriod: {
      handler(period) {
        if (this.selectedTip !== 'other') {
          this.setTip();
        }
      },
      immediate: true,
    },

    selectedTip: {
      handler(tipPecentage, oldVal) {
        if (!this.inpurIsFocused && tipPecentage !== 'other') {
          this.setTip();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.selectedTip = this.percent;
  },

  methods: {
    selectTip(tip: number|string): void {
      this.selectedTip = tip;
    },
    setTip(): void {
      this.$store.commit('setTip', this.donationAmount * (this.recurringPeriod || 1) * (this.selectedTip as number / 100));
    },
  },
});
export default class DonationTip extends VueComponent {}
