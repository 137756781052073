
import Vue from 'vue'
import axios from 'axios'
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import urls from '@/constants/urls';

export default Vue.extend({
  data() {
    return {
      file: null,
      loading: false,
      uploaded: false,
    } as {
      file: null | File,
      loading: boolean
      uploaded: boolean
    }
  },

  computed: {
    ...customMapState({
      dId: (state: IRootState) => state.donation.donationId,
      cmpId: (state: IRootState) => state.donation.campaign.data.id,
    }),
  },

  methods: {
    upload() {
      this.loading = true;

      const formData = new FormData();

      if (this.file) {
        formData.append('file', this.file);
      }

      const params = {
        donation_id: this.dId,
      }

      axios
        .post(
          urls.moduleVideo.replace(':id', String(this.cmpId)),
          formData,
          { params },
        )
        .then(() => {
          this.loading = false;
          this.uploaded = true;
        })
        .catch(({ response }) => {
          this.loading = false;

          const { commit } = this.$store
          const [error] = response.data?.errors || []

          commit('setError', {
            title: 'File Upload Error!',
            translationKey: [error?.meta?.error_translation_key, error?.detail],
          });
        })
    },
  },
})
