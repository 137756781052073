var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.show)?_c('div',{staticClass:"wrapper mt-6",class:_vm.$vuetify.breakpoint.mobile ? 'pa-6' : 'pa-8'},[_c('h1',{staticClass:"pb-6",class:{
      'mobile': _vm.$vuetify.breakpoint.mobile,
    }},[_vm._v(" "+_vm._s(_vm.$t('tip.box_title'))+" ")]),_c('div',{staticClass:"d-flex pb-4"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"tip-menu-btn",class:{
            other: _vm.selectedTip === 'other',
            mobile: _vm.$vuetify.breakpoint.mobile,
          },attrs:{"depressed":"","color":"#ECECEC"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedTip)+_vm._s(_vm.selectedTip === 'other' ? '' : '%')+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"28"}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,1399263779)},[_c('v-list',[_vm._l((_vm.tipOptions),function(option,index){return _c('v-list-item',{key:index,on:{"click":function($event){$event.preventDefault();return _vm.selectTip(option)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(option)+"% ")])],1)}),_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.selectTip('other')}}},[_c('v-list-item-title',[_vm._v(" Other ")])],1)],2)],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],ref:"input",staticClass:"amount-input",class:{
        'mobile pl-3 pr-3': _vm.$vuetify.breakpoint.mobile,
        'pl-6 pr-6': !_vm.$vuetify.breakpoint.mobile,
      },attrs:{"id":"tip-input","disabled":!(_vm.donationAmount > 0) || _vm.selectedTip !== 'other'},domProps:{"value":(_vm.amount)},on:{"blur":function($event){_vm.inpurIsFocused = false},"focus":function($event){_vm.inpurIsFocused = true},"input":function($event){if($event.target.composing)return;_vm.amount=$event.target.value}}})],1),_c('div',{staticClass:"ml-1 explaination pb-1",class:{
      mobile: _vm.$vuetify.breakpoint.mobile,
    }},[_vm._v(" "+_vm._s(_vm.$t('tip.label2'))+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"size":_vm.$vuetify.breakpoint.mobile ? 17 : 21}},[_vm._v(" mdi-cards-heart ")])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }