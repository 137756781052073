import axios from 'axios';
import { ActionTree, MutationTree } from 'vuex';
import { IRootState } from '@/models/IRootState';
import { iso2iso3 } from '@/constants/countryList';
import { get } from '@/helpers/getUrlParameters';
import { PayrixState } from './models/payrix';

const test = get('test') === '1'

const host = `https://${test ? 'test-' : ''}api.payrix.com`

const createCustomers = `${host}/customers`
const createTokens = `${host}/tokens`

const state: PayrixState = {
  number: '',
  cvv: '',
  expiration: '',
}

const mutations: MutationTree<PayrixState> = {
  setPayrixData(state, { key, value }) {
    state[key] = value;
  },
}

const actions: ActionTree<PayrixState, IRootState> = {
  payrixCreateCustomer({ rootState, dispatch, commit }) {
    const { api_key, merchant } = rootState.donation.paymentConfig.attributes.configuration;

    const {
      email,
      address_city,
      // address_state,
      address_zip,
      address_line1,
      address_line2,
      address_country,
      phone,
      billing_first_name: bfn,
      billing_last_name: bln,
      receipt_name: rn,
      billing_name,
    } = rootState.donation.donationData.attributes;

    const [fn, ...ln] = (rn || billing_name || `${bfn} ${bln}`).replace(/[`'"]+/g, '').split(' ');
    const shortPhone = phone.replace(/[+\- ]+/g, '');

    const payload = {
      merchant,
      first: fn,
      last: ln.join(' '),
      email,
      address1: address_line1 || null,
      address2: address_line2 || null,
      city: address_city || null,
      // state: address_state || null,
      zip: address_zip || null,
      country: iso2iso3[address_country || ''] || null,
      phone: shortPhone || null,
    }

    return new Promise(resolve => {
      axios.post(
        createCustomers,
        payload,
        { headers: { APIKEY: api_key } },
      )
        .then(({ data }) => {
          const res = data?.response || data

          if (res?.errors?.length) {
            const errorText = res?.errors?.reduce(
              (res: string, el: any) => `${res}${res && '\n'}${el.field || ''} - ${el.msg}`, '',
            )

            commit('setError', {
              title: 'Payrix Create Customer Error!',
              text: errorText,
            });
            dispatch('trackClientError')
            commit('setIsSubmitted', false);
          } else {
            resolve(res?.data)
          }
        })
        .catch(err => {
          commit('setIsSubmitted', false);
          console.log(err);
        })
    });
  },

  payrixCreateToken({
    commit, dispatch, state, rootState,
  }) {
    const { api_key } = rootState.donation.paymentConfig.attributes.configuration;

    return new Promise(resolve => {
      dispatch('payrixCreateCustomer')
        .then(customer => {
          const payload = {
            customer: customer?.id,
            payment: {
              number: state.number,
              cvv: state.cvv,
            },
            expiration: state.expiration || null,
          }

          axios.post(
            createTokens,
            payload,
            { headers: { APIKEY: api_key } },
          )
            .then(({ data }) => {
              const res = data?.response || data

              if (res?.errors?.length) {
                const errorText = res?.errors?.reduce(
                  (res: string, el: any) => `${res}${res && '\n'}${el.field || ''} - ${el.msg}`, '',
                )

                commit('setError', {
                  title: 'Payrix Create Token Error!',
                  text: errorText,
                });
                dispatch('trackClientError')
                commit('setIsSubmitted', false);
              } else {
                resolve(res?.data)
              }
            })
            .catch(err => {
              commit('setIsSubmitted', false);
              console.log(err);
            })
        })
        .catch(err => {
          console.log(err);
          commit('setIsSubmitted', false);
        })
    });
  },
}

export default {
  state,
  mutations,
  actions,
}
