
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import { pmListIFrame } from '@/constants/paymentMethodsInIFrame';

import StripeElement from '@/components/subcomponents/paymentSystems/StripeElement.vue';
import StripeTerminal from '@/components/subcomponents/paymentSystems/StripeTerminal.vue';
import NedarimPlusBitContent from '@/components/subcomponents/paymentSystems/NedarimPlusBitContent.vue';
import MercadoPagoDirect from '@/components/subcomponents/paymentSystems/MercadoPagoDirect.vue';

import { IRootState } from '../models/IRootState';
import { IDialogData } from './components-models/Dialog.model';

const VueComponent = Vue.extend({
  components: {
    StripeElement,
    NedarimPlusBitContent,
    MercadoPagoDirect,
    StripeTerminal,
  },

  data(): IDialogData {
    return {
      showIframe: false,
      loading: false,
      meshulamLoading: false,
      form: null,
    };
  },

  computed: {
    ...customMapState({
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
      title: (state: IRootState) => state.donation.dialog.title,
      iframeSrc: (state: IRootState) => state.donation.dialog.iframe.src,
      lang: (state: IRootState) => state.translation.locate,
      nedarimplusSubmitting: (state: IRootState) => state.nedarimplus.submitting,
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
    }),

    ...mapGetters({
      confirmationStep1: 'confirmationStep1',
    }),

    titleText(): string {
      if (Array.isArray(this.title)) {
        return this.$t(...this.title) as string;
      }
      return this.$t(`template.${this.paymentMethod}_dialog_title`, this.title) as string;
    },

    dialogCustomClass(): Object {
      return {
        'cardcom-bit-dialog-body': this.paymentMethod === 'cardcom-bit' || this.paymentMethod === 'jaffa-bit',
        'nedarim-plus-dialog-body': this.paymentMethod === 'nedarim-plus-native',
      }
    },
    iframeCustomClass(): Object {
      return {
        'cardcom-bit-dialog-body-iframe': this.paymentMethod === 'cardcom-bit' || this.paymentMethod === 'jaffa-bit',
        'nedarim-plus-dialog-body-iframe': this.paymentMethod === 'nedarim-plus-native',
      }
    },
  },

  created() {
    this.$root.$on('MeshulamActiveLoader_nauK1M54J', (v: boolean) => {
      this.meshulamLoading = v;
    });
  },

  beforeDestroy() {
    this.$root.$off('MeshulamActiveLoader_nauK1M54J');

    document.body.classList.remove('no-scroll');
    document.documentElement.classList.remove('no-scroll');
  },

  mounted(): void {
    const pmList = pmListIFrame;

    if (pmList.includes(this.paymentMethod)) {
      this.showIframe = true;
      this.loading = true;
    }
    this.disableScroll();
  },

  methods: {
    ...mapActions({
      nedarimSetIFrame: 'NedarimSetIFrame',
      nedarimPay: 'NedarimPay',
    }),

    closeDialog(): void {
      this.$store.commit('setDialogVisibleStatus', false);
      this.$store.commit('setIsSubmitted', false);
      this.$store.commit('setDialogIframeSrc', '');
      // this.$store.commit('setDialogParameters', null);

      this.showIframe = false;
      if (this.form) this.form.remove();

      // Enable sroll again
      document.body.classList.remove('no-scroll');
      document.documentElement.classList.remove('no-scroll');
    },

    disableScroll(): void {
      document.body.classList.add('no-scroll');
      document.documentElement.classList.add('no-scroll');
    },

    loaded(): void {
      this.loading = false;

      const event = new CustomEvent('donationsIframeLoaded');
      window.dispatchEvent(event);

      if (this.paymentMethod === 'nedarim-plus-native') {
        const iframe = this.$refs.donationsIframe as HTMLIFrameElement
        this.nedarimSetIFrame(iframe)
      }

      if (this.paymentMethod === 'israeltoremet' && !this.form) {
        const mainSrc = this.iframeSrc.split('&');

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = this.iframeSrc;
        form.target = 'donationsIframe';

        for (let i = 0; mainSrc.length > i; i += 1) {
          if (!mainSrc[i].includes('sum')) {
            const el = document.createElement('input');
            [el.name, el.value] = mainSrc[i].split('=');
            el.hidden = true;
            form.appendChild(el);
          }
        }

        if (mainSrc.length) {
          document.body.appendChild(form);
        }

        form.submit();
        this.form = form;
      }
    },
  },
});
export default class Dialog extends VueComponent {}
