
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '../../../models/IRootState';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  props: {
    shouldValidate: Boolean,
  },

  computed: {
    ...customMapState({
      paymentConfig: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
    }),

    localZeout: {
      get() :number {
        return this.$store.state.donation.donationIncludedGatewayParams.attributes.nedarim_plus_bit_zehut;
      },
      set(value: number) {
        this.$store.commit('setDonationIncludedGatewayParams', { nedarim_plus_bit_zehut: Number(value) });
      },
    },

    fallbackErrMsg() {
      if (this.vErrors.has('zeout')) {
        return this.vErrors.items[0]?.msg
      }

      return ''
    },
  },
});
export default class NedarimPlusBit extends VueComponent {}
