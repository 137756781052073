export const popupWindow = (url: string, title: string, width: number, height: number): Window => {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  const options = `${[
    `width=${width}`,
    `height=${height}`,
    `top=${top}`,
    `left=${left}`,
  ].join(',')}`;

  return window.open(url, title, options) as Window;
};

export default popupWindow;
