import axios from 'axios';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { nanoid } from 'nanoid';
import urls from '@/constants/urls';
import { get } from '@/helpers/getUrlParameters';
import { IRootState } from '@/models/IRootState';
import { IStripeState } from './models/stripe';
import { PaymentConfig } from './models/donation';

const state: IStripeState = {
  stripe: null,
  elements: null,
  canMakePayment: null,

  stripeIdempotencyKey: '',

  paymentIntentClientSecret: '',
}

const mutations: MutationTree<IStripeState> = {
  setStripe(state, stripe) {
    state.stripe = stripe;
  },

  setElements(state, elems) {
    state.elements = elems;
  },

  setCanMakePayment(state, res) {
    state.canMakePayment = res;
  },

  setPaymentIntentClientSecret(state, res) {
    state.paymentIntentClientSecret = res;
  },

  setStripeIdempotencyKey(state, data) {
    state.stripeIdempotencyKey = data;
  },
}

const getters: GetterTree<IStripeState, IRootState> = {
  getStripe(state) {
    if (state.stripe) {
      return state.stripe;
    }

    return Stripe;
  },

  getStripeMethod(s, g, rootState) {
    return ({ po } = {} as { po: undefined | PaymentConfig }) => {
      const paymentConfig = po || rootState.donation.paymentConfig;

      const { configuration: { stripe_method } } = paymentConfig.attributes;

      return stripe_method
    }
  },

  isPaymentRequestBtn(s, g, rootState) {
    const { paymentMethod } = rootState.donation;

    return [
      'stripe-apple-pay',
      'stripe-google-pay',
    ].includes(paymentMethod)
  },

  isCanMakePayment(s, g, rootState) {
    const { paymentMethod } = rootState.donation;

    if (paymentMethod === 'stripe-apple-pay') {
      return !!s.canMakePayment;
    }

    if (paymentMethod === 'stripe-google-pay') {
      return !!s.canMakePayment;
    }

    return true;
  },
}

const actions: ActionTree<IStripeState, IRootState> = {
  useStripe({ commit, rootState, getters }, {
    key = '', options = {}, po, returnInctance,
  } = {}): any {
    if (typeof Stripe === 'undefined') {
      commit('setError', {
        key: 'stripe-script-load-error',
        title: 'Stripe can\'t be reached!',
        translationKey: [
          'template.stripe_load_error',
          'Please check if you have a filter blocking access to stripe.com',
        ],
      });
      return undefined;
    }

    const paymentConfig = (po as PaymentConfig) || rootState.donation.paymentConfig;

    const { name, configuration } = paymentConfig.attributes;
    const { pk_live, connected_stripe_account_id: stripeAccount, stripe_ideal_direct_charge: siddc } = configuration;

    const stripeMethod = getters.getStripeMethod({ po });

    const isPi = ['stripe-becs'].includes(name) || stripeMethod === 'payment_intention' || siddc === 'true';

    const stripeOpt = (isPi && stripeAccount && { stripeAccount }) || {};

    const stripeInstance = Stripe(pk_live || key, { ...stripeOpt, ...options, apiVersion: '2020-08-27' })

    if (returnInctance) {
      return stripeInstance;
    }

    commit('setStripe', stripeInstance);

    return null;
  },

  setElements({ commit }, elems) {
    commit('setElements', elems);
  },

  async checkIfCanMakePayment({ state, commit, dispatch }) {
    await dispatch('useStripe');

    const { stripe } = state;

    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Test',
        amount: 100,
      },
    });

    const res = await paymentRequest.canMakePayment();

    commit('setCanMakePayment', res)
  },

  async checkIfCanMakePaymentOnPageLoad({ commit, dispatch, rootState }) {
    const po = rootState.donation.included.find(
      el => ['stripe-apple-pay', 'stripe-google-pay'].includes(el.attributes.name),
    )

    if (!po) {
      return true;
    }

    const stripe = await dispatch('useStripe', { po, returnInctance: true });

    if (!stripe) {
      return false
    }

    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Test',
        amount: 100,
      },
    });

    const res = await paymentRequest.canMakePayment();

    commit('setCanMakePayment', res)

    return true
  },

  statusUpdateStripe({ rootState }, { intent_id, error = false }) {
    const data = {
      intent_id,
      error,
      campaign_id: rootState.donation.campaign.data.id,
    };

    return axios
      .post(urls.stripeConfirmation, data)
  },

  statusUpdateStripeElement({ rootState }, { intent_id, error = false }) {
    const data = {
      intent_id,
      error,
      campaign_id: rootState.donation.campaign.data.id,
    };

    return axios
      .post(urls.statusUpdateStripeElement, data)
  },

  async handleCardAction({ state, commit, dispatch }, response: any) {
    const { error: errorAction, paymentIntent } = await state.stripe.handleCardAction(
      response.payment_intent_client_secret,
    );

    if (errorAction) {
      commit('setIsSubmitted', false);
      commit('setError', {
        title: 'Stripe Intent Error!',
        text: errorAction.message,
      });
      dispatch('trackClientError')
      const { paymentIntent } = await state.stripe.retrievePaymentIntent(
        response.payment_intent_client_secret,
      );
      if (paymentIntent) {
        dispatch('statusUpdateStripe', { intent_id: paymentIntent.id, error: true })
      }
    } else {
      commit('setDonationIncludedGatewayParams', {
        stripe_payment_intent_id: paymentIntent.id,
      });
      dispatch('makeDonation', { stripeIntentConfirmation: true });
    }
  },

  handleStripeRedirect({
    state, rootState, commit, dispatch,
  }) {
    const { included } = rootState.donation;
    const poId = get('po');
    const clientSecret = get('payment_intent_client_secret');

    return new Promise(resolve => {
      if (poId && clientSecret) {
        const po = included.find(el => el.id === poId && el.type === 'payment_option');

        if (po?.attributes.name === 'stripe-element') {
          const paymentIntent = get('payment_intent');
          const redirectStatus = get('redirect_status');

          dispatch('statusUpdateStripeElement', { intent_id: paymentIntent })
            .then(() => {
              if (redirectStatus === 'failed') {
                commit('setError', {
                  title: 'Error!',
                  text: 'Redirect failed',
                });
              } else {
                commit('setIsSucceeded', true);

                window.postMessage({
                  type: 'gateway-response-success',
                }, '*');
              }
            })
            .catch(err => {
              commit('setError', {
                title: 'Stripe Status Update Error!',
                text: err.response.data.error,
              });
            })
          resolve(true);
          return
        }

        dispatch('useStripe', { po });

        state.stripe.retrievePaymentIntent(clientSecret).then((response: any) => {
          if (response.error) {
            commit('setError', {
              title: 'Stripe Intent Error!',
              text: response.error.message,
            });
            dispatch('trackClientError')
          } else if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
            dispatch('statusUpdateStripe', { intent_id: response.paymentIntent.id })
              .then(() => {
                commit('setIsSucceeded', true);

                window.postMessage({
                  type: 'gateway-response-success',
                }, '*');
              })
              .catch(err => {
                commit('setError', {
                  title: 'Stripe Status Update Error!',
                  text: err.response.data.error,
                });
              })
          } else if (response.paymentIntent && response.paymentIntent.last_payment_error) {
            commit('setError', {
              title: 'Stripe Intent Error!',
              text: response.paymentIntent.last_payment_error.message,
            });
            dispatch('trackClientError')
          }
          resolve(true);
        });
      } else {
        resolve(true);
      }
    })
  },

  confirmIdealPayment({
    state, commit, dispatch, rootState,
  }, parameters) {
    const { origin, pathname, search } = window.location;
    const donationAttrs = rootState.donation.donationData.attributes;
    const { billing_first_name: bfn, billing_last_name: bln } = donationAttrs;

    const newSearch = new URLSearchParams(search);
    newSearch.append('po', donationAttrs.payment_option_id);
    newSearch.append('amount', String(donationAttrs.amount / 100));
    newSearch.append('currency', donationAttrs.currency);

    state.stripe.confirmIdealPayment(
      parameters.payment_intent_client_secret,
      {
        payment_method: {
          ideal: state.elements.idealBank,
          billing_details: {
            name: donationAttrs.billing_name || `${bfn} ${bln}`,
          },
        },
        return_url: `${origin}${pathname}?${newSearch.toString()}`,
      },
    ).then((res: any) => {
      if (res.error) {
        commit('setError', {
          title: 'Stripe Intent Error!',
          text: res.error.message,
        });
        dispatch('trackClientError')
        commit('setIsSubmitted', false);
      } else {
        commit('setIsSubmitted', false);
      }
    });
  },

  confirmAuBecsDebitPayment({
    state, commit, dispatch, rootState,
  }, parameters) {
    const donationAttrs = rootState.donation.donationData.attributes;
    const { billing_first_name: bfn, billing_last_name: bln } = donationAttrs;

    state.stripe.confirmAuBecsDebitPayment(
      parameters.payment_intent_client_secret,
      {
        payment_method: {
          au_becs_debit: state.elements.auBankAccount,
          billing_details: {
            name: donationAttrs.billing_name || `${bfn} ${bln}`,
            email: donationAttrs.email,
          },
        },
      },
    ).then(async (res: any) => {
      commit('setIsSubmitted', false);
      if (res.error) {
        commit('setError', {
          title: 'Stripe Intent Error!',
          text: res.error.message,
        });
        dispatch('trackClientError')
        const { paymentIntent } = await state.stripe.retrievePaymentIntent(
          parameters.payment_intent_client_secret,
        );
        if (paymentIntent) {
          dispatch('statusUpdateStripe', { intent_id: paymentIntent.id, error: true })
        }
      } else if (res.paymentIntent && res.paymentIntent.status === 'processing') {
        dispatch('statusUpdateStripe', { intent_id: res.paymentIntent.id })
          .then(() => {
            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
          })
          .catch(() => {
            commit('setError', {
              title: 'Stripe Status Update Error!',
              text: '',
            });
          })
      }
    });
  },

  async confirmCardPayment({
    state, commit, dispatch, rootState,
  }, parameters) {
    const { organization } = rootState.donation.donationParams.data.attributes.config
    const { attributes: data } = rootState.donation.donationData
    const { stripe } = state;

    const elements = stripe.elements();

    const { paymentConfig } = rootState.donation;
    const { billing_address_optional } = paymentConfig.attributes.configuration;

    try {
      const paymentRequest = stripe
        .paymentRequest({
          country: paymentConfig.attributes.organization_legal_entity.country,
          currency: data.currency,
          total: {
            label: `Donation to ${organization.orgname}`,
            amount: data.amount,
          },
          requestPayerName: billing_address_optional === 'false',
          requestPayerEmail: billing_address_optional === 'false',
        })

      const clientSecret = parameters.payment_intent_client_secret

      const updStatusWithError = async () => {
        const { paymentIntent } = await state.stripe.retrievePaymentIntent(clientSecret);
        if (paymentIntent) {
          dispatch('statusUpdateStripe', { intent_id: paymentIntent.id, error: true })
        }
      }

      const updStatusAndShowThanxPage = (paymentIntent: any) => {
        dispatch('statusUpdateStripe', { intent_id: paymentIntent.id })
          .then(() => {
            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
          })
          .catch(() => {
            commit('setError', {
              title: 'Stripe Status Update Error!',
              text: '',
            });
          })
      }

      window.addEventListener('message', e => {
        if (e.origin.includes('js.stripe.com')) {
          const messageData = JSON.parse(e.data)
          const { caRes } = messageData?.message?.payload || {};

          if (caRes?.type === 'error') {
            commit('setIsSubmitted', false);
            commit('setStripeIdempotencyKey', nanoid());
            commit('setError', {
              title: 'Stripe Confirm Card Payment Error!',
              text: caRes?.error?.message,
            });
            dispatch('trackClientError')
          }
        }
      })

      paymentRequest.on('paymentmethod', async (ev: any) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false },
        );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          commit('setIsSubmitted', false);
          commit('setStripeIdempotencyKey', nanoid());
          commit('setError', {
            title: 'Stripe Confirm Card Payment Error!',
            text: confirmError.message,
          });
          dispatch('trackClientError')
          updStatusWithError()

          ev.complete('fail');

          console.error(`ev.complete('fail') ${ev.paymentMethod.id} ${confirmError}`);
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11" instead
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              commit('setIsSubmitted', false);
              commit('setStripeIdempotencyKey', nanoid());
              commit('setError', {
                title: 'Stripe Confirm Card Payment Error!',
                text: error.message,
              });
              dispatch('trackClientError')
              updStatusWithError()
            } else {
              // The payment has succeeded.
              updStatusAndShowThanxPage(paymentIntent)
            }
          } else {
            // The payment has succeeded.
            updStatusAndShowThanxPage(paymentIntent)
          }
        }
      });

      paymentRequest.on('cancel', () => {
        commit('setIsSubmitted', false);
        commit('setStripeIdempotencyKey', nanoid());
      });

      const result = await paymentRequest.canMakePayment();

      if (result) {
        const prButton = elements.create(
          'paymentRequestButton',
          {
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'donate',
                height: '46px',
              },
            },
          },
        );
        prButton.mount('#payment-request-button');
        prButton.on('click', () => {
          commit('setIsSubmitted', true);
        });
        commit('setIsSubmitted', false);
      } else {
        commit('setIsSubmitted', false);
        commit('setStripeIdempotencyKey', nanoid());
      }
    } catch (err) {
      const error = err as Error
      console.log(error);
      commit('setIsSubmitted', false);
      commit('setError', {
        title: 'Stripe Create Payment Request Error!',
        text: error.message,
      });
      dispatch('trackClientError')
    }
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
