interface CustomError extends Error {
  custom: boolean;
  tk: boolean;
  translation_key: string;
  attr: any;
}

export function enhanceError(error: CustomError, attr: any) {
  const { error_message, error_translation_key } = attr;

  error.custom = true;
  if (error_message) {
    error.message = error_message;
    error.attr = attr;
  }

  if (error_translation_key) {
    error.tk = true;
    error.translation_key = error_translation_key;
  }

  return error;
}

export function createError(message: string, attr: any) {
  const error = new Error(message) as CustomError;
  return enhanceError(error, attr);
}

export function parseError(err: any, gtwName: string): [string, string] {
  let errtk = err?.tk
    ? err?.translation_key
    : err?.response?.data.error_translation_key;
  const errmsg = err?.custom
    ? err?.message
    : err?.response?.data?.error;

  if (!errtk && err?.attr?.error_code_str_gateway) {
    errtk = `errors.${gtwName}_${err.attr.error_code_str_gateway}`
  }

  return [errtk, errmsg]
}

export default { createError }
