import Vuex from 'vuex';
import Vue from 'vue';
import vuexI18n from 'vuex-i18n';
import donation from './modules/donation';
import translation from './modules/translation';
import stripe from './modules/stripe';
import redirect from './modules/redirect';
import payrix from './modules/payrix';
import nedarimplus from './modules/nedarimplus';
import trackingDonationErrorsClient from './modules/tracking-donation-errors-client';
import levels from './modules/levels';
import gateways from './modules/gateways';
import teams from './modules/teams';
import dlocal from './modules/dlocal';
import recurringInstallment from './modules/recurring-installment';
import braintreeDropin from './modules/braintree-dropin';
import donorsfund from './modules/donorsfund';
import usaepay from './modules/usaepay';
import customdata from './modules/custom-data';
import orgLangs from './modules/orgLangs';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    donation,
    translation,
    stripe,
    redirect,
    payrix,
    nedarimplus,
    trackingDonationErrorsClient,
    levels,
    gateways,
    teams,
    dlocal,
    recurringInstallment,
    braintreeDropin,
    donorsfund,
    usaepay,
    customdata,
    orgLangs,
  },
});

const config = {
  translateFilterName: 't',
};

Vue.use(vuexI18n.plugin, store, config);

Vue.i18n.set(translation.state.locate);

store.watch(store.getters.loadingLang, () => {
  Vue.i18n.set(translation.state.locate);
  if (translation.state.locate !== 'en') {
    Vue.i18n.fallback('en');
    Vue.i18n.add(translation.state.locate, translation.state.translation[translation.state.locate]);
    Vue.i18n.add('en', translation.state.translation.en);
  } else {
    Vue.i18n.fallback('en');
    Vue.i18n.add(translation.state.locate, translation.state.translation[translation.state.locate]);
  }
});

store.watch(store.getters.translation, () => {
  Vue.i18n.add(translation.state.locate, translation.state.translation[translation.state.locate]);
  Vue.i18n.add('en', translation.state.translation.en);
});

store.watch(store.getters.selectedLang, () => {
  if (translation.state.locate !== null && !translation.state.loadLang) {
    store.dispatch('getTranslation');
    Vue.i18n.fallback('en');
    Vue.i18n.set(translation.state.locate);
  }
});

export default store;
