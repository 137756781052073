var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.show)?_c('div',{staticClass:"wrapper mt-6"},[_c('h2',{staticClass:"pb-6",class:{
      mobile: _vm.$vuetify.breakpoint.mobile,
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"pb-6",class:{
      mobile: _vm.$vuetify.breakpoint.mobile,
    }},[_vm._v(" "+_vm._s(_vm.boxText)+" ")]),_c('div',{staticClass:"tip-block"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showRangePicker),expression:"!showRangePicker"}],staticClass:"tip-block-input"},[_c('div',{staticClass:"header"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('tip.tip_amount', 'Tip amount'))+": ")]),_c('button',{staticClass:"tip-type-toggle-button",on:{"click":function($event){$event.preventDefault();return (() => _vm.displayRangePicker()).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('tip.back_to_range', "Back to default option"))+" ")])]),_c('div',{ref:"inputWrap",staticClass:"tip-block-input-wrap"},[_c('div',{staticClass:"sign"},[_vm._v(" "+_vm._s(_vm.donationCurrency.sign)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ohterTipAmount),expression:"ohterTipAmount"}],ref:"inputRef",staticClass:"input",attrs:{"type":"number","autofocus":""},domProps:{"value":(_vm.ohterTipAmount)},on:{"input":function($event){if($event.target.composing)return;_vm.ohterTipAmount=$event.target.value}}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRangePicker),expression:"showRangePicker"}],staticClass:"tip-block-range"},[_c('div',{staticClass:"range-picker-div"},[_c('div',{staticClass:"tip-percentage-begin"},[_vm._v(" "+_vm._s(_vm.min)+"% ")]),_c('div',{staticClass:"vue-slider-wrapper"},[_c('vue-slider',{attrs:{"adsorb":true,"interval":_vm.interval,"marks":true,"height":_vm.barSize+'px',"width":"100%","tooltip":'always',"hide-label":true,"max":_vm.max,"min":_vm.min,"dot-size":_vm.dotSize,"direction":_vm.lang === 'he' ? 'rtl' : 'ltr',"dot-style":{
              border: '4px solid '+_vm.dotBorderColor,
              background: _vm.dotColor,
              boxShadow: '0 0 5px 1px #ccc',
              borderRadius: '50%',
              height: '100%',
              width: '100%',
              boxSizing: 'border-box',
            },"rail-style":{
              background: _vm.barColor,
              borderRadius: '15px',
            },"process-style":{
              background: _vm.progressColor,
              borderRadius: '15px',
            }},scopedSlots:_vm._u([{key:"mark",fn:function({ pos }){return [_c('div',{staticClass:"custom-mark",style:({
                  left: `${pos}%`,
                  height:`${_vm.barSize}px`
                })})]}},{key:"tooltip",fn:function({ value }){return [_c('div',{staticClass:"tip-tooltip-div"},[(_vm.donationAmount > 0)?_c('span',{staticClass:"amount"},[_vm._v(" "+_vm._s(_vm.rangePickerTipAmount)+_vm._s(_vm.donationCurrency.sign)+" ")]):_vm._e(),(_vm.donationAmount <= 0)?_c('span',{staticClass:"percentage"},[_vm._v(" "+_vm._s(value)+"% ")]):_vm._e(),(_vm.donationAmount > 0)?_c('span',{staticClass:"percentage"},[_vm._v(" ("+_vm._s(value)+"%) ")]):_vm._e()])]}}],null,false,1451342574),model:{value:(_vm.selectedPercent),callback:function ($$v) {_vm.selectedPercent=$$v},expression:"selectedPercent"}})],1),_c('div',{staticClass:"tip-percentage-end"},[_vm._v(" "+_vm._s(_vm.max)+"% ")])]),_c('div',{staticClass:"custom-tip"},[_c('button',{staticClass:"tip-type-toggle-button",on:{"click":function($event){$event.preventDefault();return (() => _vm.hideRangePicker()).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('tip.custom_tip', "Custom Tip"))+" ")])])])]),_c('div',{ref:"tipMessageRef",staticClass:"tip-message tip-message-hide"},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"support-image",attrs:{"src":`${_vm.rackcdnHost}/public_upload/1643804883_2ff461b6375bb99efb9bebd202f37d95_1643804883.png`,"alt":"support-image","srcset":""}})]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.supportMessage)+" ")])]),_c('div',{staticClass:"total-charge"},[_vm._v(" "+_vm._s(_vm.$t('tip.total_charge', { currencyCode: _vm.donationCurrency.code.toUpperCase(), amount: _vm.totalCharge, monthly: _vm.recurringPeriod, }))+" "+_vm._s(_vm.totalChargeSuffix)+" ")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }