
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { load, ReCaptchaInstance } from 'recaptcha-v3'
import VueRecaptcha from 'vue-recaptcha';

import { ConfirmationInterfaceNew } from '@/components/subcomponents/subcomponents-models/Cofirmation-models';
import { AvailableDisplayCurrencyList } from '@/store/modules/models/donation';
import customMapState from '../../helpers/mapHelper';

import { IRootState } from '../../models/IRootState';
import HelpTip from '../shared/help-tip.vue';

const VueComponent = Vue.extend({
  components: {
    VueRecaptcha,
    HelpTip,
  },

  data(): ConfirmationInterfaceNew {
    return {
      termsFlag: false,
      termsError: false,
      sendFlag: false,
      termsPrivacyFlag: false,
      reCaptchav3: null,
      tipDialog: false,
      selectedTip: 0,
      tipLabels: [
        this.$t('confirmation.tipLabel0') as string,
        this.$t('confirmation.tipLabel1') as string,
        this.$t('confirmation.tipLabel2') as string,
        this.$t('confirmation.tipLabel3') as string,
        this.$t('confirmation.tipLabel4') as string,
      ],
    };
  },

  computed: {
    ...customMapState({
      donationData: (state: IRootState) => state.donation.donationData.attributes,

      installment: (state: IRootState) => state.donation.donationData.attributes.installment,

      recurring: (state: IRootState) => state.donation.donationData.attributes.recurring,

      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),

      campaign: (state: IRootState) => state.donation.campaign.data.attributes,

      isSubmitted: (state: IRootState) => state.donation.isSubmitted,

      captcha: (state: IRootState) => state.donation.donationParams.data.attributes.captcha,

      captchaSiteKey: (state: IRootState) => state.donation.donationParams.data
        .attributes.captcha_sitekey,

      captchaSiteV3: (state: IRootState) => state.donation.donationParams.data
        .attributes.captcha_site_v3,

      captchaToken: (state: IRootState) => state.donation.donationData.attributes.captcha_token,

      config: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.tip_config,
    }),

    ...mapGetters({
      showAmountInLocCurrency: 'showAmountInLocCurrency',
      paymentsSummaryLabel: 'paymentsSummaryLabel',
      originAmount: 'originAmount',
      currencySign: 'currencySign',
      tipAmount: 'tipAmount',
      recurringPeriod: 'recurringPeriod',
      multipliedAmount: 'multipliedAmount',
      multiplier: 'multiplier',
      totalRecurringAmount: 'totalRecurringAmount',
      feeConfig: 'feeConfig',
      tipOptions: 'tipOptions',
      tipOnConfirmStep: 'tipOnConfirmStep',
      paymentMethod: 'paymentMethod',
      donationAmount: 'donationAmount',
      totalAmount: 'totalAmount',
      orgName: 'orgName',
      installmentPeriod: 'installmentPeriod',
    }),

    totalChargeAmount(): number {
      const { amount } = this.donationData
      return amount + this.tipAmount;
    },

    dontionAmountString(): string {
      const { currency_sign, currency, amount } = this.donationData
      return `${currency_sign}${amount} ${currency.toUpperCase()}`;
    },

    campaignCurrency(): AvailableDisplayCurrencyList {
      const state = this.$store.state as IRootState;
      if (this.showAmountInLocCurrency) {
        return this.showAmountInLocCurrency;
      }
      return {
        sign: state.donation.campaign.data.attributes.currency_sign,
        code: state.donation.campaign.data.attributes.currency,
      }
    },

    installmentMonthlyAmount(): string {
      return `${this.donationCurrency.sign}${Math.ceil(this.donationAmount / this.installmentPeriod)}`;
    },

    recurringTotal(): string {
      const donationAmt = this.donationAmount

      return `${this.donationCurrency.sign}${this.recurringPeriod * donationAmt}`
    },

    receivesAmount(): string {
      const amt = this.totalAmount * (this.recurringPeriod || 1);
      return `${this.campaignCurrency.sign}${amt}`
    },

    paymentFeeStatus: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes
          .include_payment_system_fee;
      },
      set(status: boolean) {
        this.$store.commit('setIncludePaymentFeeStatus', status);
        if (status) {
          this.$store.commit('setIncludePaymentFeeAmount', this.feeAmount);
        } else {
          this.$store.commit('setIncludePaymentFeeAmount', 0);
        }
      },
    },

    feeAmount(): number {
      const { feeConfig, paymentMethod, donationAmount } = this;

      const config = feeConfig.fee_rate[paymentMethod];
      const amount = Math.ceil(
        config.fixed + ((config.percentage * (donationAmount / 100)) / 100),
      )

      if (config.limit && config.limit < amount) {
        return config.limit
      }

      if (feeConfig.limit && feeConfig.limit < amount) {
        return feeConfig.limit
      }

      return amount;
    },

    percent(): number {
      const isExist = this.tipOptions.includes(this.config.percent);
      return (isExist && this.config.percent) || this.tipOptions[1];
    },
  },

  watch: {
    termsFlag: 'termsCheck',

    captchaToken(value) {
      if (value === '' && !this.captchaSiteV3) {
        (this.$refs.captcha as any).reset();
      }
    },

    selectedTip(newVal: number) {
      this.setTipByPercentage(newVal);
    },
  },

  mounted() {
    if (this.tipOnConfirmStep) {
      this.selectedTip = this.percent;
    }
    if (this.captchaSiteV3) {
      this.reCaptchav3Load()
    }
  },

  methods: {
    ...mapActions([
      'setTipByPercentage',
    ]),

    backToForm(): void {
      this.$store.commit('setCaptchaToken', '');
      this.termsFlag = false;
      this.$emit('go-back');
    },

    async reCaptchav3Load(): Promise<[ReCaptchaInstance | null, unknown | null]> {
      if (this.reCaptchav3 == null) {
        try {
          this.reCaptchav3 = await load(this.captchaSiteKey);
        } catch (error) {
          return [null, error]
        }
      }
      return [this.reCaptchav3, null]
    },

    async confirmDonation(): Promise<void> {
      if (this.isSubmitted) {
        return;
      }

      if (this.termsFlag) {
        if (this.captchaSiteV3) {
          const [, err] = await this.reCaptchav3Load()
          if (err && !this.reCaptchav3) {
            this.$store.commit('setIsSubmitted', false);
            this.$store.commit('setError', {
              title: 'Captcha',
              translationKey: [
                'donation.captcha_load_faild',
                'Failed to load captcha script. Try again',
              ],
            });
            return;
          }

          if (this.reCaptchav3) {
            let token = await this.reCaptchav3.execute('e_commerce');
            if (token === '') {
              token = await this.reCaptchav3.execute('e_commerce');
            }
            if (token === '') {
              token = await this.reCaptchav3.execute('e_commerce');
            }
            if (token === '') {
              this.$store.commit('setIsSubmitted', false);
              this.$store.commit('setError', {
                title: 'Captcha',
                translationKey: [
                  'donation.captcha_token_empty',
                  'Failed to generate captcha token. Try again',
                ],
              });
              return;
            }
            this.verify(token);
          }
        }

        if (this.paymentMethod === 'stripe-ach') {
          const promis = new Promise(resolve => this.$root.$emit('openPlaidLink', resolve))
          await promis;
          // const res = await promis;
          // if (res) {
          //   this.$store.dispatch('makeDonation');
          // }
          // return;
        }

        if (this.paymentMethod === 'stripe') {
          this.$store.commit('setIsSubmitted', true);
          const promis = new Promise(resolve => this.$root.$emit('stripeCreateToken', resolve))
          await promis;
          // const res = await promis;
          // if (res) {
          //   this.$store.dispatch('makeDonation');
          // } else {
          //   this.$store.commit('setIsSubmitted', false);
          // }
          // return;
        }

        this.$store.dispatch('makeDonation');
      } else {
        this.termsError = true;
      }
    },

    termsCheck(): void {
      if (this.termsFlag) {
        this.termsError = false;
      } else {
        this.termsError = true;
      }
    },

    verify(captchaToken: string): void {
      this.$store.commit('setCaptchaToken', captchaToken);
    },

    expired() {
      this.$store.commit('setCaptchaToken', '');
    },

    selectTip(percentage: number) {
      this.selectedTip = percentage;
    },

    getTipIndex(percentage: number): number {
      return this.tipOptions.findIndex((value: number) => value === percentage);
    },

    getTipLabel(index: number): string {
      const percentage = this.tipOptions[index];
      const amount = Math.ceil(this.originAmount * (percentage / 100))
      return ` / ${this.currencySign}${amount} ${this.tipLabels[index] || ''}`
    },
  },
});
export default class Confirmation extends VueComponent {}
