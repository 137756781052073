const host = window?.location?.hostname?.replace('donate.', '');

const publickApiHost = (process.env.VUE_APP_API_HOST || '').replace('[HOST]', host);
const dashboardApiHost = (process.env.VUE_APP_DASHBOARD_API_HOST || '').replace('[HOST]', host);

export const rackcdnHost = 'https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com'

const urls = {
  campaignParams: `${publickApiHost}/api/v1/campaign/:campaignId/donation/new`,
  stripeConfirmation: `${publickApiHost}/api/v1/campaign/donation/statusupdate/stripe`,
  statusUpdateStripeElement: `${publickApiHost}/api/v1/campaign/donation/statusupdate/stripe-element`,
  statusUpdateStripTerminal: `${publickApiHost}/api/v1/campaign/donation/statusupdate/stripe-terminal`,
  getConnectionToken: `${publickApiHost}/api/v1/campaign/:cid/paymnet_option/:poid/connection_token`,
  statusUpdateChariot: `${publickApiHost}/api/v1/campaign/donation/statusupdate/chariot/:id`,
  campaignByShortlink: `${publickApiHost}/api/v1/campaign/:shortlink`,
  getTransition: `${publickApiHost}/api/v1/locale?:group&:location`,
  getStateCity: `${publickApiHost}/api/v1/country/:isoCode/zip/:zipCode/statecity`,
  getExchange: `${publickApiHost}/api/v1/xchange`,
  getTeams: `${publickApiHost}/api/v1/campaign/:campaignId/teams`,
  getInitial: `${publickApiHost}/api/v1/initial`,
  getNewOrg: `${publickApiHost}/api/v1/neworg/donation/new`,
  uploadFile: `${publickApiHost}/api/v1/file/upload`,
  media: `${publickApiHost}/api/v1/campaign/:id/media`,
  moduleVideo: `${publickApiHost}/api/campaign/:id/module_video`,
  createTeam: `${publickApiHost}/api/v1/campaign/:id/teams`,
  // dashboard endpoints
  logIn: `${dashboardApiHost}/orgarea/api/v1/login`,
  signIn: `${dashboardApiHost}/orgarea/api/v1/signin`,
  registerUser: `${dashboardApiHost}/orgarea/api/v1/signup`,
  stripePaymentMethods: `${dashboardApiHost}/orgarea/api/v1/account/donor_account/payment_methods/stripe`,
  getDonorAccount: `${dashboardApiHost}/orgarea/api/v1/account/donor_account`,
  // modules
  belzDocuSign: `${publickApiHost}/api/v1/module/belz/docusign/:id`,
  // tracing donation error on the client
  donationsFailed: `${publickApiHost}/api/v1/donations_failed`,
  twilioLookup: `${publickApiHost}/api/v1/module/twilio/lookup/:phone`,
  // taxgift integration
  taxgift: `${publickApiHost}/api/v1/taxgift/donorcharity`,
  // taxgift integration
  donationTrack: `${publickApiHost}/api/v1/campaign/:cid/donation/:did/track`,
  // igul letova
  igulLetova: `${publickApiHost}/api/v1/post_donation/igul_letova`,

  postDonationSuccessInviteFriends: (donationId: number) => `${publickApiHost}/api/v2/donation/${donationId}/invite_friends`,

  // api/v1/campaign/donation/:id/statusupdate/paypalv2/:orderid
  statusUpdatePaypalV2: (donationId: string, orderId: string) => `${publickApiHost}/api/v1/campaign/donation/${donationId}/statusupdate/paypalv2/${orderId}`,
};

export default urls;
