var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.streame)?_c('p',{staticClass:"alert alert-info"},[_vm._v(" "+_vm._s(_vm.$t("template.streame_selected"))+" ")]):_vm._e(),(_vm.chosenGateway
      && _vm.chosenGateway.id
      && _vm.chosenGateway.attributes
      && _vm.chosenGateway.attributes.note)?_c('p',{staticClass:"alert alert-info"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('account.contacts_note'))+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(
        _vm.chosenGateway.attributes.note,
        _vm.chosenGateway.attributes.note,
      ))}})]):_vm._e(),(_vm.chosenGateway && _vm.chosenGateway.id)?_c('div',{staticClass:"organization-data alert alert-info"},[(!!(_vm.organizationLegalEntity.organization_legal_name))?_c('div',{staticClass:"organization-data__row"},[_c('strong',[_vm._v(_vm._s(_vm.$t('account.org_legal_name'))+" ")]),_vm._v(" "+_vm._s(_vm.organizationLegalEntity.organization_legal_name)+" ")]):_vm._e(),(!!(_vm.chosenGateway.attributes.transaction_descriptor))?_c('div',{staticClass:"organization-data__row"},[_c('strong',[_vm._v(_vm._s(_vm.$t('account.bank_statement'))+" ")]),_vm._v(" "+_vm._s(_vm.chosenGateway.attributes.transaction_descriptor)+" ")]):_vm._e(),(!!(_vm.organizationLegalEntity.organization_tax_id))?_c('div',{staticClass:"organization-data__row"},[_c('strong',[_vm._v(_vm._s(_vm.ifCountry('gb') ? _vm.$t('account.charity_number') : _vm.ifCountry('au') ? _vm.$t('account.tax_id') : _vm.$t('account.tax_id_not_abn'))+" ")]),_vm._v(" "+_vm._s(_vm.organizationLegalEntity.organization_tax_id)+" ")]):_vm._e(),_c('div',{staticClass:"organization-data__row"},[_vm._v(" "+_vm._s(_vm.type)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t( `account.organization_notification_${_vm.chosenGateway.attributes.name}`, { org_email: _vm.org.public_email, org_phone: _vm.org.public_phone, } ))+" ")]),(_vm.gatewayListFiltered && _vm.gatewayListFiltered.length === 1)?_c('span',{staticClass:"organization-data__row"},[_vm._v(" "+_vm._s(_vm.getDescription(_vm.gatewayListFiltered[0]))+" ")]):_vm._e()]):_vm._e(),(_vm.cmapaignCurrency !== _vm.chargedCurrency)?_c('p',{staticClass:"alert alert-info"},[_c('strong',[_vm._v(_vm._s(_vm.$t('account.contacts_note')))]),_vm._v(" "+_vm._s(_vm.$t('template.payment_method_extra_label', { cmapaignCurrency: _vm.cmapaignCurrency, chargedCurrency: _vm.chargedCurrency, rate: _vm.rateToFixed2, }))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }