
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '../../../models/IRootState';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  props: {
    shouldValidate: Boolean,
    required: Boolean,
  },

  computed: {
    ...customMapState({
      paymentConfig: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
    }),

    localZeout: {
      get() :String {
        return this.$store.state.donation.donationData.attributes.israeli_zehut;
      },
      set(value: String) {
        this.$store.commit('setIsraeliZehut', value.trim());
      },
    },

    fallbackErrMsg() {
      if (this.vErrors.has('zeout')) {
        return this.vErrors.items[0]?.msg
      }

      return ''
    },

    label() {
      return this.required
        ? this.$t('donation.zeout', 'Tehudat Zeout')
        : this.$t('donation.zeout_optional', 'Tehudat Zeout (Optional)')
    },
  },
});
export default class IsraeliZehut extends VueComponent {}
