import { getGAClientID } from './analytics';

const injectGTMNoScript = (d: Document, i: string) => {
  const ns = d.createElement('noscript');
  const f = d.createElement('iframe');

  f.src = `//www.googletagmanager.com/ns.html?id=${i}`
  f.height = '0';
  f.width = '0';
  f.style.display = 'none';
  f.style.visibility = 'hidden';

  ns.append(f)

  d.body.insertAdjacentElement('afterbegin', ns);
}

const injectGTMScript = (w: any, d: Document, s: 'script', l: string, i: string) => {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);
  const dl = l !== 'dataLayer' ? `&l=${l}` : '';
  j.async = true;
  j.src = `//www.googletagmanager.com/gtm.js?id=${i}${dl}`;
  f.parentNode?.insertBefore(j, f);
};

const injectGTMScriptAsText = (script: string, layer: string, id: string) => {
  const f = document.getElementsByTagName(script)[0];
  const j = document.createElement('script');

  j.innerHTML = `
    // Google Tag Manager
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start":
    new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src=
    "https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'${script}','${layer}','${id}');
    // End Google Tag Manager
  `;

  f?.parentNode?.insertBefore(j, f);
};

export const injectGoogleTagManager = (id: string, layer: string = 'dataLayer', iat: boolean = false) => {
  if (iat) {
    injectGTMScriptAsText('script', layer, id)
  } else {
    injectGTMScript(window, document, 'script', layer, id)
  }
  injectGTMNoScript(document, id);
}

interface TriggerStepEventProps {
  step: number,
  currency: string;
  teams: any[]
}

interface GTMData {
  event: string;
  ecommerce: {
    [key: string]: {
      actionField: {
        [key: string]: any;
      },
      products: any[],
    } | any,
  }
}

export const triggerGTMStepEvent = ({
  step, teams, currency,
}: TriggerStepEventProps) => {
  const data: GTMData = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step },
        products: teams,
      },
    },
  };

  if (step >= 2) {
    data.ecommerce.currencyCode = currency;
  }

  window.dataLayer?.push(data);
  window.dataLayer2?.push(data);

  if (step === 1) {
    const clientIdEvent = {
      event: 'clientId',
      ...getGAClientID(),
    }

    window.dataLayer?.push(clientIdEvent);
    window.dataLayer2?.push(clientIdEvent);
  }
}

export default { injectGoogleTagManager }
