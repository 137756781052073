
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    totalAmount: {
      type: Number,
      required: true,
    },
    campaignCurrency: {
      type: Object,
      required: true,
    },
  },
}
