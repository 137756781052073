
import Vue from 'vue';
import customMapState from '../../../helpers/mapHelper';
import { IRootState } from '../../../models/IRootState';
import { IHKBankTransferData, IHKBankBranch, IIHKBank } from './payments-models/HKBankTransfer';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  data(): IHKBankTransferData {
    return {
      // fprm
      teudat_zehut: '',
      note: '',
      day_of_charge: '',
      account: '',
      selectedBank: null,
      selectedBranch: null,
      signatureIsEmpty: false,
    };
  },

  computed: {
    ...customMapState({
      paymentConfigAttrs: (state: IRootState) => state.donation.paymentConfig.attributes,
      installment: (state: IRootState) => state.donation.donationData.attributes.installment_period,
    }),

    bankList(): IIHKBank[] {
      return JSON.parse(this.paymentConfigAttrs.configuration.bank_branch_list || '[]');
    },

    branchList(): IHKBankBranch[] {
      return this.selectedBank?.branch_list || []
    },
  },

  watch: {
    selectedBank() {
      this.selectedBranch = null;
    },
  },

  mounted(): void {
    this.$root.$on('setDonationGatewayParams', this.setDonationGatewayParams);
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    clearSignature() {
      (this.$refs.signaturePad as any).clearSignature();
      this.signatureIsEmpty = true;
    },

    setDonationGatewayParams(resolve: (value?: unknown) => void) {
      const { isEmpty, data } = (this.$refs.signaturePad as any).saveSignature();

      if (isEmpty) {
        resolve({
          title: 'Signature Error!',
          text: this.$t('donation.hk_bank_transfer_signature_is_empty'),
        });
        this.signatureIsEmpty = true;
        return;
      }

      resolve(false)

      this.$store.commit('setDonationIncludedGatewayParams', {
        hk_bank_transfer_teudat_zehut: this.teudat_zehut,
        hk_bank_transfer_note: this.note,
        hk_bank_transfer_number_of_months: this.installment,
        hk_bank_transfer_day_of_charge: Number(this.day_of_charge),
        hk_bank_transfer_bank: String(this.selectedBank?.bank_code),
        hk_bank_transfer_bankname: this.selectedBank?.bank_name,
        hk_bank_transfer_branch: String(this.selectedBranch?.branch_code),
        hk_bank_transfer_account: this.account,
        hk_bank_transfer_digital_signature_b64: data,
      });
    },
  },
});
export default class HKBankTransfer extends VueComponent {}
