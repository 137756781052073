
import Vue from 'vue'
import { mapGetters } from 'vuex'
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';

export default Vue.extend({
  computed: {
    ...customMapState({
      paymentMethod: (s: IRootState) => s.donation.paymentMethod,
    }),

    ...mapGetters({
      isCanMakePayment: 'isCanMakePayment',
    }),
  },
})
