
import Vue from 'vue'
import { mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import { InstallmentConfig, RecurringConfig } from '@/models/IDonationParams';
import { tipRecurrringFor } from '@/constants';
import DonationInfoRow from './confirmation/DonationInfoRow.vue';
import DonationInfoTeams from './confirmation/DonationInfoTeams.vue';

export default Vue.extend({
  components: { DonationInfoRow, DonationInfoTeams },

  computed: {
    ...customMapState({
      donationData: (state: IRootState) => state.donation.donationData.attributes,
      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign.toUpperCase(),
      }),
      tipAmount: (state: IRootState) => state.donation.donationData.attributes.tip_amount / 100,
      recurringPeriod: (state: IRootState) => state.donation.donationData.attributes.recurring_period,
      installmentPeriod: (state: IRootState) => state.donation.donationData.attributes.installment_period,
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      donateToAllTeams: (state: IRootState) => state.donation.donationData.attributes.donate_to_all_teams,
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
    }),

    ...mapGetters({
      multiplierOnDay: 'multiplierOnDay',
      selectedTeams: 'selectedTeams',
    }),

    show(): boolean {
      return this.tipAmount > 0
        || this.feeAmount > 0
        || this.selectedTeams.length
        || this.donateToAllTeams
        || this.isDailyDanation
        || this.isRecurring
        || this.isInstallment
    },

    recurringConfig(): RecurringConfig {
      return this.config.recurring_config;
    },

    installmentConfig(): InstallmentConfig {
      return this.config.installment_config;
    },

    isDailyDanation(): boolean {
      return this.recurringConfig.period === 'day' && this.recurringPeriod > 0;
    },

    isRecurring(): boolean {
      switch (this.recurringConfig.period) {
        case 'week':
        case 'month':
          return this.recurringPeriod > 1;
        case 'year':
          return this.recurringPeriod > 0;

        default:
          return false;
      }
    },

    xSufix(): string {
      switch (this.recurringConfig.period) {
        case 'month':
          return this.$t(
            'donations.x_months',
            'x {periond} month:::x {periond} months',
            {
              periond: this.recurringPeriod || this.installmentPeriod,
            },
            this.recurringPeriod || this.installmentPeriod,
          ) as string

        case 'week':
          return this.$t(
            'donations.x_weeks',
            'x {periond} week:::x {periond} weeks',
            {
              periond: this.recurringPeriod || this.installmentPeriod,
            },
            this.recurringPeriod || this.installmentPeriod,
          ) as string

        case 'year':
          return this.$t(
            'donations.x_years',
            'x {periond} year:::x {periond} years',
            {
              periond: this.recurringPeriod || this.installmentPeriod,
            },
            this.recurringPeriod || this.installmentPeriod,
          ) as string

        default:
          return '';
      }
    },

    isInstallment(): boolean {
      return this.installmentConfig.available && this.installmentPeriod > 1;
    },

    feeAmount(): number {
      return (
        this.donationData.include_payment_system_fee
        && this.donationData.include_payment_system_fee_amount / 100
      ) || 0
    },

    multiplyier(): number {
      if (this.isDailyDanation) {
        return this.recurringPeriod;
      }

      if (this.isRecurring) {
        return this.recurringPeriod;
      }

      if (this.isInstallment) {
        return this.installmentPeriod;
      }

      return 1
    },

    feeAmountText(): string {
      let sufix = ''

      if (this.isDailyDanation || this.isRecurring) {
        sufix = this.xSufix
      }

      return `${this.donationCurrency.sign}${this.formatAmount(this.feeAmount)} ${sufix}`
    },

    amount(): number {
      let extraMultiplyier = 1
      const { amount } = this.donationData

      if (this.isDailyDanation) {
        extraMultiplyier = this.multiplierOnDay
      }

      if (this.isInstallment) {
        extraMultiplyier = this.installmentPeriod / (this.installmentPeriod ** 2);
      }

      return extraMultiplyier * (amount / 100)
    },

    totalChargeAmount(): number {
      if (this.isDailyDanation || this.isRecurring) {
        if (tipRecurrringFor.includes(this.paymentMethod)) {
          return ((this.amount + this.tipAmount + this.feeAmount) * this.multiplyier);
        }
      }

      return (this.amount * this.multiplyier) + this.tipAmount + this.feeAmount;
    },

    dontionAmountString(): string {
      let sufix = ''
      const { sign } = this.donationCurrency

      if (this.isDailyDanation || this.isRecurring || this.isInstallment) {
        sufix = this.xSufix
      }

      return `${sign}${this.formatAmount(this.amount)} ${sufix}`;
    },

    tipAmountRowText(): string {
      let sufix = ''

      if (this.isDailyDanation || this.isRecurring) {
        if (tipRecurrringFor.includes(this.paymentMethod)) {
          sufix = this.xSufix
        }
      }

      return `${this.donationCurrency.sign}${this.formatAmount(this.tipAmount)} ${sufix}`
    },
  },

  methods: {
    formatAmount(amt: number): string {
      return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(amt)
    },
  },
})
