
import Vue from 'vue';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  props: {
    value: {
      type: Object,
      default: (): any => ({
        month: '',
        year: '',
      }),
    },
    yearFormat: {
      type: String,
      default: 'full',
    },
    optional: Boolean,
  },

  computed: {
    currentYear(): number {
      return new Date().getFullYear();
    },
  },

  methods: {
    formatYear(value: number): number {
      if (this.yearFormat === 'short') {
        return Number(value.toString().substr(2, 2));
      }
      return value;
    },

    input(data: any) {
      this.$emit('input', { ...this.value, ...data });
    },
  },
});

export default class CardExpDate extends VueComponent {}
