import urls from '@/constants/urls';
import { createError } from '@/helpers/customError';
import { useExternalScript } from '@/helpers/useExternalScript';
import store from '@/store/store';
import axios from 'axios';

const chariotStript = useExternalScript('https://cdn.givechariot.com/chariot-connect.umd.js')

export const useChariot = () => {
  const statusUpdate = (did: string, data: any) => axios
    .post(urls.statusUpdateChariot.replace(':id', did), data)

  let getChariotData = (): any => null
  let chariotDonationData = null as any
  let did = ''

  return {
    setup() {
      chariotStript?.load()
    },
    onDonationRequest(callback: () => any) {
      getChariotData = callback
    },
    async donate() {
      const chariot: HTMLElement | null = document.getElementById('chariot')
      if (!chariot) {
        return Promise.reject(new Error('Chariot element not found'))
      }

      if (!chariotStript?.exist) {
        return Promise.reject(new Error('Chariot script not loaded'))
      }

      try {
        const data_3 = await new Promise<any>((resolve, reject) => {
          // @ts-ignore
          chariot.onDonationRequest(() => {
            const data = getChariotData()
            chariotDonationData = data.parameters
            did = data.donation_id
            return data.parameters
          });

          const onChariotSuccess = (chariotEvent_1: any) => {
            statusUpdate(did, chariotEvent_1.detail)
              .then(({ data }) => {
                resolve(data);
              })
              .catch(reject);
          };
          chariot?.removeEventListener('CHARIOT_SUCCESS', onChariotSuccess);
          chariot?.addEventListener('CHARIOT_SUCCESS', onChariotSuccess);

          const onChariotExit = (chariotEvent_3: any) => {
            const detail = {
              ...chariotEvent_3.detail,
              hash: chariotDonationData.metadata.hash,
            };
            statusUpdate(did, detail)
              .then(({ data: data_1 }) => {
                resolve(data_1);
              })
              .catch(reject);
          };
          chariot?.removeEventListener('CHARIOT_EXIT', onChariotExit);
          chariot?.addEventListener('CHARIOT_EXIT', onChariotExit);
        });
        const { Successful, ErrorCodeStrGateway, ErrorMessage } = data_3;

        if (!Successful) throw createError(`[${ErrorCodeStrGateway}] ${ErrorMessage}`, {});

        window.postMessage({
          type: 'gateway-response-success',
        }, '*');

        return data_3;
      } catch (error) {
        const err = error as any;
        const errmsg = err?.custom
          ? err?.message
          : err?.response?.data?.error;
        store.commit('setError', {
          title: 'Chariot Error!',
          text: errmsg,
        });
        return Promise.reject(err);
      } finally {
        store.commit('setIsSubmitted', false);
        store.commit('setCaptchaToken', '');
      }
    },
  }
}

export default { useChariot }
