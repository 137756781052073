
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';

export default Vue.extend({
  computed: customMapState({
    donationCurrency: (state: IRootState) => state.donation.donationData.attributes.currency,
  }),
});
