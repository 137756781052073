
import Vue from 'vue';
import AccountNumber from '@/components/subcomponents/paymentSystems/payments-models/AsserBishvil';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  props: {
    shouldValidate: Boolean,
  },

  data(): AccountNumber {
    return {
      accountNumber: '',
    };
  },

  watch: {
    accountNumber(value): void {
      this.$store.commit('setDonationIncludedGatewayParams', {
        tevini_acc: value,
      });
    },
  },
});
export default class Tevini extends VueComponent {}
