
import Vue from 'vue';
import { ISEPAData } from './payments-models/SEPADirectDebit.model';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  data(): ISEPAData {
    return {
      iban: '',
    }
  },

  mounted(): void {
    this.$root.$on('setDonationGatewayParams', this.createSourceObject);
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    async createSourceObject(resolve: Function): Promise<void> {
      const stripe_sepa_direct_debit_iban = this.iban;

      this.$store.commit('setDonationIncludedGatewayParams', { stripe_sepa_direct_debit_iban });

      resolve(false);
    },
  },
})

export default class SEPADirectDebit extends VueComponent {}
