
import customMapState from '@/helpers/mapHelper';
import { WarningMessageByAmount } from '@/models/IDonationParams';
import { IRootState } from '@/models/IRootState';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    ...customMapState({
      originAmount: (state: IRootState) => state.donation.originAmount,
      currency: (state: IRootState) => state.donation.donationData.attributes.currency,
      warningMessageByAmount: (state: IRootState) => state.donation.donationParams.data.attributes.config?.warning_message_by_amount,
    }),

    messages(): WarningMessageByAmount[] {
      return this.warningMessageByAmount.filter((el: any) => {
        if (this.currency === el.currency_code && el.amount <= this.originAmount) {
          return true
        }
        return false
      })
    },
  },
})
