
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '../../../models/IRootState';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  props: {
    shouldValidate: Boolean,
  },

  computed: {
    ...customMapState({
      paymentConfig: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
      zeout: (state: IRootState) => state.nedarimplus.zeout,
    }),

    requiredZeout(): boolean {
      return this.paymentConfig.RequiredIdNumber === 'true'
    },

    localZeout: {
      get() {
        return this.zeout;
      },
      set(value: string) {
        this.$store.commit('setZeout', value);
      },
    },

    fallbackErrMsg() {
      if (this.vErrors.has('zeout')) {
        return this.vErrors.items[0]?.msg
      }

      return ''
    },
  },
});
export default class NedarimPlus extends VueComponent {}
