import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2459A9',
        accent: '#FFEE00',
        greyBG: colors.grey.lighten4,
        info: colors.blueGrey.base,
      },
    },
  },
});
