import axios from 'axios';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import urls from '@/constants/urls';
import { IRootState } from '@/models/IRootState';
import { merge, deepCopy } from '@/helpers/objectHelper';
import NewState, { State, ITranslationResponse } from './models/translation';
// import NewState from './models/translation';

export interface RootState extends IRootState {
  version: string;
}

const state: State = new NewState();

const mutations: MutationTree<State> = {
  SET_TRANSLATION(state, data) {
    state.translation = data;
  },

  SET_LOADING_LANG(state: State, value: boolean) {
    state.loadLang = value;
  },

  SET_LOCATE(state, val: string) {
    state.locate = val;
  },
};

const getters: GetterTree<State, RootState> = {
  loadingLang: state => () => state.loadLang,
  selectedLang: state => () => state.locate,
  translation: state => () => state.translation,
};

const actions: ActionTree<State, RootState> = {
  getTranslation({ state, commit, rootState }) {
    const cmpId = rootState.donation.campaign.data.id;
    const groups = `group=${[
      'template',
      'donors',
      'donation',
      'dashboard',
      'training',
      'campaigns',
      'add_campaign',
      'account',
      'gateways',
      'edit_campaign',
      'teams',
      'leads',
      'integration',
      'email_confirm',
      'success',
      'errors',
      'tip',
      'level_widget',
      'widget_teams',
      'confirmation',
      'httperror',
    ].join('&group=')}`;

    const params = {
      mutate_by_cid: cmpId || null,
    }

    axios
      .get<ITranslationResponse>(
        urls.getTransition
          .replace(':group', groups)
          .replace(':location', `loc=${state.locate}&loc=en`),
        { params },
      )
      .then(response => {
        const { value, langs } = rootState.donation.donationParams
          .data.attributes.config.custom_translation_config
        let transitionData = response.data;

        if (value) {
          transitionData = merge(transitionData, deepCopy(langs));
        }

        commit('SET_TRANSLATION', transitionData);
        commit('SET_LOADING_LANG', false);
      })
      .catch(error => {
        commit('SET_LOADING_LANG', false);
        commit('setError', {
          title: 'Translation request error',
          text: error?.response?.data.error,
        });
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
