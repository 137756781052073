
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { IDonationIncluded } from '@/store/modules/models/donation'
import { IRootState } from '@/models/IRootState'
import customMapState from '@/helpers/mapHelper'

import DonationInfoRow from './DonationInfoRow.vue'

export default Vue.extend({
  components: { DonationInfoRow },

  computed: {
    ...customMapState({
      donateToAllTeams: (state: IRootState) => state.donation.donationData.attributes.donate_to_all_teams,
      teamsTotal: (state: IRootState) => state.donation.teamsTotal,
      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign.toUpperCase(),
      }),
    }),

    ...mapGetters({
      selectedTeams: 'selectedTeams',
      selectedTeamsTotalAmount: 'selectedTeamsTotalAmount',
      donationAmount: 'donationAmount',
    }),

    teams(): IDonationIncluded[] {
      return this.selectedTeams
    },

    totalAmount(): number {
      return (
        this.donationAmount > this.selectedTeamsTotalAmount
          ? this.donationAmount
          : this.selectedTeamsTotalAmount
      ) / 100
    },

    show(): boolean {
      return Boolean(this.teams.length) || this.donateToAllTeams
    },

    title(): string | undefined {
      if (this.teams.length === 1) {
        return this.$t(
          'donation.to_team',
          'Donation to {teamName}:',
          { teamName: this.teams[0].attributes.name },
        )
      }

      if (this.teams.length > 1) {
        return this.$t(
          'donation.to_teams',
          'Donation to teams:',
        )
      }

      if (this.donateToAllTeams) {
        return this.$t(
          'donation.to_all_teams',
          'Donation to all teams: (total {total_teams} teams)',
          {
            total_teams: this.teamsTotal,
          },
        )
      }

      return ''
    },
  },

  methods: {
    formatAmount(amt: number): string {
      return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(amt)
    },
  },
})
