
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';

export default Vue.extend({
  data() {
    return {};
  },

  computed: {
    ...customMapState({
      nedarimg_bit_url: (state: IRootState) => state.donation.donationData.attributes.nedarimg_bit_url,
      phone: (state: IRootState) => state.donation.donationData.attributes.phone,
    }),
  },

  methods: {
    phoneFormatting(p :String) {
      const symbols = ['(', ')', '-', ' '];
      let phone = p;

      for (let i = 0; i < symbols.length; i += 1) {
        while (phone.indexOf(symbols[i]) >= 0) {
          phone = phone.replace(symbols[i], '');
        }
      }

      if (phone.indexOf('+972') === 0) {
        phone = `0${phone.slice(4)}`;
      }

      if (phone.indexOf('+') === 0) {
        phone = phone.slice(1);
      }

      return phone;
    },

    goToBit() {
      window.open(this.nedarimg_bit_url)
    },
  },
});
