
import Vue from 'vue'

import { mapActions } from 'vuex';
import urls from '@/constants/urls';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import { get, getTeamId } from '@/helpers/getUrlParameters';
import { TeamsPreFormStep } from '@/models/IDonationParams';

import multiselectSearch from '../shared/multiselect-search.vue';

export default Vue.extend({
  components: { multiselectSearch },

  data() {
    return {
      selected: null as any,
    };
  },

  computed: {
    ...customMapState({
      cId: (state: IRootState) => state.donation.campaign.data.id,
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
    }),

    teamsPreFormStep(): TeamsPreFormStep {
      return this.config.teams_pre_form_step;
    },

    hideRegularDonation(): boolean {
      return this.teamsPreFormStep.regular_donation.hide
    },

    getSearchUrl(): string {
      return `${urls.getTeams.replace(':campaignId', String(this.cId))}`;
    },

    searchOptions(): any {
      return {
        placeholder: this.$t('template.team_search_placeholder', 'Start type a team name'),
        changeParams: ({
          query, page, per_page: pp, renderPrefetch,
        }: any) => {
          if (renderPrefetch) {
            return {
              team_id: this.selected?.id,
            };
          }

          return {
            q: query,
            offset: (page - 1) * pp,
            limit: pp,
          };
        },
        customLabel: (e: any) => `${e.name}`,
        processResults: (data: any) => data.data.map((el: any) => ({
          id: el.id,
          ...el.attributes,
        })),
      };
    },
  },

  created() {
    const id = getTeamId();
    if (id) {
      this.selected = { id };
    }
  },

  methods: {
    ...mapActions({
      getCampaign: 'getCampaign',
      setPreSelectTeams: 'setPreSelectTeams',
    }),

    next(regular: boolean) {
      if (this.selected && !regular) {
        const stcid = this.selected.attributes?.campaign_id;

        if (stcid && stcid !== this.cId) {
          this.getCampaign({ cid: stcid })
            .then(() => {
              this.setPreSelectTeams({ ids: [this.selected.id] })
            });
        } else {
          this.setPreSelectTeams({ ids: [this.selected.id] })
        }
      }

      const rdcid = this.teamsPreFormStep?.regular_donation?.cid;
      if (regular && rdcid && rdcid !== this.cId) {
        this.getCampaign({ cid: rdcid })
          .then(() => {
            this.$emit('next');
          });
        return
      }

      this.$emit('next');
    },
  },
})
