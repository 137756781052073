
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';

const VueComponent = Vue.extend({
  computed: {
    ...customMapState({
      stripePaymentMethods: (state: IRootState) => state.donation.stripePaymentMethods,
      paymentMethodName: (state: IRootState) => state.donation.paymentMethod,
    }),

    paymentMethod: {
      get(): any {
        return this.$store.state.donation.savedCreditCard;
      },
      set(val: any) {
        this.$store.commit('setSavedCreditCard', val);
      },
    },
  },

  watch: {
    stripePaymentMethods: {
      handler(val: any[]) {
        const isCard = ['stripe'].includes(this.paymentMethodName);

        if (isCard) {
          const cards = val.filter(e => e.type === 'card');
          if (cards?.length > 0) {
            const defaultCards = cards.filter(e => e.default)
            if (defaultCards?.length > 0) {
              [this.paymentMethod] = defaultCards
              return
            }

            [this.paymentMethod] = cards
            return
          }
        }

        [this.paymentMethod] = val;
      },
      immediate: true,
    },
  },
});
export default class SavedCreditCardList extends VueComponent {}
