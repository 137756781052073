
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { useExternalScript } from '@/helpers/useExternalScript';
import { IData } from './payments-models/MercadoPagoCheckout.model';
import { IRootState } from '../../../models/IRootState';

const mercadopagoScript = useExternalScript('https://sdk.mercadopago.com/js/v2')

const VueComponent = Vue.extend({
  inject: ['$validator'],

  data(): IData {
    return {
      mp: null,
      // FORM DATA
      identificationType: null,
      identificationNumber: '',
      // UI
      identificationTypes: [],
    }
  },

  computed: {
    ...customMapState({
      publicKey: (state: IRootState) => state.donation.paymentConfig.attributes
        .configuration.public_key,
      documentIdRequired: (state: IRootState) => state.donation.paymentConfig.attributes
        .configuration.document_id_required === 'true',
      locale: (state: IRootState) => state.i18n.locale,
    }),

    idTypeValidation() {
      if (this.documentIdRequired) {
        return { required: true, is_not: '' }
      }
      return {}
    },
  },

  mounted(): void {
    mercadopagoScript?.onLoad(() => {
      this.mp = new MercadoPago(this.publicKey)

      this.getIdTypes();

      this.$root.$on('setDonationGatewayParams', this.setDonationGatewayParams);
    })

    mercadopagoScript?.load()
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    getIdTypes(): void {
      this.mp?.getIdentificationTypes().then(res => {
        this.identificationTypes = res
      })
    },

    setDonationGatewayParams(resolve: (value?: unknown) => void) {
      const { origin, pathname } = window.location;

      this.$store.commit(
        'setDonationIncludedGatewayParams',
        {
          identification_type: this.identificationType?.id,
          identification_number: this.identificationNumber,
          redirect_url: `${origin}${pathname}?lang=${this.locale}`,
        },
      );
      resolve(false);
    },
  },
});

export default class MercadoPagoCheckout extends VueComponent {}
