export const getPlatform = () => {
  if (navigator.userAgent.match(/Android/i)) {
    return 'android'
  }

  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return 'iphone'
  }

  return 'web'
}

export const isChrome = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('chrome') > -1;
}

export const isFBApp = () => {
  const ua = navigator.userAgent || navigator.vendor;
  return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
}

export default {
  getPlatform,
  isFBApp,
}
