
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { getPlatform } from '@/helpers/platform';

export default Vue.extend({
  computed: {
    platform: getPlatform,
    ...mapGetters({
      isGatewayDisabled: 'isGatewayDisabled',
      isMeshulamGooglePayDisabled: 'isMeshulamGooglePayDisabled',
    }),
  },
})
