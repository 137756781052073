import { ReCaptchaInstance, load } from 'recaptcha-v3';
import { reactive } from 'vue';

const state = reactive({
  active: false,
  keyV3: '',
  reCaptchaV3: null as (ReCaptchaInstance | null),
})

export const useReCaptchaV3 = () => {
  const getInstance = () => state.reCaptchaV3 as ReCaptchaInstance | null

  const setInstance = (val: ReCaptchaInstance | null) => {
    state.reCaptchaV3 = val
    state.active = true;
    if (val !== null) {
      state.keyV3 = val.getSiteKey()
    }
  }

  const setKey = (val: string) => {
    state.keyV3 = val
  }

  const loadLoc = async (): Promise<[ReCaptchaInstance | null, unknown | null]> => {
    if (state.reCaptchaV3 == null && state.keyV3 !== '') {
      try {
        state.reCaptchaV3 = await load(state.keyV3);
      } catch (error) {
        return [null, error]
      }
    }
    return [getInstance(), null]
  }

  const getToken = async (token?: string): Promise<[string, any | null]> => {
    if (token !== '') {
      return [token || '', null]
    }

    if (state.reCaptchaV3 && state.active) {
      const [, err] = await loadLoc()
      if (err && !state.reCaptchaV3) {
        return [
          '',
          [
            'donation.captcha_load_faild',
            'Failed to load captcha script. Try again',
          ],
        ];
      }

      if (state.reCaptchaV3) {
        let token = await state.reCaptchaV3.execute('e_commerce');
        if (token === '') {
          token = await state.reCaptchaV3.execute('e_commerce');
        }
        if (token === '') {
          token = await state.reCaptchaV3.execute('e_commerce');
        }
        if (token === '') {
          return [
            '',
            [
              'donation.captcha_load_faild',
              'Failed to load captcha script. Try again',
            ],
          ];
        }

        return [token, null]
      }
    }

    return ['', null];
  }

  return { setInstance, getToken, setKey }
}

export default { useReCaptchaV3 }
