import { ActionTree, MutationTree } from 'vuex';
import { get } from '@/helpers/getUrlParameters';
import { IRootState } from '@/models/IRootState';
import { RedirectState, RedirectData } from './models/redirect';

const state: RedirectState = {
  isSucceeded: false,
  data: null,
}

const mutations: MutationTree<RedirectState> = {
  setIsSucceeded(state, val) {
    state.isSucceeded = val;
  },

  setData(state, data) {
    state.data = data;
  },
}

const actions: ActionTree<RedirectState, IRootState> = {
  handleRedirect({ commit }) {
    let data: RedirectData | null = null;

    if (get('dd')) {
      try {
        data = JSON.parse(atob(get('dd')))
      } catch {
        data = null;
      }
    }

    const dId = get('did');
    const status = get('status') as ('succeeded' | 'failed' | 'preview');
    const errorMsg = get('error_msg');

    return new Promise(resolve => {
      if (status === 'succeeded' && dId) {
        if (Number(dId) === data?.donation_id) {
          commit('setIsSucceeded', true);
          commit('setDonationId', dId, { root: true });
          commit('setData', data);

          window.postMessage({
            type: 'gateway-response-success',
          }, '*');
        }
      }
      if (status === 'preview') {
        commit('setIsSucceeded', true);
        commit('setStepFlag', 3);
      }

      if (status === 'failed' && errorMsg) {
        commit('setError', {
          title: 'Redirect Error!',
          translationKey: ['', errorMsg],
        }, { root: true });
      }

      resolve(true)
    });
  },
}

export default {
  state,
  mutations,
  actions,
}
