
import Vue from 'vue';
import { mapGetters } from 'vuex';

import levelQuantitySelection from '@/components/shared/level-quantity-selection.vue';

import { extractLevelAmountByLocationDefaultCurrency, isLevelAvailable } from '@/helpers/levels';
import { SlectedLevels } from '@/store/modules/models/levels';
import customMapState from '../../helpers/mapHelper';
import { IRootState } from '../../models/IRootState';
import { IDonationLevel } from '../../models/IDonationParams';
import { get } from '../../helpers/getUrlParameters';

const VueComponent = Vue.extend({
  components: {
    levelQuantitySelection,
  },

  data() {
    return {
      intl: new Intl.NumberFormat('en-US'),
    }
  },

  computed: {
    ...customMapState({
      lang: (state: IRootState) => state.translation.locate,
      stepFlag: (state: IRootState) => state.donation.stepFlag,
      panelColor: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.style_config
        .preset_colors.primary || '#ca6d4f',
      campaign: (state: IRootState) => state.donation.campaign.data.attributes,
      currencyList: (state: IRootState) => state.donation.donationParams
        .data.attributes.available_currency_list,
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      recurringConfig: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.recurring_config,
      defaultCurrencyByIp: (state: IRootState) => state.donation.donationParams.data.attributes.ip_location_default_currency,
    }),

    ...mapGetters({
      getLevelByID: 'getLevelByID',
      getSelectedLevel: 'getSelectedLevel',
    }),

    recurringUiMode(): string {
      const { ui_mode } = this.recurringConfig;
      return ui_mode || 'checkbox';
    },

    show(): boolean {
      return this.config.show_levels_on_donation;
    },

    required(): boolean {
      return this.config.levels_config.required;
    },

    quantitySelectionActive(): boolean {
      return this.config.levels_config.quantity_selection || false;
    },

    quantitySelectionModel: {
      get(): SlectedLevels {
        const state = this.$store.state as IRootState

        return state.levels.slectedLevels;
      },

      set(value: SlectedLevels): void {
        this.$store.commit('setSlectedLevels', value)
      },
    },

    quantitySelectionTotal(): { total: number, currSign: string, currCode: string } {
      return Object.keys(this.quantitySelectionModel).reduce(
        (res, data) => {
          if (data) {
            const level = this.getLevelByID[data]
            const { levelAmount, levelCurrencySign, levelCurrencyCode } = extractLevelAmountByLocationDefaultCurrency(level, this.defaultCurrencyByIp)

            res.total += levelAmount * (this.quantitySelectionModel[data]?.count || 1);
            res.currSign = levelCurrencySign || this.currencySign;
            res.currCode = levelCurrencyCode;
          }

          return res;
        },
        {
          total: 0,
          currSign: '',
          currCode: '',
        },
      )
    },

    isLevelSelected(): boolean {
      return Object.keys(this.quantitySelectionModel).length > 0
    },

    recurring(): number {
      return this.config.call_level_as_recurring;
    },

    installments(): number {
      return this.config.call_level_as_installments;
    },

    levels(): IDonationLevel[] {
      return this.config.donation_levels;
    },

    currencySign(): string {
      return this.campaign.currency_sign;
    },
  },

  created() {
    const hasAmount = Number(get('amount')) > 0;
    if (
      (hasAmount || !this.show || !this.levels.length)
        && this.stepFlag === 0
    ) {
      this.$store.commit('setStepFlag', 1);
    }

    if (this.required && this.levels.length >= 1) {
      this.onSelect(this.levels[0])
    }
  },

  methods: {
    isLevelAvailable,

    getTitle(level: IDonationLevel): string {
      return level.meta?.title_loc?.[this.lang] || level.title;
    },

    getSubTitle(level: IDonationLevel): string {
      return level.meta?.subtitle_loc?.[this.lang] || level.subtitle;
    },

    getAmountText(level: IDonationLevel): string {
      const { currencySign, recurring } = this;

      const rp = (!level?.meta?.installment && level?.recurring_n) || recurring;

      const { levelAmount, levelCurrencySign } = extractLevelAmountByLocationDefaultCurrency(level, this.defaultCurrencyByIp)
      const currSign = levelCurrencySign || currencySign;

      return ` ${currSign}${this.intl.format(levelAmount)}${(rp > 1 ? this.$t('level_widget.month') : '')}`
    },

    getTotalAmountText(): string {
      const { recurring } = this;

      const level: IDonationLevel|null = this.getSelectedLevel;
      const rp = (!level?.meta?.installment && level?.recurring_n) || recurring;

      const { total, currSign } = this.quantitySelectionTotal;

      return ` ${currSign}${total}${(rp > 1 ? this.$t('level_widget.month') : '')}`
    },

    onSelect(level: IDonationLevel) {
      const available = isLevelAvailable(level)

      if (!available) return;

      if (this.quantitySelectionActive) {
        this.quantitySelectionModel = {
          ...this.quantitySelectionModel,
          [level.id]: {
            count: (this.quantitySelectionModel[level.id]?.count || 0) + 1,
          },
        }
      } else {
        this.quantitySelectionModel = { [level.id]: { count: 1 } }
      }
    },

    next() {
      const lvl: IDonationLevel = this.getSelectedLevel
      const { total: amt, currCode } = this.quantitySelectionTotal;

      if (this.currencyList.length && currCode) {
        const currency = this.currencyList.find(
          el => el.code.toLowerCase() === currCode,
        ) || this.currencyList[0];
        this.$store.commit('setDonationCurrency', { ...currency });
      }

      this.$store.commit('setOriginAmount', amt);
      // this.$store.commit('setStepFlag', 1);
      this.$emit('next');

      const rp = (!lvl?.meta?.installment && lvl?.recurring_n) || this.recurring;
      const ip = (lvl?.meta?.installment && lvl?.recurring_n) || this.installments;

      if (amt && rp && !this.quantitySelectionActive) {
        const cb = this.recurringUiMode === 'checkbox';
        if (cb && rp > 1) {
          this.$store.commit('setConfigRecurringDuration', rp);
        }
        if (cb && rp <= 1) {
          this.$store.commit('setConfigRecurringDuration', 12);
        }
        this.$store.commit(
          'setRecurringPeriod',
          cb ? rp || this.recurringConfig.duration : rp,
        );
      }

      if (amt && ip && !this.quantitySelectionActive) {
        this.$store.commit('setInstallmentStatus', Boolean(ip));
        this.$store.commit('setInstallmentPeriod', Number(ip));
      }
    },
  },
});
export default class Levels extends VueComponent {}
