
import Vue from 'vue';
import AccountNumber from '@/components/subcomponents/paymentSystems/payments-models/AsserBishvil';

const VueComponent = Vue.extend({
  // This will make the component inherit it's parent's validator scope, thus sharing all errors
  // and validation state. Meaning it has access to the same errors and fields computed properties
  // as well.
  // TIP. If the direct parent isn't able to provide a validator scope, the API will traverse the
  // tree upwards looking for a parent that can.
  inject: ['$validator'],

  props: {
    shouldValidate: Boolean,
  },

  data(): AccountNumber {
    return {
      accountNumber: '',
    };
  },

  watch: {
    accountNumber(value): void {
      this.$store.commit('setDonationIncludedGatewayParams', {
        asserbishvil_cid: value,
      });
    },
  },
});
export default class asserBishvil extends VueComponent {}
