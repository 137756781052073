import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { IRootState } from '@/models/IRootState';
import { get } from '@/helpers/getUrlParameters';
import { GatewaysSatate } from './models/gateways';
import { IIncluded } from './models/donation';

const state: GatewaysSatate = {
  force_gateway_id: get('force_gateway_id'),
  lock_gateway: JSON.parse(get('lock_gateway') || '0'),
}

const mutations: MutationTree<GatewaysSatate> = {
  setForceGatewayId(s, id) {
    s.force_gateway_id = id
  },
  setLockGateway(s, val) {
    s.lock_gateway = val
  },
}

const getters: GetterTree<GatewaysSatate, IRootState> = {
  someGatewaySupportRecurring(s, g, rs, rg) {
    const { recurring_config } = rs.donation.donationParams.data.attributes.config
    const { available } = recurring_config
    const list = rg.getGatewayListByCurrency as IIncluded[]

    return available && list.some(i => recurring_config.gateways.includes(i.attributes.name))
  },
  someGatewaySupportInstallment(s, g, rs, rg) {
    const { installment_config } = rs.donation.donationParams.data.attributes.config
    const { available } = installment_config
    const list = rg.getGatewayListByCurrency as IIncluded[]

    return available && list.some(i => installment_config.gateways.includes(i.attributes.name))
  },
}

const actions: ActionTree<GatewaysSatate, IRootState> = {
  setGatewayAndLock({ commit }, gid) {
    if (gid) {
      commit('setForceGatewayId', String(gid))
      commit('setLockGateway', 1)
      return
    }

    commit('setForceGatewayId', '')
    commit('setLockGateway', 0)
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
