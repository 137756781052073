export const countryList = [
  { id: 1, name: 'Afghanistan', iso_code: 'AF' },
  { id: 2, name: 'Aland Islands', iso_code: 'AX' },
  { id: 3, name: 'Albania', iso_code: 'AL' },
  { id: 4, name: 'Algeria', iso_code: 'DZ' },
  { id: 5, name: 'American Samoa', iso_code: 'AS' },
  { id: 6, name: 'Andorra', iso_code: 'AD' },
  { id: 7, name: 'Angola', iso_code: 'AO' },
  { id: 8, name: 'Anguilla', iso_code: 'AI' },
  { id: 9, name: 'Antarctica', iso_code: 'AQ' },
  { id: 10, name: 'Antigua and Barbuda', iso_code: 'AG' },
  { id: 11, name: 'Argentina', iso_code: 'AR' },
  { id: 12, name: 'Armenia', iso_code: 'AM' },
  { id: 13, name: 'Aruba', iso_code: 'AW' },
  { id: 14, name: 'Australia', iso_code: 'AU' },
  { id: 15, name: 'Austria', iso_code: 'AT' },
  { id: 16, name: 'Azerbaijan', iso_code: 'AZ' },
  { id: 17, name: 'Bahamas', iso_code: 'BS' },
  { id: 18, name: 'Bahrain', iso_code: 'BH' },
  { id: 19, name: 'Bangladesh', iso_code: 'BD' },
  { id: 20, name: 'Barbados', iso_code: 'BB' },
  { id: 21, name: 'Belarus', iso_code: 'BY' },
  { id: 22, name: 'Belgium', iso_code: 'BE' },
  { id: 23, name: 'Belize', iso_code: 'BZ' },
  { id: 24, name: 'Benin', iso_code: 'BJ' },
  { id: 25, name: 'Bermuda', iso_code: 'BM' },
  { id: 26, name: 'Bhutan', iso_code: 'BT' },
  { id: 27, name: 'Bolivia', iso_code: 'BO' },
  { id: 28, name: 'Bosnia and Herzegowina', iso_code: 'BA' },
  { id: 29, name: 'Botswana', iso_code: 'BW' },
  { id: 30, name: 'Bouvet Island', iso_code: 'BV' },
  { id: 31, name: 'Brazil', iso_code: 'BR' },
  { id: 32, name: 'British Indian Ocean Territory', iso_code: 'IO' },
  { id: 33, name: 'Brunei Darussalam', iso_code: 'BN' },
  { id: 34, name: 'Bulgaria', iso_code: 'BG' },
  { id: 35, name: 'Burkina Faso', iso_code: 'BF' },
  { id: 36, name: 'Burundi', iso_code: 'BI' },
  { id: 37, name: 'Cambodia', iso_code: 'KH' },
  { id: 38, name: 'Cameroon', iso_code: 'CM' },
  { id: 39, name: 'Canada', iso_code: 'CA' },
  { id: 40, name: 'Cape Verde', iso_code: 'CV' },
  { id: 41, name: 'Caribbean Netherlands', iso_code: 'BQ' },
  { id: 42, name: 'Cayman Islands', iso_code: 'KY' },
  { id: 43, name: 'Central African Republic', iso_code: 'CF' },
  { id: 44, name: 'Chad', iso_code: 'TD' },
  { id: 45, name: 'Chile', iso_code: 'CL' },
  { id: 46, name: 'China', iso_code: 'CN' },
  { id: 47, name: 'Christmas Island', iso_code: 'CX' },
  { id: 48, name: 'Cocos (Keeling) Islands', iso_code: 'CC' },
  { id: 49, name: 'Colombia', iso_code: 'CO' },
  { id: 50, name: 'Comoros', iso_code: 'KM' },
  { id: 51, name: 'Congo', iso_code: 'CG' },
  { id: 52, name: 'Cook Islands', iso_code: 'CK' },
  { id: 53, name: 'Costa Rica', iso_code: 'CR' },
  { id: 54, name: "Cote D'Ivoire", iso_code: 'CI' },
  { id: 55, name: 'Croatia', iso_code: 'HR' },
  { id: 56, name: 'Cuba', iso_code: 'CU' },
  { id: 57, name: 'Curaçao', iso_code: 'CW' },
  { id: 58, name: 'Cyprus', iso_code: 'CY' },
  { id: 59, name: 'Czech Republic', iso_code: 'CZ' },
  { id: 60, name: 'Democratic Republic of Congo', iso_code: 'CD' },
  { id: 61, name: 'Denmark', iso_code: 'DK' },
  { id: 62, name: 'Djibouti', iso_code: 'DJ' },
  { id: 63, name: 'Dominica', iso_code: 'DM' },
  { id: 64, name: 'Dominican Republic', iso_code: 'DO' },
  { id: 65, name: 'Ecuador', iso_code: 'EC' },
  { id: 66, name: 'Egypt', iso_code: 'EG' },
  { id: 67, name: 'El Salvador', iso_code: 'SV' },
  { id: 68, name: 'Equatorial Guinea', iso_code: 'GQ' },
  { id: 69, name: 'Eritrea', iso_code: 'ER' },
  { id: 70, name: 'Estonia', iso_code: 'EE' },
  { id: 71, name: 'Ethiopia', iso_code: 'ET' },
  { id: 72, name: 'Falkland Islands (Malvinas)', iso_code: 'FK' },
  { id: 73, name: 'Faroe Islands', iso_code: 'FO' },
  { id: 74, name: 'Fiji', iso_code: 'FJ' },
  { id: 75, name: 'Finland', iso_code: 'FI' },
  { id: 76, name: 'France', iso_code: 'FR' },
  { id: 77, name: 'French Guiana', iso_code: 'GF' },
  { id: 78, name: 'French Polynesia', iso_code: 'PF' },
  { id: 79, name: 'French Southern Territories', iso_code: 'TF' },
  { id: 80, name: 'Gabon', iso_code: 'GA' },
  { id: 81, name: 'Gambia', iso_code: 'GM' },
  { id: 82, name: 'Georgia', iso_code: 'GE' },
  { id: 83, name: 'Germany', iso_code: 'DE' },
  { id: 84, name: 'Ghana', iso_code: 'GH' },
  { id: 85, name: 'Gibraltar', iso_code: 'GI' },
  { id: 86, name: 'Greece', iso_code: 'GR' },
  { id: 87, name: 'Greenland', iso_code: 'GL' },
  { id: 88, name: 'Grenada', iso_code: 'GD' },
  { id: 89, name: 'Guadeloupe', iso_code: 'GP' },
  { id: 90, name: 'Guam', iso_code: 'GU' },
  { id: 91, name: 'Guatemala', iso_code: 'GT' },
  { id: 92, name: 'Guernsey', iso_code: 'GG' },
  { id: 93, name: 'Guinea', iso_code: 'GN' },
  { id: 94, name: 'Guinea-bissau', iso_code: 'GW' },
  { id: 95, name: 'Guyana', iso_code: 'GY' },
  { id: 96, name: 'Haiti', iso_code: 'HT' },
  { id: 97, name: 'Heard and Mc Donald Islands', iso_code: 'HM' },
  { id: 98, name: 'Honduras', iso_code: 'HN' },
  { id: 99, name: 'Hong Kong', iso_code: 'HK' },
  { id: 100, name: 'Hungary', iso_code: 'HU' },
  { id: 101, name: 'Iceland', iso_code: 'IS' },
  { id: 102, name: 'India', iso_code: 'IN' },
  { id: 103, name: 'Indonesia', iso_code: 'ID' },
  { id: 104, name: 'Iran (Islamic Republic of)', iso_code: 'IR' },
  { id: 105, name: 'Iraq', iso_code: 'IQ' },
  { id: 106, name: 'Ireland', iso_code: 'IE' },
  { id: 107, name: 'Isle of Man', iso_code: 'IM' },
  { id: 108, name: 'Israel', iso_code: 'IL' },
  { id: 109, name: 'Italy', iso_code: 'IT' },
  { id: 110, name: 'Jamaica', iso_code: 'JM' },
  { id: 111, name: 'Japan', iso_code: 'JP' },
  { id: 112, name: 'Jersey', iso_code: 'JE' },
  { id: 113, name: 'Jordan', iso_code: 'JO' },
  { id: 114, name: 'Kazakhstan', iso_code: 'KZ' },
  { id: 115, name: 'Kenya', iso_code: 'KE' },
  { id: 116, name: 'Kiribati', iso_code: 'KI' },
  { id: 117, name: 'North Korea', iso_code: 'KP' },
  { id: 118, name: 'Korea, Republic of', iso_code: 'KR' },
  { id: 119, name: 'Kuwait', iso_code: 'KW' },
  { id: 120, name: 'Kyrgyzstan', iso_code: 'KG' },
  { id: 121, name: "Lao People's Democratic Republic", iso_code: 'LA' },
  { id: 122, name: 'Latvia', iso_code: 'LV' },
  { id: 123, name: 'Lebanon', iso_code: 'LB' },
  { id: 124, name: 'Lesotho', iso_code: 'LS' },
  { id: 125, name: 'Liberia', iso_code: 'LR' },
  { id: 126, name: 'Libyan Arab Jamahiriya', iso_code: 'LY' },
  { id: 127, name: 'Liechtenstein', iso_code: 'LI' },
  { id: 128, name: 'Lithuania', iso_code: 'LT' },
  { id: 129, name: 'Luxembourg', iso_code: 'LU' },
  { id: 130, name: 'Macau', iso_code: 'MO' },
  { id: 131, name: 'Macedonia', iso_code: 'MK' },
  { id: 132, name: 'Madagascar', iso_code: 'MG' },
  { id: 133, name: 'Malawi', iso_code: 'MW' },
  { id: 134, name: 'Malaysia', iso_code: 'MY' },
  { id: 135, name: 'Maldives', iso_code: 'MV' },
  { id: 136, name: 'Mali', iso_code: 'ML' },
  { id: 137, name: 'Malta', iso_code: 'MT' },
  { id: 138, name: 'Marshall Islands', iso_code: 'MH' },
  { id: 139, name: 'Martinique', iso_code: 'MQ' },
  { id: 140, name: 'Mauritania', iso_code: 'MR' },
  { id: 141, name: 'Mauritius', iso_code: 'MU' },
  { id: 142, name: 'Mayotte', iso_code: 'YT' },
  { id: 143, name: 'Mexico', iso_code: 'MX' },
  { id: 144, name: 'Micronesia, Federated States of', iso_code: 'FM' },
  { id: 145, name: 'Moldova, Republic of', iso_code: 'MD' },
  { id: 146, name: 'Monaco', iso_code: 'MC' },
  { id: 147, name: 'Mongolia', iso_code: 'MN' },
  { id: 148, name: 'Montenegro', iso_code: 'ME' },
  { id: 149, name: 'Montserrat', iso_code: 'MS' },
  { id: 150, name: 'Morocco', iso_code: 'MA' },
  { id: 151, name: 'Mozambique', iso_code: 'MZ' },
  { id: 152, name: 'Myanmar', iso_code: 'MM' },
  { id: 153, name: 'Namibia', iso_code: 'NA' },
  { id: 154, name: 'Nauru', iso_code: 'NR' },
  { id: 155, name: 'Nepal', iso_code: 'NP' },
  { id: 156, name: 'Netherlands', iso_code: 'NL' },
  { id: 157, name: 'New Caledonia', iso_code: 'NC' },
  { id: 158, name: 'New Zealand', iso_code: 'NZ' },
  { id: 159, name: 'Nicaragua', iso_code: 'NI' },
  { id: 160, name: 'Niger', iso_code: 'NE' },
  { id: 161, name: 'Nigeria', iso_code: 'NG' },
  { id: 162, name: 'Niue', iso_code: 'NU' },
  { id: 163, name: 'Norfolk Island', iso_code: 'NF' },
  { id: 164, name: 'Northern Mariana Islands', iso_code: 'KP' },
  { id: 165, name: 'Norway', iso_code: 'NO' },
  { id: 166, name: 'Oman', iso_code: 'OM' },
  { id: 167, name: 'Pakistan', iso_code: 'PK' },
  { id: 168, name: 'Palau', iso_code: 'PW' },
  { id: 169, name: 'Panama', iso_code: 'PA' },
  { id: 170, name: 'Papua New Guinea', iso_code: 'PG' },
  { id: 171, name: 'Paraguay', iso_code: 'PY' },
  { id: 172, name: 'Peru', iso_code: 'PE' },
  { id: 173, name: 'Philippines', iso_code: 'PH' },
  { id: 174, name: 'Pitcairn', iso_code: 'PN' },
  { id: 175, name: 'Poland', iso_code: 'PL' },
  { id: 176, name: 'Portugal', iso_code: 'PT' },
  { id: 177, name: 'Puerto Rico', iso_code: 'PR' },
  { id: 178, name: 'Qatar', iso_code: 'QA' },
  { id: 179, name: 'Reunion', iso_code: 'RE' },
  { id: 180, name: 'Romania', iso_code: 'RO' },
  { id: 181, name: 'Russian Federation', iso_code: 'RU' },
  { id: 182, name: 'Rwanda', iso_code: 'RW' },
  { id: 183, name: 'Saint Barthélemy', iso_code: 'BL' },
  { id: 184, name: 'Saint Kitts and Nevis', iso_code: 'KN' },
  { id: 185, name: 'Saint Lucia', iso_code: 'LC' },
  { id: 186, name: 'Saint Martin', iso_code: 'MF' },
  { id: 187, name: 'Saint Vincent and the Grenadines', iso_code: 'VC' },
  { id: 188, name: 'Samoa', iso_code: 'WS' },
  { id: 189, name: 'San Marino', iso_code: 'SM' },
  { id: 190, name: 'Sao Tome and Principe', iso_code: 'ST' },
  { id: 191, name: 'Saudi Arabia', iso_code: 'SA' },
  { id: 192, name: 'Senegal', iso_code: 'SN' },
  { id: 193, name: 'Serbia', iso_code: 'RS' },
  { id: 194, name: 'Seychelles', iso_code: 'SC' },
  { id: 195, name: 'Sierra Leone', iso_code: 'SL' },
  { id: 196, name: 'Singapore', iso_code: 'SG' },
  { id: 197, name: 'Sint Maarten', iso_code: 'SX' },
  { id: 198, name: 'Slovak Republic', iso_code: 'SK' },
  { id: 199, name: 'Slovenia', iso_code: 'SI' },
  { id: 200, name: 'Solomon Islands', iso_code: 'SB' },
  { id: 201, name: 'Somalia', iso_code: 'SO' },
  { id: 202, name: 'South Africa', iso_code: 'ZA' },
  { id: 203, name: 'South Georgia &amp; South Sandwich Islands', iso_code: 'GS' },
  { id: 204, name: 'South Sudan', iso_code: 'SS' },
  { id: 205, name: 'Spain', iso_code: 'ES' },
  { id: 206, name: 'Sri Lanka', iso_code: 'LK' },
  { id: 207, name: 'St. Helena', iso_code: 'SH' },
  { id: 208, name: 'St. Pierre and Miquelon', iso_code: 'PM' },
  { id: 209, name: 'Sudan', iso_code: 'SD' },
  { id: 210, name: 'Suriname', iso_code: 'SR' },
  { id: 211, name: 'Svalbard and Jan Mayen Islands', iso_code: 'SJ' },
  { id: 212, name: 'Swaziland', iso_code: 'SZ' },
  { id: 213, name: 'Sweden', iso_code: 'SE' },
  { id: 214, name: 'Switzerland', iso_code: 'CH' },
  { id: 215, name: 'Syrian Arab Republic', iso_code: 'SY' },
  { id: 216, name: 'Taiwan', iso_code: 'TW' },
  { id: 217, name: 'Tajikistan', iso_code: 'TJ' },
  { id: 218, name: 'Tanzania, United Republic of', iso_code: 'TZ' },
  { id: 219, name: 'Thailand', iso_code: 'TH' },
  { id: 220, name: 'Timor-Leste', iso_code: 'TL' },
  { id: 221, name: 'Togo', iso_code: 'TG' },
  { id: 222, name: 'Tokelau', iso_code: 'TK' },
  { id: 223, name: 'Tonga', iso_code: 'TO' },
  { id: 224, name: 'Trinidad and Tobago', iso_code: 'TT' },
  { id: 225, name: 'Tunisia', iso_code: 'TN' },
  { id: 226, name: 'Turkey', iso_code: 'TR' },
  { id: 227, name: 'Turkmenistan', iso_code: 'TM' },
  { id: 228, name: 'Turks and Caicos Islands', iso_code: 'TC' },
  { id: 229, name: 'Tuvalu', iso_code: 'TV' },
  { id: 230, name: 'Uganda', iso_code: 'UG' },
  { id: 231, name: 'Ukraine', iso_code: 'UA' },
  { id: 232, name: 'United Arab Emirates', iso_code: 'AE' },
  { id: 233, name: 'United Kingdom', iso_code: 'GB' },
  { id: 234, name: 'United States', iso_code: 'US' },
  { id: 235, name: 'United States Minor Outlying Islands', iso_code: 'UM' },
  { id: 236, name: 'Uruguay', iso_code: 'UY' },
  { id: 237, name: 'Uzbekistan', iso_code: 'UZ' },
  { id: 238, name: 'Vanuatu', iso_code: 'VU' },
  { id: 239, name: 'Vatican City State (Holy See)', iso_code: 'VA' },
  { id: 240, name: 'Venezuela', iso_code: 'VE' },
  { id: 241, name: 'Viet Nam', iso_code: 'VN' },
  { id: 242, name: 'Virgin Islands (British)', iso_code: 'VG' },
  { id: 243, name: 'Virgin Islands (U.S.)', iso_code: 'VI' },
  { id: 244, name: 'Wallis and Futuna Islands', iso_code: 'WF' },
  { id: 245, name: 'Western Sahara', iso_code: 'EH' },
  { id: 246, name: 'Yemen', iso_code: 'YE' },
  { id: 247, name: 'Democratic Republic of Congo', iso_code: 'CD' },
  { id: 248, name: 'Zambia', iso_code: 'ZM' },
  { id: 249, name: 'Zimbabwe', iso_code: 'ZW' },
];

export const iso2iso3: Record<string, string> = {
  AF: 'AFG',
  AL: 'ALB',
  DZ: 'DZA',
  AS: 'ASM',
  AD: 'AND',
  AO: 'AGO',
  AI: 'AIA',
  AQ: 'ATA',
  AG: 'ATG',
  AR: 'ARG',
  AM: 'ARM',
  AW: 'ABW',
  AU: 'AUS',
  AT: 'AUT',
  AZ: 'AZE',
  BS: 'BHS',
  BH: 'BHR',
  BD: 'BGD',
  BB: 'BRB',
  BY: 'BLR',
  BE: 'BEL',
  BZ: 'BLZ',
  BJ: 'BEN',
  BM: 'BMU',
  BT: 'BTN',
  BO: 'BOL',
  BQ: 'BES',
  BA: 'BIH',
  BW: 'BWA',
  BV: 'BVT',
  BR: 'BRA',
  IO: 'IOT',
  BN: 'BRN',
  BG: 'BGR',
  BF: 'BFA',
  BI: 'BDI',
  CV: 'CPV',
  KH: 'KHM',
  CM: 'CMR',
  CA: 'CAN',
  KY: 'CYM',
  CF: 'CAF',
  TD: 'TCD',
  CL: 'CHL',
  CN: 'CHN',
  CX: 'CXR',
  CC: 'CCK',
  CO: 'COL',
  KM: 'COM',
  CD: 'COD',
  CG: 'COG',
  CK: 'COK',
  CR: 'CRI',
  HR: 'HRV',
  CU: 'CUB',
  CW: 'CUW',
  CY: 'CYP',
  CZ: 'CZE',
  CI: 'CIV',
  DK: 'DNK',
  DJ: 'DJI',
  DM: 'DMA',
  DO: 'DOM',
  EC: 'ECU',
  EG: 'EGY',
  SV: 'SLV',
  GQ: 'GNQ',
  ER: 'ERI',
  EE: 'EST',
  SZ: 'SWZ',
  ET: 'ETH',
  FK: 'FLK',
  FO: 'FRO',
  FJ: 'FJI',
  FI: 'FIN',
  FR: 'FRA',
  GF: 'GUF',
  PF: 'PYF',
  TF: 'ATF',
  GA: 'GAB',
  GM: 'GMB',
  GE: 'GEO',
  DE: 'DEU',
  GH: 'GHA',
  GI: 'GIB',
  GR: 'GRC',
  GL: 'GRL',
  GD: 'GRD',
  GP: 'GLP',
  GU: 'GUM',
  GT: 'GTM',
  GG: 'GGY',
  GN: 'GIN',
  GW: 'GNB',
  GY: 'GUY',
  HT: 'HTI',
  HM: 'HMD',
  VA: 'VAT',
  HN: 'HND',
  HK: 'HKG',
  HU: 'HUN',
  IS: 'ISL',
  IN: 'IND',
  ID: 'IDN',
  IR: 'IRN',
  IQ: 'IRQ',
  IE: 'IRL',
  IM: 'IMN',
  IL: 'ISR',
  IT: 'ITA',
  JM: 'JAM',
  JP: 'JPN',
  JE: 'JEY',
  JO: 'JOR',
  KZ: 'KAZ',
  KE: 'KEN',
  KI: 'KIR',
  KP: 'PRK',
  KR: 'KOR',
  KW: 'KWT',
  KG: 'KGZ',
  LA: 'LAO',
  LV: 'LVA',
  LB: 'LBN',
  LS: 'LSO',
  LR: 'LBR',
  LY: 'LBY',
  LI: 'LIE',
  LT: 'LTU',
  LU: 'LUX',
  MO: 'MAC',
  MG: 'MDG',
  MW: 'MWI',
  MY: 'MYS',
  MV: 'MDV',
  ML: 'MLI',
  MT: 'MLT',
  MH: 'MHL',
  MQ: 'MTQ',
  MR: 'MRT',
  MU: 'MUS',
  YT: 'MYT',
  MX: 'MEX',
  FM: 'FSM',
  MD: 'MDA',
  MC: 'MCO',
  MN: 'MNG',
  ME: 'MNE',
  MS: 'MSR',
  MA: 'MAR',
  MZ: 'MOZ',
  MM: 'MMR',
  NA: 'NAM',
  NR: 'NRU',
  NP: 'NPL',
  NL: 'NLD',
  NC: 'NCL',
  NZ: 'NZL',
  NI: 'NIC',
  NE: 'NER',
  NG: 'NGA',
  NU: 'NIU',
  NF: 'NFK',
  MP: 'MNP',
  NO: 'NOR',
  OM: 'OMN',
  PK: 'PAK',
  PW: 'PLW',
  PS: 'PSE',
  PA: 'PAN',
  PG: 'PNG',
  PY: 'PRY',
  PE: 'PER',
  PH: 'PHL',
  PN: 'PCN',
  PL: 'POL',
  PT: 'PRT',
  PR: 'PRI',
  QA: 'QAT',
  MK: 'MKD',
  RO: 'ROU',
  RU: 'RUS',
  RW: 'RWA',
  RE: 'REU',
  BL: 'BLM',
  SH: 'SHN',
  KN: 'KNA',
  LC: 'LCA',
  MF: 'MAF',
  PM: 'SPM',
  VC: 'VCT',
  WS: 'WSM',
  SM: 'SMR',
  ST: 'STP',
  SA: 'SAU',
  SN: 'SEN',
  RS: 'SRB',
  SC: 'SYC',
  SL: 'SLE',
  SG: 'SGP',
  SX: 'SXM',
  SK: 'SVK',
  SI: 'SVN',
  SB: 'SLB',
  SO: 'SOM',
  ZA: 'ZAF',
  GS: 'SGS',
  SS: 'SSD',
  ES: 'ESP',
  LK: 'LKA',
  SD: 'SDN',
  SR: 'SUR',
  SJ: 'SJM',
  SE: 'SWE',
  CH: 'CHE',
  SY: 'SYR',
  TW: 'TWN',
  TJ: 'TJK',
  TZ: 'TZA',
  TH: 'THA',
  TL: 'TLS',
  TG: 'TGO',
  TK: 'TKL',
  TO: 'TON',
  TT: 'TTO',
  TN: 'TUN',
  TR: 'TUR',
  TM: 'TKM',
  TC: 'TCA',
  TV: 'TUV',
  UG: 'UGA',
  UA: 'UKR',
  AE: 'ARE',
  GB: 'GBR',
  UM: 'UMI',
  US: 'USA',
  UY: 'URY',
  UZ: 'UZB',
  VU: 'VUT',
  VE: 'VEN',
  VN: 'VNM',
  VG: 'VGB',
  VI: 'VIR',
  WF: 'WLF',
  EH: 'ESH',
  YE: 'YEM',
  ZM: 'ZMB',
  ZW: 'ZWE',
  AX: 'ALA',
}

export default { countryList };
