
import Vue from 'vue';
import { IRootState } from '@/models/IRootState';
import customMapState from '@/helpers/mapHelper';
import { useExternalScript } from '@/helpers/useExternalScript';
import cardExpDate from '@/components/shared/card-exp-date/card-exp-date.vue'

const sumitScript = useExternalScript('https://app.sumit.co.il/scripts/payments.js')

export default Vue.extend({
  components: {
    cardExpDate,
  },

  inject: ['$validator'],

  data() {
    return {
      error: '',
      expDate: {
        month: '',
        year: '',
      },
      cccvv: '',
      cardnumber: '',
    }
  },

  computed: {
    ...customMapState({
      comapny_id: (state: IRootState) => state.donation.paymentConfig.attributes.configuration.comapny_id,
      public_key: (state: IRootState) => state.donation.paymentConfig.attributes.configuration.public_key,
    }),
  },

  mounted() {
    sumitScript?.load()
  },
});
