export const networks: {[key:string]: { sharer: string }} = {
  email: {
    sharer: 'mailto:?subject=@title&body=@url%0D%0A%0D%0A@description',
  },

  facebook: {
    sharer: 'https://www.facebook.com/sharer/sharer.php?u=@url&title=@title&description=@description&quote=@quote',
  },

  googleplus: {
    sharer: 'https://plus.google.com/share?url=@url',
  },

  line: {
    sharer: 'http://line.me/R/msg/text/?@description%0D%0A@url',
  },

  linkedin: {
    sharer: 'https://www.linkedin.com/shareArticle?mini=true&url=@url&title=@title&summary=@description',
  },

  odnoklassniki: {
    sharer: 'https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=@url&st.comments=@description',
  },

  pinterest: {
    sharer: 'https://pinterest.com/pin/create/button/?url=@url&media=@media&description=@title',
  },

  reddit: {
    sharer: 'https://www.reddit.com/submit?url=@url&title=@title',
  },

  skype: {
    sharer: 'https://web.skype.com/share?url=@description%0D%0A@url',
  },

  telegram: {
    sharer: 'https://t.me/share/url?url=@url&text=@description',
  },

  twitter: {
    sharer: 'https://twitter.com/intent/tweet?text=@description&url=@url&hashtags=@hashtags@twitteruser',
  },

  viber: {
    sharer: 'viber://forward?text=@url @description',
  },

  vk: {
    sharer: 'https://vk.com/share.php?url=@url&title=@title&description=@description&image=@media&noparse=true',
  },

  weibo: {
    sharer: 'http://service.weibo.com/share/share.php?url=@url&title=@title',
  },

  whatsapp: {
    sharer: 'whatsapp://send?text=@quote%0D%0A@url',
  },

  'whatsapp-web': {
    sharer: 'https://web.whatsapp.com/send?text=@quote%0D%0A@url',
  },

  sms: {
    sharer: 'sms:?body=@url%20@description',
  },
}

export default networks
