
import Vue from 'vue'
import customMapState from '@/helpers/mapHelper'
import { IRootState } from '@/models/IRootState'
import { useExternalScript } from '@/helpers/useExternalScript';

import CardknoxGooglePayButton from './CardknoxGooglePayButton.vue';
import CardknoxApplePayButton from './CardknoxApplePayButton.vue';

const cardknoxIFieldsScript = useExternalScript('https://cdn.cardknox.com/ifields/2.13.2204.1401/ifields.min.js')

export default Vue.extend({
  components: {
    CardknoxGooglePayButton,
    CardknoxApplePayButton,
  },

  props: {
    donateBtnDisabled: Boolean,
  },

  data() {
    return {
      scriptLoaded: false,
      loaded: false,
    }
  },

  computed: {
    ...customMapState({
      isSubmitted: (state: IRootState) => state.donation.isSubmitted,
    }),

    isLoading(): boolean {
      return this.isSubmitted || !this.loaded;
    },
  },

  mounted() {
    this.scriptLoaded = false
    cardknoxIFieldsScript?.onLoad(() => {
      this.scriptLoaded = true
    })
    cardknoxIFieldsScript?.load()
  },

  methods: {
    onLoaded() {
      this.loaded = true
    },
  },
})
