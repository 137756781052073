function sendToParent(data: Record<string, unknown>) {
  const inIframe = window.self !== window.parent;

  if (inIframe) {
    window.parent.postMessage(data, '*')
  }
}

function unloadListener() {
  sendToParent({ type: 'unload-donation-form' });
}

function beforeUnloadListener(event: BeforeUnloadEvent) {
  sendToParent({ type: 'confirm-close-from-before-unload' });
  const confirmationMessage = 'Are you sure to quit this page?'; // Compatibility (doesn't work)
  (event || window.event).returnValue = confirmationMessage; // Gecko + IE
  return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
}

export function initBeforeUnloadListener() {
  window.addEventListener('unload', unloadListener, { capture: true });
  window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
}

export function removeBeforeUnloadListener() {
  window.removeEventListener('beforeunload', beforeUnloadListener, {
    capture: true,
  });
}

export default {}
