
import Vue from 'vue'
import { mapGetters } from 'vuex';

import customMapState from '@/helpers/mapHelper'
import { IRootState } from '@/models/IRootState'
import { AvailableDisplayCurrencyList } from '@/store/modules/models/donation';
import { RecurringConfig } from '@/models/IDonationParams';

export default Vue.extend({
  computed: {
    ...customMapState({
      originAmount: (state: IRootState) => state.donation.originAmount,
      selectedRecurringPeriod: (state: IRootState) => state.donation.donationData.attributes.recurring_period,
      multiplier: (state: IRootState) => state.donation.donationParams.data.attributes.current_multiplier,
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      changeRate: (state: IRootState) => state.donation.changeRate,
      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),
    }),

    ...mapGetters({
      showAmountInLocCurrency: 'showAmountInLocCurrency',
    }),

    recurringConfig(): RecurringConfig {
      return this.config.recurring_config;
    },

    orgName(): string {
      return this.config.organization.orgname;
    },

    campaignCurrency(): AvailableDisplayCurrencyList {
      const state = this.$store.state as IRootState;
      const cmp = state.donation.campaign.data
      if (this.showAmountInLocCurrency) {
        return this.showAmountInLocCurrency;
      }
      return {
        sign: cmp.attributes.currency_sign,
        code: cmp.attributes.currency,
      }
    },

    cmpCurCode(): string {
      return this.campaignCurrency.code;
    },

    total(): number {
      const {
        originAmount,
        selectedRecurringPeriod,
      } = this;

      return (originAmount * ((selectedRecurringPeriod * 30) || 1))
    },
  },
})
