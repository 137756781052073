export const getUtmParameters = (search: URLSearchParams, extraParams: string[]): string => {
  const arr: string[] = [];

  search.forEach((v, k) => {
    if (k.includes('utm_')) {
      const value = `${k}:${v}`;

      if (!arr.includes(value)) {
        arr.push(`${k}:${v}`)
      }
    }
  })

  if (extraParams.length) {
    arr.push(...extraParams);
  }

  return arr.join(';');
}

export const parentUtmParameters = (extraParams: string[]) => {
  if (document.referrer) {
    return getUtmParameters(new URL(document.referrer).searchParams, extraParams);
  }
  return '';
}

export const composeReferrer = (searchParams: URLSearchParams, referrerId: string) => {
  const extra = [];

  if (referrerId) {
    extra.push(`utm_source:${referrerId}`)
  }

  const parentUtm = parentUtmParameters(extra);
  if (parentUtm) {
    return parentUtm
  }
  return getUtmParameters(searchParams, extra)
}

export default {
  getUtmParameters,
  parentUtmParameters,
}
