import { IDonationRequest, DonationAttributes } from '@/store/modules/models/donation';

export const getDonationDataFromLoacalStarage = (): DonationAttributes | null => {
  const dd = localStorage.getItem('_dd');

  try {
    return dd ? JSON.parse(dd) as DonationAttributes : null;
  } catch {
    return null;
  }
};

export const setDonationDataToLoacalStarage = (data: IDonationRequest) => {
  try {
    localStorage.setItem('_dd', JSON.stringify(data.data.attributes))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
