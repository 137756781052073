
import Vue from 'vue';
import axios from 'axios';
import customMapState from '@/helpers/mapHelper';
import urls from '@/constants/urls';
import { IRootState } from '../../models/IRootState';
import { IXchangeResponse } from './subcomponents-models/DonationAmount-models';

const VueComponent = Vue.extend({
  computed: {
    ...customMapState({
      goalAsItems: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.goal_as_items,
      totalAmount: (state: IRootState) => state.donation.totalAmount,
      originAmount: (state: IRootState) => state.donation.originAmount,
      reccuringPeriod: (state: IRootState) => state.donation.donationData
        .attributes.recurring_period || 1,
      multiplier: (state: IRootState) => state.donation.donationParams.data
        .attributes.current_multiplier,
      secondaryColor: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.style_config.preset_colors.secondary || '#ca6d4f',
      donationCurrencyCode: (state: IRootState) => state.donation.donationData.attributes.currency,
      campaignCurrencyCode: (state: IRootState) => state.donation.campaign.data.attributes.currency,
    }),

    amountRestricted(): boolean {
      return this.goalAsItems.amount_restricted_to_item_price;
    },

    total(): number {
      return this.totalAmount * this.reccuringPeriod * this.multiplier;
    },

    numberOfItems(): number {
      const { item_cost, item_cost_min_curr_denom } = this.goalAsItems;
      const itemCost = item_cost || item_cost_min_curr_denom / 100;
      return Math.floor(this.total / itemCost);
    },
  },

  methods: {
    async trigger(action: string) {
      const { item_cost, item_cost_min_curr_denom } = this.goalAsItems;
      const itemCost = (item_cost || item_cost_min_curr_denom / 100) / this.multiplier;

      const amount = await this.getExchangeRate(itemCost);

      if (action === '+') {
        this.$store.commit('setOriginAmount', this.originAmount + amount);
      }
      if (action === '-' && (this.originAmount - amount) > 0) {
        this.$store.commit('setOriginAmount', this.originAmount - amount);
      } else if (action === '-' && (this.originAmount - amount) <= 0) {
        this.$store.commit('setOriginAmount', 0);
      }
    },

    async getExchangeRate(amount: number): Promise<number> {
      const params = {
        amount,
        from: this.campaignCurrencyCode,
        to: this.donationCurrencyCode,
      };

      const { data: { data } } = await axios.get<IXchangeResponse>(urls.getExchange, { params });
      const { attributes: attr } = data;

      return Math.ceil(attr.amount_out);
    },
  },
});
export default class GoalAsItem extends VueComponent {}
