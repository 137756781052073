
import Vue from 'vue';
import { IRootState } from '@/models/IRootState';
import customMapState from '@/helpers/mapHelper';

const VueComponent = Vue.extend({
  props: {
    donation: {
      type: Object,
      default: () => {},
    },
  },

  computed: customMapState({
    paymentConfig: (state: IRootState) => state.donation.paymentConfig,
  }),

  methods: {
    openTaxGiftForm() {
      this.$store.commit('setStepFlag', 4);
      window.parent.postMessage({ type: 'continue-to-next-step' }, '*');
    },
  },
});

export default class TaxGiftExtraPanel extends VueComponent { }
