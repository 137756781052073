
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  data() {
    return {
      autocomplete: {} as any,
    }
  },

  computed: {
    country: {
      get(): String {
        return this.$store.state.donation.donationData.attributes.address_country || null;
      },
      set(value: String) {
        this.$store.commit('setCountry', value);
        this.getStateCity();
      },
    },

    state: {
      get(): String {
        return this.$store.state.donation.donationData.attributes.address_state;
      },
      set(value: String) {
        this.$store.commit('setState', value);
      },
    },

    city: {
      get(): String {
        return this.$store.state.donation.donationData.attributes.address_city;
      },
      set(value: String) {
        this.$store.commit('setCity', value);
      },
    },

    zipcode: {
      get(): String {
        return this.$store.state.donation.donationData.attributes.address_zip;
      },
      set(value: String) {
        this.$store.commit('setZipcode', value);
        this.getStateCity();
      },
    },

    address: {
      get(): String {
        return this.$store.state.donation.donationData.attributes.address_line1;
      },
      set(value: String) {
        this.$store.commit('setAddress', value);
      },
    },

    address_number: {
      get(): String {
        return this.$store.state.donation.donationData.attributes.address_number;
      },
      set(value: String) {
        this.$store.commit('setAddressNumber', value);
      },
    },

    suite: {
      get(): String {
        return this.$store.state.donation.donationData.attributes.address_line2;
      },
      set(value: String) {
        this.$store.commit('setSuite', value);
      },
    },

    addressRequired(): boolean {
      const { required } = this.addressConfig;

      return this.isAddressRequired(required)
    },

    addressFieldRequired(): boolean {
      const { address_field_required } = this.addressConfig;

      return this.isAddressRequired(address_field_required)
    },

    zipFieldRequired(): boolean {
      const { zip_field_required } = this.addressConfig;

      if (['banquest'].includes(this.psName)) {
        return true
      }

      return this.isAddressRequired(zip_field_required)
    },

    cityFieldRequired(): boolean {
      const { city_field_required } = this.addressConfig;

      return this.isAddressRequired(city_field_required)
    },

    stateMandatory(): boolean {
      const { state_mandatory } = this.addressConfig;
      return state_mandatory
    },

    useAutocomplete(): boolean {
      return (this.addressConfig.available || this.addressRequired)
      && this.addressConfig.autocomplete
    },

    ...customMapState({
      donationAmount: (state: IRootState) => state.donation.donationData.attributes.amount / 100,

      giftAid: (state: IRootState) => state.donation.donationData.attributes.gift_aid,

      addressConfig: (state: IRootState) => state.donation.donationParams
        .data.attributes.address_config,

      countryList: (state: IRootState) => state.donation.donationParams
        .data.attributes.address_config.country_list,

      retrievedFromZip: (state: IRootState) => state.donation.retrievedFromZip,

      orgLegalEntity: (state: IRootState) => state.donation.paymentConfig
        ?.attributes.organization_legal_entity,

      psName: (state: IRootState) => state.donation.paymentMethod,

      mailReceipt: (state: IRootState) => state.donation.donationData.attributes.mail_receipt,
    }),
  },

  mounted() {
    if (this.useAutocomplete) {
      this.initAutocomplete();
    }
  },

  methods: {
    isAddressRequired(required: boolean): boolean {
      const { required_for_gateways, not_required_for_gateways } = this.addressConfig;

      if (
        required
        && not_required_for_gateways?.includes(this.psName)
        && !this.mailReceipt
        && !this.giftAid
      ) {
        return false;
      }

      return (
        required
        || this.giftAid
        || required_for_gateways?.includes(this.psName)
        || this.mailReceipt
      )
    },

    suffix(required: boolean | null = null): string {
      const forCountry = ['au'];
      const countrylc = this.country?.toLowerCase()
      let res = '';

      if (forCountry.includes(countrylc)) {
        res = `_${countrylc}`;
      }
      if (!this.addressRequired && required === null) {
        res += '_optional'
      }
      if (required !== null && !required) {
        res += '_optional'
      }

      return res;
    },

    initAutocomplete() {
      const el = this.$refs.address;
      if (!google) {
        return
      }
      this.autocomplete = new google.maps.places.Autocomplete(
        el, { types: ['geocode'] },
      );
      this.autocomplete.setFields(['address_component']);
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },

    fillInAddress() {
      const componentForm: {[key:string]: {key: string, add?: boolean, name: string}} = {
        street_number: { name: 'short_name', key: 'address' },
        route: { name: 'long_name', add: true, key: 'address' },
        locality: { name: 'long_name', key: 'city' },
        administrative_area_level_1: { name: 'short_name', key: 'state' },
        country: { name: 'short_name', key: 'country' },
        postal_code: { name: 'short_name', key: 'zipcode' },
        subpremise: { name: 'short_name', key: 'suite' },
      };

      const place = this.autocomplete.getPlace();

      for (let i = 0; i < place.address_components.length; i += 1) {
        const addressType = place.address_components[i].types[0];
        const { name, add, key } = componentForm[addressType] || {};
        if (name) {
          const val = place.address_components[i][name];
          if (add) {
            // @ts-ignore
            this[key] += ` ${val}`;
          } else {
            // @ts-ignore
            this[key] = val;
          }
        }
      }
    },
    // get geolocation of user for autocomplete (not used)
    geolocate() {
      if (navigator.geolocation && this.addressConfig.autocomplete) {
        navigator.geolocation.getCurrentPosition(position => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const circle = new google.maps.Circle(
            { center: geolocation, radius: position.coords.accuracy },
          );
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },

    getStateCity(): void {
      if (this.useAutocomplete) {
        return
      }
      if (this.zipcode.length > 3 && this.country !== null) {
        this.$store.dispatch('getStateCity', {
          zipCode: this.zipcode,
          isoCode: this.country,
        });
      }
    },
  },
});
export default class AddressData extends VueComponent {}
