
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IGiftAidConfig } from '@/models/IDonationParams';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  data() {
    return {
      showWarning: false,
    }
  },

  computed: {
    show(): boolean {
      const isSupportedByGateway = this.giftAidConfig.gateways.includes(this.paymentMethod)

      if (this.orgLegalEntity.country === 'GB' && isSupportedByGateway) {
        return true;
      }

      this.$store.commit('setGiftAid', false);
      return false;
    },

    country: {
      get(): String {
        return this.$store.state.donation.donationData.attributes.address_country || null;
      },
    },

    giftAid: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes.gift_aid;
      },
      set(value: boolean) {
        this.$store.commit('setGiftAid', value);
      },
    },

    content(): string | undefined {
      const fallback = `<p>Gift Aid is applicable only to UK residents who pay enough UK income tax and/or capital gains tax to cover the Gift Aid benefit.</p>
        <p>By ticking the box, I confirm I have paid or will pay an amount of Income Tax and/or Capital Gains Tax for each tax year (6 April
        to 5 April) that is at least equal to the amount of tax that all the charities or Community Amateur Sports Clubs (CASCs) that I
        donate to will reclaim on my gifts for that tax year. I understand that other taxes such as VAT and Council Tax do not qualify.
        I understand the charity will reclaim 25p of tax on every £1 that I give on or after 6 April 2008.</p>
        <p><small>Please notify us if you:<br>
        - Want to cancel this declaration<br>
        - Change your name or home address<br>
        - No longer pay sufficient tax on your income and/or capital gains.<br>
        - If you pay Income Tax at the higher or additional rate and want to receive the additional tax relief due
        to you, you must include all your Gift Aid donations on your Self Assessment tax return or ask HM
        Revenue and Customs to adjust your tax code.</small></p>`

      return this.$t('donation.gift_aid_tooltip_text', fallback)
    },

    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      donationAmount: (state: IRootState) => state.donation.donationData.attributes.amount / 100,
      reccuringPeriod: (state: IRootState) => state.donation.donationData.attributes.recurring_period || 1,
      currencySign: (state: IRootState) => state.donation.donationData.attributes.currency_sign,
      currency: (state: IRootState) => state.donation.donationData.attributes.currency,
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,

      orgLegalEntity: (state: IRootState) => (state.donation.paymentConfig?.attributes?.organization_legal_entity || { country: '' }),
    }),

    giftAidConfig(): IGiftAidConfig {
      return this.config.gift_aid_config;
    },

    effective(): number {
      const k = this.giftAidConfig.additional_percent / 100;
      return this.donationAmount * k;
    },

    orgName(): string {
      return this.config.organization.orgname;
    },
  },

  watch: {
    show(v) {
      if (v) {
        this.showWarning = false;
      }
    },

    giftAid(nv, ov) {
      if (!nv === ov) {
        this.showWarning = true;
      }
    },
  },
});
export default class GiftAid extends VueComponent {}
