var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{staticClass:"notranslate",class:{
    [_vm.lang]: _vm.lang,
    [_vm.gatewayClass()]: _vm.paymentConfig,
    [_vm.customView]: _vm.customView,
    iframe: _vm.iframe
  },style:({
    direction: _vm.direction
  }),attrs:{"id":"app","data-gateway-id":_vm.paymentConfig && _vm.paymentConfig.id}},[_c('div',{ref:"main",staticClass:"plate-main"},[_c('app-error-block'),_c('app-donate-extended'),(_vm.isDialogVisible)?_c('app-dialog'):_vm._e(),_c('app-overlay'),(_vm.fbPixelId !== null)?_c('noscript',[_c('img',{staticStyle:{"display":"none"},attrs:{"height":"1","width":"1","src":`https://www.facebook.com/tr?id=${_vm.fbPixelId}&ev=PageView&noscript=1`}})]):_vm._e()],1),_c('donor-rescue')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }