
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';

const VueComponent = Vue.extend({
  computed: {
    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
      cid: (state: IRootState) => state.donation.campaign.data.id,
    }),

    show() {
      return this.config.gateway_custom_header_config?.gateways?.includes(this.paymentMethod);
    },
  },
});
export default class PaymentCustomHeader extends VueComponent {}
