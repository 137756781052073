
import Vue from 'vue'
import { mapActions } from 'vuex'
import customMapState from '@/helpers/mapHelper'
import { IRootState } from '@/models/IRootState'

export default Vue.extend({

  data() {
    return {
      elements: null as any,

      showStripeLoadWarning: false,

      errorMessage: '',
    };
  },

  computed: {
    ...customMapState({
      stripe: (state: IRootState) => state.stripe.stripe,
      paymentIntentClientSecret: (state: IRootState) => state.stripe.paymentIntentClientSecret,
      donationAttr: (state: IRootState) => state.donation.donationData.attributes,
      locale: (state: IRootState) => state.i18n.locale,
    }),
  },

  mounted() {
    if (this.paymentIntentClientSecret) {
      this.init()
    }
  },

  methods: {
    ...mapActions({
      useStripe: 'useStripe',
    }),

    init() {
      // create Stripe instance in store
      this.useStripe();

      if (typeof Stripe === 'undefined') {
        this.showStripeLoadWarning = true;
        return;
      }

      const options = {
        clientSecret: this.paymentIntentClientSecret,
      };

      this.elements = this.stripe.elements(options);

      const paymentElement = this.elements.create('payment');
      paymentElement.mount(this.$refs.paymentElement);
    },

    async submit() {
      const { origin, pathname } = window.location;

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${origin}${pathname}?lang=${this.locale}&po=${this.donationAttr.payment_option_id}`,
        },
      });

      if (error) {
        this.errorMessage = error.message;
      } else {
        this.errorMessage = '';
      }
    },
  },

})
