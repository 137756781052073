import axios from 'axios';
import urls from '@/constants/urls';
import { useExternalScript } from '@/helpers/useExternalScript';
import { computed, reactive } from 'vue';
import { IError, PaymentConfig } from '@/store/modules/models/donation';

interface State {
  campaign_id: number,
  payment_option: PaymentConfig | undefined,

  terminal?: any,
}

const state = reactive<State>({
  campaign_id: 0,
  payment_option: undefined,

  terminal: undefined,
})

const stripeTerminalStript = useExternalScript('https://js.stripe.com/terminal/v1/')

export const useStripeTerminal = () => {
  function statusUpdate(intent_id: any, error: boolean) {
    const data = {
      intent_id,
      error,
      campaign_id: state.campaign_id,
    };

    return axios
      .post(urls.statusUpdateStripTerminal, data)
  }

  async function fetchConnectionToken() {
    if (!state.payment_option) {
      return ''
    }
    const url = urls.getConnectionToken
      .replace(':cid', state.campaign_id.toString())
      .replace(':poid', state.payment_option.id)
    const response = await axios.post(url);
    return response.data.secret;
  }

  function unexpectedDisconnect() {
    // You might want to display UI to notify the user and start re-discovering readers
  }

  async function discoverReaders(config = { simulated: false }): Promise<[any[] | undefined, IError| null]> {
    if (!state.terminal) {
      return [
        undefined,
        {
          key: 'terminal_not_exist',
          text: 'Terminal not initialized',
          title: 'Terminal',
          translationKey: ['', ''],
        },
      ]
    }

    const discoverResult = await state.terminal.discoverReaders(config)
      .then((data: any) => data)
      .catch((err: any) => ({ error: err.error }));
    if (discoverResult.error) {
      return [
        discoverResult,
        {
          key: 'terminal_discover_error',
          text: `Failed to discover: ${discoverResult.error.message}`,
          title: 'Terminal',
          translationKey: ['', ''],
        },
      ]
    }

    return [discoverResult.discoveredReaders, null]
  }

  async function connectReader(selectedReader: any): Promise<[any | undefined, IError| null]> {
    if (!state.terminal) {
      return [
        undefined,
        {
          key: 'terminal_not_exist',
          text: 'Terminal not initialized',
          title: 'Terminal',
          translationKey: ['', ''],
        },
      ]
    }

    const connectResult = await state.terminal.connectReader(selectedReader)
      .then((data: any) => data)
      .catch((err: any) => ({ error: err.error }));
    if (connectResult.error) {
      switch (connectResult.error.code) {
        case 'already_connected':
          return [selectedReader, null]

        default:
      }

      return [
        connectResult,
        {
          key: 'terminal_connect_error',
          text: `Failed to connect: ${connectResult.error.message}`,
          title: 'Terminal',
          translationKey: ['', ''],
        },
      ]
    }

    return [connectResult.reader, null]
  }

  async function getPaymentStatus(): Promise<[string, IError| null]> {
    if (!state.terminal) {
      return [
        '',
        {
          key: 'terminal_not_exist',
          text: 'Terminal not initialized',
          title: 'Terminal',
          translationKey: ['', ''],
        },
      ]
    }

    const result = await state.terminal.getPaymentStatus()

    return [result, null]
  }

  async function collectPaymentMethod(clientSecret: string): Promise<[any | undefined, IError| null]> {
    if (!state.terminal) {
      return [
        undefined,
        {
          key: 'terminal_not_exist',
          text: 'Terminal not initialized',
          title: 'Terminal',
          translationKey: ['', ''],
        },
      ]
    }

    const result = await state.terminal.collectPaymentMethod(clientSecret)
      .then((data: any) => data)
      .catch((err: any) => ({ error: err.error }));
    if (result.error) {
      return [
        result,
        {
          key: 'terminal_collect_payment_method_error',
          text: `Failed to collect payment method: ${result.error.message}`,
          title: 'Terminal',
          translationKey: ['', ''],
        },
      ]
    }

    return [result.paymentIntent, null]
  }

  async function processPayment(paymentIntent: string): Promise<[any | undefined, IError| null]> {
    if (!state.terminal) {
      return [
        undefined,
        {
          key: 'terminal_not_exist',
          text: 'Terminal not initialized',
          title: 'Terminal',
          translationKey: ['', ''],
        },
      ]
    }

    const result = await state.terminal.processPayment(paymentIntent)
      .then((data: any) => data)
      .catch((err: any) => ({ error: err.error }));
    if (result.error) {
      return [
        result.error.payment_intent,
        {
          key: 'terminal_process_payment_error',
          text: `Failed to process payment: ${result.error.message}`,
          title: 'Terminal',
          translationKey: ['', ''],
        },
      ]
    }

    return [result.paymentIntent, null]
  }

  return {
    terminal: computed(() => state.terminal),
    initialized: computed(() => state.terminal !== undefined),

    initialize(
      campaignID: number,
      paymentOption: PaymentConfig,
    ) {
      state.campaign_id = campaignID
      state.payment_option = paymentOption

      stripeTerminalStript?.onLoad(() => {
        state.terminal = StripeTerminal.create({
          onFetchConnectionToken: fetchConnectionToken,
          onUnexpectedReaderDisconnect: unexpectedDisconnect,
        });
      })

      stripeTerminalStript?.load()
    },

    discoverReaders,
    connectReader,
    getPaymentStatus,
    collectPaymentMethod,
    processPayment,
    statusUpdate,
  }
}

export default { useStripeTerminal }
