
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '../../models/IRootState';
import { get } from '../../helpers/getUrlParameters';

export interface Country {
  name: string;
  iso2: string;
  dialCode: string;
}

export interface PhoneObject {
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  country: Country;
  countryCode: string;
  valid: boolean;
  formatted: string;
}

const VueComponent = Vue.extend({
  inject: ['$validator'],

  data() {
    return {
      phoneObject: {} as PhoneObject | null,
    };
  },

  computed: {
    ...customMapState({
      defaultCountryCode: (s: IRootState) => s.donation.donationParams.data.attributes.ip_location_country_code,
    }),

    smsReceipt: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes.sms_receipt;
      },
      set(value: boolean) {
        this.$store.commit('setSmsReceipt', value);
      },
    },

    phone: {
      get(): Number {
        return this.$store.state.donation.donationData.attributes.phone;
      },
      set(value: Number) {
        this.$store.commit('setPhone', value);
      },
    },

    minErrorMsg(): string {
      const exist = this.vErrors.items.find(el => el.field === 'phone' && el.rule === 'min')

      if (exist) {
        return this.$t(
          'template.field_error_min_length',
          { length: this.phoneConfig.min_length },
        ) as string;
      }

      return ''
    },

    twilioLookupErrorMsg(): string {
      const exist = this.vErrors.items.find(el => el.field === 'phone' && el.rule === 'twilio_lookup')

      if (exist) {
        return this.$t('template.field_error_twilio_lookup', 'Twilio lookup error') as string;
      }

      return ''
    },

    phoneAvailable(): boolean {
      const { available, required_for_gateways, gateways } = this.phoneConfig;
      return available
        || required_for_gateways?.includes(this.psName)
        || gateways?.includes(this.psName)
        || this.smsReceipt;
    },

    phoneRequired(): boolean {
      const { required, required_for_gateways } = this.phoneConfig;
      return required
        || required_for_gateways?.includes(this.psName)
        || this.mailReceipt
        || this.smsReceipt;
    },

    twilioLookup(): { valid: boolean } | boolean {
      const { twilio_lookup } = this.phoneConfig;

      const data = {
        valid: this.phoneObject?.valid || false,
      }

      if (!this.phoneRequired) {
        return false
      }

      return twilio_lookup ? data : false;
    },

    suffix(): string {
      const forCountry = ['au'];
      const countrylc = this.country?.toLowerCase()
      let res = '';

      if (countrylc && forCountry.includes(countrylc)) {
        res = `_${countrylc}`;
      }
      if (!this.phoneRequired) {
        res += '_optional'
      }

      return res;
    },

    ...customMapState({
      country: (state: IRootState) => state.donation.donationData.attributes.address_country,

      phoneConfig: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.phone_config,

      countryList: (state: IRootState) => state.donation.donationParams
        .data.attributes.address_config.country_list,

      retrievedFromZip: (state: IRootState) => state.donation.retrievedFromZip,

      gatewayConfig: (state: IRootState) => state.donation.paymentConfig?.attributes?.configuration,

      psName: (state: IRootState) => state.donation.paymentMethod,

      mailReceipt: (state: IRootState) => state.donation.donationData.attributes.mail_receipt,
    }),

    showSmsReceipt(): boolean {
      return this.gatewayConfig?.show_sms_receipt_option === '1';
    },
  },

  created(): void {
    if (this.phoneAvailable && get('phone')) {
      this.$store.commit('setPhone', get('phone'));
    }
  },

  methods: {
    vErrorsHas(field:string, rule: string) {
      return this.vErrors.items.find(el => el.field === field && el.rule === rule);
    },

    validate(phoneObject: PhoneObject) {
      this.phoneObject = phoneObject
    },
  },
});
export default class PhoneField extends VueComponent {}
