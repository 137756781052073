
import Vue from 'vue'
import customMapState from '@/helpers/mapHelper'
import { IRootState } from '@/models/IRootState'

export default Vue.extend({
  computed: {
    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
    }),

    orgLogoShow(): boolean {
      return this.config.org_logo_config?.value
    },

    position(): string {
      return this.config.org_logo_config?.position || 'left center'
    },

    orgLogo(): string {
      return this.config.org_logo_config?.custom_logo || this.config.organization.avatar;
    },
  },
})
