import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { IRootState } from '@/models/IRootState';
import { DLocalState } from './models/dlocal';

const state: DLocalState = {
  dlocalRef: null,
  fieldsRef: null,
  installmentsConfig: null,
  createInstallmentsPlanError: '',
}

const mutations: MutationTree<DLocalState> = {
  setDLocalRef(s, ref) {
    s.dlocalRef = ref;
  },
  setFieldsRef(s, ref) {
    s.fieldsRef = ref;
  },
  setInstallmentsConfig(s, data) {
    s.installmentsConfig = data;
  },
  setCreateInstallmentsPlanError(s, data) {
    s.createInstallmentsPlanError = data;
  },
}

const getters: GetterTree<DLocalState, IRootState> = {

}

const actions: ActionTree<DLocalState, IRootState> = {
  addDLocalScript({ rootState }) {
    const exist = document.getElementById('dlocal-script')

    if (exist) {
      return Promise.resolve()
    }

    const { test } = rootState.donation.donationData.attributes
    return new Promise(resolve => {
      const src = `https://${test ? 'js-sandbox.' : 'js.'}dlocal.com`

      const scriptEl = document.createElement('script');
      scriptEl.id = 'dlocal-script'
      scriptEl.setAttribute('src', src);
      document.head.appendChild(scriptEl);

      scriptEl.addEventListener('load', () => {
        resolve(true)
      });
    })
  },

  useDLocalSmartFields({ commit, rootState }) {
    commit('setDLocalRef', null)

    const ref = dlocal(rootState.donation.paymentConfig.attributes.configuration.api_key);

    commit('setDLocalRef', ref)
  },

  initDLocalFields({ state, rootState, commit }) {
    commit('setFieldsRef', null)

    const currencyCode = rootState.donation.donationData.attributes.currency
    const oleCountry = rootState.donation.paymentConfig.attributes.organization_legal_entity.country

    const country = () => {
      const allowedCountries = ['AR', 'BR', 'CL', 'UY']

      const currencyCodeToCountryCode: Record<string, string> = {
        USD: 'PA',
        ARS: 'AR',
        BOB: 'BO',
        BRL: 'BR',
        XAF: 'CM',
        CLP: 'CL',
        CNY: 'CN',
        COP: 'CO',
        CRC: 'CR',
        DOP: 'DO',
        EGP: 'EG',
        GHS: 'GH',
        INR: 'IN',
        IDR: 'ID',
        KES: 'KE',
        MYR: 'MY',
        MXN: 'MX',
        MAD: 'MA',
        NGN: 'NG',
        PYG: 'PY',
        PEN: 'PE',
        PHP: 'PH',
        XOF: 'SN',
        ZAR: 'ZA',
        TRY: 'TR',
        UYU: 'UY',
        VND: 'VN',
      }

      const countryCode = currencyCodeToCountryCode[currencyCode.toUpperCase()]

      if (countryCode) {
        return countryCode
      }

      if (allowedCountries.includes(oleCountry)) {
        return oleCountry
      }

      return 'PA'
    }

    const ref = state.dlocalRef.fields({
      locale: rootState.i18n.locale,
      country: country(),
    });

    commit('setFieldsRef', ref)
  },

  dLocalCreateInstallmentsPlan({ state, rootState, commit }, { card }) {
    commit('setCreateInstallmentsPlanError', '')
    commit('setInstallmentsConfig', null)

    const { amount, currency } = rootState.donation.donationData.attributes;
    const totalAmount = amount / 100;

    const ref = state.dlocalRef.createInstallmentsPlan(card, totalAmount, currency)
      .then((result: any) => {
        commit('setInstallmentsConfig', result.installments)
      })
      .catch((result: any) => {
        const msg = result?.details || result?.error?.message

        commit('setCreateInstallmentsPlanError', msg)
      });

    commit('setFieldsRef', ref)
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
