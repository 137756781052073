
import customMapState from '@/helpers/mapHelper';
import { RootState } from '@/store/modules/translation';
import { GooglePayResponse } from '@/types/cardknox';
import Vue from 'vue'
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      loaded: false,
    }
  },

  computed: {
    ...customMapState({
      donationData: (s: RootState) => s.donation.donationData.attributes,
      test: (s: RootState) => s.donation.donationData.attributes.test || false,
    }),
    ...mapGetters({
      getFullBillingName: 'getFullBillingName',
    }),

    getEnvironment() {
      if (this.test) {
        return GPEnvironment.test
      }
      return GPEnvironment.production
    },

    merchantInfo(): any {
      return {
        merchantName: this.getFullBillingName,
      }
    },

    buttonOptions(): any {
      return {
        buttonType: GPButtonType.donate,
      }
    },

    billingParams(): any {
      return {
        emailRequired: true,
        billingAddressRequired: true,
        billingAddressFormat: GPBillingAddressFormat.full,
      }
    },
  },

  mounted() {
    window.gpRequest = this

    ckGooglePay.enableGooglePay({ iframeField: 'divGpay' });
  },

  methods: {
    initGP(): any {
      return {
        merchantInfo: this.merchantInfo,
        buttonOptions: this.buttonOptions,
        environment: this.getEnvironment,
        billingParameters: this.billingParams,
        shippingParams: {
          shippingAddressRequired: false,
        },
        onGetTransactionInfo: 'gpRequest.onGetTransactionInfo',
        onProcessPayment: 'gpRequest.onProcessPayment',
        onPaymentCanceled: 'gpRequest.onPaymentCanceled',
        onGPButtonLoaded: 'gpRequest.gpButtonLoaded',
        isDebug: this.test,
      };
    },
    onGetTransactionInfo(): any {
      const amt = this.donationData.amount / 100;
      return {
        displayItems: [
          {
            label: 'Donation amount',
            type: 'SUBTOTAL',
            price: amt.toString(),
          },
        ],
        countryCode: this.donationData.address_country?.toUpperCase(),
        currencyCode: this.donationData.currency.toUpperCase(),
        totalPriceStatus: 'FINAL',
        totalPrice: amt.toString(),
        totalPriceLabel: 'Total',
      }
    },
    onProcessPayment(paymentResponse: GooglePayResponse): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          const paymentToken = paymentResponse.paymentData.paymentMethodData.tokenizationData.token;

          const { billingAddress } = paymentResponse.paymentData.paymentMethodData.info

          if (billingAddress.countryCode) {
            this.$store.commit('setCountry', billingAddress.countryCode);
          }
          if (billingAddress.administrativeArea) {
            this.$store.commit('setState', billingAddress.administrativeArea);
          }
          if (billingAddress.locality) {
            this.$store.commit('setCity', billingAddress.locality);
          }
          if (billingAddress.postalCode) {
            this.$store.commit('setZipcode', billingAddress.postalCode);
          }
          if (billingAddress.address1) {
            this.$store.commit('setAddress', billingAddress.address1);
          }
          if (billingAddress.address2) {
            this.$store.commit('setSuite', billingAddress.address2);
          }

          this.$store.commit(
            'setDonationIncludedGatewayParams',
            {
              card_token: btoa(paymentToken),
              cardknox_digital_wallet_type: 'googlepay',
            },
          );

          this.$store.dispatch('makeDonation')

          try {
            resolve({ status: iStatus.success });
          } catch (err) {
            const emsg = JSON.stringify(err);
            this.$store.dispatch('trackClientError', { error: emsg })
            reject(err);
          }
        } catch (err) {
          reject(err);
        }
      });
    },
    onPaymentCanceled(respCanceled: any):boolean {
      this.$store.dispatch('trackClientError', { error: respCanceled })
      return true
    },
    gpButtonLoaded(resp: any): void {
      if (!resp) return;
      if (resp.status === iStatus.success) {
        this.loaded = true
        this.$emit('loaded')
      } else if (resp.reason) {
        this.$store.commit('setError', {
          title: 'Cardknox Error!',
          translationKey: [null, resp.reason],
        });
        this.$store.dispatch('trackClientError')
      }
    },
  },
})
