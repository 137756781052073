import axios from 'axios';
import { Validator } from 'vee-validate';
import { debounce } from 'vue-debounce'

import urls from '@/constants/urls';
import parseStringToNumber from '@/helpers/parseStringToNumber';

Validator.extend('fullname', {
  validate: value => value.match(/\S+/g).length >= 2,
});

Validator.extend('donationAmount', {
  validate: (value, { min, recurringPeriod }: any = { min: '0' }) => parseStringToNumber(value.replace(/^.*\s/, ''))
  * (Number(recurringPeriod) || 1) >= Number(min),
}, {
  paramNames: ['min', 'recurringPeriod'],
});

Validator.extend('contain16Digits', {
  validate: (value: string) => {
    const [res] = value.match(/(\d{4}[- ]?){4}/g) || [];
    if (res?.length) {
      return res?.length < 16;
    }

    return true;
  },
});

Validator.extend('israeliID', {
  validate: (value: string) => {
    let id = String(value).trim();

    if (id.length > 9 || id.length < 5) return false;

    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? (`00000000${id}`).slice(-9) : id;

    return Array
      .from(id, Number)
      .reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) % 10 === 0;
  },

});

const twilioLookupValidate: (url: string, resolve:(val?: any) => void) => void = debounce(
  (url, resolve) => {
    axios.get(url)
      .then(() => {
        resolve({
          valid: true,
          data: undefined,
        });
      })
      .catch(() => {
        resolve({
          valid: false,
          data: undefined,
        });
      })
  },
  350,
)

Validator.extend('twilio_lookup', {
  validate: (value: string, { valid }: any) => new Promise(resolve => {
    if (!valid) {
      resolve({
        valid,
        data: undefined,
      });
      return;
    }

    const phone = String(value).trim()

    const url = urls.twilioLookup.replace(':phone', phone)

    twilioLookupValidate(url, resolve);
  }),
});

export const ALLOWED_TYPES = {
  'image/png': '.png',
  'image/jpeg': '.jpg',
  'video/mp4': '.mp4',
  'image/gif': '.gif',
  'application/pdf': '.pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  'image/webp': '.webp',
  'image/avif': '.avif',
  'image/svg+xml': '.svg',
}
// eslint-disable-next-line no-bitwise
const ALLOWED_FILE_SIZE = 8 << 20 // 8MB
Validator.extend('public_file_upload', {
  validate: (value: Array<File> | File) => {
    let file;

    if (Array.isArray(value)) {
      [file] = value;
    } else {
      file = value;
    }

    if (file.size > ALLOWED_FILE_SIZE) {
      return false;
    }

    if (!Object.keys(ALLOWED_TYPES).includes(file.type)) {
      return false;
    }

    return true;
  },
  getMessage() {
    return 'File is bigger than 8MB or has wrong file type. Allowed file types: {ALLOWED_TYPES_SEPCOMMA}'
      .replace('{ALLOWED_TYPES_SEPCOMMA}', Object.values(ALLOWED_TYPES).join(', '))
  },
})

export default {}
