
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import VueRecaptcha from 'vue-recaptcha';

import { ConfirmationInterface } from '@/components/subcomponents/subcomponents-models/Cofirmation-models';
import { AvailableDisplayCurrencyList } from '@/store/modules/models/donation';
import { pmListIFrame } from '@/constants/paymentMethodsInIFrame';
import { useExternalScript } from '@/helpers/useExternalScript';
import { useReCaptchaV3 } from '@/compositions/captcha';
import { feeInstallmentFor } from '@/constants';
import { useChariot } from '@/compositions/chariot';
import customMapState from '../../helpers/mapHelper';

import { IRootState } from '../../models/IRootState';

import ConfirmationDonationInfo from './ConfirmationDonationInfo.vue';
import CardknoxGoogleApplePayButton from './cardknox/CardknoxGoogleApplePayButton.vue';
import PayPalV2 from './paymentSystems/PayPalV2.vue';

const recaptchaV2Script = useExternalScript('https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')

const { setInstance, setKey } = useReCaptchaV3()

const VueComponent = Vue.extend({
  components: {
    VueRecaptcha,
    ConfirmationDonationInfo,
    CardknoxGoogleApplePayButton,
    PayPalV2,
  },

  data(): ConfirmationInterface {
    return {
      termsFlag: false,
      termsError: false,
      sendFlag: false,
      termsPrivacyFlag: false,
      reCaptchav3: null,
      showChariotButton: false,
    };
  },

  computed: {
    ...customMapState({
      donationAmount: (state: IRootState) => state.donation.donationData.attributes.amount,

      donationData: (state: IRootState) => state.donation.donationData.attributes,

      totalAmount: (state: IRootState) => state.donation.totalAmount,

      multiplier: (state: IRootState) => state.donation.donationParams.data
        .attributes.current_multiplier,

      recurringPeriod: (state: IRootState) => state.donation.donationData.attributes
        .recurring_period,

      installment: (state: IRootState) => state.donation.donationData.attributes.installment,

      recurring: (state: IRootState) => state.donation.donationData.attributes.recurring,
      recurringInterval: (state: IRootState) => state.donation.donationData.attributes.recurring_interval,

      installmentPeriod: (state: IRootState) => state.donation.donationData
        .attributes.installment_period,

      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),

      campaign: (state: IRootState) => state.donation.campaign.data.attributes,

      isSubmitted: (state: IRootState) => state.donation.isSubmitted,

      captcha: (state: IRootState) => state.donation.donationParams.data.attributes.captcha,

      captchaSiteKey: (state: IRootState) => state.donation.donationParams.data
        .attributes.captcha_sitekey,

      captchaSiteV3: (state: IRootState) => state.donation.donationParams.data
        .attributes.captcha_site_v3,

      captchaToken: (state: IRootState) => state.donation.donationData.attributes.captcha_token,

      paymentMethod: (state: IRootState) => state.donation.paymentMethod,

      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,

      feeConfig: (state: IRootState) => state.donation.donationParams.data.attributes.config
        .include_payment_system_fee_config,

      recurringConfig: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.recurring_config,
      confirmationWithoutCheckbox: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.confirmation_without_checkbox,
      paymentMethodVersion: (s: IRootState) => s.donation.paymentConfig.attributes.configuration.version,
      tipAmount: (state: IRootState) => state.donation.donationData.attributes.tip_amount / 100,
      pConf: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
    }),

    ...mapGetters({
      showAmountInLocCurrency: 'showAmountInLocCurrency',
      multiplierOnDay: 'multiplierOnDay',
      isPaymentRequestBtn: 'isPaymentRequestBtn',
      orgName: 'orgNameByLang',
    }),

    paymentMethodV2(): boolean {
      return this.paymentMethodVersion === '2';
    },

    donateBtnDisabled(): boolean {
      const {
        termsFlag,
        isSubmitted,
        captchaSiteV3,
        captchaToken,
        captcha,
      } = this;

      return !termsFlag
        || isSubmitted
        || (!captchaSiteV3 && !captchaToken && captcha)
    },

    checkedForGateways(): string[] {
      return this.config.term_checkbox_config?.checked_for_gateways || [];
    },

    btnText(): string {
      const pmList = pmListIFrame;

      return (
        pmList.includes(this.paymentMethod)
          ? this.$t('template.continue_btn')
          : this.$t('template.pre_donations_button')
      ) as string;
    },

    dontionAmountString(): string {
      const { currency_sign, currency, amount } = this.donationData
      return `${currency_sign}${(amount / 100).toLocaleString()} ${currency.toUpperCase()}`;
    },

    campaignCurrency(): AvailableDisplayCurrencyList {
      const state = this.$store.state as IRootState;
      if (this.showAmountInLocCurrency) {
        return this.showAmountInLocCurrency;
      }
      return {
        sign: state.donation.campaign.data.attributes.currency_sign,
        code: state.donation.campaign.data.attributes.currency,
      }
    },

    monthlyAmountNum(): number {
      let amt = this.donationAmount / 100;
      if (this.recurring) {
        amt *= this.multiplierOnDay
      }
      if (this.installment) {
        amt /= this.installmentPeriod
      }
      return amt;
    },

    monthlyAmount(): string {
      return `${this.donationCurrency.sign}${this.monthlyAmountNum.toFixed(2)}`;
    },

    feeAmountFmt(): string {
      return `${this.donationCurrency.sign}${this.feeAmount}`;
    },

    extra(): number {
      let amt = 0
      if (this.paymentFeeStatus) {
        amt += this.feeAmount;
      }
      if (this.tipAmount > 0) {
        amt += this.tipAmount;
      }

      if (this.installment) {
        if (feeInstallmentFor.includes(this.paymentMethod)) {
          amt /= this.installmentPeriod
        }
      }
      return amt
    },

    monthlyAmountWithExtra(): number {
      return this.monthlyAmountNum + this.extra;
    },

    monthlyAmountWithExtraFmt(): string {
      return `${this.donationCurrency.sign}${this.monthlyAmountWithExtra.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },

    recurringTotal(): string {
      let donationAmt = this.donationAmount / 100

      if (this.paymentFeeStatus) {
        donationAmt += this.feeAmount;
      }

      return `${this.donationCurrency.sign}${this.recurringPeriod * donationAmt * this.multiplierOnDay}`
    },

    receivesAmount(): string {
      const amt = Math.floor(
        (this.totalAmount
          * ((this.recurringPeriod * this.multiplierOnDay) || 1) * (this.multiplier || 1)
        ),
      );
      // return `${this.donationCurrency.sign}${amt}`
      // const amt = Math.floor((this.totalAmount * (this.recurringPeriod || 1)));
      return `${this.campaignCurrency.sign}${amt.toLocaleString()}`
    },

    paymentFeeStatus: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes
          .include_payment_system_fee;
      },
      set(status: boolean) {
        this.$store.commit('setIncludePaymentFeeStatus', status);
        if (status) {
          this.$store.commit('setIncludePaymentFeeAmount', this.feeAmount * 100);
        } else {
          this.$store.commit('setIncludePaymentFeeAmount', 0);
        }
      },
    },

    showFeeCheckbox(): boolean {
      const { feeConfig, paymentMethod, donationAmount } = this;

      return feeConfig
        && feeConfig.available
        && feeConfig.fee_rate
        && feeConfig.fee_rate[paymentMethod]
        && feeConfig.show_on_confirmation_step
        && donationAmount > 100
    },

    feeAmount(): number {
      const { feeConfig, paymentMethod, donationAmount } = this;

      if (!feeConfig.fee_rate) {
        return 0;
      }

      const config = feeConfig.fee_rate[paymentMethod];

      if (!config) {
        return 0
      }

      const amount = Math.ceil(
        config.fixed + ((config.percentage * (donationAmount / 100)) / 100),
      )

      if (config.limit && config.limit < amount) {
        return config.limit
      }

      if (feeConfig.limit && feeConfig.limit < amount) {
        return feeConfig.limit
      }

      return amount;
    },
  },

  watch: {
    termsFlag: 'termsCheck',

    captchaToken(value) {
      if (value === '' && !this.captchaSiteV3) {
        (this.$refs.captcha as any).reset();
      }
    },

    showFeeCheckbox(newVal: boolean, oldVal: boolean): void {
      if (!newVal === oldVal && this.showFeeCheckbox) {
        this.paymentFeeStatus = this.feeConfig?.checked || false;
      }
    },

    feeAmount(val) {
      if (this.showFeeCheckbox && this.paymentFeeStatus) {
        this.$store.commit('setIncludePaymentFeeAmount', val * 100)
      }
    },
  },

  created() {
    if (this.checkedForGateways.includes(this.paymentMethod)) {
      this.termsFlag = true;
    }
    if (this.confirmationWithoutCheckbox) {
      this.termsFlag = true;
    }
    if (this.showFeeCheckbox && this.feeConfig?.checked) {
      this.paymentFeeStatus = true;
    }
  },

  mounted() {
    if (this.captchaSiteV3) {
      this.reCaptchav3Load()
      setKey(this.captchaSiteKey)
    } else {
      recaptchaV2Script?.load()
    }
  },

  methods: {
    fallback(key: string) {
      const tcLink = '<a href="https://www.charidy.com/terms" target="_blank">Terms &amp; Conditions</a>'
      const ppLink = '<a href="https://www.charidy.com/terms#privacy" target="_blank">Privacy Policy</a>'

      switch (key) {
        case 'confirmation_notification_not_checkbox':
          return `By clicking “DONATE”, you confirm that you accept our ${tcLink} and acknowledge our ${ppLink}.`

        default:
          return ''
      }
    },

    backToForm(): void {
      this.$store.commit('setCaptchaToken', '');
      this.termsFlag = false;
      this.$emit('go-back');
    },

    async reCaptchav3Load(): Promise<[ReCaptchaInstance | null, unknown | null]> {
      if (this.reCaptchav3 == null) {
        try {
          this.reCaptchav3 = await load(this.captchaSiteKey);
        } catch (error) {
          return [null, error]
        }
      }
      setInstance(this.reCaptchav3)
      return [this.reCaptchav3, null]
    },

    async confirmDonation() {
      if (this.isSubmitted) {
        return null;
      }

      const makeDonationWithTokenGeneration = async (tokenGenerationFuncName: string) => {
        const promis = new Promise(resolve => this.$root.$emit(tokenGenerationFuncName, resolve))
        try {
          const res = await promis;
          if (res) {
            this.$store.dispatch('makeDonation');
          } else {
            this.$store.commit('setIsSubmitted', false);
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (this.termsFlag) {
        await this.$store.dispatch('setIsSubmitted', true)
        await this.$nextTick()

        if (this.captchaSiteV3) {
          const [, err] = await this.reCaptchav3Load()
          if (err && !this.reCaptchav3) {
            this.$store.commit('setIsSubmitted', false);
            this.$store.commit('setError', {
              title: 'Captcha',
              translationKey: [
                'donation.captcha_load_faild',
                'Failed to load captcha script. Try again',
              ],
            });
            return null;
          }

          if (this.reCaptchav3) {
            let token = await this.reCaptchav3.execute('e_commerce');
            if (token === '') {
              token = await this.reCaptchav3.execute('e_commerce');
            }
            if (token === '') {
              token = await this.reCaptchav3.execute('e_commerce');
            }
            if (token === '') {
              this.$store.commit('setIsSubmitted', false);
              this.$store.commit('setError', {
                title: 'Captcha',
                translationKey: [
                  'donation.captcha_token_empty',
                  'Failed to generate captcha token. Try again',
                ],
              });
              return null;
            }
            this.verify(token);
          }
        }

        if (this.paymentMethod === 'stripe-ach') {
          makeDonationWithTokenGeneration('openPlaidLink')
          return null;
        }

        if (this.paymentMethod === 'stripe') {
          makeDonationWithTokenGeneration('stripeCreateToken')
          return null;
        }

        if (this.paymentMethod === 'cardknox') {
          makeDonationWithTokenGeneration('cardknoxCreateAndSetToken')
          return null;
        }

        if (this.paymentMethod === 'banquest') {
          makeDonationWithTokenGeneration('banquestCreateAndSetToken')
          return null;
        }

        if (this.paymentMethod === 'dlocal' && this.paymentMethodV2) {
          makeDonationWithTokenGeneration('dlocalCreateAndSetToken')
          return null;
        }

        if (this.paymentMethod === 'walletdoc-direct') {
          makeDonationWithTokenGeneration('walletDocCreateAndSetToken')
          return null;
        }

        if (this.paymentMethod === 'authorize') {
          makeDonationWithTokenGeneration('authorizeCreateAndSetToken')
          return null;
        }

        if (this.paymentMethod === 'payarc') {
          makeDonationWithTokenGeneration('payarcCreateAndSetToken')
          return null;
        }

        if (this.paymentMethod === 'mercadopago-direct') {
          this.$store.commit('setDialogTitle', 'Mercadopago');
          this.$store.commit('setDialogVisibleStatus', true);
          this.$store.commit('setIsSubmitted', false);
          return null;
        }

        const data = await this.$store.dispatch('makeDonation');
        return data;
      }

      return null;
    },

    termsCheck(): void {
      if (this.termsFlag) {
        this.termsError = false;

        if (this.isPaymentRequestBtn) {
          this.confirmDonation();
        }

        if (this.paymentMethod === 'chariot') {
          const chariot = useChariot()
          this.confirmDonation().then(data => {
            chariot.onDonationRequest(() => data);
            this.showChariotButton = true;
            this.$nextTick(() => {
              chariot.donate()
            })
          })
        }
      } else {
        this.termsError = true;
      }
    },

    verify(captchaToken: string): void {
      this.$store.commit('setCaptchaToken', captchaToken);
    },

    expired() {
      this.$store.commit('setCaptchaToken', '');
    },
  },
});
export default class Confirmation extends VueComponent {}
