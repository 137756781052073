export const currencies = [
  {
    code: 'usd',
    sign: '$',
  },
  {
    code: 'ils',
    sign: '₪',
  },
  {
    code: 'gbp',
    sign: '£',
  },
  {
    code: 'eur',
    sign: '€',
  },
  {
    code: 'aud',
    sign: '$',
  },
  {
    code: 'brl',
    sign: 'R$',
  },
  {
    code: 'cad',
    sign: '$',
  },
  {
    code: 'nzd',
    sign: '$',
  },
  {
    code: 'sek',
    sign: 'kr',
  },
  {
    code: 'zar',
    sign: 'R',
  },
  {
    code: 'mxn',
    sign: '$',
  },
  {
    code: 'ars',
    sign: '$',
  },
  {
    code: 'chf',
    sign: 'CHF',
  },
  {
    code: 'rub',
    sign: '₽',
  },
  {
    code: 'sgd',
    sign: 'S$',
  },
  {
    code: 'hkd',
    sign: 'HK$',
  },
  {
    code: 'uah',
    sign: '₴',
  },
  {
    code: 'clp',
    sign: '$',
  },
];

export const currenciesMap = currencies
  .reduce(
    (res, el) => {
      res[el.code] = el;
      return res;
    },
    {} as Record<string, typeof currencies[0]>,
  );

export default { currencies, currenciesMap };
