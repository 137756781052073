export const merge = (e: any, t: any): any => {
  // eslint-disable-next-line no-restricted-syntax
  for (const s of Object.keys(t)) {
    if (t[s] instanceof Object && e[s]) {
      Object.assign(t[s], merge(e[s], t[s]));
    }
  }
  return { ...e, ...t };
};

export const deepCopy = <T>(o: T): T => JSON.parse(JSON.stringify(o));

export default { merge, deepCopy };
