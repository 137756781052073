<template>
  <div style="display: contents">
    <svg
      v-if="icon === 'facebook'"
      viewBox="0 0 264 512"
    >
      <path
        fill="#0085ad"
        d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0
              61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
      />
    </svg>

    <svg
      v-if="icon === 'twitter'"
      viewBox="0 0 512 512"
    >
      <path
        fill="#0085ad"
        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558
              298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299
              49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772
              6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3
              27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214
              12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391
              0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3
              105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04
              0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67
              76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366
              44.833-46.132 57.827 21.117-2.273 41.584-8.122
              60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
      />
    </svg>

    <svg
      v-if="icon === 'email'"
      viewBox="0 0 512 512"
    >
      <path
        fill="#0085ad"
        d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48
              48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7
              22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8
              92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3
              154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3
              142.8-104.7 173.4-128.7 5.8-4.5
              9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5
              64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6
              23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
      />
    </svg>

    <svg
      v-if="icon === 'copy_link'"
      viewBox="0 0 512 512"
    >
      <path
        fill="#0085ad"
        d="M326.612 185.391c59.747 59.809 58.927
              155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699
              59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84
              26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567
              12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155
              101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191
              28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037
              16.037 0 0 1-6.947-12.606c-.396-10.567
              3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521
              14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547
              44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566
              58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468
              15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239
              11.698-29.806a16.037 16.037
              0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639
              0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3
              26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783
              16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906
              17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
      />
    </svg>

    <svg
      v-if="icon === 'linkedin'"
      viewBox="0 0 448 512"
    >
      <path
        fill="#0085ad"
        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5
        0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1
        54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29
        0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3
        87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
      />
    </svg>

    <svg
      v-if="icon === 'viber'"
      viewBox="0 0 512 512"
    >
      <!-- eslint-disable max-len -->
      <path
        fill="#0085ad"
        d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"
      />
    </svg>

    <svg
      v-if="icon === 'whatsapp'"
      viewBox="0 0 1536 1600"
    ><path
      d="M985 878q13 0 97.5 44t89.5 53q2 5 2 15q0 33-17 76q-16 39-71 65.5T984 1158q-57 0-190-62q-98-45-170-118T476 793q-72-107-71-194v-8q3-91 74-158q24-22 52-22q6 0 18 1.5t19 1.5q19 0 26.5 6.5T610 448q8 20 33 88t25 75q0 21-34.5 57.5T599 715q0 7 5 15q34 73 102 137q56 53 151 101q12 7 22 7q15 0 54-48.5t52-48.5zm-203 530q127 0 243.5-50t200.5-134t134-200.5t50-243.5t-50-243.5T1226 336t-200.5-134T782 152t-243.5 50T338 336T204 536.5T154 780q0 203 120 368l-79 233l242-77q158 104 345 104zm0-1382q153 0 292.5 60T1315 247t161 240.5t60 292.5t-60 292.5t-161 240.5t-240.5 161t-292.5 60q-195 0-365-94L0 1574l136-405Q28 991 28 780q0-153 60-292.5T249 247T489.5 86T782 26z"
      fill="#0085ad"
    /></svg>

    <svg
      v-if="icon === 'plus-circle'"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      focusable="false"
      role="img"
      aria-label="plus circle"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      class="bi-plus-circle bi"
      :style="`font-size: ${100*Number(fontScale)}%;`"
    >
      <g>
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
      </g>
    </svg>

    <svg
      v-if="icon === 'dash-circle'"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      class="bi bi-dash-circle"
      :style="`font-size: ${100*Number(fontScale)}%;`"
      width="1em"
      height="1em"
    >
      <g>
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    fontScale: {
      type: [Number, String],
      default: 1,
    },
  },
};
</script>
