
import Vue from 'vue';
import { mapActions } from 'vuex';
import customMapState from '../../../helpers/mapHelper';
import { IRootState } from '../../../models/IRootState';

const options = {
  style: {
    base: {
      padding: '13px 12px',
      '::placeholder': {
        color: '#CFD7DF',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },
    },
    invalid: {
      color: '#E25950',
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
  classes: {
    focus: 'focused',
    empty: 'empty',
    invalid: 'invalid',
  },
};

const VueComponent = Vue.extend({
  data() {
    return {
      showError: false,
      isEmpty: true,
    }
  },

  computed: {
    ...customMapState({
      stripe: (state: IRootState) => state.stripe.stripe,
    }),
  },

  mounted() {
    this.useStripe();

    const elements = this.stripe.elements();
    const idealBank = elements.create('idealBank', options);

    idealBank.mount('#ideal-bank-element');

    idealBank.on('change', (event: any) => {
      this.isEmpty = false;
    });

    this.setElements({
      idealBank,
    });

    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      if (this.isEmpty) {
        resolve({
          title: 'Form Error!',
          text: this.$t('donation.stripe_ideal_bank_is_empty'),
        });
        this.showError = true;
        return;
      }

      resolve(false)
    });
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    ...mapActions({
      useStripe: 'useStripe',
      setElements: 'setElements',
    }),
  },
});
export default class StripeiDEAL extends VueComponent {}
