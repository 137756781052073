
import Vue from 'vue';
import { mapGetters } from 'vuex';

const VueComponent = Vue.extend({
  computed: {
    ...mapGetters([
      'stepFlag',
    ]),

    steps(): number[] {
      return [1, 2]
    },

    show(): boolean {
      return this.steps.includes(this.stepFlag);
    },
  },
})

export default class StepsBullets extends VueComponent {}
