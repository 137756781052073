
import Vue from 'vue'
import { RootState } from '@/store/modules/translation'
import customMapState from '@/helpers/mapHelper'
import { useExternalScript } from '@/helpers/useExternalScript'

export default Vue.extend({
  data() {
    return {
      tokenResolve: (value: unknown) => value,
      tokenReject: (reason?: any) => reason,
    }
  },

  computed: {
    ...customMapState({
      test: (s: RootState) => s.donation.donationData.attributes.test || false,
      paymentConfig: (state: RootState) => state.donation.paymentConfig.attributes.configuration,
    }),

    apiKey(): string {
      return this.paymentConfig.payarc_api_key
    },
  },

  mounted() {
    const payarcScript = useExternalScript(`https://${this.test ? 'test' : ''}portal.payarc.net/js/iframeprocess.js`)

    fetch('./payarc-styles.css').then(r => r.text()).then(s => {
      if (document.getElementById('payarc-styles') === null) {
        const styleTag = document.createElement('style');
        styleTag.id = 'payarc-styles'
        styleTag.type = 'text/css'
        styleTag.appendChild(document.createTextNode(s));
        document.head.appendChild(styleTag);
      }

      payarcScript?.onLoad(this.initPayarcTokenizer)
      payarcScript?.load()
    })

    this.$root.$on(
      'setDonationGatewayParams',
      (resolve: (value?: unknown) => void) => {
        this.getPayarcToken().then(data => {
          this.setToken(data)
          resolve(false)
        }).catch(msg => {
          resolve(msg)
        })
      },
    );

    this.$root.$on(
      'payarcCreateAndSetToken',
      (next: (value?: unknown) => void) => {
        this.getPayarcToken().then(data => {
          this.setToken(data)
          next(true)
        }).catch(msg => {
          this.$store.commit('setError', {
            title: 'Payarc Create Token Error!',
            text: msg,
          });
          next(false)
        })
      },
    );
  },

  beforeDestroy() {
    this.$root.$off('payarcCreateAndSetToken');
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    getPayarcInitialValues() {
      return {
        FORM_STATUS: 'form-status',
        INITIATE_PAYMENT: 'initiate-payment',
        FIELDS_CONTAINER: 'card-token-container',
        TOKEN_CALLBACK: {
          success: this.onSuccessToken,
          error: this.onErrorToken,
        },
      }
    },

    initPayarcTokenizer(): void {
      window.initPayarcTokenizer(this.apiKey, this.getPayarcInitialValues())
    },

    getPayarcToken() {
      return new Promise((resolve, reject) => {
        this.tokenResolve = resolve
        this.tokenReject = reject
        window.getPayarcToken(this.$refs.initiatePayment)
      })
    },

    onSuccessToken(obj: XMLHttpRequest) {
      const response = JSON.parse(obj.response);
      this.tokenResolve(response)
    },

    onErrorToken(obj: XMLHttpRequest) {
      const statusResponse = obj.responseText;
      this.tokenReject(statusResponse)
      if (obj.status !== 422) {
        this.initPayarcTokenizer()
      }
    },

    setToken(data: any): void {
      this.$store.commit(
        'setDonationIncludedGatewayParams',
        {
          payarc_token: data.token,
        },
      );
    },
  },
})
