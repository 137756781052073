
import Vue from 'vue';

import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import cardNumberInput from '../../shared/donors-fund/donors-fund-number.vue';

const VueComponent = Vue.extend({
  components: {
    cardNumberInput,
  },

  inject: ['$validator'],

  computed: {
    ...customMapState({
      num: (s: IRootState) => s.donorsfund.number,
      cvv: (s: IRootState) => s.donorsfund.cvv,
    }),

    number: {
      get(): string {
        return this.num;
      },
      set(val: string) {
        this.$store.commit('setDonorsFundData', { key: 'number', value: val.replace(/ /g, '') });
        this.$store.commit('setDonationIncludedGatewayParams', { donorsfund_donor: val, donorsfund_donor_authorization: this.cvv });
      },
    },

    cccvv: {
      get(): string {
        return this.cvv;
      },
      set(val: string) {
        this.$store.commit('setDonorsFundData', { key: 'cvv', value: val });
        this.$store.commit('setDonationIncludedGatewayParams', { donorsfund_donor_authorization: val, donorsfund_donor: this.num });
      },
    },
  },
});
export default class Blackbaud extends VueComponent {}
