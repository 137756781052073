
import Vue from 'vue'
import customMapState from '@/helpers/mapHelper'
import { IRootState } from '@/models/IRootState'

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...customMapState({
      dedicationDropdownConfig: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.dedication_dropdown_config,
      locale: (state: IRootState) => state.i18n.locale,
    }),

    active(): boolean {
      return this.dedicationDropdownConfig.value
    },

    title(): string {
      const { title, title_t } = this.dedicationDropdownConfig;
      return this.$t(title_t, title) as string
    },

    options(): string[] {
      const { options } = this.dedicationDropdownConfig;

      return options?.[this.locale] || options?.en || []
    },

    dedication: {
      get(): string {
        const msg = this.value.split('|')
        return msg[0]?.trim()
      },
      set(val: string) {
        const msg = this.value.split('|')
        if (msg.length && val) {
          msg[0] = `${val} | `
          msg[1] = msg[1]?.trim()
        }
        if (msg[0]?.trim() === '|') {
          msg[0] = ''
        }
        this.$emit('input', msg.join(''))
      },
    },
  },

  mounted() {
    if (this.active) {
      this.dedication = this.options[0] || ''
    }
  },
})
