import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { IRootState } from '@/models/IRootState';
import { TeamsState } from './models/teams';

const state: TeamsState = {
  preSelectTeams: [],
}

const mutations: MutationTree<TeamsState> = {
  setPreSelectTeams(s, data) {
    s.preSelectTeams = data
  },
}

const getters: GetterTree<TeamsState, IRootState> = {

}

const actions: ActionTree<TeamsState, IRootState> = {
  setPreSelectTeams({ commit }, { ids }) {
    commit('setPreSelectTeams', ids)
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
