
import Vue from 'vue';
import axios from 'axios';
import { Store } from 'vuex';
import urls from '@/constants/urls';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      success: false,
      loading: false,
      error: null,
    }
  },

  computed: {
    ...customMapState({
      show: (state: IRootState) => state.donation.donationParams.data.attributes.config
        .create_account_config.available,
      donationDataAttr: (state: IRootState) => state.donation.donationData.attributes,
      paymentConfigAttr: (state: IRootState) => state.donation.paymentConfig?.attributes,
    }),

    paymentName(): string {
      return this.paymentConfigAttr && this.paymentConfigAttr.name
    },

    saveCard: {
      get() {
        const { state } = this.$store as Store<IRootState>;
        return state.donation.donationData.attributes.save_stripe_card;
      },
      set(val) {
        this.$store.commit('setSaveStripeCard', val);
      },
    },
  },

  methods: {
    createAccount(): void {
      if (
        this.password.length === 0
          || this.passwordConfirmation.length === 0
          || this.password !== this.passwordConfirmation
      ) {
        return
      }

      const {
        billing_first_name,
        billing_last_name,
        billing_name,
        email,
      } = this.donationDataAttr;
      const [first, last] = billing_name.split(' ');

      const data = {
        email,
        password: this.password,
        first_name: billing_first_name || first,
        last_name: billing_last_name || last,
      }

      this.loading = true;

      axios
        .post(urls.registerUser, data)
        .then(res => {
          this.password = '';
          this.passwordConfirmation = '';
          this.success = true;
          this.loading = false;
          this.error = null;
          this.$store.commit('setAuthorizationToken', '');
        }).catch(err => {
          this.error = err.response.data.error;
          this.loading = false;
        })
    },
  },
});
export default class CreateAccount extends VueComponent {}
