
import { defineComponent } from 'vue';
import axios from 'axios';
import urls from '@/constants/urls';
import customMapState from '@/helpers/mapHelper';
import { getMatchedDonationId } from '@/helpers/getUrlParameters';
import { IRootState } from '../../models/IRootState';
import AppVTagsInput from '../shared/app-v-tags-input.vue';

export default defineComponent({
  components: { AppVTagsInput },

  data() {
    return {
      isSuccess: false,
      error: '',
      sending: false,
      form: {
        email: '',
      },
      emailTags: [] as string[],
    };
  },

  computed: {
    ...customMapState({
      dId: (state: IRootState) => state.donation.donationId,
      allowConditionalDonations: (state: IRootState) => state.donation.donationParams.data?.attributes.config.allow_conditional_donations,
      locked: (state: IRootState) => state.donation.donationData.attributes.locked,
    }),
    show(): boolean {
      // hide when user tries to match donation
      const matchedDonationId = getMatchedDonationId();
      if (matchedDonationId) {
        return false;
      }

      // hide when match checkbox is not selected
      if (!this.locked) {
        return false;
      }

      return this.allowConditionalDonations?.value;
    },
    tagsEmpty(): boolean {
      return !this.emailTags.length;
    },
  },

  methods: {
    postDonationSuccessInviteFriends(emails: string[]) {
      return axios.post(urls.postDonationSuccessInviteFriends(this.dId), { emails });
    },
    async onSubmit() {
      this.sending = true;

      try {
        await this.postDonationSuccessInviteFriends(this.emailTags);
      } catch (e: any) {
        this.error = `${e.response.data.errors?.[0].title}: ${e.response.data.errors?.[0].detail}`;
        return;
      } finally {
        this.sending = false;
      }

      this.isSuccess = true;
    },
  },
})
