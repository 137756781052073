function distinct<T>(list: T[]) {
  return [...new Set(list.map(o => JSON.stringify(o)))]
    .map(s => JSON.parse(s) as T);
}

export function range(start: number, stop: number, step = 1): number[] {
  return Array(Math.ceil((stop + 1 - start) / step)).fill(start).map((x, y) => x + y * step);
}

export default distinct;
