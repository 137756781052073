<template>
  <div class="extra-panel igul-letova-panel">
    <div class="icon-div">
      <img
        src="https://www.igul.org.il/wp-content/uploads/2020/06/%D7%9C%D7%95%D7%92%D7%95.png"
        alt="igul letova logo"
      >
    </div>
    <h3 class="color-green extra-panel__title">
      {{ $t('success.igul_letova_title') }}
    </h3>
    <p class="desc">
      {{ $t('success.igul_letova_text') }}
    </p>
    <form
      v-if="step1"
      @submit.prevent="submitForm"
    >
      <div
        v-if="showZehut"
        class="id-input"
      >
        <label class="typo__label">{{ $t('success.igul_letova_id_label') }}</label>
        <input
          v-model="zehut"
          type="text"
          name="zehut"
          :placeholder="$t('success.igul_letova_id_placeholder')"
          :class="{ invalid: zehutError !== '' }"
          @input="clearZehutError()"
        >
        <span class="color-red">{{ zehutError }}</span>
      </div>
      <div
        v-if="showIssuer"
        class="issuer-select"
      >
        <label class="typo__label">{{ $t('success.igul_letova_issuer_label') }}</label>
        <multiselect
          v-model="cardIssuer"
          :placeholder="$t('success.igul_letova_issuer_placeholder')"
          label="name"
          track-by="name"
          :options="cardIssuers"
          :option-height="104"
          :show-labels="false"
          :class="{ invalid: issuerError !== '' }"
          @input="clearIssuerError()"
        >
          <template
            slot="singleLabel"
            slot-scope="props"
          >
            <div class="flex">
              <img
                class="option__image"
                :src="props.option.image"
                :alt="$t('success.igul_letova_issuer_image_alt')"
              ><span class="option__desc"><span class="option__title">{{ props.option.label }}</span></span>
            </div>
          </template>
          <template
            slot="option"
            slot-scope="props"
          >
            <div class="flex">
              <img
                class="option__image"
                :src="props.option.image"
                :alt="$t('success.igul_letova_issuer_image_alt')"
              >
              <div class="option__desc">
                <span class="option__desc"><span class="option__title">{{ props.option.label }}</span></span>
              </div>
            </div>
          </template>
        </multiselect>
        <span class="color-red">{{ issuerError }}</span>
      </div>
      <div class="privacy-policy">
        <div class="checkbox-container">
          <input
            v-model="privacyPolicy"
            name="privacy-policy"
            type="checkbox"
            :class="{ invalid: privacyPolicyError !== '' }"
            @change="clearPrivecPolicyError()"
          >
          <span class="checkbox-label">{{ $t('success.igul_letova_terms') }}</span>
        </div>
        <span class="color-red">{{ privacyPolicyError }}</span>
      </div>
      <div class="submit-button">
        <button type="submit">
          {{ $t('success.igul_letova_submit_button') }}
        </button>
      </div>
    </form>
    <div v-if="step2">
      <h3 class="color-green extra-panel__title">
        {{ $t('success.igul_letova_success_title') }}
      </h3>
    </div>
    <div
      v-if="mainError"
      class="main-error"
    >
      <span class="color-red">{{ mainError }}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import customMapState from '@/helpers/mapHelper';
import urls from '@/constants/urls';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    showZehut: {
      type: Boolean,
      default: false,
    },
    showIssuer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const storage_url = 'https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com';
    return {
      step1: true,
      step2: false,
      zehut: '',
      cardIssuer: null,
      privacyPolicy: false,
      zehutError: '',
      issuerError: '',
      privacyPolicyError: '',
      mainError: '',
      cardIssuers: [
        {
          id: 1,
          name: 'Isracard',
          label: 'ישראכרט',
          image: `${storage_url}/images/281/WhatsApp%20Image%202023-05-23%20at%2012.59.58_1685361284.jpeg`,
        },
        {
          id: 2,
          name: 'Max',
          label: 'מקס',
          image: `${storage_url}/images/281/WhatsApp%20Image%202023-05-23%20at%2012.59.58%20%281%29_1685361274.jpeg`,
        },
        {
          id: 3,
          name: 'Cal',
          label: 'כאל',
          image: `${storage_url}/images/281/WhatsApp%20Image%202023-05-23%20at%2012.59.57_1685361298.jpeg`,
        },
        {
          id: 4,
          name: 'American Express',
          label: 'אמריקן אקספרס',
          image: `${storage_url}/images/281/WhatsApp%20Image%202023-05-23%20at%2012.59.59_1685361252.jpeg`,
        },
        {
          id: 5,
          name: 'Diners',
          label: 'דיינרס',
          image: `${storage_url}/images/281/WhatsApp%20Image%202023-05-23%20at%2012.59.58%20%282%29_1685361261.jpeg`,
        },
        {
          id: 6,
          name: 'LeumiCard',
          label: 'לאומי קארד',
          image: `${storage_url}/images/281/811771201000100640360no_1685362055.jpeg`,
        },
      ],
    };
  },
  computed: {
    ...customMapState({
      donationId: state => state.donation.donationId,
    }),
  },
  methods: {
    submitForm() {
      this.mainError = '';
      const valid = this.validate();
      if (!valid) return;

      axios.post(urls.igulLetova, {
        zehut: this.zehut,
        donation_id: Number(this.donationId),
        card_issuer: this.cardIssuer.name,
      }).then(() => {
        this.step1 = false;
        this.step2 = true;
      }).catch(error => {
        this.mainError = error;
      });
    },
    validate() {
      this.zehutError = '';
      this.privacyPolicyError = '';

      if (this.showZehut) {
        if (this.zehut === '') {
          this.zehutError = this.$t('success.igul_letova_required');
        }

        if (!this.validateZehut() && this.zehut !== '') {
          this.zehutError = this.$t('success.igul_letova_invalid_id');
        }
      }

      if (!this.cardIssuer) {
        this.issuerError = this.$t('success.igul_letova_required');
      }

      if (!this.privacyPolicy) {
        this.privacyPolicyError = this.$t('success.igul_letova_required');
      }

      if (this.zehutError !== '' || this.privacyPolicyError !== '') {
        return false;
      }

      return true;
    },
    clearZehutError() {
      this.zehutError = '';
    },
    clearPrivecPolicyError() {
      this.privacyPolicyError = '';
    },
    clearIssuerError() {
      this.issuerError = '';
    },
    validateZehut() {
      let id = String(this.zehut).trim();

      if (id.length > 9 || id.length < 5) return false;

      id = id.length < 9 ? (`00000000${id}`).slice(-9) : id;

      return Array
        .from(id, Number)
        .reduce((counter, digit, i) => {
          const step = digit * ((i % 2) + 1);
          return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
    },
  },

};
</script>

<style lang="scss" scoped>
.igul-letova-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;

    & > * {
      margin: 0.5rem 0;
    }
  }

  & > * {
    margin: 1rem 0;
  }

  .icon-div img {
    width: 9rem;
    height: 5rem;
  }

  .title-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      color: #000;
    }
  }

    .desc {
      font-size: 1.3rem;
      color: #373232;
      margin: 0 !important;
      font-weight: 600;
    }

  .id-input {
    width: 70%;

    input {
      padding: 5px;
      border: 1px solid #ccc !important;
      border-radius: 5px;
      outline: none;
      width: 100%;
      color: #484848;
      min-height: 46px !important;
      font-size: 1.2rem;
    }

    input::placeholder {
      color: #484848;
    }
  }

  .issuer-select {
    width: 70%;
    select {
      padding: 5px;
      border: 2px solid #636363;
      border-radius: 5px;
      outline: none;
      width: 100%;
      color: #484848;
    }
  }

  .privacy-policy {
    // display: flex;
    label {
      display: flex;
    }

    input[type='checkbox'] {
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 0 !important;
      text-replace: auto;
    }

    span {
      font-size: 14px;
    }
  }

  .submit-button button {
    border-radius: 100px;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    font-weight: bold;
    color: #fff;
    background: #4f9c2e;
    cursor: pointer;
  }

  .submit-button button:hover {
    background: #315f1d;
  }
}

.color-green {
  color: #4f9c2e !important;
}
.color-red {
  color: red;
}
.invalid {
  border: 2px solid red !important;
}
.option__image {
  max-height: 2rem;
  max-width: 4rem;
  object-fit: contain;
  margin: 0 7px
}
.flex {
  display: flex;
  align-items: center;
}
</style>
