import { MutationTree } from 'vuex';
import { DonorsFundState } from './models/donorsfund';

const state: DonorsFundState = {
  number: '',
  cvv: '',
}

const mutations: MutationTree<DonorsFundState> = {
  setDonorsFundData(state, { key, value }) {
    state[key] = value;
  },
}

export default {
  state,
  mutations,
  // actions,
}
