
import Vue from 'vue';

const VueComponent = Vue.extend({
  inject: ['$validator'],

  props: {
    value: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    validate: {
      type: Object,
      default: () => {},
    },
    debounce: {
      type: Function,
      default: () => {},
    },
  },
});

export default class CardNumberInput extends VueComponent {}
