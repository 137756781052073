
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';

const VueComponent = Vue.extend({
  data() {
    return {
    };
  },

  computed: {
    ...customMapState({
      paymentConfig: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
      dropinInstance: (state: IRootState) => state.braintreeDropin.dropinInstance,
    }),
  },

  mounted() {
    this.$store.dispatch(
      'braintreeDropinInit',
      {
        key: this.paymentConfig.token,
        container: '#braintree-dropin-container',
      },
    )

    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      const isRequestable = this.dropinInstance?.isPaymentMethodRequestable()

      if (isRequestable) {
        resolve(false);
      } else {
        resolve('braintree payment method is not available');
      }
    });
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },
});
export default class Braintree extends VueComponent {}
