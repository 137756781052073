import { ActionTree, MutationTree, GetterTree } from 'vuex';

import dropin, { IDropinError, IDropinInstance } from 'braintree-web-drop-in';

import { IRootState } from '@/models/IRootState';
import { BraintreeState } from './models/braintree-dropin';

const state: BraintreeState = {
  dropinInstance: null,
}

const mutations: MutationTree<BraintreeState> = {
  setDropinInstance(s, i) {
    s.dropinInstance = i
  },
}

const getters: GetterTree<BraintreeState, IRootState> = {

}

const actions: ActionTree<BraintreeState, IRootState> = {
  braintreeDropinInit({ commit }, { key, container }) {
    dropin.create({
      authorization: key,
      container,
      card: false,
      venmo: {
        allowNewBrowserTab: false,
        allowDesktop: true,
        paymentMethodUsage: 'multi_use',
      },
    })
      .then((dropinInstance: IDropinInstance) => {
        commit('setDropinInstance', dropinInstance)
      })
      .catch((err: IDropinError) => {
        commit(
          'setError',
          {
            title: err.name,
            text: err.message,
          },
        )
      })
  },

  braintreeDropinRequestPaymentMethod({ state, commit }) {
    const { dropinInstance } = state;

    return new Promise((resolve, reject) => {
      if (!dropinInstance) {
        commit(
          'setError',
          {
            title: 'Dropin Instance',
            text: 'instance was not created',
          },
        )
        reject(new Error('Dropin instance is null'));
      }

      dropinInstance?.requestPaymentMethod()
        .then(payload => {
          resolve(payload)
        })
        .catch((err: IDropinError) => {
          commit(
            'setError',
            {
              title: err.name,
              text: err.message,
            },
          )
          commit('setCaptchaToken', '');
          commit('setIsSubmitted', false);
          commit('setOverlay', false);
          reject(err)
        })
    })
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
