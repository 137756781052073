
import Vue from 'vue'
import { mapGetters } from 'vuex'
import customMapState from '@/helpers/mapHelper'

import { IRootState } from '@/models/IRootState'
import { IDonationStreams, IOrganization, OrganizationLegalEntity } from '@/models/IDonationParams'
import { PaymentConfig } from '@/store/modules/models/donation'
import { IGatewayListByCurrency } from '@/models/IGatewayList'

export default Vue.extend({
  data() {
    return {
      rate: 0,
    };
  },

  computed: {
    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      paymentConfig: (state: IRootState) => state.donation.paymentConfig,
      cid: (state: IRootState) => state.donation.campaign.data.id,
      cmapaignCurrency: (state: IRootState) => state.donation.campaign.data.attributes.currency.toUpperCase(),
      chargedCurrency: (state: IRootState) => state.donation.donationData.attributes.currency.toUpperCase(),
      streamList: (state: IRootState) => state.donation.donationParams.data.attributes.config.donation_streams,
      countryList: (state: IRootState) => state.donation.donationParams.data.attributes.address_config.country_list,
    }),

    ...mapGetters({
      getStreamId: 'getStreamId',
      gatewayList: 'getGatewayListByCurrency',
    }),

    gatewayListFiltered(): IGatewayListByCurrency[] {
      const res = this.gatewayList.filter((el: IGatewayListByCurrency) => {
        if (this.streame?.gateway_id) {
          return this.streame.gateway_id === parseInt(el.id, 10);
        }
        return true;
      })

      if (this.streame?.gateway_id && !res?.length) {
        return this.gatewayList;
      }

      return res;
    },

    chosenGateway(): false | PaymentConfig {
      if (this.paymentConfig && Object.keys(this.paymentConfig).length) {
        return this.paymentConfig;
      }

      return false;
    },

    streame(): IDonationStreams | undefined {
      return this.streamList.find(
        s => s.id === parseInt(this.getStreamId, 10),
      );
    },

    org(): IOrganization {
      return this.config.organization;
    },

    organizationLegalEntity(): OrganizationLegalEntity {
      const { attributes } = this.chosenGateway || {};
      return attributes?.organization_legal_entity || {} as OrganizationLegalEntity;
    },

    type(): string {
      const { type, country } = this.organizationLegalEntity;
      const contrylc = country?.toLowerCase();

      if (type === 'nonprofit') {
        if (contrylc === 'us' || contrylc === 'il') {
          return this.$t(`template.nonprofit_${contrylc}`) as string;
        }
        return this.$t('template.nonprofit_other') as string;
      }

      return '';
    },

    rateToFixed2(): number {
      return parseFloat(this.rate.toFixed(2))
    },

  },

  watch: {
    chargedCurrency: {
      handler(code: string) {
        if (this.cmapaignCurrency !== code) {
          this.$store.dispatch('getExchangeRate').then((rate: number) => {
            this.rate = rate;
          })
        }
      },
      immediate: true,
    },
  },

  methods: {
    ifCountry(name: string): boolean {
      const { country } = this.organizationLegalEntity;
      return country?.toLowerCase() === name;
    },

    getDescription(item: IGatewayListByCurrency): string {
      const { country: countruOlc } = item.attributes.organization_legal_entity || {};
      const { description, description_key } = item.attributes;
      const countryObj = this.countryList.find(
        el => el.iso_code.toLowerCase() === countruOlc.toLowerCase(),
      );
      const country = (countryObj && countryObj.name) || countruOlc;
      return description || this.$t(description_key, { country }) as string;
    },
  },
})
