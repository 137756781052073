
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';

export default Vue.extend({
  data() {
    return {
      error: '',

      ccname: '',

      hostedFormInstance: null as any,
    }
  },

  computed: {
    ...customMapState({
      lang: (state: IRootState) => state.translation.locate,
      paymentConfig: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
    }),

    mid(): string {
      return this.paymentConfig.merchant
    },

    host(): string {
      return this.paymentConfig.host
    },
  },

  mounted() {
    this.error = ''

    const exist = document.getElementById('blink-script')
    if (exist) {
      this.initiate(false)
    } else {
      const scriptEl = document.createElement('script');
      scriptEl.id = 'blink-script'
      scriptEl.setAttribute('src', `${this.host}/sdk/web/v1/js/hostedfields.min.js`);
      document.head.appendChild(scriptEl);

      scriptEl.addEventListener('load', () => {
        this.initiate(true)
      });
    }

    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      if (this.error) {
        resolve(this.error);
        return
      }

      const also = {
        customerName: this.ccname,
      }

      const $form = this.hostedFormInstance

      $form.getPaymentDetails(also, true)
        .then(
          (details: any) => {
            if (details.invalid) {
              resolve(this.error);
              return
            }

            if (details.success) {
              this.$store.commit(
                'setDonationIncludedGatewayParams',
                { blink_payment_token: details.paymentToken },
              );
              resolve(false)
            }
          },
        )
        .catch((e: Error) => {
          resolve(e.message);
        });
    });
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    initiate(first: boolean) {
      try {
        let field = new window.hostedFields.classes.Field(this.$refs.cardDetailsEl, {
          nativeEvents: true,
          disabled: false,
          readOnly: false,
          required: true,
          locale: this.lang,
          classes: {
            invalid: 'error',
          },
        });

        if (first) {
          field.destroy()

          field = new window.hostedFields.classes.Field(this.$refs.cardDetailsEl, {
            nativeEvents: true,
            disabled: false,
            readOnly: false,
            required: true,
            locale: this.lang,
            classes: {
              invalid: 'error',
            },
          });
        }

        this.hostedFormInstance = field.form;
      } catch (error) {
        this.error = (error as Error).message
      }
    },
  },
});
