import axios from 'axios';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { IRootState } from '@/models/IRootState';
import { deepCopy } from '@/helpers/objectHelper';
import features from '@/constants/features';
import urls from '@/constants/urls';
import { TrackingDonationErrorsClientState } from './models/tracking-donation-errors-client';
import { IDonationRequest } from './models/donation';

const state: TrackingDonationErrorsClientState = {

}

const mutations: MutationTree<TrackingDonationErrorsClientState> = {

}

const getters: GetterTree<TrackingDonationErrorsClientState, IRootState> = {
  getPayload(s, g, rootState) {
    const {
      donationParams,
      donationData,
      donationIncludedGatewayParams,
      donationIncluded,
      error,
    } = rootState.donation;

    const { recurring_config } = donationParams.data.attributes.config;
    const data = deepCopy(donationData);

    const payload: IDonationRequest = {
      data,
      included: [...[donationIncludedGatewayParams], ...donationIncluded],
    };

    payload.data.attributes.lang = rootState.translation.locate;

    if (payload.data.attributes.recurring && recurring_config.period === 'day') {
      payload.data.attributes.amount *= 30;
    }

    if (donationParams.data.attributes.ui_mode === features.DELTA_DONATION_AND_TIP) {
      payload.data.attributes.amount *= 100;
      payload.data.attributes.tip_amount *= 100;
    }

    if (error) {
      payload.data.relationships.client_error = {
        data: {
          type: 'client_error',
          id: '0',
        },
      }
      payload.included.push(
        {
          type: 'client_error',
          id: '0',
          attributes: {
            details: error.text || error.translationKey?.[1],
          },
        },
      )
    }

    return payload;
  },

  getPayloadWithExtraData(s, g) {
    const p = g.getPayload as IDonationRequest;

    return ({ session_id, error }: any) => {
      if (session_id) {
        p.data.relationships.donor_rescue_extra_data = {
          data: {
            type: 'donor_rescue_extra_data',
            id: '0',
          },
        }

        p.included.push(
          {
            type: 'donor_rescue_extra_data',
            id: '0',
            attributes: {
              session_id,
            },
          },
        )
      }

      if (error) {
        p.data.relationships.client_error = {
          data: {
            type: 'client_error',
            id: '0',
          },
        }
        p.included = p.included.filter(e => e.type !== 'client_error')
        p.included.push(
          {
            type: 'client_error',
            id: '0',
            attributes: {
              details: error?.text || error?.translationKey?.[1] || error,
            },
          },
        )
      }

      return p;
    }
  },
}

const actions: ActionTree<TrackingDonationErrorsClientState, IRootState> = {
  trackClientError({ getters }, { session_id, error } = {}) {
    const data = getters.getPayloadWithExtraData({ session_id, error }) as IDonationRequest;

    axios.post(urls.donationsFailed, data)
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
