
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';

const usaepay_payment_form_id = 'hidden-usaepay-button';

export default Vue.extend({
  data() {
    return {
      error: '',
      usaepay_payment_form_id,
    }
  },

  computed: {
    ...customMapState({
      pub_key: (state: IRootState) => state.donation.paymentConfig.attributes.configuration.usaepay_pub_key,
      test: (s: IRootState) => s.donation.donationData.attributes.test || false,
    }),
  },

  mounted() {
    const exist = document.getElementById('usaepay-script')
    if (exist) {
      this.initiateUsaepay()
    } else {
      const usaepaeScript = document.createElement('script');
      usaepaeScript.id = 'usaepay-script'
      usaepaeScript.setAttribute('type', 'text/javascript')
      usaepaeScript.setAttribute('src', `https://${this.test ? 'sandbox.' : ''}usaepay.com/js/v1/pay.js`);
      document.getElementsByTagName('head')[0].appendChild(usaepaeScript)
      document.head.appendChild(usaepaeScript);

      usaepaeScript.addEventListener('load', () => {
        this.initiateUsaepay()
      });
    }

    this.$store.commit('setUsaepaySubmitFormID', usaepay_payment_form_id)
  },

  methods: {
    initiateUsaepay() {
      const client = new usaepay.Client(this.pub_key);

      this.$store.commit('setUsaepayclient', client)

      const paymentCard = client.createPaymentCardEntry();

      const style = {

      };

      paymentCard.generateHTML(style);

      paymentCard.addHTML('paymentCardContainer');

      // listen for errors so that we can display error messages
      paymentCard.addEventListener('error', (errorMessage:string) => {
        this.$data.error = errorMessage
      });

      this.$store.commit('setUsaepayPaymentCard', paymentCard)
    },

  },
});
