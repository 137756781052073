
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import { StripeModel } from '@/components/subcomponents/paymentSystems/payments-models/stripe.model';
import { PaymentConfig, PaymentConfigConfiguration } from '@/store/modules/models/donation';
import { IRootState } from '@/models/IRootState';

import SavedCreditCardList from '@/components/shared/saved-credit-card-list/SavedCreditCardList.vue';

const elementClasses = {
  focus: 'focused',
  empty: 'empty',
  invalid: 'invalid',
};

const elementStyles = {
  base: {
    color: '#555',
    ':-webkit-autofill': {
      color: '#e39f48',
    },
  },
  invalid: {
    color: '#E25950',
    '::placeholder': {
      color: '#FFCCA5',
    },
  },
};

const VueComponent = Vue.extend({
  components: {
    SavedCreditCardList,
  },

  props: {
    publicKey: {
      type: String,
      default: '',
    },
    paymentConfig: {
      type: Object as () => PaymentConfig,
      default: Object as () => PaymentConfig,
    },
  },

  data(): StripeModel {
    return {
      expirationMonth: '',
      expirationYear: '',
      securityCode: '',

      card: {
        number: '',
        brand: '',
      },

      // elements
      cardNumber: {},

      // errors
      stripeError: '',
      cardNumberError: '',
      loading: false,
      showStripeLoadWarning: false,
    };
  },

  computed: {
    ...customMapState({
      stripePaymentMethods: (state: IRootState) => state.donation.stripePaymentMethods,
      savedCreditCard: (state: IRootState) => state.donation.savedCreditCard,
      donateAttrs: (state: IRootState) => state.donation.donationData.attributes,
      stripe: (state: IRootState) => state.stripe.stripe,
      step: (state: IRootState) => state.donation.stepFlag,
      locale: (state: IRootState) => state.i18n.locale,
      stripeIdempotencyKey: (state: IRootState) => state.stripe.stripeIdempotencyKey,
    }),

    ...mapGetters({
      getStripeMethod: 'getStripeMethod',
    }),

    configuration(): PaymentConfigConfiguration {
      return this.paymentConfig.attributes.configuration;
    },

    paymentIntention(): boolean {
      return this.getStripeMethod() === 'payment_intention';
    },
  },

  watch: {
    configuration() {
      this.setUpStripeForm();
    },

    savedCreditCard(val: boolean) {
      if (!val) {
        this.setUpStripeForm();
      }
    },

    step(nv, ov) {
      if (nv === 1 && ov === 2) {
        this.cardNumber.clear();
      }
    },
  },

  mounted(): void {
    this.setUpStripeForm();

    this.$store.commit('setStripeIdempotencyKey', this.$nanoid())

    this.$root.$on('stripeCreateToken', this.createToken);

    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      this.submitFormToCreateToken();

      resolve(this.stripeError || this.cardNumberError)
    });
  },

  beforeDestroy() {
    this.$root.$off('stripeCreateToken');
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    ...mapActions({
      useStripe: 'useStripe',
    }),

    setUpStripeForm(): void {
      // create Stripe instance in store
      this.useStripe();

      if (typeof Stripe === 'undefined') {
        this.showStripeLoadWarning = true;
        return;
      }

      const elements = this.stripe.elements();

      this.cardNumber = elements.create('card', {
        style: elementStyles,
        classes: elementClasses,
        hidePostalCode: true,
      });

      if (!this.savedCreditCard) {
        this.cardNumber.mount('#card-number');
      }
      this.listenForErrors();
    },

    listenForErrors(): void {
      const vm = this;
      this.cardNumber.addEventListener('change', (event: any) => {
        vm.card.brand = event.brand === 'unknown' ? '' : event.brand;
        vm.toggleError(event);
        vm.card.number = !!event.complete;
      });
    },

    toggleError(event: any): void {
      if (event.error) {
        this.stripeError = event.error.message;
      } else {
        this.clearCardErrors();
      }
    },

    clearCardErrors(): void {
      this.stripeError = '';
      this.cardNumberError = '';
    },

    submitFormToCreateToken(): boolean {
      let valid = true;

      if (!this.card.number && !this.savedCreditCard) {
        valid = false;
        this.cardNumberError = this.$t('donation.stripe_card_number_error') as string; // 'Card Number is Required';
      }
      if (this.stripeError && !this.savedCreditCard) {
        valid = false;
      }

      this.$store.commit('setStripeIdempotencyKey', this.$nanoid())

      if (valid) {
        this.clearCardErrors();
      }

      return valid;
    },

    async createToken(resolve: (value: unknown) => void) {
      let $token;
      let $paymentMethod;

      const showError = (msg: string) => {
        this.$store.commit('setError', {
          title: 'Stripe Error!',
          translationKey: [null, msg],
          skipNext: true,
        });
        this.$store.dispatch('trackClientError')
      }

      const {
        billing_name,
        billing_first_name,
        billing_last_name,
        address_line1,
        address_line2,
        address_city,
        address_state,
        address_zip,
        address_country,
        currency,
        email,
        phone,
      } = this.donateAttrs;
      const fname = `${billing_first_name} ${billing_last_name}`;

      if (!this.savedCreditCard && this.paymentIntention) {
        const billing_details = {
          address: {
            city: address_city,
            country: address_country,
            line1: address_line1,
            line2: address_line2,
            postal_code: address_zip,
            state: address_state,
          },
          name: billing_name || fname,
          email,
        } as {[k: string]: any}

        if (phone) {
          billing_details.phone = phone;
        }

        const { paymentMethod, error } = await this.stripe.createPaymentMethod(
          {
            type: 'card',
            card: this.cardNumber,
            billing_details,
          },
        );

        if (error) {
          showError(error.message);
          // this.stripeError = error.message;

          return resolve(false);
        }

        $paymentMethod = paymentMethod;
      } else if (!this.savedCreditCard) {
        const data = {
          name: billing_name || fname,
          address_line1,
          address_line2,
          address_city,
          address_state,
          address_zip,
          address_country,
          currency,
        };

        const { token, error } = await this.stripe.createToken(this.cardNumber, data);

        if (error) {
          showError(error.message);
          // this.stripeError = error.message;

          return resolve(false);
        }

        $token = token;
      }

      const { origin, pathname } = window.location;

      this.$store.commit('setDonationIncludedGatewayParams', {
        stripe_source_id: this.savedCreditCard
          ? String(this.savedCreditCard.id)
          : $token?.card?.id || '',
        token: $token?.id || '',
        stripe_payment_method_id: $paymentMethod && $paymentMethod.id,
        redirect_url: `${origin}${pathname}?lang=${this.locale}`,
        stripe_idempotency_key: this.stripeIdempotencyKey,
      });

      // this.clearCardErrors();

      // this.$store.commit('setStripeIdempotencyKey', this.$nanoid())

      return resolve(true);
    },
  },
});
export default class stripeCheckout extends VueComponent {}
