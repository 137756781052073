
import Vue from 'vue'
import Bugsnag from '@bugsnag/js';
import { IRootState } from '@/models/IRootState';
import customMapState from '@/helpers/mapHelper'
import { generateDeviceId } from '@/helpers/generateDeviceId';
import { mapGetters } from 'vuex';
import urls from '@/constants/urls';

export default Vue.extend({
  data() {
    const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const nanoid: () => string = this.$nanoidCustomAlphabet(alphabet, 25);

    return {
      uid: `${nanoid()}_d-${generateDeviceId()}`,
    }
  },

  computed: {
    ...customMapState({
      donorData: (s: IRootState) => s.donation.donationData.attributes,
      stepFlag: (s: IRootState) => s.donation.stepFlag,
    }),

    ...mapGetters({
      getPayloadWithExtraData: 'getPayloadWithExtraData',
    }),

    allowedToTrack(): boolean {
      return Boolean(this.donorData.email || this.donorData.phone) && this.stepFlag !== 3
    },

    beaconData(): any {
      if (this.allowedToTrack) {
        return this.getPayloadWithExtraData({ session_id: `${this.uid}_page-closed` })
      }
      return {}
    },
  },

  created() {
    Bugsnag.addOnError(this.bugsnagOnError);
    window.addEventListener('beforeunload', this.onBeforeUnload);
  },

  beforeDestroy() {
    Bugsnag.removeOnError(this.bugsnagOnError)
  },

  methods: {
    bugsnagOnError(event: any) {
      event.setUser(this.uid, this.donorData.email, this.donorData.billing_name)

      if (this.allowedToTrack) {
        event.addMetadata(
          'donationData',
          { data: JSON.stringify(this.donorData) },
          { capture: true },
        )
      }

      const errors = event.errors.map((e: any) => `[${e.errorClass}] ${e.errorMessage}`)

      this.trackClientError(errors.join(';'))
    },

    onBeforeUnload(event: any) {
      if (this.allowedToTrack) {
        this.sendBeacon()
      }

      return event;
    },

    trackClientError(error: string) {
      const p = { session_id: `${this.uid}_console-error`, error: '' }
      if (error) {
        p.error = error
      }
      this.$store.dispatch('trackClientError', p)
    },

    sendBeacon() {
      if (this.stepFlag !== 3) {
        navigator.sendBeacon(
          urls.donationsFailed,
          JSON.stringify(this.beaconData),
        )
      }
    },
  },
})
