
import customMapState from '@/helpers/mapHelper';
import Vue from 'vue';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  data() {
    return {
      showGiveAndGetForm: false,
    }
  },
  computed: {
    ...customMapState({
      donationDataAttrs: (state: IRootState) => state.donation.donationData.attributes,
      sign: (state: IRootState) => state.redirect.data?.currency_sign,
      amount: (state: IRootState) => state.redirect.data?.original_amount,
    }),
    giveandget_url() :string {
      let fName = this.donationDataAttrs.billing_first_name;
      let lName = this.donationDataAttrs.billing_last_name;
      if ((fName === '' || lName === '') && this.donationDataAttrs.billing_name !== '') {
        const parts = this.donationDataAttrs.billing_name.split(' ');
        if (parts.length > 1) {
          lName = parts[parts.length - 1]
          fName = parts.slice(0, -1).join(' ')
        }
      }

      const amount = this.donationDataAttrs.amount / 100

      const phone = this.donationDataAttrs.phone.replace(/\D/g, '');

      const baseUrl = 'https://www.givenget.co.il/tax-form';

      const searchParams = new URLSearchParams();
      searchParams.set('sum', amount.toString());
      searchParams.set('currency', this.donationDataAttrs.currency.toUpperCase());
      searchParams.set('firstName', fName);
      searchParams.set('lastName', lName);
      searchParams.set('phone', phone);
      searchParams.set('email', this.donationDataAttrs.email);
      searchParams.set('lang', this.donationDataAttrs.lang)
      searchParams.set('form', '1');

      return `${baseUrl}?${searchParams.toString()}`
    },
    amountToReturnText(): string {
      let amount = 0
      let sign = '';
      if (this.donationDataAttrs.amount !== 0) {
        amount = this.donationDataAttrs.amount / 100;
        sign = this.donationDataAttrs.currency_sign;
      } else if (this.amount !== 0) {
        amount = this.amount || 0;
        sign = this.sign || '';
      }

      const amountToReturn = (amount * 0.35).toFixed(2);

      if (amount <= 0) {
        return '35%';
      }

      return `${sign}${amountToReturn}`;
    },
  },
  methods: {
    handleIframeLoad() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })

      const iframe: HTMLIFrameElement = this.$refs.giveandget_iframe as HTMLIFrameElement;

      if (iframe.contentWindow) {
        iframe.contentWindow.scrollTo(0, 0);
      }
    },
  },
});
export default VueComponent
