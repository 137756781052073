
import Vue from 'vue';
import { IRootState } from '@/models/IRootState';
import customMapState from '../../helpers/mapHelper';

import giftAid from './GiftAid.vue';

import stripeCheckout from './paymentSystems/StripeCheckout.vue';
import broomFoundation from './paymentSystems/BroomFoundation.vue';
import asserBishvil from './paymentSystems/AsserBishvil.vue';
import mercadoPagoCheckout from './paymentSystems/MercadoPagoCheckout.vue';
import sepaDebit from './paymentSystems/SEPADirectDebit.vue';
import klarnaCheckout from './paymentSystems/KlarnaCheckout.vue';
import ojc from './paymentSystems/OJCGateway.vue';
import blackbaud from './paymentSystems/BlackbaudGateway.vue';
import achisomoch from './paymentSystems/Achisomoch.vue';
import stripeACH from './paymentSystems/StripeACH.vue';
import fund from './paymentSystems/Foundations.vue';
import hkBankTransfer from './paymentSystems/HKBankTransfer.vue';
import StripeiDEAL from './paymentSystems/StripeiDEAL.vue';
import BECSDirectDebit from './paymentSystems/BECSDirectDebit.vue';
import CheckoutFi from './paymentSystems/CheckoutFi.vue';
import MeshulamBit from './paymentSystems/MeshulamBit.vue';
import PayPal from './paymentSystems/PayPal.vue';
import MHTrust from './paymentSystems/MHTrust.vue';
import ZoreyaTzedokos from './paymentSystems/ZoreyaTzedokos.vue';
import StripeAppleGooglePay from './paymentSystems/StripeAppleGooglePay.vue';
import ThreePillars from './paymentSystems/ThreePillars.vue';
import KolYom from './paymentSystems/KolYom.vue';
import DLocal from './paymentSystems/DLocal.vue';
import PayrixGateway from './paymentSystems/PayrixGateway.vue';
import NedarimPlus from './paymentSystems/NedarimPlus.vue';
import NedarimPlusBit from './paymentSystems/NedarimPlusBit.vue';
import Coinbase from './paymentSystems/Coinbase.vue';
import PayFast from './paymentSystems/PayFast.vue';
import Cardknox from './paymentSystems/Cardknox.vue';
import Braintree from './paymentSystems/Braintree.vue';
import Kehilot from './paymentSystems/Kehilot.vue';
import DonorsFundGateway from './paymentSystems/DonorsFund.vue';
import Mancal from './paymentSystems/Mancal.vue';
import Usaepay from './paymentSystems/Usaepay.vue';
import BlinkFidelipay from './paymentSystems/BlinkFidelipay.vue';
import BanquestGateway from './paymentSystems/BanquestGateway.vue';
import BanquestACHGateway from './paymentSystems/BanquestACHGateway.vue';
import Kcbobov from './paymentSystems/Kcbobov.vue';
import CardknoxGoogleApplePay from './paymentSystems/CardknoxGoogleApplePay.vue';
import Gama from './paymentSystems/Gama.vue';
import Tevini from './paymentSystems/Tevini.vue';
import YadShlomo from './paymentSystems/YadShlomo.vue';
import BelzVouchers from './paymentSystems/BelzVouchers.vue';
import WalletDocDirect from './paymentSystems/WalletDocDirect.vue';
import Authorize from './paymentSystems/Authorize.vue';
import Matbia from './paymentSystems/MatbiaGateway.vue';
import Bancontact from './paymentSystems/Bancontact.vue';
import RedirectURLSetup from './paymentSystems/RedirectURLSetup.vue';
import IsraeliZehut from './paymentSystems/IsraelZehut.vue';
import UTA from './paymentSystems/UTA.vue';
import CMZ from './paymentSystems/CMZ.vue';
import Sumit from './paymentSystems/Sumit.vue';
import PayArc from './paymentSystems/PayArc.vue';

function toTitleCase(str: string): string {
  let upper = true;
  let newStr = '';
  for (let i = 0, l = str.length; i < l; i += 1) {
    if (str[i] === ' ') {
      upper = true;
      newStr += ' ';
      // eslint-disable-next-line no-continue
      continue;
    }
    newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
    upper = false;
  }
  return newStr;
}

const VueComponent = Vue.extend({
  components: {
    Bancontact,
    stripeCheckout,
    broomFoundation,
    asserBishvil,
    mercadoPagoCheckout,
    sepaDebit,
    klarnaCheckout,
    ojc,
    blackbaud,
    achisomoch,
    stripeACH,
    giftAid,
    fund,
    hkBankTransfer,
    StripeiDEAL,
    CheckoutFi,
    BECSDirectDebit,
    MeshulamBit,
    PayPal,
    MHTrust,
    ZoreyaTzedokos,
    StripeAppleGooglePay,
    ThreePillars,
    KolYom,
    DLocal,
    PayrixGateway,
    NedarimPlus,
    NedarimPlusBit,
    Coinbase,
    Cardknox,
    Braintree,
    Kehilot,
    DonorsFundGateway,
    Mancal,
    Usaepay,
    BlinkFidelipay,
    BanquestGateway,
    BanquestACHGateway,
    Kcbobov,
    CardknoxGoogleApplePay,
    Gama,
    Tevini,
    YadShlomo,
    PayFast,
    WalletDocDirect,
    Authorize,
    Matbia,
    RedirectURLSetup,
    IsraeliZehut,
    BelzVouchers,
    UTA,
    CMZ,
    Sumit,
    PayArc,
  },

  computed: {
    ...customMapState({
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
      paymentConfig: (state: IRootState) => state.donation.paymentConfig,
      israliIDInput: (state: IRootState) => state.donation.paymentConfig.attributes.configuration.israeli_zehut_input,
    }),

    isCardPaymnt(): boolean {
      return ['card', 'stripe'].includes(this.paymentMethod)
    },

    securePaymentsText(): string {
      const pmWithCustomImg = ['blink-fidelipay', 'blink-fidelipay-direct']

      if (pmWithCustomImg.includes(this.paymentMethod) || this.isCardPaymnt) {
        return this.$t(
          'template.secure_payment',
          'Secure payment',
        ) as string
      }

      return this.$t(
        'template.secure_payments',
        { paymentMethod: toTitleCase(this.paymentMethodWithExclude) },
      ) as string
    },

    paymentMethodWithExclude(): string {
      const list = [
        'meshulam',
        'meshulam-v2',
        'meshulam-bit',
        'meshulam-v2-bit',
        'blink-fidelipay',
        'blink-fidelipay-direct',
      ];
      if (list.includes(this.paymentMethod)) {
        return ''
      }
      return this.paymentMethod
    },

    showImg(): boolean {
      // @ts-expect-error fields comes from vee-validate
      if (this.fields && Object.keys(this.fields).length === 0) {
        return false;
      }

      return (
        !!this.paymentConfig
        && !!this.paymentConfig.attributes
        && !!this.paymentConfig.attributes.icon_url
      );
    },
  },
});
export default class PaymentData extends VueComponent {}
