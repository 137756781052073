
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import { loadScript, PayPalNamespace } from '@paypal/paypal-js';
import { usePaypalV2 } from '@/compositions/paypalv2';
import { createError } from '@/helpers/customError';

export interface Data {
  paypal: null | PayPalNamespace;
}

export default Vue.extend({
  data(): Data {
    return {
      paypal: null,
    };
  },

  computed: {
    ...customMapState({
      currency: (state: IRootState) => state.donation.donationData.attributes.currency,
      country: (state: IRootState) => state.donation.donationData.attributes.address_country,
      paymentConfig: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
      test: (s: IRootState) => s.donation.donationData.attributes.test || false,
    }),
  },

  async mounted() {
    try {
      const params: any = {
        clientId: this.paymentConfig.client_id,
        environment: this.test ? 'sandbox' : 'production',
        currency: this.currency.toUpperCase(),
        enableFunding: ['venmo'],
      }

      if (this.test) {
        params.buyerCountry = this.country?.toUpperCase()
      }

      this.paypal = await loadScript(params);
    } catch (error) {
      this.$store.commit('setError', {
        title: 'PayPal',
        translationKey: ['donation.paypalv2_script_load_failed', 'failed to load the PayPal JS SDK script'],
      });
    }

    if (this.paypal) {
      const $self = this;

      const { initPromise, statusUpdate, getDonationID } = usePaypalV2()

      try {
        await this.paypal
          .Buttons?.({
            style: {
              color: 'blue',
              label: 'paypal',
            },
            onInit(data, actions) {
              actions.disable();
              document.querySelector('#terms-privacy-option')?.addEventListener('change', (event: any) => {
                if (event?.target?.checked) {
                  actions.enable();
                } else {
                  actions.disable();
                }
              });
            },
            async createOrder() {
              const data = await initPromise(() => {
                $self.$store.commit('setIsSubmitted', true);
                $self.$store.dispatch('makeDonation');
              });

              if (data.error) {
                throw new Error(data.error)
              }

              return data.orderID;
            },
            async onApprove(data) {
              const donationID = getDonationID()
              if (donationID) {
                try {
                  const resp = await statusUpdate(donationID, data.orderID, { test: $self.test ? '1' : null })

                  const { Successful, ErrorMessage } = resp.data;

                  if (!Successful) throw createError(ErrorMessage, {});

                  window.postMessage({
                    type: 'gateway-response-success',
                  }, '*');
                } catch (error: any) {
                  const errtk = error?.tk
                    ? error?.translation_key
                    : error?.response?.data.error_translation_key;
                  const errmsg = error?.custom
                    ? error?.message
                    : error?.response?.data?.error;
                  $self.$store.commit('setError', {
                    title: 'PayPal Error!',
                    translationKey: [errtk, errmsg],
                  });
                  throw error;
                }
              }
            },
          })
          .render('#paypal-button-container');
      } catch (error) {
        this.$store.commit('setError', {
          title: 'PayPal',
          translationKey: ['donation.paypalv2_script_render_failed', 'failed to render the PayPal Buttons'],
        });
      }
    }
  },

  beforeDestroy() {},
});
