
import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import customMapState from '@/helpers/mapHelper';
import parseStringToNumber from '@/helpers/parseStringToNumber';
import { get } from '@/helpers/getUrlParameters';
import urls from '../../constants/urls';
import { IRootState } from '../../models/IRootState';
import { AvailableCurrencyList } from '../../models/IDonationParams';
import { IXchangeResponse } from './subcomponents-models/DonationAmount-models';

import { AvailableDisplayCurrencyList } from '../../store/modules/models/donation';
import { range } from '../../helpers/arrayHelper';

const uniq = require('lodash/uniq');
const compact = require('lodash/compact');

const VueComponent = Vue.extend({
  components: {},

  data() {
    return {
      installmentTmOption: false,
      paymentSchedule: 'onetime',
      companyFinished: false,
      isInputActive: false,
      multipliedAmountPrev: 0,
    };
  },

  computed: {
    amount: {
      get(): string {
        const formatted = this.donationAmount ? `${this.donationCurrency.sign}${(this.donationAmount).toLocaleString()}` : '';
        if (this.isInputActive) {
          return formatted;
        }
        return formatted;
      },
      set(value: string): void {
        const numeric = parseStringToNumber(value);
        this.$store.commit('setOriginAmount', numeric);
      },
    },

    showPaymentsMenu(): boolean {
      return this.originAmount > 0
        && (this.installmentAvailable || (this.recurringConfig.available
        && this.limitedCurrencies
        && this.showRecurringOption))
    },

    selectedRecurringPeriod: {
      get(): number {
        return this.$store.state.donation.donationData.attributes
          .recurring_period;
      },
      set(period: number) {
        if (this.selectedRecurringPeriod !== period) {
          this.$store.commit('setRecurringPeriod', period);
          if (this.installmentPeriod) {
            this.$store.commit('setInstallmentPeriod', 1);
            this.$store.commit('setInstallmentStatus', false);
          }
        }
      },
    },

    paymentFeeStatus: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes
          .include_payment_system_fee;
      },
      set(status: boolean) {
        this.$store.commit('setIncludePaymentFeeStatus', status);
        if (status) {
          this.$store.commit('setIncludePaymentFeeAmount', this.feeAmount);
        } else {
          this.$store.commit('setIncludePaymentFeeAmount', 0);
        }
      },
    },

    recurringAvailable() {
      return this.recurringConfig.available && this.limitedCurrencies && this.showRecurringOption;
    },

    recurringCheckboxLabel(): string {
      const { donationAmount, recurringConfig, donationCurrency } = this;

      return this.$t(
        'donation.recurring_charge_card',
        {
          amount: donationAmount,
          period: recurringConfig.period,
          duration: recurringConfig.duration,
          currencyCode: donationCurrency.code,
          currencySign: donationCurrency.sign,
          total: donationCurrency.sign + Math.floor(
            donationAmount * (recurringConfig.duration || 1),
          ),
        },
        recurringConfig.duration,
      ) as string;
    },

    recurringDuration(): number[] {
      const { recurring_max, recurring_min, duration } = this.recurringConfig;

      const min = recurring_min > 1 ? recurring_min : 2;

      if (recurring_max && recurring_max) {
        return range(min, recurring_max);
      }

      return range(2, duration);
    },

    recurringUiMode(): string {
      const { ui_mode, available } = this.recurringConfig;

      if (available && ui_mode === undefined) {
        this.$store.dispatch('addError', {
          title: 'Recurring Config',
          text: 'Parameter ui_mode not exist',
        });
      }

      return ui_mode || 'checkbox';
    },

    installmentAvailable(): boolean {
      const { available, currencies } = this.installmentConfig;

      if (available) {
        if (currencies && currencies.length) {
          return currencies.includes(this.donationCurrency.code);
        }
        return true;
      }

      return false;
    },

    installmentUiMode(): string {
      const { ui_mode, available } = this.installmentConfig;

      if (available && ui_mode === undefined) {
        this.$store.dispatch('addError', {
          title: 'Installment Config',
          text: 'Parameter ui_mode not exist',
        });
      }

      return ui_mode || 'ddl_months_split_charge';
    },

    limitedCurrencies(): boolean {
      const { currencies } = this.recurringConfig;
      return (
        currencies?.length === 0
        || currencies === null
        || currencies.indexOf(
          this.donationCurrency.code.toLowerCase(),
        ) !== -1
      );
    },

    feeAmount(): number {
      const { feeConfig, paymentMethod, donationAmount } = this;

      const config = feeConfig.fee_rate[paymentMethod];
      const amount = Math.ceil(
        config.fixed + (config.percentage * (donationAmount / 100)),
      )

      if (config.limit && config.limit < amount) {
        return config.limit
      }

      if (feeConfig.limit && feeConfig.limit < amount) {
        return feeConfig.limit
      }

      return amount;
    },

    campaignCurrency(): AvailableDisplayCurrencyList {
      const state = this.$store.state as IRootState;
      if (this.showAmountInLocCurrency) {
        return this.showAmountInLocCurrency;
      }
      return {
        sign: state.donation.campaign.data.attributes.currency_sign,
        code: state.donation.campaign.data.attributes.currency,
      }
    },

    ...mapGetters([
      'showRecurringOption',
      'showAmountInLocCurrency',
      'selectedTeamsTotalAmount',
      'paymentsLabel',
      'installmentLabel',
      'installmentPeriod',
      'recurringLabel',
      'recurringPeriod',
      'recurringConfig',
      'multipliedAmount',
      'multiplier',
      'orgName',
      'installmentMaxTmOptionMonths',
    ]),

    paymentLevels(): number[] {
      let levels = [1];
      if (this.recurringConfig.available) {
        levels = levels.concat(this.recurringDuration);
      } if (this.installmentConfig.available) {
        levels = levels.concat(this.installmentConfig.available_periods);
      }
      return uniq(compact(levels));
    },

    ...customMapState({
      isLoadCampaignParams: (state: IRootState) => state.donation.isLoadCampaignParams,

      currencyList: (state: IRootState) => state.donation.donationParams.data
        .attributes.available_currency_list.sort((a, b) => a.code.localeCompare(b.code)),

      donationAmount: (state: IRootState) => state.donation.donationData.attributes.amount,

      totalAmount: (state: IRootState) => state.donation.totalAmount,

      changeRate: (state: IRootState) => state.donation.changeRate,

      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),

      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,

      paymentMethod: (state: IRootState) => state.donation.paymentMethod,

      exchangeValue: (state: IRootState) => state.donation.exchangeValue,

      feeConfig: (state: IRootState) => state.donation.donationParams.data.attributes.config
        .include_payment_system_fee_config,

      recurringConfig: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.recurring_config,

      installmentConfig: (state: IRootState) => state.donation.donationParams.data.attributes
        .config.installment_config,

      customStyle: (state: IRootState): string => {
        const customStyle = state.donation.donationParams.data.attributes.config.style_config
          .custom_style;

        if (customStyle.length > 0) {
          return customStyle;
        }

        return '';
      },

      customScript: (state: IRootState): string => {
        if (!state.donation.isLoadCampaignParams) return '';
        const customScript = state.donation.donationParams.data.attributes.config.style_config
          .custom_script;

        if (customScript && customScript.length > 0) {
          return customScript;
        }

        return '';
      },

      originAmount: (state: IRootState) => state.donation.originAmount,

      amountRestricted: (state: IRootState) => {
        const { readonly } = state.donation;
        const {
          value,
          amount_restricted_to_item_price,
        } = state.donation.donationParams.data.attributes.config.goal_as_items;

        return (value && amount_restricted_to_item_price) || readonly;
      },
    }),

    recurringStatus: {
      get(): boolean {
        return this.$store.state.donation.donationData.attributes.recurring;
      },
      set(status: boolean) {
        this.$store.commit(
          'setRecurringPeriod',
          status ? this.recurringConfig.duration : 0,
        );
        if (this.installmentPeriod) {
          this.$store.commit('setInstallmentPeriod', 0);
          this.$store.commit('setInstallmentStatus', false);
        }
      },
    },

    minAmount(): number {
      const selectedCode = this.donationCurrency.code.toUpperCase();
      const campaignCurrencyCode = this.campaignCurrency.code.toUpperCase();
      const minDonationAmount = this.config.minimum_donation_amount;

      if (this.recurringStatus !== undefined && this.recurringConfig.min_amount) {
        if (this.changeRate) {
          return Math.ceil(
            this.recurringConfig.min_amount / this.changeRate,
          );
        }

        return this.recurringConfig.min_amount;
      }

      if (this.installmentPeriod > 1 && this.installmentConfig.min_amount) {
        if (this.changeRate) {
          return Math.ceil(
            this.installmentConfig.min_amount / this.changeRate,
          );
        }

        return this.installmentConfig.min_amount;
      }

      if (minDonationAmount[selectedCode]) {
        return minDonationAmount[selectedCode];
      }
      if (this.changeRate) {
        return Math.ceil(
          (minDonationAmount[campaignCurrencyCode] || 1) / this.changeRate,
        );
      }

      return 1;
    },
  },

  watch: {
    multipliedAmount(value: number): void {
      if (this.$refs.multipliedAmount) {
        (this.$refs.multipliedAmount as any).reset(this.multipliedAmountPrev, value);
        (this.$refs.multipliedAmount as any).start();
        this.multipliedAmountPrev = value;
      }
    },
    originAmount: {
      handler(value: any) {
        this.checkExchange(value);
      },
      immediate: true,
    },

    installmentTmOption(value: boolean): void {
      this.$store.commit('setInstallmentTmOption', value);
    },

    installmentAvailable(newVal: boolean, oldVal: boolean): void {
      if (!newVal === oldVal) {
        this.installmentPeriod = 1;
      }
    },

    showRecurringOption(newVal: boolean, oldVal: boolean): void {
      if (!newVal === oldVal) {
        this.recurringStatus = false;
      }
    },

    selectedTeamsTotalAmount(newAmount: number) {
      const amount = newAmount / 100;
      const dAmount = this.donationAmount / 100;

      if (amount && amount > dAmount) {
        this.checkExchange(newAmount / 100);
      } else if (this.originAmount) {
        this.checkExchange(this.originAmount);
      }
    },

    donationCurrency: {
      handler(currency: AvailableCurrencyList) {
        if (currency.code.toLowerCase() === 'ils' && this.installmentConfig.available_tm_option) {
          this.installmentTmOption = true;
        } else {
          this.installmentTmOption = false;
        }
      },
      immediate: true,
    },
  },

  mounted() {
    const amount = Number(get('amount'));
    const currencyCode = get('currency');
    const forceRecurring = get('force_recurring');
    const presetInstallments = Number(get('preset_installments'));

    const currency = this.currencyList.find(
      (el: AvailableCurrencyList) => el.code === currencyCode.toLocaleUpperCase(),
    );

    if (forceRecurring) {
      const cb = this.recurringUiMode === 'checkbox';
      this.$store.commit(
        'setRecurringPeriod',
        cb ? this.recurringConfig.duration : Number(forceRecurring),
      );
    }

    if (presetInstallments) {
      this.$store.commit('setInstallmentPeriod', presetInstallments);
      this.$store.commit('setInstallmentStatus', presetInstallments > 1);
    }

    this.$nextTick(() => {
      if (amount) {
        this.checkExchange(amount);
        this.$store.commit('setOriginAmount', amount);
      }
      if (currency) this.currencySelect(currency);
    });

    this.setCustomStyle();
    this.setCustomScript();
  },

  methods: {
    parseStringToNumber(value: string): number {
      return Number(value.replace(/[^\d.]/g, ''));
    },

    setInstallmentPeriod(period: number) {
      if (this.installmentPeriod !== period) {
        const maxPeriod = this.installmentMaxTmOptionMonths;
        if (period > maxPeriod) this.installmentTmOption = false;

        this.$store.commit('setInstallmentPeriod', period);
        this.$store.commit('setInstallmentStatus', period > 1);
        if (!!period && this.recurringStatus) {
          this.$store.commit('setRecurringPeriod', 0);
        }
      }
    },

    setRecurringPeriod(period: number): void {
      if (this.selectedRecurringPeriod !== period) {
        this.$store.commit('setRecurringPeriod', period);
        if (this.installmentPeriod) {
          this.$store.commit('setInstallmentPeriod', 1);
          this.$store.commit('setInstallmentStatus', false);
        }
      }
    },

    formatter(num: number): string {
      return num.toFixed(0);
    },

    onlyNumber($event: any): void {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && ![46, 8]
        .includes(keyCode)) { // 46 is dot, 8 is Backspace
        $event.preventDefault();
      }
    },

    checkExchange(data: string | number) {
      const donationCurrency = this.donationCurrency.code.toLowerCase();
      const campaignCurrency = this.campaignCurrency.code.toLowerCase();
      const currentAmount = Number(data) || 0;

      if (currentAmount !== 0 && donationCurrency !== campaignCurrency) {
        const params = `?amount=${currentAmount}&from=${donationCurrency}&to=${campaignCurrency}`;
        axios
          .get<IXchangeResponse>(urls.getExchange + params)
          .then(response => {
            const { data } = response;
            const amountIn = data.data.attributes.amount_in;
            const amountOut = data.data.attributes.amount_out;

            this.$store.commit('setTotalAmount', amountOut);

            const rate = amountIn === 1 ? amountOut : amountOut / amountIn;

            this.$store.commit('setChangeRate', rate);
          })
          .catch(() => {
            this.$store.dispatch('addError', {
              title: 'Exchange service error!',
              text: 'Please try later',
            });
          });
      } else {
        this.$store.commit('setTotalAmount', currentAmount);
        this.$store.commit('setChangeRate', 0);
      }

      this.$store.commit('setDonationAmount', data === '' ? '' : currentAmount);
    },

    currencySelect(currency: any) {
      this.$store.commit('setDonationCurrency', { ...currency });
      this.$store.commit('setGiftAid', false);

      if (
        this.recurringConfig.currencies !== null
        && this.recurringConfig.currencies.indexOf(
          this.donationCurrency.code.toLowerCase(),
        ) === -1
      ) {
        this.$store.commit('setRecurringPeriod', 0);
      }
      this.checkExchange(
        this.donationAmount.toString() === ''
          ? ''
          : this.donationAmount,
      );
    },

    setCustomStyle() {
      if (this.customStyle) {
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');

        style.type = 'text/css';
        style.appendChild(document.createTextNode(this.customStyle));
        head.appendChild(style);
      }
    },

    setCustomScript(): void {
      if (this.customScript) {
        const scriptTryCatch = (script: string) => `try {${script}} catch(e) {console.warn('custom-script error: '+e)}`;
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.setAttribute('custome-script', 'true');
        script.appendChild(document.createTextNode(scriptTryCatch(this.customScript)));
        document.body.appendChild(script);
      }
    },

    getInstallmentMonthlyAmount(period: number): number {
      return Math.floor(this.donationAmount / 100 / period);
    },

    getAmoutFieldError(): string {
      const selectedCode = this.donationCurrency.code.toUpperCase();
      const campaignCurrencyCode = this.campaignCurrency.code.toUpperCase();
      const minDonationAmount = this.config.minimum_donation_amount;

      const error = (this as any).vErrors.items.find((i: any) => i.field === 'amount');

      if (error.rule === 'donationAmount') {
        return this.$t('donation.minimum_amount_is', {
          minInSelectedCurrecy: this.minAmount,
          selectedCurrencyCode: selectedCode,
          minInCampaignCurrecy: minDonationAmount[campaignCurrencyCode],
          campaignCurrencyCode,
        }) as string
      }

      return this.$t('donation.amount_required') as string;
    },
  },
});
export default class DonationAmount extends VueComponent {}
