
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '../../../models/IRootState';

const VueComponent = Vue.extend({
  props: {
    shouldValidate: Boolean,
  },

  data() {
    return {
      foundation: '',
      accountNumber: '',
    };
  },

  computed: {
    ...customMapState({
      paymentConfig: (state: IRootState) => state.donation.paymentConfig,
    }),

    foundations(): [string, string][] {
      const config = this.paymentConfig.attributes.configuration
      return Object.entries(config) || []
    },
  },

  watch: {
    foundation() {
      this.updateGatewayParams()
    },
    accountNumber() {
      this.updateGatewayParams()
    },
  },

  mounted() {
    [[this.foundation = '']] = this.foundations;
  },

  methods: {
    updateGatewayParams() {
      this.$store.commit(
        'setDonationIncludedGatewayParams',
        {
          fund: this.foundation,
          fund_account_number: this.accountNumber,
        },
      );
    },
  },
});
export default class broomFoundation extends VueComponent {}
