import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { IRootState } from '@/models/IRootState';
import { IDonationLevel } from '@/models/IDonationParams';
import { get } from '@/helpers/getUrlParameters';
import { LevelsState } from './models/levels';
import { IDonationRequest } from './models/donation';

const getPreselectObj = () => {
  const [prefix, amount, id] = get('donate_with_level').split('_') || []

  if (prefix && amount && id) {
    return {
      [id]: {
        count: 1,
      },
    }
  }

  return {}
}

const state: LevelsState = {
  slectedLevels: {
    ...getPreselectObj(),
  },
}

const mutations: MutationTree<LevelsState> = {
  setSlectedLevels(s, data) {
    s.slectedLevels = data
  },
}

const getters: GetterTree<LevelsState, IRootState> = {
  getSelectedLevel(s, g) {
    const ids = Object.keys(s.slectedLevels)

    if (ids.length === 1) {
      return g.getLevelByID[ids[0]]
    }

    return null
  },

  hasLevelsWithSameAmount(s, g, rootState) {
    const levels = rootState.donation.donationParams.data.attributes.config.donation_levels || []

    const amounts: number[] = []
    let res = false

    for (let i = 0; i < levels.length; i += 1) {
      const level = levels[i];

      if (amounts.includes(level.amount)) {
        res = true
        break
      } else {
        amounts.push(level.amount)
      }
    }

    return res;
  },

  getLevelByID(s, g, rootState) {
    const levels = rootState.donation.donationParams.data.attributes.config.donation_levels || []

    return levels.reduce(
      (res, lvl) => {
        res[lvl.id] = lvl
        return res;
      },
      {} as Record<number, IDonationLevel>,
    )
  },
}

const actions: ActionTree<LevelsState, IRootState> = {
  extendRequestPayloadWithLevels({ state }, payload: IDonationRequest) {
    const { data, included } = payload

    const { lvlIncluded, lvlRelData } = Object.entries(state.slectedLevels).reduce(
      (res, [id, data]) => {
        res.lvlIncluded.push({
          type: 'selected_level',
          id: String(id),
          attributes: {
            ...data,
          },
        })

        res.lvlRelData.push({
          id: String(id),
          type: 'selected_level',
        })

        return res;
      },
      {
        lvlIncluded: [] as any[],
        lvlRelData: [] as any[],
      },
    )

    included.push(...lvlIncluded)

    data.relationships.selected_levels = {
      data: lvlRelData,
    }

    return payload;
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
