
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { tipRecurrringFor } from '@/constants/';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  data() {
    return {
      selectedTip: 5,
      tipAmount: 5,
    };
  },

  computed: {
    ...customMapState({
      config: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.tip_config,
      category: (state: IRootState) => state.donation.donationParams.data
        .attributes.config.category?.name,
      donationAmount: (state: IRootState) => state.donation.donationData.attributes.amount / 100,
      recurringPeriod: (state: IRootState) => state.donation.donationData
        .attributes.recurring_period,
      installmentPeriod: (state: IRootState) => state.donation.donationData
        .attributes.installment_period,
      totalAmount: (state: IRootState) => state.donation.totalAmount,
      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
    }),

    show(): boolean {
      const isSupport = this.config.gateways?.includes(this.paymentMethod);
      const showRangePicker = this.config.range_bar?.value

      return this.config.available && isSupport && !showRangePicker;
    },

    title(): string {
      const { hesed } = this.config;
      const title = this.$t(`tip.title_${this.category}`) as string;

      if (hesed) {
        return this.$t('tip.title_hesed') as string;
      }
      if (!title.includes('tip.title_')) {
        return title;
      }

      return this.$t('tip.title') as string;
    },

    options(): {text: string, value: number}[] {
      const { options, thresholds } = this.config;
      const tp = thresholds?.map(el => el.percent) || [];

      const list = (options?.length > 0
        ? [...new Set([...options, ...tp])]
        : [...new Set([5, 10, 15, 20, ...tp])]).sort((a, b) => a - b);

      list.push(-1);

      return list.map(el => ({
        text: this.getText(el),
        value: el,
      }))
    },

    percent(): number {
      const isExist = this.options.find(e => e.value === this.config.percent);
      return (isExist && this.config.percent) || this.options[0].value;
    },

    amount(): number {
      if (this.config.amount >= 0) {
        return this.config.amount;
      }

      return 0;
    },

    presetOther(): boolean {
      if (this.config.preset_other) {
        return this.config.preset_other;
      }

      return false;
    },

    defaultOtherAmount(): number {
      return this.config.default_other_amount || 0;
    },

    totalCharge(): number {
      if (this.selectedTip !== -1) {
        return (
          this.donationAmount + this.tipAmoun(this.selectedTip)
        );
      }

      return +this.tipAmount + this.donationAmount;
    },

    totalChargeSuffix(): string {
      if (!tipRecurrringFor.includes(this.paymentMethod)) {
        return ''
      }
      if (this.recurringPeriod > 1) {
        return this.$t('donations.in_month', 'in month') as string;
      }
      return ''
    },
  },

  watch: {
    show(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.$store.commit('setTip', 0);
      }
    },
    paymentMethod() {
      if (!this.show) return;
      const amount = this.round(this.totalCharge - this.donationAmount);
      this.$store.commit('setTip', amount * 100);
    },
    donationAmount(value) {
      const { thresholds } = this.config;
      const threshold = thresholds?.find(el => {
        if (el.from <= value && el.to >= value) {
          return true;
        }
        return false;
      })
      this.selectedTip = threshold?.percent || this.selectedTip;
    },
    totalCharge(value) {
      if (!this.show) return;
      const amount = this.round(value - this.donationAmount);
      this.$store.commit('setTip', amount * 100);
    },
  },

  mounted() {
    this.selectedTip = this.percent;

    if (this.presetOther) {
      this.tipAmount = this.amount;
      this.selectedTip = -1;
    }

    if (!this.presetOther) {
      this.tipAmount = this.defaultOtherAmount;
    }
  },

  methods: {
    getText(item: number) {
      if (item === -1) {
        return this.$t('tip.other') as string
      }

      return this.$t(
        'tip.select_opt_text',
        {
          percent: item,
          currencySign: this.donationCurrency.sign,
          amount: this.tipAmoun(item),
        },
      ) as string
    },
    tipAmoun(n: number) {
      const amt = this.donationAmount * (n / 100);
      return this.round(amt);
    },
    round(amt: number) {
      if (this.config?.amount_with_fraction) {
        return Number(amt.toFixed(this.config?.fraction_precision));
      }
      return Math.round(amt);
    },
  },
});
export default class TipBlock extends VueComponent {}
