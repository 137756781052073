
import Vue from 'vue';

import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import cardNumberInput from '../../shared/card-number-input/card-number-input.vue';
import cardExpDate from '../../shared/card-exp-date/card-exp-date.vue';

const VueComponent = Vue.extend({
  components: {
    cardNumberInput,
    cardExpDate,
  },

  inject: ['$validator'],

  data() {
    return {
      expDate: {
        month: '',
        year: '',
      },
    }
  },

  computed: {
    ...customMapState({
      num: (s: IRootState) => s.payrix.number,
      cvv: (s: IRootState) => s.payrix.cvv,
    }),

    number: {
      get(): string {
        return this.num
      },
      set(val: string) {
        this.$store.commit('setPayrixData', { key: 'number', value: val.replace(/ /g, '') })
      },
    },

    cccvv: {
      get(): string {
        return this.cvv
      },
      set(val: string) {
        this.$store.commit('setPayrixData', { key: 'cvv', value: val })
      },
    },

    expiration(): string {
      return `${this.expDate.month}${this.expDate.year}`
    },
  },

  watch: {
    expiration(val) {
      this.$store.commit('setPayrixData', { key: 'expiration', value: val })
    },
  },
});
export default class Blackbaud extends VueComponent {}
