export const checksum = (s: string): number => {
  let hash = 0;
  const strlen = s.length;

  if (strlen === 0) { return hash; }

  for (let i = 0; i < strlen; i += 1) {
    const c = s.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + c;
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export const generateDeviceId = () => {
  const nav = window.navigator;
  const { screen } = window;
  const deviceId = [
    nav.mimeTypes.length.toString(),
    ...Object.values(navigator.mimeTypes).map(p => p.type),
    nav.userAgent.replace(/\D+/g, ''),
    nav.plugins.length.toString(),
    ...Object.values(navigator.plugins).map(p => p.filename),
    `${screen.height || ''}`,
    `${screen.width || ''}`,
    `${screen.pixelDepth || ''}`,
  ];
  return checksum(deviceId.join(''));
};
