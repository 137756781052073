import {
  GetterTree,
} from 'vuex';
import { IRootState } from '@/models/IRootState';
import { OrgLangs } from './models/org-langs';

const state: OrgLangs = {};

const getters: GetterTree<OrgLangs, IRootState> = {
  orgNameByLang(state, _, rootState) {
    const langs = rootState.donation.donationParams.data.attributes.config.organization.org_name_languages
    const lang = langs.find(
      el => el.code === rootState.i18n.locale,
    );

    if (lang?.value) {
      return lang.value;
    }

    const { orgname } = rootState.donation.donationParams.data.attributes.config.organization;

    return orgname;
  },
}

export default {
  state,
  getters,
}
