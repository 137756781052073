
import Vue from 'vue'
import { mapActions } from 'vuex'
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import CantMakePaymentAlert from '../CantMakePaymentAlert.vue';

export default Vue.extend({
  components: { CantMakePaymentAlert },

  computed: {
    ...customMapState({
      paymentMethod: (s: IRootState) => s.donation.paymentMethod,
    }),
  },

  watch: {
    paymentMethod: {
      handler() {
        this.checkIfCanMakePayment();
      },
      immediate: true,
    },
  },

  mounted() {
    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      this.$store.commit(
        'setDonationIncludedGatewayParams',
        {
          stripe_idempotency_key: this.$nanoid(),
        },
      );
      resolve(false);
    });
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    ...mapActions({
      checkIfCanMakePayment: 'checkIfCanMakePayment',
    }),
  },
})
