import { IRootState } from '@/models/IRootState';

export default function replaceVarsInGatewayFields(value: string, rootState: IRootState) {
  let parsedString: string = value;

  const replacements: {[key: string]: string } = {
    '{{.DonationID}}': rootState.donation.donationId.toString(),
    '{{.CampaignID}}': rootState.donation.campaign.data.attributes.campaign_id.toString(),
    '{{.CampaignName}}': rootState.donation.campaign.data.attributes.title,
    '{{.TeamID}}': rootState.donation.first_selected_team?.id?.toString() || '',
    '{{.TeamName}}': rootState.donation.first_selected_team?.name || '',
    '{{.ParentTeamID}}': rootState.donation.first_selected_team?.parent_team_id || '',
  };

  Object.keys(replacements).forEach(key => {
    const searchKey = key;
    const replacementValue = replacements[key];

    parsedString = parsedString.split(searchKey).join(replacementValue);
  });

  return parsedString
}
