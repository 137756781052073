import urls from '@/constants/urls';
import axios from 'axios';
import { reactive } from 'vue';

type ResolveVal = { orderID: string; error: string; }

interface State {
  resolve: ((value: ResolveVal | PromiseLike<ResolveVal>) => void) | null;
  donationID: string | null;
}

const state = reactive<State>({
  resolve: null,
  donationID: null,
})

export const usePaypalV2 = () => {
  function initPromise(cb: () => void) {
    return new Promise<ResolveVal>(resolve => {
      state.resolve = resolve;
      cb()
    })
  }

  function getResolve() {
    return state.resolve
  }

  function setDonationID(id: string) {
    state.donationID = id
  }

  function getDonationID() {
    return state.donationID
  }

  const statusUpdate = (did: string, orderID: string, params: any) => axios
    .post(urls.statusUpdatePaypalV2(did, orderID), null, { params })

  return {
    state,
    initPromise,
    getResolve,
    statusUpdate,
    setDonationID,
    getDonationID,
  }
}

export default { usePaypalV2 }
