
import Vue from 'vue';

import { IRootState } from '@/models/IRootState';
import customMapState from '@/helpers/mapHelper';
import axios from 'axios';
import cardNumberInput from '../../shared/card-number-input/card-number-input.vue';
import cardExpDate from '../../shared/card-exp-date/card-exp-date.vue';

const VueComponent = Vue.extend({
  components: {
    cardNumberInput,
    cardExpDate,
  },

  inject: ['$validator'],

  data() {
    return {
      cardNo: '',
      expDate: {
        month: '',
        year: '',
      },
      cvv: '',
    }
  },

  computed: {
    ...customMapState({
      donationData: (state: IRootState) => state.donation.donationData.attributes,
      pKey: (state: IRootState) => state.donation.paymentConfig.attributes.configuration.public_key,
    }),
  },

  mounted() {
    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      this.tokenize()
        .then(token => {
          this.$store.commit(
            'setDonationIncludedGatewayParams',
            { walletdoc_card_token: token.id },
          );
          resolve(false);
        })
        .catch(res => {
          if (res?.response?.data) {
            const { data } = res?.response
            resolve(`Tokenize error: ${data.error.message}`);
          } else {
            resolve(`Tokenize error: ${(res as Error).message}`);
          }
        })
    });

    this.$root.$on(
      'walletDocCreateAndSetToken',
      (resolve: (value?: unknown) => void) => {
        this.tokenize()
          .then(token => {
            this.$store.commit(
              'setDonationIncludedGatewayParams',
              { walletdoc_card_token: token.id },
            );
            resolve(true);
          })
          .catch(res => {
            if (res?.response?.data) {
              const { data } = res?.response
              this.$store.commit(
                'setError',
                {
                  title: 'WalletDoc Error!',
                  text: `Tokenize error: ${data.error.message}`,
                },
              )
              resolve(false);
            } else {
              this.$store.commit(
                'setError',
                {
                  title: 'WalletDoc Error!',
                  text: `Tokenize error: ${(res as Error).message}`,
                },
              )
              resolve(false);
            }
          })
      },
    );
  },

  beforeDestroy() {
    this.$root.$off('walletDocCreateAndSetToken');
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    tokenize() {
      const { billing_first_name: bfn, billing_last_name: bln, receipt_name: rn } = this.donationData;
      const name = rn || this.donationData.billing_name || `${bfn} ${bln}`;

      const payload = {
        card: {
          nickname: 'main',
          cardholder: name,
          number: this.cardNo.replace(/ /g, ''),
          expiry_month: this.expDate.month,
          expiry_year: this.expDate.year,
          cvv: this.cvv,
        },
      }

      return axios
        .post('https://www.walletdoc.com/v1/tokens', payload, {
          headers: {
            Authorization: `Basic ${this.pKey}`,
          },
        })
        .then(({ data }) => data)
    },
  },
});
export default class Blackbaud extends VueComponent {}
