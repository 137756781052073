
import Vue from 'vue';

const VueComponent = Vue.extend({
  props: {
    light: Boolean,
    triggerClass: { type: String, default: '' },
  },
});

export default class HelpTip extends VueComponent {}
