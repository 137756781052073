import { render, staticRenderFns } from "./BelzWidget.vue?vue&type=template&id=528f357b&scoped=true&"
import script from "./BelzWidget.vue?vue&type=script&lang=ts&"
export * from "./BelzWidget.vue?vue&type=script&lang=ts&"
import style0 from "./BelzWidget.vue?vue&type=style&index=0&id=528f357b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "528f357b",
  null
  
)

export default component.exports