
import Vue from 'vue';

import { IOJCGatewayParams } from './payments-models/OJC.model';

import cardNumberInput from '../../shared/card-number-input/card-number-input.vue';
import cardExpDate from '../../shared/card-exp-date/card-exp-date.vue';

const VueComponent = Vue.extend({
  components: {
    cardNumberInput,
    cardExpDate,
  },

  inject: ['$validator'],

  data() {
    return {
      ojcCardNo: '',
      expDate: {
        month: '',
        year: '',
      },
    }
  },

  computed: {
    gatewayParams(): IOJCGatewayParams {
      return {
        ojc_card_no: this.ojcCardNo.replace(/ /g, ''),
        ojc_exp_date: Object.values(this.expDate).join(''),
      };
    },
  },

  watch: {
    gatewayParams() {
      this.setParams();
    },
  },

  methods: {
    setParams() {
      this.$store.commit('setDonationIncludedGatewayParams', this.gatewayParams);
    },
  },
});
export default class OJC extends VueComponent {}
