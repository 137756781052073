export const logger = {
  logMakeDonationCatchError(err: any, ...data: any) {
    // eslint-disable-next-line no-console
    console.log(err, JSON.stringify(err), ...data);
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(err));
    // eslint-disable-next-line no-console
    console.log(...data);
  },
}

export default { logger }
