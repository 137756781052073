
import Vue from 'vue';
import customMapState from '../../helpers/mapHelper';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  computed: {
    ...customMapState({
      campaign: (state: IRootState) => state.donation.campaign.data,
    }),

    show(): boolean {
      return Boolean(this.campaign.attributes.givingday_id);
    },

    origin(): string {
      return 'https://charidy.com'
    },

    title(): string {
      return this.campaign.relationships.givingday_stats.data.title;
    },

    shortlink(): string {
      return this.campaign.relationships.givingday_stats.data?.shortlink;
    },
  },
});

export default class PartOfGivingDayMsg extends VueComponent {}
