import { IDonationParams } from '@/models/IDonationParams';
import { get } from './getUrlParameters';
import { getNumber } from './validator';

export const reMapConfig = ({ data }: IDonationParams):IDonationParams => {
  const { recurring_config, installment_config } = data.attributes.config;

  const forceRecurring = getNumber(get('force_recurring'));
  const presetInstallments = getNumber(get('preset_installments'));
  const recurringInterval = get('recurring_interval');

  if (recurringInterval && recurringInterval !== recurring_config.period) {
    recurring_config.period = recurringInterval
  }

  if (
    installment_config.available
      && recurring_config.force_recurring
      && forceRecurring
  ) {
    installment_config.available = false;
    recurring_config.available = true
  }

  if (
    recurring_config.available
      && recurring_config.force_recurring
      && presetInstallments
  ) {
    installment_config.available = true;
    recurring_config.available = false
  }

  // if ui mode is 'chaeckbox' and url has 'force_recurring' parameter set
  // then set value from 'force_recurring' to duration
  if (
    recurring_config.available
      && (recurring_config.ui_mode === '' || recurring_config.ui_mode === 'checkbox')
      && forceRecurring
  ) {
    recurring_config.duration = Number(forceRecurring);
  }

  return { data }
}

export default { reMapConfig }
