import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { IRootState } from '@/models/IRootState';
import { range } from '@/helpers/arrayHelper';
import { DurationByAmountThresholdItem } from '@/models/IDonationParams';
import { get } from '@/helpers/getUrlParameters';
import { getNumber } from '@/helpers/validator';
import { RecurringInstallmentState } from './models/recurring-installment';

const getDurationByAmountThreshold = (amountMinDenom: number, items: DurationByAmountThresholdItem[]): number[] => {
  const amt = amountMinDenom / 100

  if (!amt) {
    return []
  }

  const item = items.find(el => {
    switch (el.condition) {
      case 'above':
        if (el.amount < amt) {
          return true
        }
        return false;

      case 'above_equal':
        if (el.amount <= amt) {
          return true
        }
        return false;

      case 'below':
        if (el.amount > amt) {
          return true
        }
        return false;

      case 'below_equal':
        if (el.amount >= amt) {
          return true
        }
        return false;

      default:
        return false;
    }
  })

  if (item?.duration.custom_range?.length) {
    return item.duration.custom_range
  }

  if (item && item?.duration.from <= item?.duration.to) {
    return range(item.duration.from, item.duration.to);
  }

  return []
}

const state: RecurringInstallmentState = {
}

const mutations: MutationTree<RecurringInstallmentState> = {
}

const getters: GetterTree<RecurringInstallmentState, IRootState> = {
  zeroRecurringActive(s, g, rs) {
    const config = rs.donation.donationParams.data.attributes.config.recurring_config
    const { zero_recurring } = config;
    return zero_recurring?.includes(rs.donation.paymentMethod) || false
  },

  recurringDuration(s, g, rs) {
    const { amount } = rs.donation.donationData.attributes
    const config = rs.donation.donationParams.data.attributes.config.recurring_config
    const {
      recurring_max,
      recurring_min,
      duration,
      available_periods: ap,
      duration_by_amount_threshold,
      ui_mode,
      period,
    } = config;

    const isCheckbox = ['', 'checkbox'].includes(ui_mode)

    const min = recurring_min > 1 ? recurring_min : 2;

    const extend = (r: number[]) => {
      const forceRecurring = getNumber(get('force_recurring'));

      if (forceRecurring && period === 'year') {
        r.push(forceRecurring)
      }

      return r
    }

    if (duration_by_amount_threshold) {
      const range = getDurationByAmountThreshold(amount, duration_by_amount_threshold)

      if (range.length) {
        return extend(range)
      }
    }

    if (ap && ap.length && !isCheckbox) {
      return extend(ap.map((e: string) => Number(e)))
    }

    if (recurring_max) {
      return extend(range(min, recurring_max));
    }

    if (duration < 2) {
      return []
    }

    return extend(range(2, duration));
  },

  installmentAvailablePeriods(s, g, rs) {
    const { amount } = rs.donation.donationData.attributes
    const config = rs.donation.donationParams.data.attributes.config.installment_config
    const { available_periods: ap, duration_by_amount_threshold } = config;

    if (duration_by_amount_threshold) {
      const range = getDurationByAmountThreshold(amount, duration_by_amount_threshold)

      if (range.length) {
        return range
      }
    }

    return ap
  },

  installmentMaxTmOptionMonths(s, g, rs) {
    const { id: pmid, attributes: pmattrs } = rs.donation.paymentConfig
    const pmname = pmattrs?.name;
    const config = rs.donation.donationParams.data.attributes.config.installment_config

    const cfg = config.configs_for_gateways?.find(cfg => cfg.gateways.includes(pmid) || cfg.gateways.includes(pmname))
    if (cfg) {
      return cfg.max_tm_option_months || 12
    }

    return config.max_tm_option_months || 12
  },
}

const actions: ActionTree<RecurringInstallmentState, IRootState> = {
}

export default {
  state,
  mutations,
  getters,
  actions,
}
