
import Vue from 'vue';

import { IRootState } from '@/models/IRootState';
import customMapState from '@/helpers/mapHelper';

const VueComponent = Vue.extend({

  data() {
    return {
      cardFormRef: null as any,

      cardToken: '',
      expiryMonth: 0,
      expiryYear: 0,

      error: null as any,
    }
  },

  computed: {
    ...customMapState({
      paymentConfig: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
    }),

    sandbox(): boolean {
      return this.paymentConfig.env === 'sandbox'
    },
  },

  mounted() {
    const src = `https://tokenization.${this.sandbox ? 'sandbox.' : ''}banquestgateway.com/tokenization/v0.2`

    const exist = document.getElementById('banquestgateway-script')
    if (exist) {
      this.init()
    } else {
      const scriptEl = document.createElement('script');
      scriptEl.id = 'banquestgateway-script'
      scriptEl.setAttribute('src', src);
      document.head.appendChild(scriptEl);

      scriptEl.addEventListener('load', () => {
        this.init()
      });
    }

    this.$root.$on('setDonationGatewayParams', async (resolve: (value?: unknown) => void) => {
      if (this.error) {
        resolve(this.error);
        return
      }

      resolve(false);
    });

    this.$root.$on('banquestCreateAndSetToken', this.createAndSetToken);
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
    this.$root.$off('banquestCreateAndSetToken');
  },

  methods: {
    init(): any {
      if (!this.paymentConfig.public_key) {
        return
      }

      try {
        const hostedTokenization = new window.HostedTokenization(this.paymentConfig.public_key);
        const cardForm = hostedTokenization
          .create('card-form')
          .mount(this.$refs.cardContainer)
          .on('input', this.onEvent)
          .on('change', this.onEvent);

        cardForm.setStyles({
          container: 'padding: 6px 12px;',
          card: 'padding:0;height: 30px;font-size: 14px;width: calc(100% - 160px);',
          expiryMonth: 'padding:0;height: 30px;font-size: 14px;',
          expiryYear: 'padding:0;height: 30px;font-size: 14px;',
          cvv2: 'padding:0;height: 30px;font-size: 14px;width: 40px;',
        });

        this.cardFormRef = cardForm
      } catch (error) {
        this.error = (error as Error).message
      }
    },

    onEvent({ error, result }: any) {
      if (error) {
        this.error = error
        return
      }
      this.error = null

      this.expiryMonth = result.expiryMonth
      this.expiryYear = result.expiryYear
    },

    async createAndSetToken(next: (value: unknown) => void) {
      const process = () => {
        this.$store.commit(
          'setDonationIncludedGatewayParams',
          {
            banquest_card_token: `nonce-${this.cardToken}`,
            banquest_expiry_month: this.expiryMonth,
            banquest_expiry_year: this.expiryYear,
          },
        );
        next(true);
      }

      this.cardFormRef.getNonceToken()
        .then((res:any) => {
          this.cardToken = res.nonce
          process()
        })
        .catch((e: Error) => {
          this.$store.commit('setError', {
            title: 'Create Token Error!',
            text: e.message,
          });
          next(false);
        })
    },
  },
});
export default class BanquestGateway extends VueComponent {}
