
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import { IError } from '@/store/modules/models/donation';

const VueComponent = Vue.extend({
  computed: {
    ...customMapState({
      error: (state: IRootState) => state.donation.error,
      stepFlag: (state: IRootState) => state.donation.stepFlag,
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
    }),

    hideCloseBtn() {
      return this.error?.hideCloseBtn;
    },

    msg(): string {
      if (this.error?.translationKey) {
        const args = this.error.translationKey;
        return this.$t(...args) as string;
      }
      return ''
    },
  },
  watch: {
    error(val: IError): void {
      this.$nextTick(() => {
        if (val && this.$refs.error) { (this.$refs.error as Element).scrollIntoView(); }
      });
    },
    stepFlag(val): void {
      if (val > 1) {
        this.$store.dispatch('clearError');
      }
    },
  },
})
export default class ErrorBlock extends VueComponent {}
