
import Vue from 'vue';

import { IRootState } from '@/models/IRootState';
import customMapState from '@/helpers/mapHelper';

const VueComponent = Vue.extend({

  data() {
    return {
      routingNumber: '',
      accountNumber: '',
    }
  },

  computed: {
    ...customMapState({
      paymentConfig: (state: IRootState) => state.donation.paymentConfig.attributes.configuration,
    }),

    sandbox(): boolean {
      return this.paymentConfig.env === 'sandbox'
    },
  },

  mounted() {
    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      this.$store.commit(
        'setDonationIncludedGatewayParams',
        {
          banquest_routing_number: this.routingNumber,
          banquest_account_number: this.accountNumber,
        },
      );
      resolve(false);
    });
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },
});
export default class BanquestGateway extends VueComponent {}
