<template>
  <v-container fluid>
    <v-combobox
      :value="selected"
      :items="tags"
      :search-input="search"
      :delimiters="[',']"
      :allow-overflow="false"
      hide-selected
      :label="label"
      multiple
      small-chips
      deletable-chips
      clearable
      solo
      prepend-inner-icon="mdi-tag-multiple-outline"
      v-bind="$attrs"
      @input="e => $emit('update:selected', e)"
      @update:search-input="$emit('input', $event)"
    >
      <template #no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Press <kbd>enter</kbd> to create new tag
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #selection="{ attrs, item, parent, selected: templateSelected }">
        <v-chip
          v-bind="attrs"
          :input-value="templateSelected"
          label
          small
          color="primary"
        >
          <span class="pr-2">
            {{ item }}
          </span>
          <v-icon
            small
            @click="parent.selectItem(item)"
          >
            mdi-close
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>
  </v-container>
</template>

<script lang="js">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
})
</script>
