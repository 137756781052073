import { IRootState } from '@/models/IRootState';
import { ActionTree, MutationTree } from 'vuex';
import { IDonationRequest } from './models/donation';

interface CustomDataState {
  fields: {
    [key: string]: {
      type: 'string' | 'number'
      value: any
    }
  }
}

const typeToFieldname = {
  string: 'val_str',
  number: 'val_int',
}

const state: CustomDataState = {
  fields: {},
}

const mutations: MutationTree<CustomDataState> = {
  setCustomDataField(state, { key, type, value }) {
    state.fields[key] = { type, value };
  },
  clearFeilds(state) {
    state.fields = {}
  },
}

const actions: ActionTree<CustomDataState, IRootState> = {
  extendCustomDataInPayload({ state, commit }, payload: IDonationRequest) {
    const { data, included } = payload

    if (state.fields.upsell_type) {
      const removeUpsell = () => {
        delete state.fields.upsell_type
        delete state.fields.upsell_amount
        delete state.fields.upsell_duration
      }

      switch (state.fields.upsell_type.value) {
        case 'installments':
          if (!data.attributes.installment) {
            removeUpsell()
          }
          break;

        case 'recurring':
          if (!data.attributes.recurring) {
            removeUpsell()
          }
          break;

        default:
          break;
      }
    }

    const { cdIncluded, cdRelData } = Object.entries(state.fields).reduce(
      (res, [key, data], i) => {
        res.cdIncluded.push({
          type: 'custom_data',
          id: String(included.length + i),
          attributes: {
            type: data.type,
            key,
            [typeToFieldname[data.type]]: data.value,
          },
        })

        res.cdRelData.push({
          id: String(included.length + i),
          type: 'custom_data',
        })

        return res;
      },
      {
        cdIncluded: [] as any[],
        cdRelData: [] as any[],
      },
    )

    included.push(...cdIncluded)

    data.relationships.custom_data = {
      data: [...data.relationships?.custom_data?.data || [], ...cdRelData],
    }

    commit('clearFeilds')

    return payload;
  },
}

export default {
  state,
  mutations,
  actions,
}
