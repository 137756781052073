import { ActionTree, MutationTree } from 'vuex';
import { IRootState } from '@/models/IRootState';
import { UsaepayState } from './models/usaepay';

const state: UsaepayState = {
  paymentSubmitFormID: '',
  paymentCard: null,
  client: null,
}

const mutations: MutationTree<UsaepayState> = {
  setUsaepaySubmitFormID(state, value) {
    state.paymentSubmitFormID = value;
  },

  setUsaepayclient(state, value) {
    state.client = value;
  },

  setUsaepayPaymentCard(state, value) {
    state.paymentCard = value;
  },
}

const actions: ActionTree<UsaepayState, IRootState> = {
  handleUsaepaySubmit({
    state,
  }) {
    document.querySelector<HTMLFormElement>(`.${state.paymentSubmitFormID}`)?.submit()

    return state.client
      .getPaymentKey(state.paymentCard)
  },
}

export default {
  state,
  mutations,
  actions,
}
