export const useExternalScript = (src: string, id?: string) => {
  if (src === '') {
    return null
  }

  let idLoc = id || ''
  if (!id) {
    idLoc = btoa(src)
  }

  let onLoadCallbackFunc = () => {}

  const script = {
    get element() {
      return <HTMLScriptElement>document.getElementById(idLoc)
    },
    get exist() {
      return Boolean(this.element)
    },
  }

  const load = () => {
    if (script.exist) {
      onLoadCallbackFunc()
    } else {
      const scriptTag = document.createElement('script');
      scriptTag.id = idLoc
      scriptTag.setAttribute('type', 'text/javascript')
      scriptTag.setAttribute('src', src);

      document.getElementsByTagName('head')[0].appendChild(scriptTag)
      document.head.appendChild(scriptTag);

      scriptTag.addEventListener('load', () => {
        onLoadCallbackFunc()
      });
    }
  }

  const remove = () => {
    if (script.exist) {
      script.element.remove()
      return true
    }
    return false
  }

  return {
    get elementID() {
      return idLoc
    },
    get exist() {
      return script.exist
    },
    load,
    onLoad(cb:()=>void) {
      onLoadCallbackFunc = cb
    },
    remove,
  }
}

export default { useExternalScript }
