
import Vue from 'vue';
import axios from 'axios';
import { mapActions } from 'vuex';
import urls from '../../constants/urls';
import customMapState from '../../helpers/mapHelper';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      signupForm: {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
      },
      error: {
        title: '',
        text: '',
      },
      signupSuccess: false,
      signupLoading: false,
    }
  },

  computed: {
    ...customMapState({
      donorAccount: (state: IRootState) => state.donation.donorAccount,
      showSignup: (state: IRootState) => state.donation.donationParams.data.attributes.config
        .create_account_config.available,
      donationDataAttr: (state: IRootState) => state.donation.donationData.attributes,
    }),
  },

  watch: {
    donationDataAttr: {
      handler() {
        const {
          billing_first_name,
          billing_last_name,
          billing_name,
          email,
        } = this.donationDataAttr;
        const [first, last] = billing_name.split(' ');

        this.signupForm.email = email;
        this.signupForm.first_name = billing_first_name || first;
        this.signupForm.last_name = billing_last_name || last;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      getStripePaymentMethods: 'getStripePaymentMethods',
      getDonorAccount: 'getDonorAccount',
    }),

    onSubmit() {
      this.resetError();
      axios.post(urls.logIn, this.form).then(({ data }) => {
        const { jwt_token } = data.data.attributes;
        document.cookie = `jwt_token=${jwt_token}; domain=.charidy.com; path=/`;
        this.$store.commit('setAuthorizationToken', jwt_token);
        this.getStripePaymentMethods(jwt_token);
        this.getDonorAccount(jwt_token);
        // @ts-ignore
        $('#singIn').modal('hide');
      }).catch(({ response }) => {
        this.error = {
          title: response?.statusText,
          text: response?.data?.error,
        };
      });
    },

    createAccount(): void {
      this.resetError();

      const {
        password, password_confirmation, email, first_name, last_name,
      } = this.signupForm

      if (
        password.length === 0
          || password_confirmation.length === 0
          || password !== password_confirmation
      ) {
        return
      }

      const data = {
        email,
        password,
        first_name,
        last_name,
        generate_token: true,
      }

      this.signupLoading = true;

      axios
        .post(urls.registerUser, data)
        .then(res => {
          const { token } = res.data.data.attributes.jwt_token
          this.signupForm = {
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            password_confirmation: '',
          }
          this.signupSuccess = true;
          this.signupLoading = false;
          this.$store.commit('setAuthorizationToken', token);
          this.getDonorAccount(token);
        }).catch(({ response }) => {
          this.error = {
            title: response?.statusText,
            text: response?.data?.error,
          };
          this.signupLoading = false;
        })
    },

    signOut() {
      document.cookie = 'jwt_token=; domain=.charidy.com; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      this.$store.commit('setAuthorizationToken', '');
      this.$store.commit('setStripePaymentMethods', []);
      this.$store.commit('setDonorAccount', null);
      this.$store.commit('setDefaultDonationData');
      this.resetError();
    },

    resetError() {
      this.error = {
        title: '',
        text: '',
      }
    },
  },
});
export default class LoginPanel extends VueComponent {}
