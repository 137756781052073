export default [
  'stripe',
  'stripe-ach',
  'broomfoundation',
  'asserbishvil',
  'yaadpay',
  'israeltoremet',
  'paypal',
  'paypalv2',
  'blackbaud',
  'stripe-sepa-direct-debit',
  'checkout-fi',
  'ojc',
  'matbia',
  'bancontact',
  'pledgeonly',
  'achisomoch',
  'fund',
  'hk-bank-transfer',
  'stripe-ideal',
  'stripe-becs',
  'paygate',
  'meshulam',
  'meshulam-bit',
  'meshulam-v2',
  'meshulam-v2-bit',
  'meshulam-google-pay',
  'mhtrust',
  'zoreyatzedokos',
  'yadshlomo',
  'belzvouchers',
  'uta',
  'cmz',
  'stripe-apple-pay',
  'stripe-google-pay',
  'threepillars',
  'kolyom',
  'dlocal',
  'forward',
  'payrix',
  'nedarim-plus-native',
  'nedarim-plus-native-bit',
  'payfast',
  'mercadopago',
  'mercadopago-direct',
  'tranzila',
  'tranzila-bit',
  'walletdoc',
  'walletdoc-direct',
  'authorize',
  'pelecard',
  'coinbase',
  'cardcom',
  'cardcom-bit',
  'icredit-rivhit',
  'jaffa',
  'jaffa-bit',
  'blink-fidelipay',
  'blink-fidelipay-direct',
  'aminut',
  'cardknox',
  'braintree',
  'kehilot',
  'kehilot-bit',
  'donorsfund',
  'pledger',
  'mancal',
  'mancal-bit',
  'usaepay',
  'banquest',
  'banquest-ach',
  'stripe-element',
  'stripe-terminal',
  'kcbobov',
  'cardknox-google-apple-pay',
  'gama',
  'gama-bit',
  'tevini',
  'peach',
  'peach-bit',
  'powerdoc-direct-debit',
  'sumit',
  'chariot',
  'payarc',
  // 'stripe-klarna',
];
