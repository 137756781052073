
import Vue from 'vue';
import { mapActions } from 'vuex';
import customMapState from '../../../helpers/mapHelper';
import { IRootState } from '../../../models/IRootState';

const style = {
  base: {
    fontSize: '14px',
    '::placeholder': {
      color: '#CFD7DF',
    },
    ':-webkit-autofill': {
      color: '#e39f48',
    },
  },
  invalid: {
    iconColor: '#E25950',
    color: '#E25950',
    '::placeholder': {
      color: '#FFCCA5',
    },
  },
};

const options = {
  style,
  disabled: false,
  hideIcon: false,
  iconStyle: 'solid',
}

const VueComponent = Vue.extend({
  data() {
    return {
      showError: false,
      bankName: '',
      branchName: '',
      validationState: {
        empty: true,
        complete: false,
      },
      errorMessage: '',
    }
  },

  computed: {
    ...customMapState({
      donateAttrs: (state: IRootState) => state.donation.donationData.attributes,
      stripe: (state: IRootState) => state.stripe.stripe,
    }),

    mandateAcceptanceFallbackText() {
      return `By providing your bank account details and confirming this payment,
        you agree to this Direct Debit Request and the
        <a href="stripe.com/au-becs-dd-service-agreement/legal">Direct Debit Request service agreement</a>,
        and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343
        Direct Debit User ID number 507156 (“Stripe”) to debit your account
        through the Bulk Electronic Clearing System (BECS) on behalf of
        Rocket Rides (the "Merchant") for any amounts separately
        communicated to you by the Merchant. You certify that you are either
        an account holder or an authorised signatory on the account listed above.`
    },
  },

  mounted() {
    this.useStripe();

    const elements = this.stripe.elements();
    const auBankAccount = elements.create('auBankAccount', options);

    auBankAccount.mount('#au-bank-account-element');

    auBankAccount.on('change', (event: any) => {
      this.showError = false;
      this.validationState = {
        empty: event.empty,
        complete: event.complete,
      }
      this.bankName = event.bankName;
      this.branchName = event.branchName;
      if (event.error) {
        this.errorMessage = event.error.message;
      } else {
        this.errorMessage = '';
      }
    });

    this.setElements({
      auBankAccount,
    });

    this.$root.$on('setDonationGatewayParams', (resolve: (value?: unknown) => void) => {
      const { empty, complete } = this.validationState;
      if (empty || !complete) {
        const sufix = (empty && 'empty') || (!complete && 'incomplete')
        resolve({
          title: 'Form Error!',
          text: this.$t(`donation.stripe_becs_bank_account_is_${sufix}`),
        });
        this.showError = empty;
        return;
      }

      resolve(false)
    });
  },

  beforeDestroy() {
    this.$root.$off('setDonationGatewayParams');
  },

  methods: {
    ...mapActions({
      useStripe: 'useStripe',
      setElements: 'setElements',
    }),
  },
});
export default class StripeiDEAL extends VueComponent {}
