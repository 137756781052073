var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.showSmsReceipt)?_c('div',{staticClass:"form-group"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.smsReceipt),expression:"smsReceipt"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.smsReceipt)?_vm._i(_vm.smsReceipt,null)>-1:(_vm.smsReceipt)},on:{"change":function($event){var $$a=_vm.smsReceipt,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.smsReceipt=$$a.concat([$$v]))}else{$$i>-1&&(_vm.smsReceipt=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.smsReceipt=$$c}}}}),_vm._v(" "+_vm._s(_vm.$t('donation.show_sms_receipt_option_label'))+" ")])]):_vm._e(),(_vm.phoneAvailable)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t(`donation.phone_title${_vm.phoneRequired ? '' : _vm.suffix}`)))]),_c('vue-tel-input',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: _vm.phoneRequired,
        min: _vm.phoneConfig.min_length,
        twilio_lookup: _vm.twilioLookup
      }),expression:"{\n        required: phoneRequired,\n        min: phoneConfig.min_length,\n        twilio_lookup: twilioLookup\n      }"}],staticClass:"form-control",class:{ 'invalid': _vm.vErrors.has('phone')},attrs:{"name":"phone","input-options":{
        placeholder: _vm.$t(`donation.phone_placeholder${_vm.phoneRequired ? '' : _vm.suffix}`),
        showDialCode: true
      },"mode":"international","data-vv-validate-on":"blur","valid-characters-only":"","auto-default-country":false,"default-country":_vm.defaultCountryCode,"ignored-countries":['PS']},on:{"validate":_vm.validate},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),(_vm.vErrorsHas('phone', 'required') || _vm.vErrorsHas('phone', 'decimal'))?_c('span',{staticClass:"validation-error"},[_vm._v(" "+_vm._s(_vm.$t('template.field_is_required'))+" ")]):_vm._e(),(_vm.minErrorMsg)?_c('span',{staticClass:"validation-error"},[_vm._v(" "+_vm._s(_vm.minErrorMsg)+" ")]):_vm._e(),(_vm.twilioLookupErrorMsg)?_c('span',{staticClass:"validation-error"},[_vm._v(" "+_vm._s(_vm.twilioLookupErrorMsg)+" ")]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }