export const countryReference = [
  {
    country_name: 'Argentina',
    country_code: 'AR',
    currency_code: 'ARS',
    doc_name: 'DNI or CUIT',
    doc_format: 'between 7 to 9, or 11 digits',
    required: true,
  },
  {
    country_name: 'Bolivia',
    country_code: 'BO',
    currency_code: 'BOB',
    doc_name: 'CI',
    doc_format: 'between 5 to 20 digits',
    required: true,
  },
  {
    country_name: 'Brazil',
    country_code: 'BR',
    currency_code: 'BRL',
    doc_name: 'CPF or CNPJ',
    doc_format: 'between 11 to 14 digits',
    required: true,
  },
  {
    country_name: 'Cameroon',
    country_code: 'CM',
    currency_code: 'XAF',
    doc_name: '​',
    doc_format: '​',
    required: false,
  },
  {
    country_name: 'Chile',
    country_code: 'CL',
    currency_code: 'CLP',
    doc_name: 'CI/RUT',
    doc_format: 'between 8 to 9 characters',
    required: true,
  },
  {
    country_name: 'China',
    country_code: 'CN',
    currency_code: 'CNY',
    doc_name: '公民身份号码 (citizen ID number)',
    doc_format: 'between 5 to 20 digits',
    required: true,
  },
  {
    country_name: 'Colombia',
    country_code: 'CO',
    currency_code: 'COP',
    doc_name: 'CC',
    doc_format: 'between 6 to 10 digits',
    required: true,
  },
  {
    country_name: 'Costa Rica',
    country_code: 'CR',
    currency_code: 'CRC',
    doc_name: 'CI',
    doc_format: '9 digits',
    required: true,
  },
  {
    country_name: 'Dominican Republic',
    country_code: 'DO',
    currency_code: 'DOP',
    doc_name: 'ID',
    doc_format: '11 digits',
    required: false,
  },
  {
    country_name: 'Ecuador',
    country_code: 'EC',
    currency_code: 'USD',
    doc_name: 'CI',
    doc_format: 'between 5 to 20 digits',
    required: true,
  },
  {
    country_name: 'Egypt',
    country_code: 'EG',
    currency_code: 'EGP',
    doc_name: 'ID',
    doc_format: '14 digits',
    required: true,
  },
  {
    country_name: 'Ghana',
    country_code: 'GH',
    currency_code: 'GHS',
    doc_name: '​',
    doc_format: '​',
    required: false,
  },
  {
    country_name: 'India',
    country_code: 'IN',
    currency_code: 'INR',
    doc_name: 'PAN',
    doc_format: '10 characters (5 letters, 4 numbers, 1 letter)',
    required: true,
  },
  {
    country_name: 'Indonesia',
    country_code: 'ID',
    currency_code: 'IDR',
    doc_name: 'NIK',
    doc_format: '16 digits',
    required: true,
  },
  {
    country_name: 'Kenya',
    country_code: 'KE',
    currency_code: 'KES',
    doc_name: '​',
    doc_format: '​',
    required: false,
  },
  {
    country_name: 'Malaysia',
    country_code: 'MY',
    currency_code: 'MYR',
    doc_name: 'NRIC',
    doc_format: '12 digits',
    required: true,
  },
  {
    country_name: 'Mexico',
    country_code: 'MX',
    currency_code: 'MXN',
    doc_name: 'CURP',
    doc_format: 'between 10 to 18 characters',
    required: true,
  },
  {
    country_name: 'Morocco',
    country_code: 'MA',
    currency_code: 'MAD',
    doc_name: 'CNIE',
    doc_format: 'between 5 to 20 characters',
    required: true,
  },
  {
    country_name: 'Nigeria',
    country_code: 'NG',
    currency_code: 'NGN',
    doc_name: 'NIN',
    doc_format: '11 digits',
    required: true,
  },
  {
    country_name: 'Paraguay',
    country_code: 'PY',
    currency_code: 'PYG',
    doc_name: 'CI',
    doc_format: 'between 5 to 20 digits',
    required: true,
  },
  {
    country_name: 'Peru',
    country_code: 'PE',
    currency_code: 'PEN',
    doc_name: 'DNI',
    doc_format: 'between 8 to 9 digits',
    required: true,
  },
  {
    country_name: 'Philippines',
    country_code: 'PH',
    currency_code: 'PHP',
    doc_name: 'PSN',
    doc_format: '12 digits',
    required: true,
  },
  {
    country_name: 'Senegal',
    country_code: 'SN',
    currency_code: 'XOF',
    doc_name: '​',
    doc_format: '​',
    required: false,
  },
  {
    country_name: 'South Africa',
    country_code: 'ZA',
    currency_code: 'ZAR',
    doc_name: 'ID',
    doc_format: 'between 5 to 20 digits',
    required: true,
  },
  {
    country_name: 'Turkey',
    country_code: 'TR',
    currency_code: 'TRY',
    doc_name: 'T.C. Kimlik No.',
    doc_format: 'between 5 to 20 digits',
    required: true,
  },
  {
    country_name: 'Uruguay',
    country_code: 'UY',
    currency_code: 'UYU',
    doc_name: 'CI',
    doc_format: 'between 6 to 8 digits',
    required: true,
  },
  {
    country_name: 'Vietnam',
    country_code: 'VN',
    currency_code: 'VND',
    doc_name: 'VNID',
    doc_format: '9 or 12 digits',
    required: true,
  },
]

export default { countryReference }
