import { currenciesMap } from '@/constants/currencies';

import { IDonationLevel, IpLocationDefaultCurrency } from '@/models/IDonationParams';

export const extractLevelAmountByLocationDefaultCurrency = (
  level: IDonationLevel,
  defaultCurrencyByIp: IpLocationDefaultCurrency | undefined = undefined,
) => {
  const { amount, currency, meta } = level
  let amt = amount;
  let currSign = '';
  let currCode = currency;

  const hasMetaAmount = !!Object.keys(meta?.amount || {}).length
  const ccode = defaultCurrencyByIp?.code?.toLowerCase();

  if (hasMetaAmount && ccode && meta.amount[ccode]) {
    currSign = currenciesMap[ccode]?.sign
    currCode = currenciesMap[ccode]?.code || currency
    amt = meta.amount[ccode];
  }

  return {
    levelAmount: amt,
    levelCurrencySign: currSign,
    levelCurrencyCode: currCode,
  }
}

export const isLevelAvailable = (level: IDonationLevel): boolean => (level.limited > 0 ? level.available !== 0 : true)

export default { extractLevelAmountByLocationDefaultCurrency }
