import { render, staticRenderFns } from "./Success.vue?vue&type=template&id=2a80a6a1&scoped=true&"
import script from "./Success.vue?vue&type=script&lang=ts&"
export * from "./Success.vue?vue&type=script&lang=ts&"
import style0 from "./Success.vue?vue&type=style&index=0&id=2a80a6a1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a80a6a1",
  null
  
)

export default component.exports