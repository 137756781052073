
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import axios from 'axios';
import urls from '@/constants/urls';
import { IRootState } from '../../models/IRootState';

import BelzWidget from './BelzWidget.vue';
import VideoShareWidget from './VideoShareWidget.vue';

const VueComponent = Vue.extend({
  components: {
    BelzWidget,
    VideoShareWidget,
  },

  data() {
    return {
      loading: false,
      success: false,
    };
  },

  computed: {
    ...customMapState({
      dId: (state: IRootState) => state.donation.donationId,
      cId: (state: IRootState) => state.donation.campaign.data.id,
      donationAttrs: (state: IRootState) => state.donation.donationData.attributes,
      locate: (state: IRootState) => state.translation.locate,
      actionAfter: (state: IRootState) => state.donation.donationParams.data.attributes.config.action_after_donation,
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
    }),

    getData(): string | null {
      return this.donationAttrs.get_data;
    },

    show(): boolean {
      if (this.actionAfter) {
        if (this.actionAfter.value && this.actionAfter.action === 'belz_doc_signing') {
          return Boolean(Number(this.getData))
            && !(this.actionAfter.hide_for_gateway?.includes(this.paymentMethod) || false)
        }
        return this.actionAfter.value
      }

      return false;
    },

    action(): string {
      if (this.show) {
        return this.actionAfter.action
      }

      return '';
    },

    tkFallback() {
      if (this.action === 'belz_doc_signing') {
        return {
          title: 'signing the contract',
          subtitle: 'signing the contract',
          button: '',
        }
      }
      if (this.action === 'share_video') {
        return {
          title: 'Leave a little video/image',
          subtitle: 'which will be displayed on the campaign page',
          button: '',
        }
      }
      if (this.action === 'donation_track') {
        return {
          title: '',
          subtitle: '',
          button: 'Track',
          success_msg: 'Thank you!',
        }
      }
      return {
        title: '',
        subtitle: '',
        button: '',
        success_msg: '',
      }
    },

    link(): string {
      const params = {
        campaign_id: this.cId,
        locale: this.locate,
        donation_id: this.dId,
        team_id: this.donationAttrs.team_id,
      };

      const paramsString = Object.entries(params)
        .filter(([, v]) => v)
        .map(([k, v]) => `${k}=${v}`)
        .join('&');

      return `https://view.charidy.com/tickets_module/?${paramsString}`;
    },
  },

  methods: {
    dispatchAction() {
      const inIframe = window.self !== window.parent;

      if (this.action === 'donation_track') {
        this.loading = true;

        const url = urls.donationTrack
          .replace(':cid', this.cId.toString())
          .replace(':did', this.dId.toString())

        axios.get(url, { params: this.actionAfter.action_params || {} })
          .then(() => {
            this.success = true
          })
          .catch(({ response }) => {
            const [error] = response.data?.errors || []

            this.$store.commit('setError', {
              title: 'Donation track error!',
              translationKey: [error?.meta?.error_translation_key, error?.detail],
            });
          })
          .finally(() => {
            this.loading = false;
          })
        return;
      }

      if (this.$isMobile() || !inIframe) {
        if (this.action === 'ticket_free_price') {
          window.location.href = this.link;
        }
        return;
      }

      const payload = {
        link: this.link,
        donation_id: this.dId,
        donation_data: this.donationAttrs,
      };

      window.parent.postMessage(
        {
          type: 'action-after-donation',
          action: this.action,
          payload,
        },
        '*',
      );
    },
  },
});
export default class ActionAfterDonation extends VueComponent {}
