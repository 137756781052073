
import { getTeamId } from '@/helpers/getUrlParameters';
import customMapState from '@/helpers/mapHelper';
import { ICustomGoalConfig, ICustomGoalsTeam } from '@/models/IDonationParams';
import { IRootState } from '@/models/IRootState';
import { defineComponent } from 'vue';

const IntlNumberFormatWithCurrency = (currency: string) => new Intl.NumberFormat('en', {
  style: 'currency',
  currency,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default defineComponent({
  computed: {
    ...customMapState({
      customGoals: (state: IRootState) => state.donation.donationParams.data.attributes.config?.custom_goals,
      originAmount: (state: IRootState) => state.donation.originAmount,
      donationCurrencyCode: (state: IRootState) => state.donation.donationData.attributes.currency,
    }),
    teamIdOnUrl(): string {
      return getTeamId();
    },
    amountForCustomGoals(): number {
      if (this.customGoals.length === 0) return this.originAmount

      return this.originAmount / this.customGoalsForTeamId.length;
    },
    customGoalsForTeamId(): ICustomGoalConfig[] {
      const teamGoal = this.customGoals.filter((goal: ICustomGoalsTeam) => {
        if (Number.isNaN(Number(this.teamIdOnUrl))) return false;

        return goal.team_id === Number(this.teamIdOnUrl);
      });

      if (teamGoal.length === 0) return [];

      return teamGoal[0]?.goals_config || [];
    },
  },
  methods: {
    intlNumberFormat(data: number) {
      const format = (number: number) => IntlNumberFormatWithCurrency(this.donationCurrencyCode).format(number);
      return format(data);
    },
  },
})
