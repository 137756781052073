
import customMapState from '@/helpers/mapHelper';
import { peerMatchThresholdsValidation } from '@/helpers/peerMatchThresholds';
import { IRootState } from '@/models/IRootState';
import axios from 'axios';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import urls from '@/constants/urls';
import { IXchangeResponse } from './subcomponents-models/DonationAmount-models';

const VueComponent = Vue.extend({
  data() {
    return {
      internalValue: false,
      internalLockedType: '',
      internalValueInt: undefined,

      minAmountThresholdChangeRate: 0,
      maxAmountThresholdChangeRate: 0,
      thresholdChangeRateLoading: false,
    }
  },
  computed: {
    ...customMapState({
      donateAnonymously: (state: IRootState) => state.donation.donateAnonymouslyFlag,
      allowConditionalDonations: (state: IRootState) => state.donation.donationParams.data?.attributes.config.allow_conditional_donations,
      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),
      campaignCurrency: (state: IRootState) => ({
        code: state.donation.campaign.data.attributes.currency,
        sign: state.donation.campaign.data.attributes.currency_sign,
      }),
    }),
    ...mapGetters({
      originAmount: 'originAmount',
    }),

    minAmountThreshold(): number {
      return this.minAmountThresholdChangeRate || this.allowConditionalDonations?.min_amount_threshold;
    },
    maxAmountThreshold(): number {
      return this.maxAmountThresholdChangeRate || this.allowConditionalDonations?.max_amount_threshold;
    },

    hasThresholdsError(): string {
      const validation = peerMatchThresholdsValidation(
        {
          minAmountThreshold: this.minAmountThreshold,
          maxAmountThreshold: this.maxAmountThreshold,
          donationCurrency: this.donationCurrency.code,
        },
        this.originAmount,
      );

      if (validation.valid) {
        return '';
      }

      return validation.data.message;
    },

    show(): boolean {
      if (this.donateAnonymously) {
        return false;
      }

      return true;
    },
  },
  watch: {
    hasThresholdsError() {
      this.onLockedChange('', false);
    },
    donateAnonymously(v) {
      if (v) {
        this.onLockedChange('', false);
      }
    },
    donationCurrency() {
      this.getThresholdExchangeRate();
    },
  },
  mounted() {
    this.getThresholdExchangeRate();
  },
  methods: {
    resetLocked() {
      this.internalValue = false;
      this.internalLockedType = '';

      this.$store.commit('setLocked', false);
      this.$store.commit('setLockedType', '');
      this.$store.commit('deleteLockedVarInt');
    },
    onLockedChange(type: string, value: boolean) {
      this.resetLocked();

      switch (type) {
        case 'team_reach_x':
          this.internalValue = value;
          this.internalLockedType = value ? 'team_reach_x' : '';

          this.$store.commit('setLocked', this.internalValue);
          this.$store.commit('setLockedType', this.internalLockedType);
          break;

        case 'peer_match':
          this.internalValue = value;
          this.internalLockedType = value ? 'peer_match' : '';
          this.internalValueInt = undefined;

          this.$store.commit('setLocked', this.internalValue);
          this.$store.commit('setLockedType', this.internalLockedType);
          this.$store.commit('deleteLockedVarInt');
          break;

        default:
          this.resetLocked();
          break;
      }
    },
    onLockedVarIntInput(event: Event) {
      const { value } = event.target as HTMLInputElement;
      const number = Number(value);
      if (Number.isNaN(number)) {
        return
      }

      (this.internalValueInt as unknown as number) = number;
      this.$store.commit('setLockedVarInt', number);
    },
    async getThresholdExchangeRate() {
      this.thresholdChangeRateLoading = true;

      const thresholdCurr = this.campaignCurrency.code.toLowerCase();
      const donCurr = this.donationCurrency.code.toLowerCase();
      const minAmountThreshold = this.allowConditionalDonations?.min_amount_threshold;
      const maxAmountThreshold = this.allowConditionalDonations?.max_amount_threshold;

      if (donCurr === thresholdCurr) {
        this.thresholdChangeRateLoading = false;
        return
      }

      try {
        if (minAmountThreshold) {
          const { data: { data } } = await axios.get<IXchangeResponse>(urls.getExchange, {
            params: {
              amount: minAmountThreshold,
              from: thresholdCurr,
              to: donCurr,
            },
          });
          const { attributes: attr } = data;
          this.minAmountThresholdChangeRate = attr.amount_out
        }

        if (maxAmountThreshold) {
          const { data: { data } } = await axios.get<IXchangeResponse>(urls.getExchange, {
            params: {
              amount: maxAmountThreshold,
              from: thresholdCurr,
              to: donCurr,
            },
          });
          const { attributes: attr } = data;
          this.maxAmountThresholdChangeRate = attr.amount_out
        }
      } finally {
        this.thresholdChangeRateLoading = false;
      }
    },
  },
});

export default class AllowConditionalDonations extends VueComponent {}
