export const stateList = [
  {
    state_id: 1,
    country_id: 223,
    code: 'AL',
    name: 'Alabama',
  },
  {
    state_id: 2,
    country_id: 223,
    code: 'AK',
    name: 'Alaska',
  },
  {
    state_id: 3,
    country_id: 223,
    code: 'AZ',
    name: 'Arizona',
  },
  {
    state_id: 4,
    country_id: 223,
    code: 'AR',
    name: 'Arkansas',
  },
  {
    state_id: 5,
    country_id: 223,
    code: 'CA',
    name: 'California',
  },
  {
    state_id: 6,
    country_id: 223,
    code: 'CO',
    name: 'Colorado',
  },
  {
    state_id: 7,
    country_id: 223,
    code: 'CT',
    name: 'Connecticut',
  },
  {
    state_id: 8,
    country_id: 223,
    code: 'DC',
    name: 'District of Columbia',
  },
  {
    state_id: 9,
    country_id: 223,
    code: 'DE',
    name: 'Delaware',
  },
  {
    state_id: 10,
    country_id: 223,
    code: 'FL',
    name: 'Florida',
  },
  {
    state_id: 11,
    country_id: 223,
    code: 'GA',
    name: 'Georgia',
  },
  {
    state_id: 12,
    country_id: 223,
    code: 'HI',
    name: 'Hawaii',
  },
  {
    state_id: 13,
    country_id: 223,
    code: 'ID',
    name: 'Idaho',
  },
  {
    state_id: 14,
    country_id: 223,
    code: 'IL',
    name: 'Illinois',
  },
  {
    state_id: 15,
    country_id: 223,
    code: 'IN',
    name: 'Indiana',
  },
  {
    state_id: 16,
    country_id: 223,
    code: 'IA',
    name: 'Iowa',
  },
  {
    state_id: 17,
    country_id: 223,
    code: 'KS',
    name: 'Kansas',
  },
  {
    state_id: 18,
    country_id: 223,
    code: 'KY',
    name: 'Kentucky',
  },
  {
    state_id: 19,
    country_id: 223,
    code: 'LA',
    name: 'Louisiana',
  },
  {
    state_id: 20,
    country_id: 223,
    code: 'ME',
    name: 'Maine',
  },
  {
    state_id: 21,
    country_id: 223,
    code: 'MD',
    name: 'Maryland',
  },
  {
    state_id: 22,
    country_id: 223,
    code: 'MA',
    name: 'Massachusetts',
  },
  {
    state_id: 23,
    country_id: 223,
    code: 'MI',
    name: 'Michigan',
  },
  {
    state_id: 24,
    country_id: 223,
    code: 'MN',
    name: 'Minnesota',
  },
  {
    state_id: 25,
    country_id: 223,
    code: 'MS',
    name: 'Mississippi',
  },
  {
    state_id: 26,
    country_id: 223,
    code: 'MO',
    name: 'Missouri',
  },
  {
    state_id: 27,
    country_id: 223,
    code: 'MT',
    name: 'Montana',
  },
  {
    state_id: 28,
    country_id: 223,
    code: 'NE',
    name: 'Nebraska',
  },
  {
    state_id: 29,
    country_id: 223,
    code: 'NV',
    name: 'Nevada',
  },
  {
    state_id: 30,
    country_id: 223,
    code: 'NH',
    name: 'New Hampshire',
  },
  {
    state_id: 31,
    country_id: 223,
    code: 'NJ',
    name: 'New Jersey',
  },
  {
    state_id: 32,
    country_id: 223,
    code: 'NM',
    name: 'New Mexico',
  },
  {
    state_id: 33,
    country_id: 223,
    code: 'NY',
    name: 'New York',
  },
  {
    state_id: 34,
    country_id: 223,
    code: 'NC',
    name: 'North Carolina',
  },
  {
    state_id: 35,
    country_id: 223,
    code: 'ND',
    name: 'North Dakota',
  },
  {
    state_id: 36,
    country_id: 223,
    code: 'OH',
    name: 'Ohio',
  },
  {
    state_id: 37,
    country_id: 223,
    code: 'OK',
    name: 'Oklahoma',
  },
  {
    state_id: 38,
    country_id: 223,
    code: 'OR',
    name: 'Oregon',
  },
  {
    state_id: 39,
    country_id: 223,
    code: 'PA',
    name: 'Pennsylvania',
  },
  {
    state_id: 40,
    country_id: 223,
    code: 'PR',
    name: 'Puerto Rico',
  },
  {
    state_id: 41,
    country_id: 223,
    code: 'RI',
    name: 'Rhode Island',
  },
  {
    state_id: 42,
    country_id: 223,
    code: 'SC',
    name: 'South Carolina',
  },
  {
    state_id: 43,
    country_id: 223,
    code: 'SD',
    name: 'South Dakota',
  },
  {
    state_id: 44,
    country_id: 223,
    code: 'TN',
    name: 'Tennessee',
  },
  {
    state_id: 45,
    country_id: 223,
    code: 'TX',
    name: 'Texas',
  },
  {
    state_id: 46,
    country_id: 223,
    code: 'UT',
    name: 'Utah',
  },
  {
    state_id: 47,
    country_id: 223,
    code: 'VT',
    name: 'Vermont',
  },
  {
    state_id: 48,
    country_id: 223,
    code: 'VA',
    name: 'Virginia',
  },
  {
    state_id: 49,
    country_id: 223,
    code: 'WA',
    name: 'Washington',
  },
  {
    state_id: 50,
    country_id: 223,
    code: 'WV',
    name: 'West Virginia',
  },
  {
    state_id: 51,
    country_id: 223,
    code: 'WI',
    name: 'Wisconsin',
  },
  {
    state_id: 52,
    country_id: 223,
    code: 'WY',
    name: 'Wyoming',
  },
  {
    state_id: 53,
    country_id: 38,
    code: 'Alberta',
    name: 'Alberta',
  },
  {
    state_id: 54,
    country_id: 38,
    code: 'British Columbia',
    name: 'British Columbia',
  },
  {
    state_id: 55,
    country_id: 38,
    code: 'Manitoba',
    name: 'Manitoba',
  },
  {
    state_id: 56,
    country_id: 38,
    code: 'New Brunswick',
    name: 'New Brunswick',
  },
  {
    state_id: 57,
    country_id: 38,
    code: 'Newfoundland',
    name: 'Newfoundland',
  },
  {
    state_id: 58,
    country_id: 38,
    code: 'Northwest Territoties',
    name: 'Northwest Territoties',
  },
  {
    state_id: 59,
    country_id: 38,
    code: 'Novs Scotia',
    name: 'Novs Scotia',
  },
  {
    state_id: 60,
    country_id: 38,
    code: 'Ontario',
    name: 'Ontario',
  },
  {
    state_id: 61,
    country_id: 38,
    code: 'Prince Edward Island',
    name: 'Prince Edward Island',
  },
  {
    state_id: 62,
    country_id: 38,
    code: 'Quebec',
    name: 'Quebec',
  },
  {
    state_id: 63,
    country_id: 38,
    code: 'Saskatchewan',
    name: 'Saskatchewan',
  },
  {
    state_id: 64,
    country_id: 38,
    code: 'Yukon',
    name: 'Yukon',
  },
]

export default { stateList }
