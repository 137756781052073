
import Vue from 'vue';
import customMapState from '@/helpers/mapHelper';
import { IRootState } from '@/models/IRootState';
import { useExternalScript } from '@/helpers/useExternalScript';

const klarnaScript = useExternalScript('https://x.klarnacdn.net/kp/lib/v1/api.js')

const VueComponent = Vue.extend({
  data() {
    return {
      stripe: {} as any,
      source: {} as any,
    }
  },

  computed: {
    ...customMapState({
      key: (state: IRootState) => state.donation.paymentConfig.attributes.configuration.pk_live,
      sourceData: (state: IRootState) => {
        const { attributes } = state.donation.donationData;
        return {
          type: 'klarna',
          amount: attributes.amount,
          currency: attributes.currency,
          klarna: {
            product: 'payment',
            purchase_country: attributes.address_country,
          },
        };
      },
    }),
  },

  watch: {
    sourceData() {
      this.getSource();
    },
  },

  mounted() {
    klarnaScript?.onLoad(() => {
      this.stripe = Stripe(this.key);
    })

    klarnaScript?.load()
  },

  methods: {
    async getSource() {
      const { source, error } = await this.stripe.createSource(this.sourceData);

      if (!error) {
        this.source = source;
      }

      // eslint-disable-next-line no-console
      console.log(error);
    },
  },
});

export default class KlarnaCheckout extends VueComponent {}
