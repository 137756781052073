
import Vue from 'vue'
import { mapGetters } from 'vuex';
import { load, ReCaptchaInstance } from 'recaptcha-v3'

import customMapState from '@/helpers/mapHelper';
import { useReCaptchaV3 } from '@/compositions/captcha';
import { IRootState } from '@/models/IRootState';
import { pmListIFrame } from '@/constants/paymentMethodsInIFrame';
import VueRecaptcha from 'vue-recaptcha';
import { IncludePaymentSystemFeeConfig, RecurringConfig } from '@/models/IDonationParams';
import { AvailableDisplayCurrencyList } from '@/store/modules/models/donation';
import { feeInstallmentFor } from '@/constants';
import CardknoxGoogleApplePayButton from './cardknox/CardknoxGoogleApplePayButton.vue';
import ConfirmationDonationInfo from './ConfirmationDonationInfo.vue';

const { setInstance, setKey } = useReCaptchaV3()

const VueComponent = Vue.extend({
  components: {
    VueRecaptcha,
    CardknoxGoogleApplePayButton,
    ConfirmationDonationInfo,
  },

  data() {
    return {
      termsFlag: false,
      termsFlagError: false,
      reCaptchav3: null as (ReCaptchaInstance | null),
    };
  },

  computed: {
    ...customMapState({
      donationParamsAttrs: (state: IRootState) => state.donation.donationParams.data.attributes,
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      paymentMethod: (state: IRootState) => state.donation.paymentMethod,
      isSubmitted: (state: IRootState) => state.donation.isSubmitted,
      paymentMethodVersion: (s: IRootState) => s.donation.paymentConfig.attributes.configuration.version,
      captchaToken: (state: IRootState) => state.donation.donationData.attributes.captcha_token,
      donationData: (state: IRootState) => state.donation.donationData.attributes,
      donationCurrency: (state: IRootState) => ({
        code: state.donation.donationData.attributes.currency,
        sign: state.donation.donationData.attributes.currency_sign,
      }),
      donationAmount: (state: IRootState) => state.donation.donationData.attributes.amount,
      installment: (state: IRootState) => state.donation.donationData.attributes.installment,
      recurring: (state: IRootState) => state.donation.donationData.attributes.recurring,
      installmentPeriod: (state: IRootState) => state.donation.donationData
        .attributes.installment_period,
      paymentFeeStatus: (state: IRootState) => state.donation.donationData.attributes
        .include_payment_system_fee,
      tipAmount: (state: IRootState) => state.donation.donationData.attributes.tip_amount / 100,
      multiplier: (state: IRootState) => state.donation.donationParams.data
        .attributes.current_multiplier,
      totalAmount: (state: IRootState) => state.donation.totalAmount,
      recurringPeriod: (state: IRootState) => state.donation.donationData.attributes
        .recurring_period,
      recurringInterval: (state: IRootState) => state.donation.donationData.attributes.recurring_interval,
    }),

    ...mapGetters({
      isPaymentRequestBtn: 'isPaymentRequestBtn',
      multiplierOnDay: 'multiplierOnDay',
      orgName: 'orgNameByLang',
      showAmountInLocCurrency: 'showAmountInLocCurrency',
    }),

    campaignCurrency(): AvailableDisplayCurrencyList {
      const state = this.$store.state as IRootState;
      if (this.showAmountInLocCurrency) {
        return this.showAmountInLocCurrency;
      }
      return {
        sign: state.donation.campaign.data.attributes.currency_sign,
        code: state.donation.campaign.data.attributes.currency,
      }
    },

    receivesAmount(): string {
      const amt = Math.floor(
        (this.totalAmount
          * ((this.recurringPeriod * this.multiplierOnDay) || 1) * (this.multiplier || 1)
        ),
      );
      return `${this.campaignCurrency.sign}${amt.toLocaleString()}`
    },

    feeConfig(): IncludePaymentSystemFeeConfig {
      return this.config.include_payment_system_fee_config;
    },

    recurringConfig(): RecurringConfig {
      return this.config.recurring_config;
    },

    dontionAmountString(): string {
      const { currency_sign, currency, amount } = this.donationData
      return `${currency_sign}${(amount / 100).toLocaleString()} ${currency.toUpperCase()}`;
    },

    monthlyAmountNum(): number {
      let amt = this.donationAmount / 100;
      if (this.recurring) {
        amt *= this.multiplierOnDay
      }
      if (this.installment) {
        amt /= this.installmentPeriod
      }
      return amt;
    },

    monthlyAmount(): string {
      return `${this.donationCurrency.sign}${this.monthlyAmountNum.toFixed(2)}`;
    },

    feeAmountFmt(): string {
      return `${this.donationCurrency.sign}${this.feeAmount}`;
    },

    recurringTotal(): string {
      let donationAmt = this.donationAmount / 100

      if (this.paymentFeeStatus) {
        donationAmt += this.feeAmount;
      }

      return `${this.donationCurrency.sign}${this.recurringPeriod * donationAmt * this.multiplierOnDay}`
    },

    feeAmount(): number {
      const { feeConfig, paymentMethod, donationAmount } = this;

      if (!feeConfig.fee_rate) {
        return 0;
      }

      const config = feeConfig.fee_rate[paymentMethod];

      if (!config) {
        return 0
      }

      const amount = Math.ceil(
        config.fixed + ((config.percentage * (donationAmount / 100)) / 100),
      )

      if (config.limit && config.limit < amount) {
        return config.limit
      }

      if (feeConfig.limit && feeConfig.limit < amount) {
        return feeConfig.limit
      }

      return amount;
    },

    extra(): number {
      let amt = 0
      if (this.paymentFeeStatus) {
        amt += this.feeAmount;
      }
      if (this.tipAmount > 0) {
        amt += this.tipAmount;
      }

      if (this.installment) {
        if (feeInstallmentFor.includes(this.paymentMethod)) {
          amt /= this.installmentPeriod
        }
      }
      return amt
    },

    monthlyAmountWithExtra(): number {
      return this.monthlyAmountNum + this.extra;
    },

    monthlyAmountWithExtraFmt(): string {
      return `${this.donationCurrency.sign}${this.monthlyAmountWithExtra.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },

    captcha(): boolean {
      return this.donationParamsAttrs.captcha;
    },

    captchaSiteKey(): string {
      return this.donationParamsAttrs.captcha_sitekey;
    },

    captchaSiteV3(): boolean {
      return this.donationParamsAttrs.captcha_site_v3;
    },

    paymentMethodV2(): boolean {
      return this.paymentMethodVersion === '2';
    },

    checkedForGateways(): string[] {
      return this.config.term_checkbox_config?.checked_for_gateways || [];
    },

    confirmationWithoutCheckbox(): boolean {
      if (this.paymentMethod === 'stripe-terminal') {
        return true;
      }

      return this.config.confirmation_without_checkbox
        && !['stripe-google-pay', 'stripe-apple-pay'].includes(this.paymentMethod);
    },

    hasAdditionalFields(): boolean {
      return this.config.custom_data.length > 0;
    },

    donateBtnDisabled(): boolean {
      const {
        termsFlag,
        isSubmitted,
        captchaSiteV3,
        captchaToken,
        captcha,
      } = this;

      return !termsFlag
        || isSubmitted
        || (!captchaSiteV3 && !captchaToken && captcha)
    },

    btnText(): string {
      const pmList = pmListIFrame;

      return (
        pmList.includes(this.paymentMethod)
          ? this.$t('template.continue_btn')
          : this.$t('template.pre_donations_button')
      ) as string;
    },
  },

  watch: {
    captchaToken(value) {
      if (value === '' && !this.captchaSiteV3) {
        (this.$refs.captcha as any).reset();
      }
    },
  },

  created() {
    if (this.captchaSiteV3) {
      this.reCaptchav3Load()
      setKey(this.captchaSiteKey)
    }

    this.$watch(
      'paymentMethod',
      (name: string): void => {
        this.termsFlag = this.checkedForGateways.includes(name) || this.confirmationWithoutCheckbox;
      },
      { immediate: true },
    )
    this.$watch(
      'termsFlag',
      (value: boolean): void => {
        if (value && this.isPaymentRequestBtn) {
          this.donate();
        }
      },
    )

    if (this.confirmationWithoutCheckbox) {
      this.termsFlag = true;
    }
  },

  methods: {
    async reCaptchav3Load(): Promise<[ReCaptchaInstance | null, unknown | null]> {
      if (this.reCaptchav3 == null) {
        try {
          this.reCaptchav3 = await load(this.captchaSiteKey);
        } catch (error) {
          return [null, error]
        }
      }
      setInstance(this.reCaptchav3)
      return [this.reCaptchav3, null]
    },

    fallback(key: string): string {
      const tcLink = '<a href="https://www.charidy.com/terms" target="_blank">Terms &amp; Conditions</a>'
      const ppLink = '<a href="https://www.charidy.com/terms#privacy" target="_blank">Privacy Policy</a>'

      switch (key) {
        case 'confirmation_notification_not_checkbox':
          return `By clicking “DONATE”, you confirm that you accept our ${tcLink} and acknowledge our ${ppLink}.`

        default:
          return ''
      }
    },

    donate(): void {
      const validationArray: Promise<boolean>[] = this.$parent?.$children?.map((c: any) => c.$validator.validateAll()) || [];

      Promise.all(validationArray)
        .then(async formsList => {
          if (formsList.indexOf(false) === -1) {
            if (this.hasAdditionalFields) {
              const isReady = new Promise<boolean>(resolve => {
                this.$root.$emit('extraInfoSetData', resolve)
              })
              await isReady
            }

            // emit root event for set donation included gateway params
            const error = await new Promise(
              resolve => {
                this.$root.$emit('setDonationGatewayParams', resolve)

                const skip = ['dlocal'];
                if (!skip.includes(this.paymentMethod)) {
                  resolve(false);
                }
              },
            );

            if (error) throw error;

            this.confirmDonation();
          } else {
            const vFieldErrors = this.vErrors.items.reduce(
              (res, el) => {
                if (!res.fields.includes(el.field)) {
                  res.fields.push(el.field)
                }

                return res;
              },
              { fields: [] as string[] },
            );

            this.$store.dispatch('addError', {
              title: `${this.$t('donation.submit_form_error_title')}`,
              text: `${this.$t('donation.check_fields_error')} ${
                vFieldErrors.fields.map(
                  (f: string) => ` ${this.$t(`donation.field_${f}`)}`,
                )
              }`,
            });
          }
          return true;
        })
        .catch(err => {
          this.$store.dispatch('addError', {
            title: err.title || `${this.$t('donation.submit_form_error_title')}`,
            text: err.text || err,
          });
        });
    },

    verify(captchaToken: string): void {
      this.$store.commit('setCaptchaToken', captchaToken);
    },

    confirmDonation() {
      if (this.isSubmitted) {
        return;
      }

      const makeDonationWithTokenGeneration = async (tokenGenerationFuncName: string) => {
        const promis = new Promise(resolve => this.$root.$emit(tokenGenerationFuncName, resolve))
        const res = await promis;
        if (res) {
          this.$store.dispatch('makeDonation');
        } else {
          this.$store.commit('setIsSubmitted', false);
        }
      }

      if (this.termsFlag) {
        this.$store.dispatch('setIsSubmitted', true).then(() => {
          this.$nextTick(async () => {
            if (this.captchaSiteV3) {
              const [, err] = await this.reCaptchav3Load()
              if (err && !this.reCaptchav3) {
                this.$store.commit('setError', {
                  title: 'Captcha',
                  translationKey: [
                    'donation.captcha_load_faild',
                    'Failed to load captcha script. Try again',
                  ],
                });
                return;
              }

              if (this.reCaptchav3) {
                let token = await this.reCaptchav3.execute('e_commerce');
                if (token === '') {
                  token = await this.reCaptchav3.execute('e_commerce');
                }
                if (token === '') {
                  token = await this.reCaptchav3.execute('e_commerce');
                }
                if (token === '') {
                  this.$store.commit('setIsSubmitted', false);
                  this.$store.commit('setError', {
                    title: 'Captcha',
                    translationKey: [
                      'donation.captcha_token_empty',
                      'Failed to generate captcha token. Try again',
                    ],
                  });
                  return;
                }
                this.verify(token);
              }
            }

            if (this.paymentMethod === 'stripe-ach') {
              makeDonationWithTokenGeneration('openPlaidLink')
              return;
            }

            if (this.paymentMethod === 'stripe') {
              makeDonationWithTokenGeneration('stripeCreateToken')
              return;
            }

            if (this.paymentMethod === 'cardknox') {
              makeDonationWithTokenGeneration('cardknoxCreateAndSetToken')
              return;
            }

            if (this.paymentMethod === 'banquest') {
              makeDonationWithTokenGeneration('banquestCreateAndSetToken')
              return;
            }

            if (this.paymentMethod === 'dlocal' && this.paymentMethodV2) {
              makeDonationWithTokenGeneration('dlocalCreateAndSetToken')
              return;
            }

            if (this.paymentMethod === 'walletdoc-direct') {
              makeDonationWithTokenGeneration('walletDocCreateAndSetToken')
              return;
            }

            if (this.paymentMethod === 'authorize') {
              makeDonationWithTokenGeneration('authorizeCreateAndSetToken')
              return;
            }

            if (this.paymentMethod === 'payarc') {
              makeDonationWithTokenGeneration('payarcCreateAndSetToken')
              return;
            }

            if (this.paymentMethod === 'mercadopago-direct') {
              this.$store.commit('setDialogTitle', 'Mercadopago');
              this.$store.commit('setDialogVisibleStatus', true);
              this.$store.commit('setIsSubmitted', false);
              return;
            }

            this.$store.dispatch('makeDonation');
          })
        })
      }
    },
  },
})
export default class ConfirmationStep1 extends VueComponent {}
